(function () {

    'use strict';

    angular
        .module('app')
        .controller('MessageCreateController', MessageCreateController)
        .config(function ($animateProvider) {
            $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
        });

    function MessageCreateController($scope, $http, $state, messageService, $stateParams, $rootScope, $timeout, langService, textAngularManager, $window) {
        if (textAngularManager.retrieveEditor('message')) {
            textAngularManager.unregisterEditor('message'); //https://payquad.atlassian.net/browse/PD-2175
        }
        $scope.t = langService.t('messages');
        $scope.isTicketingEnabled = false;
        $scope.displayCategories = 'false';

        $scope.isMobileSize = $window.innerWidth <= 768;
        angular.element($window).bind('resize', function() {
            $scope.$apply(function() {
                $scope.isMobileSize = $window.innerWidth <= 768
            });
        });
        console.log('isMobileSize', $scope.isMobileSize);

        $http.get('/api/v1/messages/ticketing-enabled').success(data => $scope.isTicketingEnabled = data.data['ticketing-enabled']);

        if ($rootScope.authUser.permissions !== 'tenant') {
            $rootScope.translations.messages.messages = $rootScope.translations.messages.communication;
        }

        $timeout(() => {
            if($rootScope.authUser.permissions === 'tenant'){
                $('button[name="variables"]').remove();
            }
        }, 0);

        $scope.messagesCategories = [];
        $http.get('/api/v1/messages/categories').success(function (data) {
            if (!data) {
                toastr.error('Error retrieving Message Categories');
                return;
            }
            $scope.messagesCategories = data.data;
            $scope.messageData.category = $scope.messagesCategories[0];
            $scope.renderTypeSelector();
        });

        $scope.renderTypeSelector = () => {
            $timeout(() => {
                $('#ticketCategorySelector').multipleSelect({
                    openOnHover: true,
                    width: '400px',
                    dropWidth: '400px',
                    single: true,
                    textTemplate: function ($el) {
                        return $el.html().split('|')[1]
                            ? "\t" + $el.html().split('|')[0] + "\t" +'<sup class=""><i class="fa fa-asterisk fa-xs" data-text="' + $el.html().split('|')[1] + '"></i></sup>'
                            : "\t" + $el.html().split('|')[0];
                    },
                    labelTemplate: function ($elm) {
                        console.log($elm)
                        return 22222221;
                    },
                });

                $('.multiselect-container li .fa').not('.filter, .group').tooltip({
                    placement: 'right',
                    container: 'body',
                    title: function () {
                        return $(this).data().text;
                    }
                });
            }, 100);
        }

        //object with error messages
        var Errors = {
            'send_to': 'Please add tag for message',
            'subject': 'Please enter subject for message',
            'message': 'Please enter message'
        };
        const vm = this;
        $scope.tags = [];
        $scope.attachments = [];
        //urls to auto complete templates
        $scope.tagUrl = 'scripts/modules/message/create/message.tag-input.html';
        $scope.autocompleteUrl = 'scripts/modules/message/create/message.tag-autocomplete.html';

        $scope.scheduledDate = '';
        $scope.scheduled_dates = [''];
        $scope.openScheduled = () => {
            console.log('modal');
            $('#scheduled-modal').modal('show');
            $('.modal-backdrop').hide();
        };
        $timeout(function () {
            $scope.initScheduledPickers();
        });

        $scope.initScheduledPickers = function(){
            $scope.scheduled_dates.forEach((scheduled_date, key) => {
                $('#scheduledDate' + key).daterangepicker({
                        'startDate': Date.now(),
                        'singleDatePicker': true,
                        'autoUpdateInput': false,
                        'timePicker': true,
                        'drops': key > 3 ? 'up' : 'down',
                        'autoApply': true,
                        'timePickerIncrement': 15,
                        locale: {
                            format: 'YYYY-MM-DD hh:mm A'
                        }
                    },
                    function (start, end, label) {
                        if ($scope.scheduled_dates[key] != start.format('YYYY-MM-DD HH:mm:ss')) {
                            $scope.scheduled_dates[key] = start.format('YYYY-MM-DD HH:mm:ss');
                            $('#scheduledDate' + key).val(start.format('YYYY-MM-DD hh:mm A'));
                        }
                    }
                );
            });
        }

        $scope.toggleDatePicker = function(key) {
            $('#scheduledDate' + key).data('daterangepicker').toggle();
        }

        $scope.addDate = function() {
            $scope.scheduled_dates.push('');
            $timeout(function () {
                $scope.initScheduledPickers();
            });
        }

        $scope.removeDate = function(key) {
            $scope.scheduled_dates.splice(key, 1);
            $scope.scheduled_dates.forEach((scheduled_date, key) => {
                $('#scheduledDate' + key).val(scheduled_date);
            });
            console.log($scope.scheduled_dates);
        }

        $scope.handleKeypress = function(e) {
            if(e.keyCode === 13) {
                e.preventDefault();
                var messageArea = angular.element("#message-textarea .placeholder-text");
                messageArea.focus();
            }
        }

        $scope.loadTemplates = function () {
            if ($rootScope.authUser.pro_messaging && $rootScope.authUser.permissions != 'tenant') {
                $http.post('/api/v1/message-template/get-message-templates').success(function (data) {
                    if (!data.success) {
                        toastr.error('Error retrieving Message Templates');
                        return;
                    }

                    // lets populate stuff
                    $scope.templates = data.data.templates;
                    $scope.templates_enabled = 1;
                });
            }
        };

        $scope.templateSelected = function (template) {
            if (template == null) {
                $scope.messageData.body = '';
                $scope.messageData.subject = '';
                return;
            }

            $scope.messageData.body = template.content;
            $scope.messageData.subject = template.subject;
        };

        $scope.templates_enabled = 0;
        $scope.templates = [];
        $scope.template = {};
        $scope.loadTemplates();

        //validation all information for message
        $scope.dataValidation = function (isScheduled = 0) {
            var tag_count = 0;
            var portfolio_count = 0;
            var group_count = 0;
            for (var i = 0; i < $scope.tags.length; i++) {
                if ($scope.tags[i].user_id == 'portfolio') {
                    portfolio_count++;
                } else if ($scope.tags[i].user_id == 'grouping') {
                    group_count++;
                } else {
                    if (typeof $scope.tags[i].count === 'undefined') {
                        tag_count = tag_count + 1;
                    } else {
                        tag_count = tag_count + $scope.tags[i].count;
                    }
                }

            }

            var confirm_message = '';
            if ($rootScope.authUser.permissions != 'tenant') {
                var message_type = '';
                if ($scope.messageData.send_to == 1) {
                    message_type = (tag_count > 1 ? ' emails and in-app messages' : ' email and in-app message');
                }
                if ($scope.messageData.send_to == 2) {
                    message_type = (tag_count > 1 ? ' emails' : ' email');
                }
                if ($scope.messageData.send_to == 3) {
                    message_type = (tag_count > 1 ? ' in-app messages' : ' in-app message');
                }
                if ($scope.messageData.send_to == 4) {
                    message_type = (tag_count > 1 ? ' urgent messages' : ' urgent message');
                }
                confirm_message += 'You are about to send ';
                if (tag_count > 0) {
                    confirm_message += tag_count + message_type;
                }
                if (group_count > 0 || portfolio_count > 0) {
                    if (tag_count > 0) {
                        confirm_message += ' as well as ';
                    }

                    if (group_count > 0 && portfolio_count == 0) {
                        confirm_message += 'a group' + message_type + ' to ' + group_count + ' group(s)';
                    } else if (group_count == 0 && portfolio_count > 0) {
                        confirm_message += 'a group' + message_type + ' to ' + portfolio_count + ' portfolio(s)';
                    } else { // both are greater than zero
                        confirm_message += 'a group' + message_type + ' to ' + portfolio_count + ' portfolio(s) and ' + group_count + ' group(s)';
                    }
                }
                if ($scope.messageData.send_to == 4) {
                    confirm_message += ' This means recepients will all be sent an email even if they have email notifications disabled.';
                }
            } else {
                confirm_message += 'You are about to send a message to managers.';
            }
            confirm_message += '. Are you ready to send it?';
            if (!confirm(confirm_message)) {
                return;
            }

            $scope.mailError = [];
            //check body text
            if (angular.isUndefined($scope.messageData.body)) {
                $scope.mailError.push(Errors['message']);
            }
            //check selected tags
            if ($scope.tags.length == 0 && $rootScope.authUser.permissions != 'tenant') {
                $scope.mailError.push(Errors['send_to']);
            }
            //check subject text
            if (angular.isUndefined($scope.messageData.subject)) {
                $scope.mailError.push(Errors['subject']);
            }
            //check for errors
            if ($scope.mailError.length == 0) {
                if (isScheduled) {
                    var has_dates = false;
                    $scope.scheduled_dates.forEach((scheduled_date, key) => {
                        if (scheduled_date != '') {
                            has_dates = true;
                        }
                    });
                    if (!has_dates) {
                        $scope.mailError.push('Please specify the date and time of scheduled sending');
                        return;
                    }
                }

                $('#scheduled-modal').modal('hide');
                $('.modal-backdrop').remove();
                $scope.sendMessage(isScheduled);
            }
        };

        $scope.messageData = {};
        $scope.messageData.send_to = 1;
        $scope.messageData.recipients = 1;
        $scope.messageData.category = {};
        $scope.inProcess = false;

        $scope.recipientsChanged = () => {
            $scope.tags = [];
            if (+$scope.messageData.recipients === 2) {
                $scope.messageData.send_to = 2;
            }
        };

        $scope.loadTags = function (query) {
            return $http.get(`api/v1/messages/search-user-old?recipients=${$scope.messageData.recipients}&q=${encodeURIComponent(query)}`);
        };

        $scope.sendMessage = function (isScheduled = 0) {
            if ($scope.total_file_size > 20) {
                toastr.error('Total file size of all attachments can not exced 20MB.  Please remove enough files to be below this limit.');
                return;
            }
            var dataAttachments = [];
            angular.forEach($scope.attachments, function (file, key) {
                dataAttachments.push({
                    name: file.name,
                    data: file.dataURL
                });
            });

            var category_id = 0;
            if (typeof($scope.messageData.category) != 'undefined' && $rootScope.authUser.permissions === 'tenant' && $scope.displayCategories === 'true') {
                category_id = $scope.messageData.category.id;
            }

            var data = {
                data: $scope.tags,
                subject: $scope.messageData.subject,
                body: $scope.messageData.body,
                send_to: $scope.messageData.send_to,
                recipients: $scope.messageData.recipients,
                attachments: dataAttachments,
                scheduled_date: $scope.scheduledDate,
                scheduled_dates: $scope.scheduled_dates,
                is_scheduled: isScheduled,
                categoryId: category_id,
            };

            $scope.inProcess = true;
            messageService.sendAMessage(data)
                .success(function (data2) {
                    $scope.inProcess = false;
                    if (data2.success)
                        if ($rootScope.authUser.permissions === 'tenant') {
                            $state.go('main.messagesInbox');
                        } else {
                            $state.go('main.messagesSent').then(() => {
                                const stats = data2.messages;
                            });
                            toastr.info('Emails sent');
                        }
                    else {
                        toastr.error('There was an error sending your message');
                    }
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.inProcess = false;
                    toastr.error('There was an error sending your message');
                });
        };

        $scope.removeFile = function (file) {
            $scope.myDropzone.removeFile(file);
        };

        $scope.processDropzoneFiles = function () {
            $scope.total_file_size = 0;
            angular.forEach($scope.attachments, function (file, key) {
                var fileReader = new FileReader();
                var base64;
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // base64 is the dataURL
                    file.dataURL = base64;
                };
                fileReader.readAsDataURL(file);

                $scope.total_file_size+= (file.size / 1000000);
            });
        };

        $scope.show_dropzone = 0;
        $scope.total_file_size = 0;
        $scope.initDropzone = function () {
            if (!$rootScope.authUser.pro_messaging) {
                return;
            }

            $scope.myDropzone = new Dropzone('#real-dropzone', {
                url: '/api/v1/noreturn/',
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 100,
                maxFiles: 20,
                maxFilesize: 8,
                addRemoveLinks: false,
                dictRemoveFile: 'Remove',
                dictFileTooBig: 'File is bigger than 8MB',

                // The setting up of the dropzone
                error: function (file, response) {
                    toastr.error(response);
                },
                addedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    }.bind(vm));
                },
                removedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    }.bind(vm));
                },
            });
        };

        $timeout(function () {
            $scope.initDropzone();
        });
        /**
         * reply to recipient by id
         */
        $scope.reply = function () {
            if (angular.isUndefined($stateParams.reply_message_id)) {
                return;
            }

            var reply_message_id = parseInt($stateParams.reply_message_id);

            if (reply_message_id <= 0) {
                console.log("no id provided, redirecting");
                $state.go("main.messagesInbox");
                return;
            }

            $http
                .get(
                "api/v1/messages/" + reply_message_id + "/get-reply-recipient"
                )
                .then(
                function success(data) {
                    $scope.replyInfo = data.data.user;
                    $scope.messageData.subject = data.data.reply_subject;

                    if ($scope.replyInfo) {
                        $scope.replyInfo["reply_id"] = reply_message_id;
                        $scope.tags.push($scope.replyInfo);
                    }
                },
                function error(error) {
                    console.log("Error getting recipient info", error);
                }
                );
        };

        $scope.reply();

        $scope.showUrgentInfoPopUp = () => {
            const tagsHelp = 'Choose this option if you have an urgent message that must go to all recepients even if they have email notifications disabled.';

            toastr.info(tagsHelp, '', {
                closeButton: true,
                timeOut: 0,
                extendedTimeOut: 0,
                preventDuplicates: true,
            });
        }

        $scope.toggleScheduledDropDown = function () {
            console.log('here I am mobile')
            $('#scheduled_dropdown').toggleClass('active');
        }

    }

}());
