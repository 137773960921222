(function() {

	'use strict';

    angular
		.module('app')
		.controller('MerchantAccountEditController', MerchantAccountEditController);

	function MerchantAccountEditController($state, merchantAccountService, resolvedMerchantAccountData) {

        var vm = this;

        // for the slider
        vm.options = {
            from: 0,
            to: 100,
            step: 1,
            dimension: "%",
            scale: [
                0, '|', 10, '|', 20, '|', 30, '|', 40, '|',
                50, '|', 60, '|', 70, '|', 80, '|', 90, '|', 100
            ]
        };

        vm.merchantAccount = resolvedMerchantAccountData.merchantAccount.data;
        if(isNaN(parseFloat(vm.merchantAccount.debit_visa_landlord_percent)))
            vm.merchantAccount.debit_visa_landlord_percent = 50;
        vm.merchantAccountData = {
            credit_tenant_percent: 50,
			credit_landlord_percent: 50,
            debit_visa_landlord_percent: 50,
            debit_tenant_percent: 50,
			debit_landlord_percent: 50,
            debit_visa_fee_amount: 0
        };

        vm.updateMerchantAccount = function() {
            var data = {
                friendly_name: vm.merchantAccount.friendly_name,
                terminal_identification: vm.merchantAccount.terminal_identification,

                institution_number: vm.merchantAccount.institution_number,
                transit_number: vm.merchantAccount.transit_number,
                account_number: vm.merchantAccount.account_number,

                debit_visa: vm.merchantAccount.debit_visa > 0 ? 1: 0,
				debit_visa_fee_amount: ''+vm.merchantAccount.debit_visa_fee_amount,
                debit_visa_landlord_percent: ''+vm.merchantAccount.debit_visa_landlord_percent,

                yardi_in_batch: vm.merchantAccount.yardi_in_batch > 0 ? 1 : 0,
                extension_payment_reverse: vm.merchantAccount.extension_payment_reverse > 0 ? 1 : 0,
                rentmanager_bank_id: vm.merchantAccount.rentmanager_bank_id,
                yardi_account_code: vm.merchantAccount.yardi_account_code,
                dd_decline_fee: vm.merchantAccount.dd_decline_fee,
                debit_threshold: vm.merchantAccount.debit_threshold,

                credit_visa: vm.merchantAccount.credit_visa,
                credit_visa_fee_percent: vm.merchantAccount.credit_visa_fee_percent,

                credit_master: vm.merchantAccount.credit_master,
                credit_master_fee_percent: vm.merchantAccount.credit_master_fee_percent,

                credit_amex: vm.merchantAccount.credit_amex,
                credit_amex_fee_percent: vm.merchantAccount.credit_amex_fee_percent,

                credit_tenant_percent:100 - vm.merchantAccount.credit_landlord_percent,
                credit_landlord_percent: vm.merchantAccount.credit_landlord_percent,

                debit: vm.merchantAccount.debit,
                debit_fee_amount: vm.merchantAccount.debit_fee_amount,
                debit_tenant_percent: 100 - vm.merchantAccount.debit_landlord_percent,
                debit_landlord_percent:  vm.merchantAccount.debit_landlord_percent,

                cardknox_xkey: vm.merchantAccount.cardknox_xkey,
                cardknox_ifields_key:  vm.merchantAccount.cardknox_ifields_key,
                cardknox_ach_cut_off_time:  vm.merchantAccount.cardknox_ach_cut_off_time,
                cardknox_fee_counting_type:  vm.merchantAccount.cardknox_fee_counting_type,
                country:  vm.merchantAccount.country,
                provider:  vm.merchantAccount.provider,
                payrix_merchant_id:  vm.merchantAccount.payrix_merchant_id,
            };
            merchantAccountService.updateMerchantAccount(vm.merchantAccount.id, data)
                .success(function(data) {
                    $state.go('main.companyEdit', {
                        companyId: vm.merchantAccount.company_id
                    });
                    toastr.success('Merchant Account Updated!', 'Success!');
                })
                .error(function(data){
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });

        };

        vm.deleteMerchantAccount = function() {
            merchantAccountService.deleteMerchantAccount(vm.merchantAccount.id)
                .success(function(data) {
                    if(data.success){
						$state.go('main.companyEdit', {
							companyId: vm.merchantAccount.company_id
						});
						toastr.success('Merchant Account Deleted', 'Success!');
					}
					else
						toastr.error(data.errors);
                })
                .error(function(data){
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error('error deleting merchant account');
                });
        }

    }

}());
