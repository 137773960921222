(function () {

    'use strict';

    angular
        .module('app')
        .controller('InspectionTemplatesEditController', function ($scope, $state, resolvedTemplate, inspectionService, companyService, $rootScope) {
            window.scrollTo(0, 0);
            let body = document.querySelector('body');
            body.style.overflowY = 'hidden';

            $scope.$on("$destroy", function() {
                let body = document.querySelector('body');
                body.style.overflowY = 'unset';
            });
            $scope.template = resolvedTemplate;
            $scope.initialTemplate = angular.copy($scope.template);  // initial template to track changes
            $scope.companies = [];
            $scope.isSaving = false;
            $scope.addArea = () => $scope.template.areas.push({name: '', items: []});
            $scope.removeArea = index => $scope.template.areas.splice(index, 1);
            $scope.addItem = (index, type) => $scope.template.areas[index].items.push({name: '', type: type});
            $scope.removeItem = (index, itemIndex) => $scope.template.areas[index].items.splice(itemIndex, 1);

            if($rootScope.authUser.permissions === 'payquad_admin'){
                companyService.getAllCompanies().then(companies => {
                    $scope.companies = companies.data;
                });
            }

            const moveItem = (arr, index, direction) => {
                const newIndex = index + direction;
                if (newIndex >= 0 && newIndex < arr.length) {
                    const tmp = arr[index];
                    arr[index] = arr[newIndex];
                    arr[newIndex] = tmp;
                }
            };

            $scope.moveArea = (index, direction) => {
                moveItem($scope.template.areas, index, direction);
            };

            $scope.moveItem = (index, itemIndex, direction) => {
                moveItem($scope.template.areas[index].items, itemIndex, direction);
            };

            const validate = () => {
                let isValid = true;
                $scope.template.areas.forEach(item => {
                    if (!item.items.length) {
                        isValid = false;
                    }
                });
                if(!isValid){
                    toastr.error('Each area should contain at least one item.')
                }
                return isValid;
            };

            $scope.save = () => {
                if (validate()) {
                    $scope.isSaving = true;
                    if (!$scope.template.id) { // if template is being created
                        inspectionService.updateTemplateHistory($scope.makeCreateRecord($scope.template)).success(() => {
                            console.log('History updated!')
                        });
                        inspectionService.createTemplate($scope.template).success(() => {
                            toastr.success('The inspection template was successfully created.');
                            $state.go('main.inspectionTemplates', {}, {reload: true});
                        });
                    } else { // if template is being edited
                        inspectionService.updateTemplateHistory($scope.compareTemplates($scope.initialTemplate, $scope.template)).success(() => {
                            console.log('History updated!')
                        });
                        inspectionService.editTemplate($scope.template).success(() => {
                            toastr.success('The inspection template was successfully saved.');
                            $state.go('main.inspectionTemplates', {}, {reload: true});
                        });
                    }

                }
            };

            $scope.delete = () => {
                if(confirm('Are you sure?')){
                    inspectionService.updateTemplateHistory($scope.makeDeleteRecord($scope.template)).success(() => {
                        console.log('History updated!')
                    });
                    inspectionService.deleteTemplate($scope.template).success(() => {
                        toastr.success('The inspection template was successfully deleted.');
                        $state.go('main.inspectionTemplates', {}, {reload: true});
                    });
                }
            }

            /**
             * Create a record of the template creation. Data structure:
             * {
             *     template_id: <id>,
             *     changes: [
             *     { field_name: 'Template', from: '', to: <name> },
             *     ...
             *     ]
             * }
             *
             * */
            $scope.makeCreateRecord = (template) => {
                return {
                    template_id: template.id,
                    changes: [
                        { field_name: 'Template', from: 'N/A', to: template.name }
                    ]
                }
            }

            /**
             * Create a record of the template deletion. Data structure is the same as for the creation record
             * but with `from` and `to` values swapped (as expected).
             */
            $scope.makeDeleteRecord = (template) => {
                return {
                    template_id: template.id,
                    changes: [
                        { field_name: 'Template', from: template.name, to: 'N/A' }
                    ]
                }
            }

            /**
             * Compare templates and return differences between them. Data structure:
             * {
             *     template_id: <id>,
             *     changes: [
             *     { field_name: <field_name>, from: <from_value / null>, to: <to_value / null> },
             *     ...
             *     ]
             * }
             * */
            $scope.compareTemplates = (before, after)=>  {
                let differences = [];

                // Compare top-level fields
                ['name', 'description', 'company'].forEach(field => {
                    if (before[field] !== after[field]) {
                        differences.push({
                            field_name: field, from: before[field], to: after[field] });
                    }
                });

                // Track areas by their IDs for O(1) lookup
                const beforeAreasMap = new Map(before.areas.map(area => [area.id, area]));
                const afterAreasMap = new Map(after.areas.map(area => [area.id, area]));

                // Detect area changes, additions, and deletions
                before.areas.forEach(area => {
                    if (!afterAreasMap.has(area.id)) {
                        differences.push({ field_name: 'Area', from: area.name, to: 'N/A' });
                    } else {
                        const afterArea = afterAreasMap.get(area.id);
                        if (area.name !== afterArea.name) {
                            differences.push({ field_name: 'Area', from: area.name, to: afterArea.name });
                        }
                    }
                });

                after.areas.forEach(area => {
                    if (!beforeAreasMap.has(area.id)) {
                        differences.push({ field_name: 'Area', from: 'N/A', to: area.name });

                        area.items.forEach(item => {
                            differences.push({ field_name: `${area.name} item`, from: 'N/A', to: item.name });
                        });
                    }

                    // Compare items within each matched area
                    else {
                        const beforeItemsMap = new Map(beforeAreasMap.get(area.id).items.map(item => [item.id, item]));
                        const afterItemsMap = new Map(area.items.map(item => [item.id, item]));

                        beforeAreasMap.get(area.id).items.forEach(item => {
                            if (!afterItemsMap.has(item.id)) {
                                differences.push({ field_name: `${area.name} item`, from: item.name, to: 'N/A' });
                            }
                        });

                        area.items.forEach(item => {
                            if (!beforeItemsMap.has(item.id)) {
                                differences.push({ field_name: `${area.name} item`, from: 'N/A', to: item.name });
                            } else {
                                const beforeItem = beforeItemsMap.get(item.id);
                                if (item.name !== beforeItem.name) {
                                    differences.push({ field_name: `${area.name} item`, from: beforeItem.name, to: item.name });
                                }
                            }
                        });
                    }
                });

                // Detect changes in the order of the areas
                let beforeAreaIds = before.areas.map(area => area.id);
                let afterAreaIds = after.areas.map(area => area.id);
                if (JSON.stringify(beforeAreaIds) !== JSON.stringify(afterAreaIds)) {
                    // loop through areas
                    let commonElements = beforeAreaIds.filter(id => afterAreaIds.includes(id));
                    let beforeAreaIdsFiltered = beforeAreaIds.filter(id => commonElements.includes(id));
                    let afterAreaIdsFiltered = afterAreaIds.filter(id => commonElements.includes(id));
                    if (beforeAreaIdsFiltered.length > 1) {
                        differences.push({field_name: 'Areas order',
                            from: beforeAreaIdsFiltered.map(id => beforeAreasMap.get(id).name),
                            to: afterAreaIdsFiltered.map(id => afterAreasMap.get(id).name)});
                    }
                }

                // Detect changes in the order of items within each area
                after.areas.forEach(area => {
                    if (beforeAreasMap.has(area.id)) {
                        const beforeItemsIds = beforeAreasMap.get(area.id).items.map(item => item.name);
                        const afterItemsIds = area.items.map(item => item.name);
                        if (JSON.stringify(beforeItemsIds) !== JSON.stringify(afterItemsIds)) {
                            let commonElements = beforeItemsIds.filter(id => afterItemsIds.includes(id));
                            let beforeItemsIdsFiltered = beforeItemsIds.filter(id => commonElements.includes(id));
                            let afterItemsIdsFiltered = afterItemsIds.filter(id => commonElements.includes(id));
                            if (beforeItemsIdsFiltered.length > 1) {
                                differences.push({field_name: area.name + ' items order',
                                    from: beforeItemsIdsFiltered,
                                    to: afterItemsIdsFiltered});
                            }
                        }
                    }
                });

                return {
                    template_id: before.id,
                    changes: differences
                }
            }

        });

}());
