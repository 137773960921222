(function () {
    controller: 'PasswordController'

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/prospect/';

        $stateProvider
            .state('main.prospects', {
                url: '/prospects',
                views: {
                    "body@": {
                        templateUrl: url + 'list/index.html',
                        controller: 'ProspectsListController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.prospects.edit', {
                url: '/:id',
                views: {
                    'sidebar@': {
                        templateUrl: url + 'edit/edit.html',
                        controller: 'ProspectEditController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.prospects.new', {
                url: '/new/:propertyId',
                views: {
                    "body@": {
                        templateUrl: url + 'create/prospect.create.html',
                        controller: 'ProspectCreateController',
						resolve: {
							resolvedProperty: function ($q, $stateParams, prospectService) {
								return $q.when(prospectService.getProperty($stateParams.propertyId));
							}
						}
                    }
                }
            })
            .state('main.prospectProperties', {
                url: '/prospects/properties/:companyId',
                views: {
                    'body@': {
                        templateUrl: url + 'create-properties/create-properties.html',
                        controller: 'ProspectCreatePropertiesController',
                        controllerAs: 'vm'
                    }
                }
            })
        ;
    }
}());
