(function () {

    'use strict';

    angular
        .module('app')
        .factory('inspectionService', inspectionService);

    function inspectionService($http) {

        const base_url = '/api/v1/inspection';

        return {
            get: id => $http.get(`${base_url}/${id}`).then(r => r.data),
            create: inspection => $http.post(`${base_url}`, JSON.stringify(inspection)),
            update: inspection =>  $http.patch(`${base_url}/${inspection.id}`, JSON.stringify(inspection)),
            getBlank: () => ({
                property_id: null,
                unit_id: null,
                template_id: null,
                type: 'one_time',
                period: 'weekly',
                cutoff: '',
            }),
            getTemplate: id => {
                if (+id) {
                    return $http.get(`${base_url}-template/${id}`).then(r => r.data.data);
                } else {
                    return {
                        name: '',
                        description: '',
                        company_id: '',
                        areas: []
                    };
                }
            },
            getAllTemplates: () => $http.get(`${base_url}-template`).then(r => r.data.data),
            createTemplate: template => $http.post(`${base_url}-template`, JSON.stringify(template)),
            editTemplate: template => $http.patch(`${base_url}-template/${template.id}`, JSON.stringify(template)),
            deleteTemplate: template =>  $http.delete(`${base_url}-template/${template.id}`),
            updateTemplateHistory: changes => $http.post(`${base_url}-template/edit-history`, JSON.stringify(changes))
        };

    }

}());
