(function () {
    'use strict';

    angular
        .module('app')
        .controller('QuickPayReceiptController', quickPayReceiptController);

    function quickPayReceiptController($scope, $http, $state, $stateParams, $rootScope, $timeout, $sce, langService) {
	    $scope.t = langService.t('payment_history');
        $scope.t_confirm_payment = $scope.t('confirm_payment');

        $scope.quick_pay = {
            transaction_id: parseInt($stateParams.id),
        };

        $scope.checkQuickPayAccess = function () {
            $http.post('/api/v1/quick-pay/receipt-check-access/' + $scope.quick_pay.transaction_id).success(function (data) {
                if (!data.success) {
                    toastr.error('Access Denied');
                    return
                }

                $scope.receipt = data.data;
                var name = $scope.receipt.tenant.name;
                var address = $scope.receipt.tenant.address;
                $scope.t_confirm_payment = $scope.t('confirm_payment_no_unit',{name: name, address: address});
            });
        };
        $scope.checkQuickPayAccess();

        $scope.print = function() {
    	   window.print();
    	};

        $scope.trustAsHtml = function(html_string) {
            return $sce.trustAsHtml(html_string);
        };
    }
}());
