(function() {

    'use strict';

    angular
        .module('app')
        .factory('paymentScheduleService', paymentScheduleService);

    function paymentScheduleService($http) {

        var base_url = '/api/v1/';
        var local_url = 'payments/payment-schedules';

        return {

            getAll: function()
            {
                return $http.get(base_url + local_url);
            },
 			


            getPaymentHistory: function()
            {
                return $http.get('api/v1/payments/payment-history')
                    .then(function (object) {
                        return object.data;
                    });
            },

            getPaymentServices: function()
            {
                return $http.get(base_url + 'payments/services');
            },

            getById: function(id)
            {
                return $http.get(base_url + local_url + "/" + id);
            },

            getConvenienceFees: function(data)
            {
                return $http({
                    method: 'POST',
                    url: '/api/v1/payments/payment-methods/convenience-fees',
                    headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                    data: $.param(data)
                });
            },

            create: function(data)
            {
                return $http({
                    method: 'POST',
                    url: '/api/v1/payments/payment-schedules',
                    headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                    dataType: 'json',
                    data: $.param(data)
                });
            },

            update: function(id, data)
            {
				console.log('update called',id,data);
                return $http.put(base_url + local_url + "/" + id, data);
            },

            delete: function(id)
            {
                return $http.delete(base_url + local_url + "/" + id);
            }

        }

    }

}());