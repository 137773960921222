
(function() {

    'use strict'

    angular
        .module('app')
        .controller('FooterController', FooterController);

    function FooterController($scope,$rootScope, $location, langService) {
        $scope.t = langService.t('footer');
	   	$scope.pagename = function() {
	   	 	return $location.path();
	   	  };
        $scope.getFooterStyle = function(){
            if(angular.isUndefined($rootScope.branding)){
                return;
            }
            if($rootScope.branding.background_file_url.length < 3)
                return {'box-sizing': 'border-box', 'overflow': 'auto'};
            return {
                'box-sizing': 'border-box', 
				'overflow': 'auto',                
                'background' : 'url('+$rootScope.branding.background_file_url+')',
				'background-size': 'cover',
            };
        }

    }
}());