(function() {

    'use strict';

    angular
        .module('app')
        .controller('MaintenanceRequestSchedulesController', maintenanceRequestSchedulesController);

    function maintenanceRequestSchedulesController($scope,$http,$state,$rootScope,$stateParams,langService) {
        $scope.t = langService.t('maint_req');
        $scope.schedules = [];

        $scope.getSchedules = function(){
            $http.post('/api/v1/maintenance-requests/schedules').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Schedules");
                }

                // lets populate stuff
                $scope.schedules = data.data;
            });
        }

        $scope.getSchedules();

        $scope.goToEdit = function(id) {
            $state.go('main.maintenanceRequestScheduleEdit', { id: id });
        };
    }

}());
