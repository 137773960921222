(function() {
    'use strict';

    angular
    .module('app')
    .controller('AmenitiesIndexController', amenitiesIndexController);

    function amenitiesIndexController($scope,$http,$state,$rootScope,langService) {
        $scope.t = langService.t('amenities');
        $scope.amenities = [];

        $scope.getAmenities = function(){
            $http.post('/api/v1/amenity/get-list').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Amenities");
                }

                // lets populate stuff
                $scope.amenities = data.data.amenities;
                $(document).ready(function () {
                    setTimeout(function () {
                        $('#dataTable').dataTable({
                            buttons: [],
                            responsive: true,
                        });

                        $('body').on('click', '#dataTable .template-edit-button', function (event) {
                            if($(this).data("amenity")) {
                                event.preventDefault();
                                $state.go('main.amenityEdit', { id: $(this).data("amenity") });
                            }
                        });
                    }, 100);
                });
            });
        };

        $http.post('/api/v1/amenity/is-enabled').success(function(data) {
            if (data.data.enabled == 0) {
                // not enabled for this user
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            if ($rootScope.authUser.permissions == 'tenant') {
                $state.go('main.amenityBook');
                return 1;
            }

            $scope.getAmenities();
        });

        $scope.goToEdit = function (amenity) {
            $state.go('main.amenityEdit', {"id": amenity.id});
        }

    }
}());
