(function () {

    'use strict';

    angular
        .module('app')
        .controller('AuthenticationLoginController', AuthenticationLoginController);

    function AuthenticationLoginController($scope, $rootScope, $http, $state, $timeout, authenticationService, propertyService, $location, langService, brandingService, jwtService) {
        let db = null;
        // if(window.Capacitor && window.sqlitePlugin) {
        //      db = window.sqlitePlugin.openDatabase({name: 'pq_db.db', location: 'default'});
        // }

        // test settings
        // $rootScope.platform = 'ios';
        // $rootScope.pqAppId = 'com.payquad.manager';

        // if (window.Capacitor) {
        //     $http.get(`/api/v1/mobile-apps/check?app-id=${$rootScope.pqAppId}&version=${$rootScope.appVersion}&platform=${$rootScope.platform}`, $scope.authData).success(function (data) {
        //         if(!data.success) {
        //             $('#update-mobile-app-modal').appendTo('body').modal('show');
        //         }
        //
        //     })
        // }

        $rootScope.isAngular2 = false;
        $rootScope.showLoader = false;
        window['fireAngularEvent']('pq.angularjs-route');

        $scope.t = langService.t('auth');
        $scope.authData = {
            property: 1,
            email: localStorage.getItem('email'),
            password: '',
            remember_me: !!localStorage.getItem('remember_me'),
            agree_terms: false,
            phone_number: '',
            last_name: '',
            first_name: '',
            cordova: window.Capacitor ? 1 : 0
        };

        $scope.storeLinkInfo = {
            ios: {
                link: 'https://itunes.apple.com/ca/app/',
                ids: {
                    'com.payquad': 'my-portal-by-payquad/id1502174216',
                    'com.payquad.manager': 'manager-app-by-payquad/id1551011738',
                    'com.medallion.app': 'my-medallion/id1555362357'
                }

            },
             android: {
                link: 'market://details?id=',
                ids: {
                    'com.payquad': 'com.payquad',
                    'com.payquad.manager': 'com.payquad.manager',
                    'com.medallion.app': 'com.medallion.app'
                }
             }
        }

        if(window.Capacitor && db) {
            db.transaction(function (tx) {
                tx.executeSql('CREATE TABLE IF NOT EXISTS payquad_config (remember_me, remember_email)');
            }, function (error) {
                console.log('transaction error: ' + error.message);
            }, function () {
                console.log('transaction ok');
            });


            db.executeSql('SELECT value FROM payquad_config WHERE key = ?', ['remember_me'], function(res) {
                if(res.rows.length > 0) {
                    $scope.authData['remember_me'] = res.rows.item(0).value === "true" ? true : false;
                }
            }, function(error) {
                console.log('GETITEM ERROR: ' + error);
            });

            db.executeSql('SELECT value FROM payquad_config WHERE key = ?', ['remember_email'], function(res) {
                if(res.rows.length > 0) {
                    $scope.authData['email'] = res.rows.item(0).value;
                }
            }, function(error) {
                console.log('GETITEM ERROR: ' + error);
            });
        }

        $scope.token = jwtService.getToken();

        $scope.openLink = function(event) {
            if(event.target.href && window.Capacitor) {
                event.preventDefault();
                window.Capacitor.InAppBrowser.open(event.target.href, '_blank');
            }
        }

        $scope.openStoreLink = function(type, id) {
            var storeLink = `${$scope.storeLinkInfo[type].link}${$scope.storeLinkInfo[type]['ids'][id]}`;
            window.Capacitor.InAppBrowser.open(storeLink, '_system');
        }


        $scope.login = function () {
            jQuery(this).fadeOut('fast');jQuery('#logging-in').fadeIn()
            if ($scope.authData.remember_me == true) {
                localStorage.setItem('email', $scope.authData.email);
                localStorage.setItem('remember_me', true);

                if(window.Capacitor && db) {
                    db.transaction(function(tx) {
                        tx.executeSql("UPDATE payquad_config SET remember_me=?, remember_email=?", ["true", $scope.authData.email], function(res){}, function(error){console.log('UPDATE ERROR: ' + error.message);});
                    });
                }
            } else {
                localStorage.removeItem('email');
                localStorage.removeItem('remember_me');
                if(window.Capacitor && db) {
                    db.transaction(function(tx) {
                        tx.executeSql('DELETE FROM payquad_config WHERE key = ?', ['remember_me'], function(res){}, function(error){console.log('DELETE ERROR: ' + error.message);} );
                        tx.executeSql('DELETE FROM payquad_config WHERE key = ?', ['remember_email'], function(res){}, function(error){console.log('DELETE ERROR: ' + error.message);} );
                    });
                }
            }

            authenticationService.login($scope.authData)
                .success(function (data) {
                    window['fireAngularEvent']('pq.sign-in', $rootScope.postLogInRoute);
                    window['subscribeToAngularEvent']('pq.user-loaded', args => {
                        // alert('pq.user-loaded' + args)
                        if (data.success === true) {
                            if (typeof data.data.redirect_to_new_portal !== 'undefined' && data.data.redirect_to_new_portal) {
                                // redirect to new portal
                                toastr.info('Please note you are being redirected to the new portal interface where you will need to login again.');
                                setTimeout(function () {
                                    window.location.replace(data.data.redirect_to_new_portal);
                                }, 3000);
                                return;
                            }
                            if (data.data.password_force_reset && data.data.password_force_reset_token) {
                                toastr.error('Your password has not been updated for a long time. Please change your password');
                                $state.go('main.passwordReset', {passwordToken: data.data.password_force_reset_token});
                                return;
                            }

                            const locale = data.data.locale ? data.data.locale : 'en';
                            jwtService.setToken(data.data.access_token, data.data.token_type, data.data.expired_in);

                            if (data.data.needs_email !== undefined) {
                                $rootScope.user_id = data.data.user_id;
                                $rootScope.password = data.data.password;
                                $state.go('main.email');
                            } else {
                                var USER_ID = data.data.user_id;
                                mixpanel.identify(USER_ID);
                                mixpanel.people.set({
                                    "$email": data.data.email,
                                    "locale": data.data.locale,
                                    "permissions": data.data.permissions,
                                    "company_id": data.data.company_id,
                                    "first_name": data.data.first_name,
                                    "last_name": data.data.last_name,
                                    "USER_ID": USER_ID,
                                });
                                mixpanel.track("Log In", {success: true});
                                $rootScope.authUser = data.data;
                                $rootScope.isModuleTemporarilyHiddenForCompany = true;
                                $scope.initMobileMenuItems();
                                authenticationService.updateEditor();
                                $rootScope.$broadcast('check-new-messages');
                                brandingService.get().then(function (data) {
                                    $rootScope.branding = data.data;
                                    if ($rootScope.postLogInRoute) {
                                        console.error('$rootScope.postLogInRoute')
                                        // debugger
                                        const route = $rootScope.postLogInRoute;

                                        $rootScope.postLogInRoute = null;
                                        $rootScope.redirectedAfterLogin = true;
                                        if (route.startsWith('/n/')) {


                                            const v2url = route.replace(/^\/n\/|\?.*$/g, '');

                                            $rootScope.isAngular2 = true;
                                            $rootScope.showLoader = true;
                                            console.log('v2url', v2url)
                                            $rootScope.goToAngular2(v2url);
                                            // window.location.href = '/#/n/' + v2url + '';
                                            // $state.go('main.dashboard');
                                        } else {
                                            $location.url(route).replace();
                                        }
                                    } else {
                                        if ($rootScope.authUser['permissions'] === 'applicant') {
                                            $state.go('main.leaseApplicationDashboard');
                                        } else {
                                            if(
                                                $rootScope.authUser['permissions'] === 'payquad_admin'
                                                || $rootScope.authUser['permissions'] === 'junior_manager'
                                                || $rootScope.authUser['permissions'] === 'admin_manager'
                                            ) {
                                                // window.location = '/#/n/dashboard';
                                                $rootScope.goToAngular2('dashboard');
                                            } else {
                                                $state.go('main.dashboard');
                                            }
                                        }
                                    }

                                    $timeout(() => {
                                        const currentLocale = $rootScope.localeButtonString === 'En' ? 'en' : 'fr';
                                        if(currentLocale !== locale){
                                            $rootScope.setLocalization(locale);
                                        }
                                    }, 0);
                                });
                            }

                        } else if (data.success === false) {
                            mixpanel.track("Log In", {success: false});
                            jQuery('#logging-in').fadeOut('normal', function () {
                                jQuery('#sign-in-btn').fadeIn('fast');
                            });
                            $scope.errors = data.errors;
                        }
                    });

                })
                .error(function (data) {
                    mixpanel.track("Log In", {success: false, message: data.message});
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    jQuery('#logging-in').fadeOut('normal', function () {
                        jQuery('#sign-in-btn').fadeIn('fast');
                    });
                    //alert("invalid username or password, please try again");
                    if (typeof data.errors != 'undefined') {
                        $scope.errors = data.errors[Object.keys(data.errors)[0]];

                        if ($scope.errors == 'The email field is required.' && $scope.authData.email != '') {
                            // uBlock ad blocker blocks Sentry CDN;
                            // avoid crash in that case
                            if (typeof Sentry !== 'undefined') {
                                Sentry.captureMessage('Email was blank but should not have been');
                            }
                        }
                    }
                });
        };


        $scope.setup = function () {
            if (!$scope.authData.agree_terms) {
                toastr.error('You must agree to the terms of use and privacy policy');
                return false;
            }
            $scope.authData.user_id = $rootScope.user_id;
            $scope.authData.password = $rootScope.password;
            $http.post('/api/v1/users/setup', $scope.authData).success(function (data) {
                if (data.success === true) {
                    $rootScope.authUser = data.data;
                    $rootScope.$broadcast('check-new-messages');
                    brandingService.get().then(function (data) {
                        $rootScope.branding = data.data;
                        $state.go('main.dashboard');
                    });

                } else if (data.success === false)
                    toastr.error(data.errors);
            })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error('Error seting up account');
                });
        };

        $scope.showModule = function () {
            if (Boolean(window.Capacitor) && !angular.isUndefined($rootScope.authUser)) {
                return true;
            } else {
                return true;
            }
        }

        $scope.getManagerMobileMenu = function() {
            return {
                companies: {
                    isActive: $scope.showModule() && $rootScope.authUser.permissions === 'payquad_admin',
                    menuNumber: 0
                },
                properties: {
                    isActive: $scope.showModule() && $rootScope.authUser.permissions !== 'payquad_admin' && $rootScope.authUser.user_permissions.properties,
                    menuNumber: 1
                },
                residents: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.residents,
                    menuNumber: 2
                },
                payments: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.payments,
                    menuNumber: 3
                },
                managers: {
                    isActive: $scope.showModule() && $rootScope.authUser.permissions !== 'junior_manager',
                    menuNumber: 4
                },
                communication: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions['communications'],
                    menuNumber: 5
                },
                maintenance: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions['maintenance-requests'],
                    menuNumber: 6
                },
                reports: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.reports,
                    menuNumber: 7
                },
                documents: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.documents,
                    menuNumber: 8
                },
                amenities: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.amenities,
                    menuNumber: 9
                },
                surveys: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.surveys,
                    menuNumber: 10
                },
                lease: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions['lease-applications'],
                    menuNumber: 11
                },
                parcels: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.parcels,
                    menuNumber: 12
                },
                inspections: {
                    isActive: $scope.showModule() && $rootScope.authUser.user_permissions.inspections,
                    menuNumber: 13
                },
            };
        };

        $scope.getTenantMobileMenu = function() {
            return {
                payments: {
                    isActive: $rootScope.authUser.user_permissions.payments,
                    menuNumber: 0
                },
                maintenance: {
                    isActive: $rootScope.authUser.user_permissions['maintenance-requests'],
                    menuNumber: 1
                },
                amenities: {
                    isActive: $rootScope.authUser.user_permissions.amenities,
                    menuNumber: 2
                },
                communication: {
                    isActive: $rootScope.authUser.user_permissions['communications'],
                    menuNumber: 3
                },
                paymentHistory: {
                    isActive: $rootScope.authUser.user_permissions.payments,
                    menuNumber: 4
                },
                parcels: {
                    isActive: $rootScope.authUser.user_permissions.parcels,
                    menuNumber: 5
                },
                documents: {
                    isActive: $rootScope.authUser.user_permissions.documents,
                    menuNumber: 6
                },
                insurance: {
                    isActive: $rootScope.authUser.insurance_nav,
                    menuNumber: 7
                },
                rewards: {
                    isActive: $rootScope.authUser.rewards,
                    menuNumber: 8
                }
            };
        };

        $scope.initMobileMenuItems = function() {
            if ($rootScope.authUser.permissions === 'tenant') {
                $rootScope.mobileMenus = $scope.getTenantMobileMenu();
            } else if($rootScope.authUser.permissions === 'admin_manager' ||
                $rootScope.authUser.permissions === 'payquad_admin' ||
                $rootScope.authUser.permissions === 'junior_manager') {

                $rootScope.mobileMenus = $scope.getManagerMobileMenu();
            } else {
                return
            }

                $rootScope.activeInMobile = [];

                let obj = $rootScope.mobileMenus;

                for (let key in obj) {
                    if (obj[key].isActive) {
                        $rootScope.activeInMobile.push(obj[key].menuNumber);
                    }
                }

                $rootScope.activeInMobile = $rootScope.activeInMobile.sort(function (a, b) {
                    return a - b;
                });

                let numberOfMenuItemsToShow = 4;

                $rootScope.activeInMobile = $rootScope.activeInMobile.slice(0, numberOfMenuItemsToShow);
        }

        $rootScope.$on("logInAfterResetPassword", function (event, args) {
           console.log('logInAfterResetPassword', event, args)
            $scope.authData = {
                // property: 1,
                email: args.email,
                password: args.password,
                remember_me: true,
                // agree_terms: false,
                // phone_number: '',
                // last_name: '',
                // first_name: '',
                // cordova: window.Capacitor ? 1 : 0
            };
            $scope.login();
        });
    }

}());
