(function() {

    'use strict';

    angular
        .module('app')
        .controller('VendorCreateController', vendorCreateController);

    function vendorCreateController($scope,$http,$state,$rootScope,$stateParams,$timeout) {

		if (!$rootScope.authUser.permissions == 'tenant') {
			 $state.go('main.dashboard');
        }

        $scope.properties = [];

        $scope.vendor = {
            company_id: $stateParams.companyId,
            name: '',
            type: '',
            phone: '',
            email: '',
            email_2: '',
            email_3: '',
            properties: []
        };

        $scope.getProperties = function() {
            $http.post('/api/v1/vendor/get-properties/' + $stateParams.companyId).success(function(data) {
                $scope.properties = data.data.properties;

                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        }

        $scope.createVendor = function() {
            $scope.creatingVendor = true;

            $http.post('/api/v1/vendor/create', $scope.vendor).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error creating vendor");
                    return;
                }

                $scope.creatingVendor = false;
                $state.go('main.companyEdit', { companyId: $stateParams.companyId });
                toastr.success('Vendor created');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingVendor = false;
                toastr.error(data.error.message);
            });
        }


        $http.post('/api/v1/vendor/is-enabled/' + $stateParams.companyId).success(function(data) {
            if (!data.success) {
                // not enabled for this user
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            if ($rootScope.authUser.permissions == 'tenant') {
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            $scope.getProperties();
        });
    }

}());
