(function () {

    'use strict';

    angular
        .module('app')
        .config(routeConfig);

    function routeConfig($urlRouterProvider, $stateProvider, RestangularProvider) {
        RestangularProvider.setBaseUrl('/api/v1');

        $urlRouterProvider.otherwise(function ($injector, $location) {
            const $rootScope = $injector.get('$rootScope');
            console.log('otherwise ' + $location.path(), 'platform: ' + window.Capacitor.getPlatform(), $rootScope.platform);
            if(window.Capacitor.getPlatform()  == 'ios' || window.Capacitor.getPlatform()  == 'web') {
                window.location.href = '/#/n/login'
            } else {
                $rootScope.goToAngular2Login();
                // return '/n/login';
            }
        });

        $stateProvider
            .state('main', { // a way of defining a public route
                url: '',
                abstract: true,
                views: {
                    'navbar': {
                        templateUrl: 'scripts/modules/navbar/views/navbar.main.html',
                        controller: 'NavbarController'
                    },
                    'body': {
                        template: '<img src="assets/images/loading.gif" />'
                    },
                    'sidenav': {
                        templateUrl: 'scripts/modules/sidenav/views/sidenav.main.html',
                        controller: 'NavbarController'
                        // templateUrl: 'scripts/layouts/sidenav.html',
                    },
                    // 'body@main.sidenav': {
                    //     template: '1234123412341234123412341234123412341234'
                    //     // templateUrl: 'scripts/layouts/sidenav.html',
                    // },
                    'footer': {
                        templateUrl: 'scripts/modules/footer/views/footer.main.html',
                        controller: 'FooterController'
                    }
                },
                resolve: {

                    localization: function ($rootScope, $http, $state) {
                        $rootScope.getTranslations = function (lang, reload) {
                            window.Capacitor.Plugins.Network.getStatus().then(status => {
                                if (status.connected) {
                                    $http.get(`${$rootScope.pqDomain}/api/v1/get-translations/${lang}`).then(response => {
                                        window.Capacitor.Plugins.Preferences.set({
                                            key: `cachedTranslations-${lang}`,
                                            value: JSON.stringify(response.data.translations)
                                        });
                                        $rootScope.translations = response.data.translations;
                                        $rootScope.localeButtonString = response.data.current_locale === 'en' ? 'En' : 'Fr';
                                        if ($rootScope.localeButtonString == 'Fr') {
                                            $.datepicker.setDefaults($.datepicker.regional['fr']);
                                        } else {
                                            $.datepicker.setDefaults($.datepicker.regional['en']);
                                        }
                                        if (reload) {
                                            $state.reload();
                                        }
                                    });
                                } else {
                                    window.Capacitor.Plugins.Preferences.get({ key: `cachedTranslations-${lang}` }).then(cachedData => {
                                        if (cachedData.value) {
                                            $rootScope.translations = JSON.parse(cachedData.value);
                                        } else {
                                            $rootScope.translations = null;
                                        }
                                    });
                                }
                            });
                        };
                        $rootScope.setLocalization = function (lang) {
                            $rootScope.getTranslations(lang, 1);
                        };

                        $rootScope.trans = function (key) {
                            var replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
                            var translation = key.split('.').reduce(function (t, i) {
                                var toreturn = typeof t == 'undefined' || t[i] == 'undefined' ? '' : t[i];
                                return toreturn;
                            }, $rootScope.translations);

                            for (var placeholder in replace) {
                                translation = translation.replace(':'.concat(placeholder), replace[placeholder]);
                            }

                            return translation;
                        };

                        if (!$rootScope.translations) {
                            $rootScope.getTranslations('default', 0);
                        }
                    },

                    branding: function ($rootScope, brandingService, $location) {
                        // return brandingService.get(null, null, $rootScope.pqDomain);
                        if ($location.search().company && $location.host().includes('lease')) {
                            localStorage.setItem('lease_branding', $location.search().company);
                            $location.search('company', null)
                        }

                        if(localStorage.getItem('lease_branding')) {
                            var brandDomain =  `https://${localStorage.getItem('lease_branding')}.payquad.com`;
                            localStorage.removeItem('lease_branding')
                        } else {
                            var brandDomain = pqAppCompanySubdomain ? `https://${pqAppCompanySubdomain}.payquad.com` : $rootScope.pqDomain;
                        }

                        return brandingService.get(null, null, brandDomain);
                    },

                    //waiting when auth will be resolver in $stateChangeStart
                    authPromise: function ($rootScope) {
                        return $rootScope.authPromise.promise;
                    }
                }
            })
            .state('main.ng2Login', {
                url: '/n/login',
                views: {
                    "body@": {
                        controller: 'NG2LoginController',
                    },
                }
            })
            .state('main.ng2Login2Fa', {
                url: '/n/login/2fa',
                views: {
                    "body@": {
                        controller: 'NG2LoginController',
                    },
                }
            })
            .state('main.ng2', {
                url: '/n/:a/:b/:c/:d/:e',
                views: {
                    "body@": {
                        controller: 'NG2Controller',
                    },
                },
                reloadOnSearch: false,
                params: {
                    a: {squash: true, value: null},
                    b: {squash: true, value: null},
                    c: {squash: true, value: null},
                    d: {squash: true, value: null},
                    e: {squash: true, value: null},
                }
            });

    }

})();
