(function() {

    'use strict';

    angular
        .module('app')
        .factory('managerService', managerService);

    function managerService($http) {
        var base_url = '/api/v1/';
        var local_url = 'managers';

        return {
            getAllManagers: getAll,
            getManagerById: getById,
            createManager: create,
            updateManager: update,
            deleteManager: destroy,
            getPreset: getPresetById,
            getNewPreset: getNewPreset,
            getManagersByPropertyId: propertyId => $http.get(`${base_url}get-managers-by-property-id/${propertyId}`).then(r => r.data.data),
            getJuniorPermissions: () => $http.get(`${base_url}junior-permissions `).then(r => r.data.data)
        };

        function getAll()
        {
            return $http.get(base_url + local_url)
                .then(function (object) {
                    return object.data;
                });
        }

        function getById(id)
        {
            return $http.get(base_url + local_url +'/'+id)
                .then(function (object) {
                    return object.data;
                });
        }

        function create(data)
        {
            return $http({
                method: 'POST',
                url: base_url + local_url,
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

        function update(id, data)
        {
            return $http.put(base_url + local_url + '/'+id, data);
        }

        function destroy(id)
        {
            return $http.delete(base_url + local_url +'/'+ id);
        }

        function getPresetById(id)
        {
            return $http.get(base_url + 'companies/manager-presets/'+id);
        }
        function getNewPreset(companyId)
        {
            return $http.get(base_url + 'companies/manager-presets/new?companyId=' + companyId );
        }

    }

}());
