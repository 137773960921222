(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/billing/';

        $stateProvider
			.state('main.billingInvoiceList', {
				url: '/billing/invoices',
				views: {
					"body@": {
						templateUrl: url + 'invoice-list/invoice-list.html',
						controller: 'InvoiceListController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.billingInvoiceEdit', {
				url: '/billing/invoice/edit/:companyId/:id',
				views: {
					"body@": {
						templateUrl: url + 'invoice-edit/invoice-edit.html',
						controller: 'InvoiceEditController',
                        controllerAs: 'vm'
					}
				}
			})
			;
	}
}());
