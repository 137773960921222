(function () {
    "use strict";

    angular
        .module('pq.property')
        .factory('propertyEditData', propertyEditData);

    function propertyEditData($q, propertyDataService, merchantAccountsDataService)
    {
        return {
            load: load
        };

        // Loads all data into this.data and returns a promise
        function load(propertyId) {
           // var merchantAccounts = merchantAccountsDataService.getMerchants();
            var property = propertyDataService.getProperty(propertyId);

            // Retrieve all promised response and package it into an object
            return $q.all([property]).then(
                function (results) {
                    return {
                        property: results[0],
                       // merchantAccounts: results[1]
                    }
                });
        }
    }

})();