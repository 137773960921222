(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/profile/';

        $stateProvider
            .state('main.profileRegister', {
                url: '/profile/register',
                views: {
                    "body@": {
                        templateUrl: url + 'register/register.html',
                        controller: 'ProfileRegisterController',
                        controllerAs: 'vm'
                    }
                }
            })
			;
	}
}());
