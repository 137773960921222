(function () {

    'use strict';

    angular
        .module('app')
        .factory('merchantAccountData', merchantAccountDataFactory);

    function merchantAccountDataFactory($q, merchantAccountService)
    {
        return {
            load: load
        };

        function load(merchantAccountId) {

            if(merchantAccountId != null) {
                var merchantAccount = merchantAccountService.getMerchantAccountById(merchantAccountId);
            }

            return $q.all([merchantAccount]).then(
                function (results) {
                    return {
                        merchantAccount: results[0]
                    }
                });

        }
    }

})();