(function () {

    'use strict';

    angular
        .module('app')
        .controller('MaintenanceRequestsIndexController', maintenanceRequestsIndexController)
        .config(function ($animateProvider) {
            $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
        });

    function maintenanceRequestsIndexController($scope, $state, $rootScope, $http, langService, helperService) {
        $scope.t = langService.t('maint_req');
        $scope.isMobile = false;

        $scope.badge_counts = {
            all: 0,
            open: 0,
            in_progress: 0,
            completed: 0,
            scheduled: 0,
            approvals: 0,
            rejected: 0,
            cancelled: 0,
            unassigned: 0
        };

        $scope.managerMobile = {
            filter: {
                text: '',
                timeline: {
                    today: false,
                    '1dayago': false,
                    '1weekago': false,
                    '2weeksago': false,
                    '1monthago': false,
                    '2monthago': false,
                    '4monthago': false,
                    '6monthago': false,
                },
                priority: {
                    high: false,
                    medium: false,
                    low: false,
                    emergency: false,
                },
                status: {
                    open: false,
                    inprogress: false,
                    completed: false,
                    rejected: false,
                    cancelled: false,
                },
            },
            loading: {
                tickets: false,
                properties: false
            }
        };

        $scope.isMobile = helperService.isMobileOrTablet() || Boolean(window.Capacitor.isNativePlatform());
        $scope.propertiesFilterString = {value: ''};

        $scope.getBadgeCounts = function () {
            $http.post('/api/v1/maintenance-requests/get-status-counts').success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.badge_counts = data.data.badge_counts;
            });
        };
        $scope.getBadgeCounts();

        $scope.getRequests = function () {
            $scope.requestsOpen = [];
            $scope.requestsCancel = [];
            $http.post('/api/v1/maintenance-requests/list').success(function (data) {
                data.forEach(element => {
                    if (element.status == 'open' || element.status == 'in progress') {
                        $scope.requestsOpen.push(element);
                    } else if (element.status == 'cancelled' || element.status == 'completed' || element.status == 'rejected') {
                        $scope.requestsCancel.push(element);
                    }
                });

                //$scope.requests = data;

                $scope.viewby = 5;
                $scope.totalItems = $scope.requestsOpen.length;
                $scope.currentPage = 1;
                $scope.itemsPerPage = $scope.viewby;
                $scope.maxSize = 5; //Number of pager buttons to show

                $scope.setPage = function (pageNo) {
                    $scope.currentPage = pageNo;
                };

                $scope.setItemsPerPage = function (num) {
                    $scope.itemsPerPage = num;
                    $scope.currentPage = 1; //reset to first page
                };

                $scope.viewbyCan = 5;
                $scope.totalItemsCan = $scope.requestsCancel.length;
                $scope.currentPageCan = 1;
                $scope.itemsPerPageCan = $scope.viewbyCan;
                $scope.maxSizeCan = 5; //Number of pager buttons to show

                $scope.setPageCan = function (pageNo) {
                    $scope.currentPageCan = pageNo;
                };

                $scope.setItemsPerPageCan = function (num) {
                    $scope.itemsPerPageCan = num;
                    $scope.currentPageCan = 1; //reset to first page
                };

            });
        };
        $scope.requests = [];
        $scope.getManagerRequestsMobile = function () {
            $scope.managerMobile.loading.tickets = true;
            $http.get('/api/v1/maintenance-requests/list-manager-mobile', {length: 100}).success(function (response) {
                $scope.allRequests = response.data.data
                $scope.filteredRequests = response.data.data

                $scope.viewbyCan = 5;
                $scope.totalItemsCan = $scope.filteredRequests.length;
                $scope.currentPageCan = 1;
                $scope.itemsPerPageCan = $scope.viewbyCan;
                $scope.maxSizeCan = 5; //Number of pager buttons to show

                $scope.setPageCan = function (pageNo) {
                    $scope.currentPageCan = pageNo;
                };

                $scope.setItemsPerPageCan = function (num) {
                    $scope.itemsPerPageCan = num;
                    $scope.currentPageCan = 1; //reset to first page
                };
                $scope.managerMobile.loading.tickets = false;
            });
        };

        if($rootScope.authUser.permissions === 'tenant'){
            $scope.getRequests();
        } else {
            $scope.getManagerRequestsMobile();
        }

        if($rootScope.authUser.permissions === 'tenant' || $scope.isMobile){ 
            $scope.priorities_strings = {}
            $http.get('/api/v1/translations/get-display-strings/', {
                params: {
                    category: "maintenance_request_priority",
                }
            }).then(function successCallback(data) {
                $scope.priorities_strings = data.data;
            }, function errorCallback(data){
                console.log('priority translation fail');
            });

            $scope.subject_strings = {}
            $http.get('/api/v1/translations/get-display-strings/', {
                params: {
                    category: "maintenance_request_subject",
                }
            }).then(function successCallback(data) {
                $scope.subjects_strings = data.data;
            }, function errorCallback(data){
                console.log('translation fail');
            });
        }


        angular.element($('body')).scope().state = $state;

        $scope.chartsInit = function () {
            if (!$rootScope.authUser.maintenance_requests_pro) {
                jQuery('#charts').slideToggle();
            } else {
                $state.go('main.maintenanceRequestsPro');
            }
        };

        $scope.calendarInit = function () {
            $state.go('main.maintenanceRequestsCalendar');
        };

        $scope.po = {
            number: ''
        };

        $scope.searchByPoNumber = function () {
            $http.post('/api/v1/maintenance-request-po/get-by-po-number', $scope.po).success(function (data) {
                if (!data.success) {
                    toastr.error('Could not find PO');
                    $scope.po.number = '';
                    return;
                }

                $state.go('main.maintenanceRequestEdit', {id: data.data.mr_id});
            });
        };



        $scope.managerMobileFilterBy = () => {
            const t = $scope.managerMobile.filter.text.toLowerCase();
            const filteredResults = $scope.allRequests.filter(item => {
                const filterByTextResult = ~item.property.toLowerCase().indexOf(t)
                    || (item.unit && ~item.unit.indexOf(t))
                    || ~item.status.toLowerCase().indexOf(t)
                    || ~item.subject.toLowerCase().indexOf(t)
                    || ~item.priority.toLowerCase().indexOf(t)
                    || ~item.id.toString().indexOf(t);
                const filterByDate = ($scope.managerMobile.filter.timeline.today ? moment(item.created_at).startOf('day').isSame(moment().startOf('day')): false)
                    || ($scope.managerMobile.filter.timeline["1dayago"] ? moment(item.created_at).isAfter(moment().startOf('day').add(-1, 'days')) : false)
                    || ($scope.managerMobile.filter.timeline["1weekago"] ? moment(item.created_at).isAfter(moment().startOf('day').add(-1, 'weeks')) : false)
                    || ($scope.managerMobile.filter.timeline["2weeksago"] ? moment(item.created_at).isAfter(moment().startOf('day').add(-2, 'weeks')) : false)
                    || ($scope.managerMobile.filter.timeline["1monthago"] ? moment(item.created_at).isAfter(moment().startOf('day').add(-1, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["2monthago"] ? moment(item.created_at).isAfter(moment().startOf('day').add(-2, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["4monthago"] ? moment(item.created_at).isAfter(moment().startOf('day').add(-4, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["6monthago"] ? moment(item.created_at).isAfter(moment().startOf('day').add(-6, 'month')) : false);
                const filterByPriority = ($scope.managerMobile.filter.priority.low ? item.priority === 'low' : false)
                    || ($scope.managerMobile.filter.priority.medium ? item.priority === 'medium' : false)
                    || ($scope.managerMobile.filter.priority.high ? item.priority === 'high' : false)
                    || ($scope.managerMobile.filter.priority.emergency ? item.priority === 'emergency' : false);
                const filterByStatus = ($scope.managerMobile.filter.status.open ? item.status === 'open' : false)
                    || ($scope.managerMobile.filter.status.inprogress ? item.status === 'in progress' : false)
                    || ($scope.managerMobile.filter.status.completed ? item.status === 'completed' : false)
                    || ($scope.managerMobile.filter.status.rejected ? item.status === 'rejected' : false)
                    || ($scope.managerMobile.filter.status.completed ? item.status === 'completed' : false)
                    || ($scope.managerMobile.filter.status.cancelled ? item.status === 'cancelled' : false);;
                const filterByProperty = $scope.properties.some(e => {
                    return e.selected && e.name.toLowerCase() === item.property.toLowerCase()
                });
                return (Object.values($scope.managerMobile.filter.priority).some(e => e) ? filterByPriority : true )
                    && (Object.values($scope.managerMobile.filter.status).some(e => e) ? filterByStatus : true )
                    && (Object.values($scope.managerMobile.filter.timeline).some(e => e) ? filterByDate : true )
                    && (Object.values($scope.properties).some(e => e.selected) ? filterByProperty : true )
                    && filterByTextResult;
            });
            $scope.filteredRequests = filteredResults;
        }

        $scope.showFilterModal = (type) => {
            if (type === 'timeline') {
                $('#mobileManagerFilterTimeline').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'priority') {
                $('#mobileManagerFilterPriority').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'status') {
                $('#mobileManagerFilterStatus').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'properties') {
                $scope.getProperties();
                $('#mobileManagerFilterProperties').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
        }

        $scope.properties = [];
        $scope.propertiesFiltered = [];
        $scope.getProperties = function () {
            if ($scope.properties.length === 0){
                $scope.managerMobile.loading.properties = true;
                $http.post('/api/v1/get-filter-properties').success(function (data) {
                    $scope.properties = data.data.properties;
                    $scope.propertiesFiltered = data.data.properties;
                    $scope.managerMobile.loading.properties = false;
                });
            }
        };


        $scope.filterProperties = (e) => {
            const search = $scope.propertiesFilterString.value.toLowerCase();
            $scope.propertiesFiltered = $scope.properties.filter(propeerty => ~propeerty.name.toLowerCase().indexOf(search));
        }

        $scope.getPriorityBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.priority).some(e => e)};
        }

        $scope.getStatusBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.status).some(e => e)};
        }

        $scope.getTimelineBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.timeline).some(e => e)};
        }

        $scope.getPropertyBtnClass = () => {
            return {"active": Object.values($scope.properties).some(e => e.selected)};
        }

        $scope.getPreviewText = function(description) {
            var descriptionSplit = description.replace("\r\n", "\n").replace("\r", "\n").split("\n");
            var previewText = descriptionSplit.find(t => t !== '');
            if(previewText) {
                return previewText;
            } else {
                return description;
            }
        }

        $scope.doExport = function() {
            var filter_tags = localStorage.getItem('filter_tagsmaintenance-requests');
            if (filter_tags == null) {
                var url = '/api/v1/maintenance-requests-csv';
                window.location.href = url;
                return;
            }
            var params = JSON.parse(filter_tags);
            var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

            var url = '/api/v1/maintenance-requests-csv?' + queryString;
            window.location.href = url;
        };
    }
}());
