(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqPropertyNotes', pqPropertyNotes);

    function pqPropertyNotes() {
        return {
            restrict: 'E',
            controller: 'PqPropertyNotesController',
            templateUrl: 'scripts/modules/property/property-edit/property-notes/property-notes.html',
            scope: {
                property: '='
            }
        };
    }

    angular
        .module('app')
        .controller('PqPropertyNotesController', function ($scope, $rootScope, $http) {
            $scope.note = {
                note: ''
            };

            console.log('property', $scope.property)

            $scope.addPropertyNote = () => {
                if ($scope.note.note.length > 65535) {
                    toastr.error('Note cannot be longer than 65535 characters');
                    return;
                }
                if($scope.note.note.length){
                    $http.post('/api/v1/properties/add-note/' + $scope.property.id, {
                        'note': $scope.note.note
                    }).then(function (r) {
                        toastr.success('Note Added');
                        $scope.property.notes.push({
                            note: $scope.note.note,
                            created_at: (new Date()).toMysqlFormat(),
                            manager: {
                                first_name: $rootScope.authUser.first_name,
                                last_name: $rootScope.authUser.last_name
                            }
                        });
                        $scope.note.note = '';
                    });

                }
            }
        });
})();
