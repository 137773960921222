(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqPayrixDocumentForm', pqPayrixDocumentForm);

    angular
        .module('app')
        .controller('PqPayrixDocumentFormController', PqPayrixDocumentFormController);

    function pqPayrixDocumentForm() {
        return {
            restrict: 'E',
            templateUrl: "scripts/modules/document/index/payrix-directive/payrix.html",
            controller: 'PqPayrixDocumentFormController',
            scope: {
                tenantData: '=',
                tenantInfo: '=',
                realtor: '=',
                merchantAccount: '=',
                billingSameAsMailing: '=',
                createBooking: '&',
                clearTenantData: '&',
                endProcessing: '&',
                t: '=',
                payrixValidationErrors: '=',
            }
        };
    }

    function PqPayrixDocumentFormController($scope, $timeout, $http) {
        $scope.offline_payment = 0;
        $scope.payrixPayfieldsStyle = {
            ".input": {
                padding: '6px 12px',
                color: '#555',
                border: '1px solid #024953',
                'font-size': '14px',
                'line-height': 1.42,
                'background-color': '#f2f2f2',
                'background-image': 'none',
                'border-radius': '4px',
                'font-family': '"Open Sans", sans-serif',
                width: '235px'
            },
            ".account_type": {
                padding: '6px 12px',
                color: '#555',
                border: '1px solid #024953',
                'font-size': '14px',
                'line-height': 1.42,
                'background-color': '#f2f2f2',
                'background-image': 'none',
                'border-radius': '4px',
                'font-family': '"Open Sans", sans-serif',
                // width: '240px',
                height: '45px'
            },
            ".card-icon": {
                'top': '6px'
            }
        };
        $scope.payFieldsLoading = false;

        $scope.continue = () => {
            $scope.tenantData.provider = 'payrix';
            if ($scope.tenantData.paymentType !== 'pad') {
                PayFields.submit()
            } else {
                $('.finalize').appendTo('body').modal('show');
            }
        };

        $scope.cconload = () => {
            $scope.payFieldsLoading = false;
            PayFields.config.apiKey = $scope.merchantAccount.payrix_api_key_public;
            PayFields.config.merchant = $scope.merchantAccount.payrix_merchant_id;

            PayFields.customizations = {
                style: $scope.payrixPayfieldsStyle,
                placeholders: {
                    '#name': 'Cardholder\'s name',
                    '#expiration': 'Expiration Date - mm/yy',
                    '#payment_cvv': 'CVV Code',
                    "#payment_number": 'Card Number'
                }
            };

            PayFields.config.amount = 0;
            // Option to create token while also a transaction. Remove this line to run a sale
            PayFields.config.mode = 'token';

            PayFields.fields = [
                {type: "name", element: "#name"},
                {type: "number", element: "#number"},
                {type: "cvv", element: "#cvv"},
                {type: "expiration", element: "#expiration"}
            ];
            PayFields.customizations.optionalFields = ['#address1', '#city', '#state', '#zip', '#email', '#phone']
            // PayFields.onSuccess = (r) => {
            //     console.log(r)
            //     $scope.tenantData.card_holder_name = `${r.data[0].customer.first} ${r.data[0].customer.last}`;
            //     $scope.tenantData.card_number = r.data[0].payment.number;
            //     $scope.tenantData.expiration_year = 20 + r.data[0].expiration.slice(-2);
            //     $scope.tenantData.expiration_month = r.data[0].expiration.substring(0, 2);
            //     $scope.tenantData.payrix = r;
            //     $scope.tenantData.provider = 'payrix';
            //     $scope.$apply();
            //
            // };
            PayFields.onValidationFailure = (r) => {
                console.log('onValidationFailure', r)
                toastr.clear();
                $timeout(() => {
                    toastr.error('Check entered data for payment method', '',  {
                        preventDuplicates: true
                    })
                }, 500);
            };
            PayFields.onFailure = () => console.log('onFailure');

            PayFields.appended ? PayFields.addFields() : null;

            $scope.$apply();
        }

        $scope.loadPayFieldsCC = function () {
            $scope.payFieldsLoading = true;
            $('[src="https://test-api.payrix.com/payFieldsScript"]').remove()
            if (document.getElementById("payFields-iframe")) {
                document.getElementById("payFields-iframe").remove();
            }

            window.PayFields = undefined

            let externalScript = document.createElement('script')
            externalScript.setAttribute('src', 'https://test-api.payrix.com/payFieldsScript')

            externalScript.onload = $scope.cconload;

            document.body.appendChild(externalScript)
        }

        $scope.$watch('tenantData.paymentType', function (newVal, oldVal) {
            if ((newVal === 'credit' || newVal === 'debit') && (oldVal === 'pad' || !oldVal)) {
                $scope.loadPayFieldsCC();
            }
        });

    }

})();
