(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyDocumentMerchant', pqPropertyDocumentMerchant);

    function pqPropertyDocumentMerchant() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-document-merchant/property-document-merchant.html'
        };
    }

})();
