(function () {
    'use strict';

    angular
        .module('app')
        .controller('QuickPayStartController', quickPayStartController);

    function quickPayStartController($scope, $http, $state, $stateParams, $rootScope, $timeout) {
        $scope.company = {
            id: parseInt($stateParams.companyId),
        };

        $scope.ref = {
            property: {}
        };

        $scope.quick_pay = {};
        $scope.quick_pay_created = 0;
        $scope.properties = [];

        $scope.getQuickPayCompany = function () {
            $http.post('/api/v1/quick-pay/get-company/' + $scope.company.id).success(function (data) {
                if (!data.success) {
                    toastr.error('Access Denied');
                    return
                }

                $scope.company = data.data.company;
                $scope.company.id = parseInt($stateParams.companyId);
                $scope.properties = data.data.properties;

                $scope.ref.property = $scope.properties[0];

                $timeout(function () {
                    $('#quick-pay-property').multipleSelect({
                        single: true,
                        filter: true,
                        width: '401px',
                        dropWidth: '330px',
                    });
                    $scope.show_tenants = 1;
                }, 500);
            });
        };
        $scope.getQuickPayCompany();

        $scope.startQuickPay = function () {
            $scope.updatingQuickPay = true;

            $scope.quick_pay.property_id = $scope.ref.property[0];

            $http.post('/api/v1/quick-pay/start/' + $scope.company.id, $scope.quick_pay).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error creating quick pay");
                    return;
                }

                $scope.updatingQuickPay = false;
                toastr.success('quick pay create, please check your email');
                $scope.quick_pay_created = 1;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.udpatingQuickPay = false;
                toastr.error(data.error.message);
            });
        };
    }
}());
