(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyRentalAddress', pqPropertyRentalAddress);

    function pqPropertyRentalAddress() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-rental-address/property-rental-address.html'
        }
    }

})();
