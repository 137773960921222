(function() {

	'use strict';

    angular
		.module('app')
		.controller('PaymentReceiptExtensionController', PaymentReceiptExtensionController);

	function PaymentReceiptExtensionController($scope,$http,$rootScope, $stateParams, $state, $log, $sce, langService) {

	    $scope.t = langService.t('payment_history');

        $scope.is_doc_receipt = 0;
		$http.get('api/v1/payments/receipt-extension/'+$stateParams.id).success(function(data) {
            $scope.receipt = data.data;
            var name = $scope.receipt.tenant.name;
            var address = $scope.receipt.tenant.address;
            var unit = $scope.receipt.tenant.unit;
            $scope.lang.confirm_payment = $rootScope.trans('payment_history.confirm_payment',{name: name, address: address, unit: unit});
            $scope.is_doc_receipt = $scope.receipt.is_doc_payment;
        })
        .error(function(data, status, headers, config) {
            if (typeof data.stopaction != 'undefined') {
                return;
            }

            $log.log(data.error + ' ' + status);
            toastr.error('','Payment not found');
        });

        $scope.print = function() {
    	   window.print();
    	};

        $scope.trustAsHtml = function(html_string) {
            return $sce.trustAsHtml(html_string);
        };

    }

}());
