(function () {
    'use strict';

    angular
        .module('pq.property')
        .directive('pqPropertyShowings', pqPropertyShowings);

    function pqPropertyShowings() {
        return {
            restrict: 'E',
            controller: 'PqPropertyShowingsController',
            templateUrl: 'scripts/modules/property/property-edit/property-showings/property-showings.html',
            scope: {
                showings: '=',
                validation: '='
            }
        };
    }

    angular
        .module('pq.property')
        .controller('PqPropertyShowingsController', function ($scope, $timeout, $rootScope) {
            $scope.stepsArray = [];
            for (let i = 14; i <= 46; i++) {
                const t = i > 25 ? i - 24 : i;
                let hour, minute;
                if (i % 2 === 0) {
                    hour = t / 2;
                    minute = '00';
                } else {
                    hour = Math.floor(t / 2);
                    minute = '30';
                }
                const ampm = i > 23 ? 'PM' : 'AM';
                $scope.stepsArray.push(`${hour}:${minute} ${ampm}`);
            }

            $scope.blankShowings = [];
            const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            days.forEach(item => $scope.blankShowings.push({
                name: item,
                isEnabled: false,
                ranges: [{from: '7:00 AM', to: '11:00 PM'}]
            }));

            $scope.$watch('showings', newVal => {
                if (!newVal) {
                    $scope.showings = angular.copy($scope.blankShowings);
                }
            });

            $scope.$watch('validation', newVal => {
                if (!newVal) {
                    $scope.validation = {isValid: true, error: ''};
                }
            });

            $scope.addRange = index => {
                $scope.showings[index].ranges.push({from: '7:00 AM', to: '11:00 PM'});
            };

            $scope.removeRange = (showingIndex, rangeIndex) => {
                $scope.showings[showingIndex].ranges.splice(rangeIndex, 1);
            };

            $scope.forceRender = () => {
                $timeout(function () {
                    $scope.$broadcast('rzSliderForceRender');
                });
            };

            $scope.onChange = () => {
                const timeToNumber = time => {
                    const part = time.split(':');
                    const part2 = part[1].split(' ');
                    let number = +part[0];
                    if (part2[1] === 'PM' && number !== 12) {
                        number += 12;
                    }
                    number *= 2;
                    if (part2[0] === '30') {
                        number += 1;
                    }
                    return number;
                };

                let invalidShowingDay = '';

                $scope.showings.filter(day => day.isEnabled).forEach(day => {
                    day.ranges.forEach((range, index) => {
                        day.ranges.forEach((checkedRange, checkedIndex) => {
                            if (index !== checkedIndex) {
                                const wrongFrom = timeToNumber(range.from) >= timeToNumber(checkedRange.from) && timeToNumber(range.from) <= timeToNumber(checkedRange.to);
                                const wrongTo = timeToNumber(range.to) >= timeToNumber(checkedRange.from) && timeToNumber(range.to) <= timeToNumber(checkedRange.to);
                                if (wrongFrom || wrongTo) {
                                    invalidShowingDay = day.name;
                                }
                            }
                        });
                    });
                });

                if (invalidShowingDay.length) {
                    $scope.validation.isValid = false;
                    $scope.validation.error = `Sorry, you specified intersecting intervals showings. Day: ${invalidShowingDay}`;
                } else {
                    $scope.validation.isValid = true;
                }
            };

            $scope.getSliderColor = () => '#008B95';
            $scope.getPointerColor = () => '#008B95';
        });


})();
