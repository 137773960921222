(function () {

    'use strict';

    angular
        .module('app')
        .factory('authenticationService', authenticationService);

    function authenticationService($http, $rootScope, $q, jwtService) {

        var base_url = 'api/v1/users/';

        return {
            register: function (credentials) {
                return $http.post(base_url + 'register', credentials);
            },

            login: function (credentials) {
                return $http({
                    method: 'POST',
                    url: base_url + 'login',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    data: $.param(credentials)
                });
            },

            validateEmail: function (email) {
                return $http({
                    method: 'POST',
                    url: base_url + 'validate-email',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    data: $.param(email)
                });
            },

            logout: function () {
                const jwt = jwtService.getToken();
                return $http({
                    method: 'POST',
                    url: base_url + 'logout',
                    headers: {'Authorization': 'Bearer ' + jwt }
                });
            },

            changeAccount: function (id) {
                return $http({
                    method: 'POST',
                    url: base_url + 'change-account',
                    data: {'id': id}
                });
            },

            getAuth: function () {
                return window.Capacitor.Plugins.Network.getStatus().then(status => {
                    if (status.connected) {
                        return $http.get(base_url + 'get-auth?new=0').then(response => {
                            window.Capacitor.Plugins.Preferences.set({
                                key: 'cachedUser',
                                value: JSON.stringify(response.data)
                            });
                            return response.data;
                        });
                    } else {
                        return window.Capacitor.Plugins.Preferences.get({ key: 'cachedUser' }).then(cachedData => {
                            if (cachedData.value) {
                                return JSON.parse(cachedData.value);
                            } else {
                                return Promise.reject('No cached user data available');
                            }
                        });
                    }
                });
            },

            showModule: function () {
                if (Boolean(window.Capacitor.isNativePlatform()) && !angular.isUndefined($rootScope.authUser)) {
                    return $rootScope.authUser.company_name !== 'Realview';
                } else {
                    return true;
                }
            },

            getTenantMobileMenu: function() {
                return {
                    payments: {
                        isActive: $rootScope.authUser.user_permissions.payments,
                        menuNumber: 0
                    },
                    maintenance: {
                        isActive: $rootScope.authUser.user_permissions['maintenance-requests'],
                        menuNumber: 1
                    },
                    amenities: {
                        isActive: $rootScope.authUser.user_permissions.amenities,
                        menuNumber: 2
                    },
                    communication: {
                        isActive: $rootScope.authUser.user_permissions['communications'],
                        menuNumber: 3
                    },
                    paymentHistory: {
                        isActive: $rootScope.authUser.user_permissions.payments,
                        menuNumber: 4
                    },
                    parcels: {
                        isActive: $rootScope.authUser.user_permissions.parcels,
                        menuNumber: 5
                    },
                    documents: {
                        isActive: $rootScope.authUser.user_permissions.documents,
                        menuNumber: 6
                    },
                    insurance: {
                        isActive: $rootScope.authUser.insurance_nav,
                        menuNumber: 7
                    },
                    rewards: {
                        isActive: $rootScope.authUser.rewards,
                        menuNumber: 8
                    }
                };
            },

            getManagerMobileMenu: function() {
                return {
                    companies: {
                        isActive: this.showModule() && $rootScope.authUser.permissions === 'payquad_admin',
                        menuNumber: 0
                    },
                    properties: {
                        isActive: this.showModule() && $rootScope.authUser.permissions !== 'payquad_admin' && $rootScope.authUser.user_permissions.properties,
                        menuNumber: 1
                    },
                    residents: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.residents,
                        menuNumber: 2
                    },
                    payments: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.payments,
                        menuNumber: 3
                    },
                    managers: {
                        isActive: this.showModule() && $rootScope.authUser.permissions !== 'junior_manager',
                        menuNumber: 4
                    },
                    communication: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions['communications'],
                        menuNumber: 5
                    },
                    maintenance: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions['maintenance-requests'],
                        menuNumber: 6
                    },
                    reports: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.reports,
                        menuNumber: 7
                    },
                    documents: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.documents,
                        menuNumber: 8
                    },
                    amenities: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.amenities,
                        menuNumber: 9
                    },
                    surveys: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.surveys,
                        menuNumber: 10
                    },
                    lease: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions['lease-applications'],
                        menuNumber: 11
                    },
                    parcels: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.parcels,
                        menuNumber: 12
                    },
                    inspections: {
                        isActive: this.showModule() && $rootScope.authUser.user_permissions.inspections,
                        menuNumber: 13
                    },
                };
            },

            initMobileMenuItems: function() {
                if ($rootScope.authUser) {
                    if ($rootScope.authUser.permissions === 'tenant') {
                        $rootScope.mobileMenus = this.getTenantMobileMenu();
                    } else if($rootScope.authUser.permissions === 'admin_manager' ||
                        $rootScope.authUser.permissions === 'payquad_admin' ||
                        $rootScope.authUser.permissions === 'junior_manager') {

                        $rootScope.mobileMenus = this.getManagerMobileMenu();
                    } else {
                        return
                    }

                    $rootScope.activeInMobile = [];

                    let obj = $rootScope.mobileMenus;

                    for (let key in obj) {
                        if (obj[key].isActive) {
                            $rootScope.activeInMobile.push(obj[key].menuNumber);
                        }
                    }

                    $rootScope.activeInMobile = $rootScope.activeInMobile.sort(function (a, b) {
                        return a - b;
                    });

                    let numberOfMenuItemsToShow = 4;

                    $rootScope.activeInMobile = $rootScope.activeInMobile.slice(0, numberOfMenuItemsToShow);
                }
            },

            initAuth: function () {
                const self = this;
                const defer = $q.defer();

                this.getAuth()
                    .then(function (data) {
                        if (typeof data.data !== 'undefined') {
                            $rootScope.html_version = data.data.html_version;
                            delete data.data.html_version;
                            $rootScope.isModuleTemporarilyHiddenForCompany = Boolean(window.Capacitor.isNativePlatform()) ? data.data.company_name !== 'Realview' : true;
                        } else if (typeof data.extra != 'undefined' && typeof data.extra.html_version != 'undefined') {
                            $rootScope.html_version = data.extra.html_version;
                            delete data.extra.html_version;
                        }
                        $rootScope.authUser = data.data;
                        self.initMobileMenuItems();
                        self.updateEditor();
                        defer.resolve();
                    });

                return defer.promise;
            },

            updateEditor: function () {
                if($rootScope.authUser){
                    window.editorVariablesIsEnabled = $rootScope.authUser.permissions === 'payquad_admin' || $rootScope.authUser.permissions === 'admin_manager';
                }else{
                    window.editorVariablesIsEnabled = false;
                }

            }

        };

    }

}());
