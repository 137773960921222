(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/amenity/';

        $stateProvider
            .state('main.amenities', {
                url: '/amenities',
                views: {
                    'body@': {
                        templateUrl: url + 'index/index.html',
                        controller: 'AmenitiesIndexController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.amenityBook', {
                url: '/amenity/book',
                views: {
                    'body@': {
                        templateUrl: url + 'book/book.html',
                        controller: 'AmenityBookController',
                        controllerAs: 'vm'
                    }
                },
                params: {ticket_booking_id: 0}
            })
            .state('main.amenityBookCreate', {
                url: '/amenity/book/create/:id/:property_id',
                views: {
                    'body@': {
                        templateUrl: url + 'book-create/book-create.html',
                        controller: 'AmenityBookCreateController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.amenityBookings', {
                url: '/amenity/bookings',
                views: {
                    'body@': {
                        templateUrl: url + 'bookings/bookings.html',
                        controller: 'AmenityBookingsController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.amenityEdit', {
                url: '/amenity/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'edit/edit.html',
                        controller: 'AmenityEditController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
}());
