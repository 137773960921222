(function () {

    'use strict';

    angular
        .module('app')
        .controller('ShowingBookingController', function ($scope, $state, resolvedProperty, showingService, $rootScope, brandingService,langService) {
            $scope.t = langService.t('lease_app');
            $rootScope.branding['hideCompanyLogo'] = true;
            $scope.property = resolvedProperty;
            $scope.prospect = {
                property_id: resolvedProperty.id,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                unit_type: '',
                move_in_at: '',
                isSent: false,
                date: null,
                time: null,
                prettyDate: null,
                virtual_showing_type: null,
                receive_marketing: 0,
                other_virtual_type: ''
            };
            $scope.isTimeSelectionActive = {isActive: false};
            $scope.showingTime = [];
            $scope.virtualMethods = [$scope.t('in_person'), 'WhatsApp', 'Zoom', 'FaceTime', 'Duo', 'Skype', $scope.t('other')];
            if (window.location.host.indexOf('leasing') == -1) {
                window.location.href = 'https://' + $scope.property.company.subdomain  + '.leasing.payquad.com/' +     window.location.hash
            }
            $scope.getBranding = function(){
                brandingService.get(null, $scope.property.id).then(function(data) {
                    $rootScope.branding = data.data;
                });
            };
            $scope.getBranding();

            $scope.onDateChange = () => {
                $scope.isTimeSelectionActive.isActive = false;
                $scope.prospect.date = moment($scope.prospect.date).format('YYYY-MM-DD');
                $scope.prospect.prettyDate = moment($scope.prospect.date).locale($rootScope.localeButtonString).format('DD MMMM, YYYY');
                showingService.checkTimes($scope.prospect.property_id, $scope.prospect.date, null).then(data => {
                //showingService.checkTimes($scope.prospect.property_id, $scope.prospect.date, null, moment().utcOffset()/60).then(data => {
                    $scope.showingTime = data;
                    if ($scope.showingTime.length) {
                        $scope.isTimeSelectionActive.isActive = true;
                    } else {
                        toastr.error($scope.t('no_avail_times'));
                    }
                });
            };

            $scope.setTime = (index, time) => {
                $scope.prospect.time = time;
                $state.go('main.showingsBooking.step2');
            };

            $scope.back = () => {
                $state.go('main.showingsBooking');
            };

            $scope.save = prospect => {
                if (prospect.$invalid) {
                    Object.keys(prospect.$error).forEach(field => prospect.$error[field].forEach(input => input.$setTouched()));
                    toastr.error($scope.t('fill_out_form_correctly_plz'));
                } else {
                    $scope.prospect.isSent = true;
                    $scope.prospect.time_zone_offset = moment().utcOffset()/60;
                    showingService.createByClient($scope.prospect);
                }
            };

            $scope.openDatepicker = () => {
                $('.ui-datepicker-trigger').click()
            };
        });

}());
