(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyCompanyInfo', pqPropertyCompanyInfo);

    function pqPropertyCompanyInfo() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-company-info/property-company-info.html'
        }
    }

})();