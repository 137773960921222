(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqSupport', pqSupport);

    pqSupport.$inject = ['$rootScope','$timeout'];

    function pqSupport($rootScope, $timeout)
    {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/support/support.html',
            scope: {
            },
            link: function ($scope) {
                $timeout(function () {
                    window.rootScope = $rootScope;

                    $('.modal.support').on('show.bs.modal',function(){
                        if(window.Capacitor.isNativePlatform()) {
                            var url = 'https://payquad.com/2342fdsa32/';
                            if  ($rootScope.authUser.permissions == 'tenant' || $rootScope.authUser.permissions == 'applicant') {
                                url = 'https://payquad.com/support-centre';
                            }
                            $('.modal.support').modal('hide');
                            window.Capacitor.InAppBrowser.open(url, '_blank');
                        }
                    })


                    $('.modal.support').on('shown.bs.modal',function(){
                        var url = 'https://payquad.com/2342fdsa32/';
                        if  ($rootScope.authUser.permissions == 'tenant' || $rootScope.authUser.permissions == 'applicant') {
                            url = 'https://payquad.com/support-centre';
                        }
                        
                        if(window.Capacitor.isNativePlatform()) {
                            $('.modal.support').modal('hide');
                        } else {
                            $(this).find('iframe').attr('src', url);
                        }
                        
                    })

                })
            }
        }
    }

})();
