(function() {

    'use strict';

    angular
        .module('app')
        .factory('accountData', accountData);

    function accountData($q, tenantService, propertyDataService)
    {
        return {
            load: load
        };

        function load(tenantId) {
console.log('load called');
            //var manageTenants = tenantService.getTenantManage();
            var approvalTenants = tenantService.getTenantApprovals();
            //var activityTenants = tenantService.getTenantActivities();
            //var properties = propertyDataService.getProperties();

            if(tenantId != null) {
                var tenant = tenantService.getTenantById(tenantId);
            }

            return $q.all([manageTenants, approvalTenants, activityTenants, properties, tenant]).then(
                function (results) {
                    return {
                      //  manageTenants: results[0],
                        approvalTenants: results[1],
                     //   activityTenants: results[2],
                    //    properties: results[3],
                        tenant: results[4]
                    }
                });

        }
    }
})();