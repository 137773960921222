(function () {

    'use strict';

    angular
        .module('app')
        .factory('maintenanceRequestService', maintenanceRequestService);

    function maintenanceRequestService($http) {
        const base_url = '/api/v1/';

        return {
            getTriggers: () => $http.get(`${base_url}maintenance-request-triggers`).then(r => r.data.data)
        };

    }

}());
