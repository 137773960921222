(function () {
    "use strict";

    angular
        .module('pq.property.data')
        .factory('merchantAccountsDataService', merchantAccountsDataService);

    /* @ngInject */
    function merchantAccountsDataService($http) {
        return {
            getMerchants: getMerchants,
            getPaymentServices: getPaymentServices
        };

        function getMerchants(id) {
            return $http.get('api/v1/merchant-accounts')
                .then(function (obj) {
                    return obj.data.data;
                });
        }

        function getPaymentServices(id) {
            return $http.get('api/v1/payments/services')
                .then(function (obj) {
                    return obj.data.data;
                });
        }

    }

})();