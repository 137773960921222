(function () {
    "use strict";

    angular
        .module('pq.property')
        .controller('PropertyCreateController', PropertyCreateController);

    /* @ngInject */
    function PropertyCreateController(propertyCreateData, $scope, _, $rootScope, propertyDataService, $state, $stateParams,$http,$upload) {
		$rootScope.newunit = {number:''};
        activate();

        function activate() {

            // Initialize paymentServices to contains a checked property which is false
            _.map(propertyCreateData.paymentServices, function(val) {
                if (!_.has(val, "checked")) {
                    val["checked"] = false;
                }
            });

            var companyId = "";
            if ($stateParams.companyId == "") {
                companyId = null;
            } else {
                companyId = $stateParams.companyId;
            }

            // Get new property model
            $scope.property = {
				new_property : true,
                additional_information: false,
                company_id: companyId,
                payment_services: propertyCreateData.paymentServices,
				property_units : []
            };

			$scope.addUnit = function(){

				for(var i in $scope.property.property_units){
					if($scope.property.property_units[i].unit ==$rootScope.newunit.number){
						toastr.warning("Unit Number "+$rootScope.newunit.number+" has already been added to this property");
						$rootScope.newunit.number = '';
						return;
					}
				}

						toastr.success("Unit Number "+$rootScope.newunit.number+" Added");
						$scope.property.property_units.push({'unit':$rootScope.newunit.number });
						$rootScope.newunit.number = '';

			}
            if($stateParams.companyId != "") {
                $http.get('/api/v1/companies/' + $stateParams.companyId + '/styling').success(function (data) {
                    if (data == 'true')
                        $scope.property.additional_information = true;
                    else
                        $scope.property.additional_information = false;
                });
                $http.get('/api/v1/companies/' + $stateParams.companyId + '/merchants').success(function (data) {
                    $scope.merchantAccounts = data;
                });
                $http.get('/api/v1/companies/' + $stateParams.companyId).success(function (data) {
                    if(typeof data.data !== 'undefined')
                        $scope.company = data.data;
                });
            }
            else {
                $http.get('/api/v1/company-styling').success(function (data) {
                    if (data == 'true')
                        $scope.property.additional_information = true;
                    else
                        $scope.property.additional_information = false;
                });
                $http.get('api/v1/company-merchant-accounts').success(function (data) {
                    $scope.merchantAccounts = data;
                });
            }

			$scope.updateMerchantAccount = function(){
				$scope.activeMerchantAccount = false;
				for(var i = 0; i < $scope.merchantAccounts.length; i++)
					if($scope.merchantAccounts[i].id == $scope.property.merchant_account_id)
						$scope.activeMerchantAccount = $scope.merchantAccounts[i];
			}
			$scope.$watch(function(scope) { return scope.property.merchant_account_id },$scope.updateMerchantAccount );
            ///api/v1/company-merchant-accounts

            $scope.requiresFileUpload = false;

            $scope.merchantAccounts = propertyCreateData.merchantAccounts;
            $scope.paymentServices = propertyCreateData.paymentServices;
            $scope.getSampleCSV = propertyDataService.getSampleCSV;
        }

		$scope.removeUnit = function(i){
			$scope.property.property_units.splice(i,1);
		}


        $scope.uploadCSVFile = function (file) {
            $scope.property.csv_file = file;
        };

        $scope.uploadLogoFile = function(file) {
            $scope.property.logo_file = file;
        };

        $scope.uploadBackgroundFile = function(file) {
            $scope.property.background_file = file;
        };

        $scope.phoneNumberPattern = (function() {
            var regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
            return {
                test: function(value) {
                    if( $scope.requireTel === false ) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();

        $scope.submit = function () {

            var files = [];
            var names = [];
            if($scope.property.csv_file !== undefined){
                files.push($scope.property.csv_file);
                names.push('csv_file');
            }
            if($scope.property.logo_file !== undefined){
                files.push($scope.property.logo_file);
                names.push('logo_file');
            }
            if($scope.property.background_file !== undefined){
                files.push($scope.property.background_file);
                names.push('background_file');
            }

            $upload.upload({
                url: 'api/v1/properties',
                method: 'POST',
                fileFormDataName: names,
                file: files,
                data: $scope.property
            }).success(function(data){
                if(data.success) {
                    if ($stateParams.companyId == "")
                        $state.go("main.properties", {});
                    else
                        $state.go("main.companyEdit", {companyId: $stateParams.companyId});
                    toastr.success('Property added');
                }
                else
                    toastr.error(data.errors);
            });//.failure(function(){toastr.error("Error adding property");});

        };
    }

})();
