(function () {
    "use strict";

    angular
        .module('app.filters')
        .filter('multiPropertyPartialMatching', multiPropertyCompleteMatchingFilter);

    /**
     * Applies multiple partial filters to item in items
     * @param _
     * @returns {Function}
     * @ngInject
     */
    function multiPropertyCompleteMatchingFilter(_) {
        return function (items, filters) {

            var filteredProperties = [];

            _.forEach(items, function (item) {

                _.forEach(filters, function (filterItem) {

                    var exitNow = false;

                    _.forEach(item, function (value) {
                        if (_.isString(value)) {
                            if (_.contains(value.toLowerCase(), filterItem.toLowerCase())) {
                                filteredProperties.push(item);
                                exitNow = true;
                                return false;
                            }
                        } else if (_.isNumber(value)) {
                            if (_.contains(value, filterItem)) {
                                filteredProperties.push(item);
                                exitNow = true;
                                return false;
                            }
                        }
                    });

                    if (exitNow) {
                        return false;
                    }
                });
            });

            return filteredProperties;
        }
    }

})();