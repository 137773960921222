(function() {

    'use strict';

    angular
        .module('app')
        .factory('passwordService', passwordService);

    function passwordService($http) {

        var base_url = '/api/v1/';
        var local_url = 'password/';

        return {

            remind: function(data)
            {
                return $http({
                    method: 'POST',
                    url: base_url + local_url + 'remind',
                    headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                    data: $.param(data)
                });
            },

            reset: function(data)
            {
                return $http({
                    method: 'POST',
                    url: base_url + local_url + 'reset',
                    headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                    data: $.param(data)
                });
            }

        }

    }

}());