(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageTemplateEditController', MessageTemplateEditController);

    function MessageTemplateEditController($scope,$http,$state,$stateParams,$rootScope,$timeout) {
        $("#app-wrapper").scrollTop(0);
        window.scrollTo(0, 0);

        $scope.submitting = false;

        $scope.template = {
            id: $stateParams.id,
            name: '',
            subject: '',
            content: '',
            company_id: 0,
            properties: []
        };

        $scope.dropdowns = {
            companies: {}
        };

        $scope.properties = [];

        $scope.getProperties = function() {
            $http.post('/api/v1/vendor/get-properties/' + $scope.template.company_id).success(function(data) {
                $scope.properties = data.data.properties;

                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        }

        $scope.getMessageTemplate = function(){
            $http.post('/api/v1/message-template/get/' + $scope.template.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.messagesTemplateList');
                }

                // lets populate stuff
                var template = data.data.template;

                $scope.template.name = template.name;
                $scope.template.subject = template.subject;
                $scope.template.content = template.content;
                $scope.template.company_id = template.company_id;

                angular.forEach(template.properties, function(property, key){
                    $scope.template.properties.push(property.id);
                });

                $scope.getProperties();

                if ($rootScope.authUser.permissions == 'payquad_admin') {
                    $http.post('/api/v1/message-template/get-companies').success(function(data) {
                        $scope.dropdowns.companies = data.data.companies
                    });
                }
            });
        }

        $scope.deleteMessageTemplate = function() {
            if (!confirm('Are you sure you want to delete this message template?')) {
                return; // they did not confirm
            }

            $scope.submitting = true;

            $http.post('/api/v1/message-template/delete/' + $scope.template.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error deleting message template");
                    $scope.submitting = false;
                    return;
                }

                $state.go('main.messagesTemplateList');
                toastr.success('Message Template deleted');
                $scope.submitting = false;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        }

        $scope.updateMessageTemplate = function() {
            $scope.submitting = true;

            $http.post('/api/v1/message-template/update/' + $scope.template.id, $scope.template).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error updating message template");
                    return;
                }

                $scope.submitting = false;
                $state.go('main.messagesTemplateList');
                toastr.success('Message Template Updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        }

        if (!$rootScope.authUser.pro_messaging) {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        $scope.getMessageTemplate();
    }
}());
