(function () {
    'use strict';

    angular
        .module('pq.property')
        .controller('PropertyTenantsController', PropertyTenantsController);

    /* @ngInject */
    function PropertyTenantsController($window, propertyChartService, $scope, $http, $stateParams) {

        var vm = this;
        vm.load = true;
        vm.chartInit = chartInit;
        vm.activate = activate;

        function activate() {
            $http.get('/api/v1/properties/' + $stateParams.property_id + '/info')
                .success(function (data) {
                    if (data.success) {
                        vm.property = data.data;
                        vm.chartInit();
                    } else {
                        toastr.error(data.errors);
                    }
                });

        }

        vm.activate();

        function chartInit() {
            if (!vm.load) {
                return;
            }
            propertyChartService.getChart(vm.property.id).then(function (value) {
                vm.load = false;
                vm.charts = value;
                vm.ticks = vm.charts.ticks.map((item, index) => [index, item]);
                vm.paidtransaction = vm.charts.paidTransactions.map((item, index) => [index, item.value]);
                vm.users = vm.charts.usersUse.map((item, index) => [index, item.value]);
                vm.apprtransactions = vm.charts.approvedTransactions.map((item, index) => [index, item.value]);
                if (vm.charts.paymentMethods.length != 0) {
                    vm.paymentMethods = vm.charts.paymentMethods.map(item => ({label: item.name, data: item.value}));
                } else {
                    vm.paymentMethods = [{label: 'No Payments Yes', data: 100}];
                }

                function drawCharts() {
                    //console.log(users);
                    console.log('open');
                    var plotR = $.plot('#graph1', [
                        {data: vm.paidtransaction, label: 'Paid Transactions'}
                    ], {
                        series: {

                            lines: {
                                show: true,
                                fill: true
                            },
                            points: {
                                show: true
                            }
                        },
                        grid: {
                            show: true,

                            hoverable: true,
                            clickable: true
                        },
                        xaxis: {
                            ticks: vm.ticks, tickLength: 0
                        },
                        yaxis:
                            {position: 'left', tickLength: 0},
                        colors: ['green'],
                        legend: {
                            show: false
                        }
                    });

                    var plotR1 = $.plot('#graph2', [
                        {data: vm.users, label: 'Users'}
                    ], {
                        series: {
                            lines: {
                                show: true,
                                fill: true
                            },
                            points: {
                                show: true
                            }
                        },
                        grid: {
                            hoverable: true,
                            clickable: true
                        },
                        xaxis: {
                            ticks: vm.ticks, tickLength: 0
                        },
                        yaxis:
                            {position: 'left', tickLength: 0},
                        colors: ['red'],
                        legend: {
                            show: false
                        }

                    });

                    var plotR2 = $.plot('#graph3', [
                        {data: vm.apprtransactions, label: 'Approved Transactions'}
                    ], {
                        series: {
                            lines: {
                                show: true,
                                fill: true
                            },
                            points: {
                                show: true
                            }
                        },
                        grid: {
                            hoverable: true,
                            clickable: true
                        },
                        xaxis: {
                            ticks: vm.ticks, tickLength: 0
                        },
                        yaxis:
                            {position: 'left', tickLength: 0},
                        colors: ['#00c7cc'],
                        legend: {
                            show: false
                        }

                    });

                    var plotR3 = $.plot('#graph4', vm.paymentMethods, {
                        series: {
                            pie: {
                                show: true,
                                label: {
                                    show: false,
                                    background: {
                                        opacity: 0.5,
                                        color: '#000',
                                        width: '100%',
                                        height: 'auto'
                                    }
                                }
                            }
                        },
                        grid: {
                            hoverable: true,
                            clickable: true
                        },
                        legend: {
                            show: false
                        }
                    });
                }

                $('#charts-toggle-btn').click(drawCharts());
            });
        }


        $('<div class=\'tooltip\'></div>').css({
            position: 'absolute',
            fontSize: '14px',
            color: 'black',
            fontWeight: 'bold',
            display: 'none',
            border: '1px solid #fdd',
            padding: '2px',
            'background-color': '#fee',
            opacity: 0.80
        }).appendTo('body');

        var pieLabel = $('#pieLabel');
        var tooltip = $('.tooltip');
        $('#graph4').bind('plothover', function (event, pos, obj) {
            if (!obj) {
                pieLabel.fadeOut(400);
                return;
            }

            function toTitleCase(str) {
                return str.replace(/\w\S*/g, function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            }

            var percent = parseFloat(obj.series.percent).toFixed(2);
            pieLabel.html(toTitleCase(obj.series.label) + ': ' + percent + '%')
                .fadeIn(200);

        });


        $('.graph').bind('plothover', function (event, pos, item) {


            if (item) {
                var y = item.datapoint[1].toFixed(2);
                y = ('' + y).replace('.00', '');
                tooltip.html(y)
                    .css({top: item.pageY + 5, left: item.pageX + 15})
                    .fadeIn(200);
            } else {
                tooltip.fadeOut(22);
            }

        });

        var win = angular.element($window);
    }

})();
