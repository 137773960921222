(function () {
    'use strict';

    angular
        .module('app')
        .controller('QuickPayFormController', quickPayFormController);

    function quickPayFormController($scope, $http, $state, $stateParams, $rootScope, langService) {
        $scope.quick_pay = {
            id: parseInt($stateParams.id),
        };
        $scope.t = langService.t('payments');
        var vm = this;
        vm.tenantInfo = {};
        vm.billing_same_as_mailing = false;
        vm.tenantData = {paymentType: false};
        $scope.has_access = 0;
        $scope.show_summary = 0;
        $scope.show_amount = 0;
        $scope.these_charges = '';
        $scope.loading_summary = false;
        $scope.terms = null;

        $scope.switchShowSummary = function(){

            if ($scope.merchantAccountProvider === 'payrix' && vm.tenantData.paymentType != 'pad') {
                PayFields.submit()
                PayFields.onSuccess = (r) => {
                    console.log(r)
                    vm.tenantData.card_holder_name = `${r.data[0].customer.first} ${r.data[0].customer.last}`;
                    vm.tenantData.card_number = r.data[0].payment.number;
                    vm.tenantData.expiration_year = 20 + r.data[0].expiration.slice(-2);
                    vm.tenantData.expiration_month = r.data[0].expiration.substring(0, 2);
                    vm.tenantData.payrix = r;
                    // vm.$apply();
                    vm.tenantData.provider = 'payrix';

                    vm.tenantData.credit_card_type = r.data[0].payment.method
                    // vm.tenantData.paymentType = vm.tenantData.paymentType
                    $scope.getConvenienceFees();
                    // debugger
                    // $('.finalize').appendTo('body').modal('show');
                };
            } else {
                if ($scope.show_summary == 0) {
                    if (vm.tenantData.paymentType != 'pad' && !jQuery('#card_type').text().replace("American Express","amex").toLowerCase()) {
                        toastr.error('invalid card number');
                        return;
                    }

                    if ($scope.quick_pay.amount == '' || isNaN($scope.quick_pay.amount)) {
                        toastr.error('Please enter a valid amount');
                        return;
                    }
                    if (vm.tenantData.paymentType !== 'pad') {
                        $scope.loading_summary = true;
                        $http.post('/api/v1/quick-pay/' + $scope.quick_pay.id + '/get-card-type', vm.tenantData).success(function(data) {
                            if (!data.success) {
                                if(data.message){
                                    toastr.error(data.message);
                                }else{
                                    toastr.error('Could not finalize Payment');
                                }
                                $scope.loading_summary = false;
                            }

                            if (data.data.type === 'credit' && vm.tenantData.paymentType !== 'credit') {
                                toastr.warning($scope.t('your_payment_method_type_has_been_changed_to_credit'));
                                vm.tenantData.paymentType = 'credit';
                            }

                            if (data.data.type === 'debit' && vm.tenantData.paymentType !== 'debit') {
                                toastr.warning($scope.t('your_payment_method_type_has_been_changed_to_debit'));
                                vm.tenantData.paymentType = 'debit';
                            }

                            $scope.getConvenienceFees();
                            // $scope.show_summary = $scope.show_summary == 1 ? 0 : 1;
                            $scope.these_charges = $rootScope.trans('payments.these_charges', {payment_method: vm.tenantData.paymentType});
                        }).error(e => {
                            toastr.error(e.message);
                            $scope.loading_summary = false;
                        });
                    } else {
                        $scope.getConvenienceFees();
                        // $scope.show_summary = $scope.show_summary == 1 ? 0 : 1;
                        $scope.these_charges = $rootScope.trans('payments.these_charges', {payment_method: vm.tenantData.paymentType});
                    }
                } else {
                    $scope.show_summary = 0;
                }
            }
        }

        $scope.clearTenantData = function () {
            vm.tenantData.institution_number = '';
            vm.tenantData.transit_number = '';
            vm.tenantData.account_number = '';
            vm.tenantData.day_of_month = '';
            vm.tenantData.description = '';
            vm.tenantData.editing_schedule_id = 0;
            vm.tenantData.paymentType = false;
            vm.tenantData.payment_recurring = 'one_time';
            vm.tenantData.billingunit = '';
            vm.tenantData.address = '';
            vm.tenantData.city = '';
            vm.tenantData.province = '';
            vm.tenantData.country = '';
            vm.tenantData.postal_code = '';
            vm.billing_same_as_mailing = false;
            vm.tenantData.card_holder_name = '';
            vm.tenantData.card_number = '';
            vm.tenantData.expiration_year = '';
            vm.tenantData.expiration_month = '';
            vm.tenantData.cvv = '';
        };

        $scope.checkQuickPayAccess = function () {
            $scope.clearTenantData();
            $http.post('/api/v1/quick-pay/check-access/' + $scope.quick_pay.id).success(function (data) {
                if (!data.success) {
                    toastr.error('Access Denied');
                    return
                }

                if (data.data.quick_pay.transaction_id != 0) {
                    toastr.success('Payment has already been proccessed');
                    $state.go('main.quickPayReceipt', {'id': data.data.quick_pay.transaction_id});
                    return;
                }

                $scope.has_access = 1;

                var quick_pay_id = $scope.quick_pay.id;
                $scope.quick_pay = data.data.quick_pay;
                $scope.quick_pay.id = quick_pay_id;

                vm.tenantData.amount = $scope.quick_pay.amount * 1;
                vm.tenantInfo.methods = data.data.methods;
                vm.tenantInfo.hasCredit = false;
                vm.tenantInfo.hasDebitVisa = false;
                vm.tenantInfo.hasDirectDebit = false;
                vm.tenantInfo.company_name =  $scope.quick_pay.company_name;

                if ($scope.quick_pay.amount == '0.00') {
                    $scope.quick_pay.amount = '';
                    $scope.show_amount = 1;
                }

                for (var i in vm.tenantInfo.methods) {
                    if (vm.tenantInfo.methods[i].value == 'credit')
                        vm.tenantInfo.hasCredit = true;
                    else if (vm.tenantInfo.methods[i].value == 'debit_visa')
                        vm.tenantInfo.hasDebitVisa = true;
                    else if (vm.tenantInfo.methods[i].value == 'direct_debit')
                        vm.tenantInfo.hasDirectDebit = true;
                }

                $scope.merchantAccountProvider = data.data.provider;
                $scope.terms = $scope.quick_pay.terms;
            });
        };
        $scope.checkQuickPayAccess();

        $scope.inProgress = 0;
        $scope.payrixValidationErrors = {};
        $scope.makePayment = function () {
            if ($scope.inProgress == 1) {
                return;
            }
            $scope.inProgress = 1;
            //vm.tenantData.credit_card_type = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();
            vm.tenantData.amount = $scope.quick_pay.amount * 1;

            $http.post('/api/v1/quick-pay/payment/' + $scope.quick_pay.id, vm.tenantData)
                .success(function (result) {
                    toastr.clear()

                    if (result.code == 200 && result.success == true) {
                        //$scope.updateResident.$setPristine();
                        toastr.success('Your payment has been processed');
                        $state.go('main.quickPayReceipt', {'id': result.data.quick_pay.transaction_id});
                        return;
                    } else {
                        toastr.clear()

                        if (result.errors) {
                            result.errors.forEach(msg => toastr.error(msg));
                        } else {
                            toastr.error($scope.t('Error processing payment'));
                        }
                        if (result.extra && result.extra.validation) {
                            console.log(result.extra.validation)
                            $scope.payrixValidationErrors = result.extra.validation;
                        }
                        $scope.switchShowSummary();
                    }
                    $scope.inProgress = 0;
                })
                .error(function (data) {
                    $scope.inProgress = 0;
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.clear()
                    toastr.error('Error creating a payment');
                });
        };

        $scope.getConvenienceFees = function () {
            if ($scope.merchantAccountProvider !== 'payrix') {
                vm.tenantData.credit_card_type = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();
            }
            var postdata = {
                property_id: $scope.quick_pay.property_id,
                total_amount: $scope.quick_pay.amount * 1,
                type: vm.tenantData.paymentType,
                credit_card_type: vm.tenantData.credit_card_type,
                provider: $scope.merchantAccountProvider
            };
            $http.post('/api/v1/quick-pay/get-convenience-fees', postdata).success(function(data) {
                if (!data.success) {
                    toastr.error('Could not finalize Payment');
                }

                $scope.convenience_fees = data.data.tenant_convenience_fees;
                $scope.fee_percent = data.data.fee_percent;
                $scope.show_summary = $scope.show_summary == 1 ? 0 : 1;
                $scope.loading_summary = false;
            });
        };
    }
}());
