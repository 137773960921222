(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageDashboardEditController', MessageDashboardEditController);

    function MessageDashboardEditController($scope,$http,$state,$stateParams,$rootScope,$timeout) {
        $scope.submitting = false;

        $scope.message = {
            id: $stateParams.id,
            message: '',
            notice_message: '',
            is_notice: 0,
            is_persistent: 0,
            properties: [],
            start_at: '',
            end_at: '',
            company_id: 0,
            email_alerts: 0
        };

        $scope.dropdowns = {
            companies: {},
            properties: []
        };

        $scope.getProperties = function() {
            $http.post('/api/v1/dashboard-message/get-properties', {company_id: $scope.message.company_id}).success(function(data) {
                $scope.dropdowns.properties = data.data.properties;
                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        }

        $scope.getDashboardMessage = function(){
            $http.post('/api/v1/dashboard-message/get/' + $scope.message.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.amenities');
                }

                // lets populate stuff
                var message = data.data.dashboard_message;

                $scope.message.message = message.message;
                $scope.message.notice_message = message.notice_message == 'null' ? '' : message.notice_message;
                $scope.message.is_notice = message.is_notice ? true : 0;
                $scope.message.is_persistent = message.is_persistent ? true : 0;
                $scope.message.start_at = message.start_at_input;
                $scope.message.end_at = message.end_at_input;
                $scope.message.company_id = message.company_id;
                $scope.message.email_alerts = message.email_alerts ? true : 0;
                angular.forEach(message.properties, function(property, key){
                    $scope.message.properties.push(property.id);
                });

                if ($rootScope.authUser.permissions == 'payquad_admin') {
                    $http.post('/api/v1/dashboard-message/get-companies').success(function(data) {
                        $scope.dropdowns.companies = data.data.companies
                        $scope.getProperties();
                    });
                } else {
                    $scope.getProperties();
                }
                $scope.initDatePickers();
            });
        }

        $scope.deleteDashboardMessage = function() {
            if (!confirm('Are you sure you want to delete this message?')) {
                return; // they did not confirm
            }

            $scope.submitting = true;

            $http.post('/api/v1/dashboard-message/delete/' + $scope.message.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error deleting message");
                    $scope.submitting = false;
                    return;
                }

                $state.go('main.messagesDashboardList');
                toastr.success('Message deleted');
                $scope.submitting = false;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        }

        $scope.updateDashboardMessage = function() {
            if ($scope.message.email_alerts == true) {
                if (!confirm('You are about to send emails to the tenants of all selected properties.  Are you ready to send?')) {
                    return;
                }
            }

            $scope.submitting = true;

            var form_data = new FormData();
            if (document.getElementById('file1').files.length > 0) {
                form_data.append('document', document.getElementById('file1').files[0]);
            }

            $.each($scope.message, function (index, value) {
                if (index == 'properties') {
                    var properties = [];
                    $.each(value, function (pindex, property) {
                        properties[pindex] = property;
                    });
                    form_data.append(index, properties);
                } else {
                    form_data.append(index, value);
                }
            });

            //$http.post('/api/v1/dashboard-message/update/' + $scope.message.id, form_data).success(function(data) {
            $http.post('/api/v1/dashboard-message/update/' + $scope.message.id, form_data, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            }).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error updating dashboard message");
                    return;
                }

                $scope.submitting = false;
                $state.go('main.messagesDashboardList');
                toastr.success('Dashboard Message Updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        }

        $scope.companyChanged = function(){
            $scope.getProperties();
        }

        $scope.initDatePickers = function(){
            setTimeout(function(){
                $('#start-date').datepicker();
                $('#end-date').datepicker();
            }, 0);
        }

        $http.post('/api/v1/dashboard-message/is-enabled').success(function(data) {
            if (!data.success) {
                // not enabled for this user
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            if ($rootScope.authUser.permissions == 'tenant') {
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            $scope.getDashboardMessage();
        });
    }
}());
