(function() {

    'use strict';

    angular
        .module('app')
        .controller('AccountSettingsController', AccountSettingsController);

    function AccountSettingsController(accountService, $rootScope /*resolvedAuthData*/) {

        var vm = this;

        vm.logosize = 'Image size must be between <b>60px * 60px</b> and <b>80px * 80px</b>!';
        vm.bgsize = 'Image size must be between <b>300px * 1200px</b> and <b>80px * 80px</b>!';

/*        accountService.getManagerAccount($rootScope.authUser.id)
            .success(function(data) {
                vm.manager = data.data;
                console.log(data);
            });*/

        vm.updateManager = function(managerId)
        {
            var data = {
                first_name: vm.manager.first_name,
                last_name: vm.manager.last_name,
                email: vm.manager.user.email,
                management_news: vm.manager.account.management_news,
                payquad_news: vm.manager.account.payquad_news,
                reminders: vm.manager.account.reminders,
                payment_processes: vm.manager.account.payment_processes
            };
            console.log(data);
            accountService.updateManagerAccount(managerId, data)
                .success(function(data, status, headers, config) {
                    console.log('Manager successfully updated!');
                    console.log(data);
                    location.reload();
                })
                .error(function(data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });
        };

        vm.updateAccountSetting = function()
        {
            var data = {
                company_id: vm.manager.company.id,
                logo_file: vm.manager.company.logo_file,  // need to upload
                background_file: vm.manager.company.background_file, // need to upload
                website: vm.manager.company.website,
                address: vm.manager.company.address,
                phone_number: vm.manager.company.phone_number,
                twitter: vm.manager.company.twitter,
                facebook: vm.manager.company.facebook,
                email: vm.manager.company.email,
                welcome_message: vm.manager.company.welcome_message,
                public_registration: vm.manager.company.public_registration,
                registration_auto_approval: vm.manager.company.registration_auto_approval
            };

            accountService.updateManagerAccountSettings(data)
                .success(function(data) {
                    console.log('account settings for company updated success!');
                    console.log(data);
                    location.reload();
                })
                .error(function(data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log('ccount settings for company updated FAILIURE!');
                    console.log(data);
                });
        };

    }

}());
