(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqPayrixAmenityForm', pqPayrixAmenityForm);

    angular
        .module('app')
        .controller('PqPayrixAmenityFormController', PqPayrixAmenityFormController);

    function pqPayrixAmenityForm() {
        return {
            restrict: 'E',
            templateUrl: "scripts/modules/amenity/book-create/payrix-directive/payrix.html",
            controller: 'PqPayrixAmenityFormController',
            scope: {
                tenantData: '=',
                tenantInfo: '=',
                billingSameAsMailing: '=',
                createBooking: '&',
                clearTenantData: '&',
                endProcessing: '&',
                amenity: '=',
                payrixValidationErrors: '=',
            }
        };
    }

    function PqPayrixAmenityFormController($scope, $timeout, $http) {
        console.log('PqPayrixAmenityFormController', $scope.amenity);
        $scope.offline_payment = 0;
        $scope.payrixPayfieldsStyle = {
            ".input": {
                padding: '6px 12px',
                color: '#555',
                border: '1px solid #024953',
                'font-size': '14px',
                'line-height': 1.42,
                'background-color': '#f2f2f2',
                'background-image': 'none',
                'border-radius': '4px',
                'font-family': '"Open Sans", sans-serif',
                width: '250px'
            },
            ".account_type": {
                padding: '6px 12px',
                color: '#555',
                border: '1px solid #024953',
                'font-size': '14px',
                'line-height': 1.42,
                'background-color': '#f2f2f2',
                'background-image': 'none',
                'border-radius': '4px',
                'font-family': '"Open Sans", sans-serif',
                // width: '240px',
                height: '45px'
            },
            ".card-icon": {
                // 'right': '30px',
                'top': '6px'
            }
        };
        $scope.payFieldsLoading = false;

        $scope.continue = () => {
            console.log('continue')
            $scope.tenantData.provider = 'payrix';
            if ($scope.tenantData.paymentType !== 'pad') {
                PayFields.submit()
            } else {
                $('.finalize').appendTo('body').modal('show');
            }
        };

        $scope.cconload = () => {
            console.log('cconload');
            $scope.payFieldsLoading = false;
            $scope.tenantInfo.tenant.payrix_api_key_public
            $scope.tenantInfo.tenant.payrix_merchant_id
            PayFields.config.apiKey = $scope.tenantInfo.tenant.payrix_api_key_public;
            PayFields.config.merchant = $scope.tenantInfo.tenant.payrix_merchant_id;

            PayFields.customizations = {
                style: $scope.payrixPayfieldsStyle,
                placeholders: {
                    '#name': 'Cardholder\'s name',
                    '#expiration': 'Expiration Date - mm/yy',
                    '#payment_cvv': 'CVV Code',
                    "#payment_number": 'Card Number'
                }
            };

            PayFields.config.amount = 0;
            // Option to create token while also a transaction. Remove this line to run a sale
            PayFields.config.mode = 'token';

            PayFields.fields = [
                {type: "name", element: "#name"},
                {type: "number", element: "#number"},
                {type: "cvv", element: "#cvv"},
                {type: "expiration", element: "#expiration"}
            ];
            PayFields.customizations.optionalFields = ['#address1', '#city', '#state', '#zip', '#email', '#phone']


            PayFields.onSuccess = (r) => {
                if (PayFields.onSuccessInProgress) {
                    return;
                }
                PayFields.onSuccessInProgress = true;
                console.log('PayFields.onSuccess',r)
                if (!r.data[0].expiration) {
                    toastr.error('Expiration year and month missing', '',  {
                        preventDuplicates: true
                    })
                    $scope.submitting = false;
                    PayFields.onSuccessInProgress = false;
                    return;
                }
                if (!r.data[0].expiration) {
                    toastr.error('CVV missing', '',  {
                        preventDuplicates: true
                    })
                    $scope.submitting = false;
                    PayFields.onSuccessInProgress = false;
                    return;
                }
                $scope.tenantData.card_holder_name = `${r.data[0].customer.first} ${r.data[0].customer.last}`;
                $scope.tenantData.card_number = r.data[0].payment.number;
                $scope.tenantData.expiration_year = 20 + r.data[0].expiration.slice(-2);
                $scope.tenantData.expiration_month = r.data[0].expiration.substring(0, 2);
                $scope.tenantData.payrix = r;
                $scope.$apply();
                $scope.tenantData.provider = 'payrix';
                $scope.payrixValidationErrors = {};
                $http.post('/api/v1/tenants/update-by-admin-single', $scope.tenantData)
                    .success(function (result) {
                        PayFields.onSuccessInProgress = false;
                        $scope.inProgress = false;
                        toastr.clear()

                        if (result.code == 200 && result.data.success) {
                            $scope.clearTenantData();
                            $scope.createBooking();
                        } else {
                            toastr.clear()
                            if (result.extra && result.extra.validation) {
                                console.log(result.extra.validation)
                                $scope.payrixValidationErrors = result.extra.validation;
                            }
                            if (result.code == 200) {
                                toastr.error(result.data.error);
                            } else {
                                toastr.error($scope.t('error processing payment'));
                            }
                            $scope.endProcessing();
                        }
                        $scope.submitting = false;
                    })
                    .error(function (data) {
                        PayFields.onSuccessInProgress = false;
                        $scope.submitting = false;
                        if (typeof data.stopaction != 'undefined') {
                            return;
                        }

                        toastr.clear()
                        toastr.error($scope.t('Error creating a payment for this booking'));
                        $scope.endProcessing();
                    });

                // $('.finalize').appendTo('body').modal('show');
            };
            PayFields.onValidationFailure = (r) => {
                console.log('onValidationFailure', r)
                toastr.clear();
                $timeout(() => {
                toastr.error('Check entered data for payment method', '',  {
                    preventDuplicates: true
                })
                }, 500);
                $scope.endProcessing();
            };
            PayFields.onFailure = () => {
                console.log('onFailure')
                $scope.endProcessing();
            };

            PayFields.appended ? PayFields.addFields() : null;
            PayFields.reload();
            PayFields.restore();
            $scope.$apply();
        }

        $scope.loadPayFieldsCC = function () {
            $scope.payFieldsLoading = true;
            $('[src="https://test-api.payrix.com/payFieldsScript"]').remove()
            if (document.getElementById("payFields-iframe")) {
                document.getElementById("payFields-iframe").remove();
            }
            if (typeof PayFields !== 'undefined') {
                PayFields.onSuccess = undefined;
            }
            window.PayFields = undefined

            let externalScript = document.createElement('script')
            externalScript.setAttribute('src', 'https://test-api.payrix.com/payFieldsScript')

            externalScript.onload = $scope.cconload;

            document.body.appendChild(externalScript)
        }

        $scope.$watch('tenantData.paymentType', function (newVal, oldVal) {
            if ((newVal === 'credit' || newVal === 'debit') && (oldVal === 'pad' || !oldVal)) {
                $scope.loadPayFieldsCC();
            }
        });

    }

})();
