(function() {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/payment/schedule/';

        $stateProvider

        /**
         * Create
         */
            .state('main.paymentScheduleCreate', {
                url: '/payments/schedule/create/:paymentMethodId',
                views: {
                    "body@": {
                        templateUrl: url + 'create/payment-schedule.create.step-1.html',
                        controller: 'PaymentScheduleCreateController',
                    }
                }
            })
            .state('main.paymentScheduleCreate.step_2', {
                templateUrl: url + 'create/payment-schedule.create.step-2.html'
            })
            .state('main.paymentScheduleCreate.step_3', {
                templateUrl: url + 'create/payment-schedule.create.step-3.html'
            })

        /**
         * Index
         */
            .state('main.paymentScheduleIndex', {
                url: '/payments/schedules',
                views: {
                    "body@": {
                        templateUrl: url + 'index/payment-schedule.index.html',
                        controller: 'PaymentScheduleIndexController'
                    }
                }
            })

        /**
         * Edit
         */
            .state('main.paymentScheduleEdit', {
                url: '/payments/schedules/:paymentScheduleId/edit',
                views: {
                    "body@": {
                        templateUrl: url + 'edit/payment-schedule.edit.html',
                        controller: 'PaymentScheduleEditController'
                    }
                }
            })

        /**
         * History
         */
            .state('main.paymentHistory', {
                url: '/payments/history',
                views: {
                    "body@": {
                        templateUrl: url + 'history/payment.history.html',
                        controller: 'PaymentHistoryController',
                        resolve: {
                            resolvedPaymentHistoryData: function(paymentScheduleService) {
                                return paymentScheduleService.getPaymentHistory()
                                    .then(function(data) {
                                        return data;
                                    });
                            }
                        }
                    }
                }
            })
        /**
        * Receipt
        */
            .state('main.paymentReceipt', {
                url: '/payments/receipt/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'receipt/payment.receipt.html',
                        controller: 'PaymentReceiptController'
                    }
                }
            })
            .state('main.paymentReceiptExtension', {
                url: '/payments/receipt-extension/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'receipt_extension/payment.receipt_extension.html',
                        controller: 'PaymentReceiptExtensionController'
                    }
                }
            });

    }

}());
