(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        const path = 'scripts/modules/inspection/';

        $stateProvider
            .state('main.inspections', {
                url: '/inspections',
                views: {
                    'body@': {
                        templateUrl: path + 'index/inspection.index.html',
                        controller: 'InspectionIndexController'
                    }
                }
            })
            .state('main.inspections.recurring', {
                url: '/recurring',
                views: {
                    'body@': {
                        templateUrl: path + 'index/inspection.index.html',
                        controller: 'InspectionIndexController'
                    }
                }
            })
            .state('main.inspections.create', {
                url: '/create',
                views: {
                    'sidebar@': {
                        templateUrl: path + 'inspections-create/inspection.create.html',
                        controller: 'InspectionCreateController'
                    }
                },
                resolve: {
                    resolvedProperties: function ($q, propertyService) {
                        return $q.when(propertyService.getAll().then(r => r.data.data));
                    },
                    resolvedTemplates: function ($q, inspectionService) {
                        return $q.when(inspectionService.getAllTemplates())
                    }
                }
            })
            .state('main.inspections.edit', {
                url: '/edit/:id',
                views: {
                    'sidebar@': {
                        templateUrl: path + 'inspections-edit/inspection.edit.html',
                        controller: 'InspectionEditController'
                    }
                },
                resolve: {
                    resolvedInspection: function ($q, inspectionService, $stateParams) {
                        return $q.when(inspectionService.get($stateParams.id));
                    }
                }
            })
            .state('main.inspectionTemplates', {
                url: '/inspections/templates',
                views: {
                    'body@': {
                        templateUrl: path + 'templates/inspection.templates.html',
                        controller: 'InspectionTemplatesController'
                    }
                }
            })
            .state('main.inspectionTemplates.edit', {
                url: '/edit/:id',
                views: {
                    'sidebar@': {
                        templateUrl: path + 'templates-edit/inspection.templates.edit.html',
                        controller: 'InspectionTemplatesEditController'
                    }
                },
                resolve: {
                    resolvedTemplate: function ($q, inspectionService, $stateParams) {
                        return $q.when(inspectionService.getTemplate($stateParams.id));
                    }
                }
            });
    }

}());
