(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/maintenance-request/';

        $stateProvider
			.state('main.maintenanceRequests', {
				url: '/maintenance-requests',
				views: {
					"body@": {
						templateUrl: url + 'index/index.html',
						controller: 'MaintenanceRequestsIndexController',
                        controllerAs: 'vm'
					}
				}
			})
            .state('main.maintenanceRequestCreate', {
                url: '/maintenance-requests/create',
                views: {
                    "body@": {
                        templateUrl: url + 'create/create.html',
                        controller: 'MaintenanceRequestCreateController',
                        controllerAs: 'vm'
                    }
                }
            })
			.state('main.maintenanceRequestEdit', {
				url: '/maintenance-requests/:id',
				views: {
					"body@": {
						templateUrl: url + 'edit/edit.html',
						controller: 'MaintenanceRequestEditController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.maintenanceRequestScheduleEdit', {
				url: '/maintenance-request-schedules/:id',
				views: {
					"body@": {
						templateUrl: url + 'schedule-edit/schedule-edit.html',
						controller: 'MaintenanceRequestScheduleEditController',
                        controllerAs: 'vm'
					}
				}
			})
            .state('main.maintenanceRequestSchedules', {
                url: '/maintenance-request-schedules',
                views: {
                    "body@": {
                        templateUrl: url + 'schedules/schedules.html',
                        controller: 'MaintenanceRequestSchedulesController',
                        controllerAs: 'vm'
                    }
                }
            })
			.state('main.maintenanceRequestsPOList', {
				url: '/maintenance-requests/po/list',
				views: {
					"body@": {
						templateUrl: url + 'po-list/po-list.html',
						controller: 'MaintenanceRequestPoListController',
						controllerAs: 'vm'
					}
				}
			})
            .state('main.maintenanceRequestPoCreate', {
                url: '/maintenance-requests/po/create/:maintenance_request_id',
                views: {
                    "body@": {
                        templateUrl: url + 'po-create/po-create.html',
                        controller: 'MaintenanceRequestPoCreateController',
                        controllerAs: 'vm'
                    }
                }
            })
			.state('main.maintenanceRequestPoEdit', {
				url: '/maintenance-requests/po/:id',
				views: {
					"body@": {
						templateUrl: url + 'po-edit/po-edit.html',
						controller: 'MaintenanceRequestPoEditController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.maintenanceRequestsPro', {
				url: '/maintenance-requests-charts',
				views: {
					"body@": {
						templateUrl: url + 'charts/index.html',
						controller: 'MaintenanceRequestsChartsController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.maintenanceRequestsCalendar', {
				url: '/maintenance-requests-calendar',
				views: {
					"body@": {
						templateUrl: url + 'calendar/calendar.html',
						controller: 'MaintenanceRequestCalendarController',
						controllerAs: 'vm'
					}
				}
			})
			;


	}

}());
