(function () {

    'use strict';

    angular
        .module('app')
        .factory('leaseApplicationService', leaseApplicationService);

    function leaseApplicationService($http) {

        const base_url = '/api/v1/lease-application';

        return {
            stats: () => $http.get(`${base_url}/get-manager-list-stats`).then(r => r.data.data),
            get: id => $http.post(`${base_url}/manager-get/${id}`).then(r => r.data.data),
            getQuestions: propertyId => $http.get(`${base_url}lease-application/questions/${propertyId}`),
        };

    }

}());
