(function() {

    'use strict';

    angular
        .module('app')
        .controller('PaymentHistoryController', PaymentHistoryController);

    function PaymentHistoryController($scope, $filter, resolvedPaymentHistoryData, $rootScope, langService) {
        $scope.t = langService.t('payment_history');
        $scope.currentPage = 0;

        $scope.multipleReceipts = {
            dateFrom: '',
            dateTo: ''
        };

        $scope.viewReceipts = () => {
            window.location.href = '/#/n/payments/receipts';
            // $('#invite-start-date').datepicker();
            // $('#invite-end-date').datepicker();
            // $('#multiple-receipts-modal').appendTo('body').modal('show');
        };

        $scope.viewReceiptsSubmit = () => {
            if($scope.multipleReceipts.dateFrom && $scope.multipleReceipts.dateTo){
                $('#multiple-receipts-modal').modal('hide');
                const url = `/#/n/payments/receipts?dateFrom=${encodeURIComponent($scope.multipleReceipts.dateFrom)}&dateTo=${encodeURIComponent($scope.multipleReceipts.dateTo)}`;
                window.location.href = url;
            }else{
                toastr.error('Please, fill out dates.');
            }
        };

        var orig_payment_transactions = resolvedPaymentHistoryData.data;

        $scope.payment_transactions = resolvedPaymentHistoryData.data;
        $scope.searchFilter = "";
        $scope.sortPred = '-[created_at]';
        $scope.sortFields = [
            {label:$scope.t('description'), value:'description'},
            {label:$scope.t('date'), value:'created_at'},
            {label:$scope.t('amount'), value:'total_amount'},
            {label:$scope.t('service_fee'), value:'tenant_fee'},
            {label:$scope.t('status'), value:'status'},
            {label:$scope.t('type'), value:'type'},
            {label:$scope.t('scheduled'), value:'recurring'},
            {label:$scope.t('reference'), value:'caledon_id'}
        ];
        $scope.defaultSortField = ['created_at', 1];

        $scope.$watch('sortPred', function(newVal, oldVal) {
            if (newVal != oldVal) {
                // Reset current page
                $scope.currentPage = 0;
                $scope.payment_transactions = $filter('orderBy')($scope.payment_transactions, newVal);
            }
        });

        $scope.$watch('searchFilter', function(newVal, oldVal) {
            console.log("searchFilter Fired");
            if (newVal != oldVal) {
                if (newVal == '') {
                    $scope.payment_transactions = orig_payment_transactions;
                } else {
                    $scope.payment_transactions = $filter('filter')(orig_payment_transactions, newVal);
                }
                // Reset current page
                $scope.currentPage = 0;
            }
        });

        _.map($scope.payment_transactions, function (val)
        {
             //val.created_at = moment(val.created_at).format('YYYY-MM-DD');
             val.recurring = (!!val.recurring) ? "Yes" : "No";

             if (val.type == 'amex') {
                val.type = 'American Express';
             }
             else if (val.type == 'mastercard') {
                val.type = 'MasterCard';
             }
             else if (val.type == 'visa') {
                val.type = 'Visa';
             }
             else if (val.type == 'debit_visa') {
                val.type = 'Visa/MasterCard Debit';
             }
             else if (val.type == 'direct_debit') {
                val.type = 'Direct Debit';
             }
        });

    }

}());
