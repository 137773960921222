(function () {

    'use strict';

    angular
        .module('app')
        .factory('tenantService', tenantService);

    function tenantService($http) {

        var base_url = '/api/v1/';
        var local_url = 'tenants';

        return {
            getTenantManage: getTenantManage,
            getTenantApprovals: getTenantApprovals,
            getTenantActivities: getTenantActivities,
            getTenantById: getById,
            createTenant: create,
            updateTenant: update,
            deleteTenant: destroy,
            getTenantsByUnitId: unitId => $http.get(`${base_url}${local_url}/find-by-unit-id/${unitId}`).then(r => r.data.data),
            getTenantsByUnit: (property_id,unit) => $http.post(`${base_url}${local_url}/find-by-unit`, {property_id: property_id, unit_number: unit}).then(r => r.data.data),
            getMyTenants: () => $http.get(`${base_url}my-tenants`).then(r => r.data.data),
            addNote: (tenant_id, note) => $http.post(`${base_url}${local_url}/add-note/${tenant_id}`, note)
        };

        function getTenantManage() {
            /*var response = $http.get(base_url + local_url)
                 .then(function(object) {
                     return object.data;
                 });

             return response;*/
        }

        function getTenantApprovals() {

            /*var response = $http.get(base_url + local_url + 'approvals')
                .then(function(object) {
                    return object.data;
                });

            return response;*/

        }

        function getTenantActivities() {
            /*var response = $http.get(base_url + local_url + 'activities')
                .then(function(object) {
                    return object.data;
                });

            return response;*/
        }

        function getById(id) {
            var response = $http.get(base_url + local_url + "/" + id)
                .then(function (object) {
                    return object.data;
                });

            return response;
        }

        function create(data) {
            return $http({
                method: 'POST',
                url: base_url + local_url + '/create-by-admin',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: $.param(data)
            });
        }

        function update(id, data) {
            return $http.put(base_url + local_url + "/" + id, data);
        }

        function destroy(id) {
            return $http.delete(base_url + local_url + "/" + id);
        }

    }

}());
