(function() {
    
    'use strict';

	angular
		.module('app.routes')
		.config(routes);

	function routes($stateProvider) {

		$stateProvider
			.state('main.properties', {
				url: '/properties',
				views: {
					"body@": {
						templateUrl: 'scripts/modules/property/properties/property.index.html',
						controller: 'PropertiesController',
						controllerAs: 'vm',
						resolve: {
							propertiesData: getPropertiesData
						}
					}
				}
			})
			.state('main.property_create', {
				url: '/property/create/:companyId',
				views: {
					"body@": {
						templateUrl: 'scripts/modules/property/property-create/property.create.html',
						controller: 'PropertyCreateController',
						resolve: {
							propertyCreateData: getPropertyCreateData
						}
					}
				}
			})
			.state('main.property', {
				url: '/properties/:property_id',
				views: {
					"body@": {
						templateUrl: 'scripts/modules/property/property-tenants/property-tenants.html',
						controller: 'PropertyTenantsController',
						controllerAs: 'vm',
						resolve: {
							propertyTenantsData: getPropertyTenantsData
						}
					}
				}
			})
			.state('main.property.edit', {
				url: '/edit',
				views: {
					"body@": {
						templateUrl: 'scripts/modules/property/property-edit/property-edit.html',
						controller: 'PropertyEditController',
						resolve: {
							propertyEditData: getPropertyEditData,
							resolvedTriggers: function (maintenanceRequestService, $q) {
								return $q.when(maintenanceRequestService.getTriggers());
							},
							resolvedManagers: function ($q, managerService, $stateParams) {
								return $q.when(managerService.getManagersByPropertyId($stateParams.property_id))
							}
						}
					}
				}
			})
            .state('main.propertiesAll', {
                url: '/properties/all/',
                views: {
                    "body@": {
                        templateUrl: 'scripts/modules/property/property-all/property.all.html',
                        controller: 'PropertyAllController',
                        controllerAs: 'vm',
                        resolve: {
                            propertiesAllData: getAllPropertiesData
                        }
                    }
                }
            });

		// data retrievers

		/* @ngInject */
		function getPropertiesData(propertiesData)
		{
			return propertiesData.load();
		}

		/* @ngInject */
		function getPropertyTenantsData(propertyTenantsData, $stateParams)
		{
			return propertyTenantsData.load($stateParams['property_id']);
		}

		/* @ngInject */
		function getPropertyCreateData(propertyCreateData)
		{
			return propertyCreateData.load();
		}

		/* @ngInject */
		function getPropertyEditData(propertyEditData, $stateParams)
		{
			return propertyEditData.load($stateParams['property_id']);
		}

        /* @ngInject */
        function getAllPropertiesData(propertyAllData)
        {
            return propertyAllData.load();
        }

	}

}());
