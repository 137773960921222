(function() {
    'use strict';

    angular
    .module('app')
    .controller('QuickPayIndexController', quickPayIndexController);

    function quickPayIndexController($scope,$http,$state,$rootScope) {
        $scope.quick_pays = [];

        $scope.getQuickPays = function(){
            $http.post('/api/v1/quick-pay/get-list').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Quick Pays");
                }

                // lets populate stuff
                $scope.quick_pays = data.data.quick_pays;
                $(document).ready(function () {
                    setTimeout(function () {
                        $('#dataTable').dataTable({
                            buttons: [],
                            responsive: true,
                        });

                        $('body').on('click', '#dataTable .template-edit-button', function (event) {
                            if($(this).data("quick-pay")) {
                                event.preventDefault();
                                $state.go('main.quickPayEdit', { id: $(this).data("quick-pay") });
                            }
                        });
                    }, 100);
                });
            });
        };
        $scope.getQuickPays();

    }
}());
