(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqSignPadPopup', pqSignPadPopup);

    angular
        .module('app')
        .controller('PqSignPadPopupController', pqSignPadPopupController);

    function pqSignPadPopup() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/sign-pad-popup/sign-pad-popup.html',
            controller: 'PqSignPadPopupController',
            scope: {
                signId: '=',
                ref: '=?',
                onSignSave: '=?', //not gonna mess with this but ref and onSignsave should be manditory
                title: '=?',
                signWidth: '=?',
                signHeight: '=?',
                textTypedSignEnabled: '=?',
                doNotTimestamp: '=?'
            }
        };
    }


    function pqSignPadPopupController($scope, $rootScope, $window, $timeout, $http, langService) {
        $scope.ref = {};
        $scope.model = null;
        $scope.signWidth = $scope.signWidth ? $scope.signWidth : 550;
        $scope.signHeight = $scope.signHeight ? $scope.signHeight : 240;
        $scope.name = '';
        $scope.t = langService.t('profile');
        $scope.currentTab = 2;

        $scope.setTab = function(i) {
            $scope.currentTab = i;
            $scope.clearSignature();
            $(`#${$scope.signId}-modal-body .active`).removeClass('active');
            $(`#${$scope.signId}-li`+i).addClass('active');
            $(`.${$scope.signId}-tab-pane`).hide();
            $(`#${$scope.signId}-content`+i).show();

            if(i == 1){
                $scope.model = $scope.existingSignature;
                $scope.name = `${$rootScope.authUser.first_name} ${$rootScope.authUser.last_name}`;
            }
        }

        $scope.addTimestamp = () => {
            console.log('pretimestamp',$scope.model);
            const secondaryCanvas = document.createElement('canvas')
            const ctx = secondaryCanvas.getContext('2d')
            let img = new Image();
            img.onload = function() {
                // load the screenshot to another canvas
                secondaryCanvas.width = img.width
                secondaryCanvas.height = img.height
                ctx.drawImage(img, 0, 0);

                console.log('width height',img.width,img.height);
                //add timestamp
                const timeNow = `Digitally signed by ${$scope.name} at ${new Date().toISOString()}`
                ctx.textAlign = "center";
                ctx.fillStyle = 'black';
                ctx.fillText(timeNow, img.width/2,img.height-5);
                $scope.model = ctx.canvas.toDataURL('image/png');
            }
            img.src = $scope.model;
        }

        $scope.saveSignature = () => {
            if(!$scope.doNotTimestamp && $scope.currentTab != 2){
                $scope.addTimestamp();
            }
            if ($scope.onSignSave) {
                $timeout(()=>{
                    const result = $scope.onSignSave($scope.model, $scope.name);
                    console.log('result',result);
                    if (result) {
                        $(`#${$scope.signId}`).modal('hide');
                        $scope.clearSignature();
                    }
                },1);
            }
        };

        $scope.clearSignature = () => {
            console.log('clearSignature')
            $scope.name = '';
            $scope.ref.clearSignature();
            if(this.textTypedSignEnabled){
                $scope.ref.clearCursiveSignature();
            }
            $scope.ref.clearUploadedSignature();
        };



        const doResizeCanvas = () => {
            console.log('resizeCanvas 1')

            $timeout(() => {
                const wrapperWidth = $(`#${$scope.signId} .wrapper`).width();
                if (wrapperWidth > 0) { //visible
                    const ratio = wrapperWidth / $scope.signWidth;
                    $scope.signWidth = wrapperWidth;
                    $scope.signHeight = $scope.signHeight * ratio;
                    $scope.ref.scale(ratio);
                }
            }, 0);
        };
        const resizeCanvas = () => {
            if (!!!window.Capacitor.isNativePlatform()) {
                doResizeCanvas();
            }
        };
        $window.addEventListener('resize', resizeCanvas);

        $scope.ref.open = () => {
            $(`#${$scope.signId}`).appendTo('body').modal('show');
            $timeout(() => {
                $('.modal-backdrop').hide();
            }, 0);
            $timeout(() => {
                doResizeCanvas();
            }, 400);
        };

        $scope.imageExists = true;
        $scope.existingSignature = null;
        $http.get("api/v1/get-manager-signature-base64").then(function success(data){
            $scope.existingSignature = data.data.signature;
        }, function error(error){
            $scope.imageExists = false;
        });

        $scope.getManagerSignature = function() {
            return `${pqDomain}/api/v1/get-manager-signature?cordova=${(!!window.Capacitor.isNativePlatform() ? '1':'0')}&token=${pqCachedToken}`;
        }
    }

})();
