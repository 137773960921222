(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqCompanyEmails', pqCompanyEmails);

    function pqCompanyEmails() {
        return {
            restrict: 'E',
            controller: 'PqCompanyEmailsController',
            templateUrl: 'scripts/modules/company/edit/company-emails/company-emails.html',
            scope: {
                companyId: '=',
                emails: '=',
                templates: '=',
                // activeTab: '=',
            }
        };
    }

    angular
        .module('app')
        .controller('PqCompanyEmailsController', function ($scope, $timeout) {
            $scope.previewSrc = null;
            $scope.emailPreviewBody = false;
            $scope.templates = $scope.emails.reduce((accumulator, currentValue) => {
                let r = accumulator;
                accumulator[currentValue.slug] = currentValue
                return r;
            }, {});



            $timeout(function () {
                $scope.active_tab = 'converted_email_message';
            }, 1);

            $scope.previewEmail = function (tab, text) {
                $scope.previewSrc = null;
                $scope.emailPreviewBody = false;
                $('#email-preview').appendTo('body').modal('show');
                $scope.previewSrc = `/email-preview/${tab}?companyId=${$scope.companyId}&text=${text.template}`
            }

        });
})();
