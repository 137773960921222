(function () {

    'use strict';

    angular
        .module('app')
        .factory('showingService', showingService);

    function showingService($http) {
        const base_url = '/api/v1/showing';

        return {
            get: id => $http.get(`${base_url}/${id}`).then(r => r.data),
            create: showing => $http.post(`${base_url}/legacy`, JSON.stringify(showing)), // Delete route when this component is not longer used
            save: showing => $http.patch(`${base_url}/${showing.id}`, JSON.stringify(showing)),
            getProperty: id => $http.get(`/api/v1/property/public/${id}`).then(r => r.data.data),
            createByClient: showing => $http.post(`${base_url}/create-by-client`, JSON.stringify(showing)),
            getAll: (propertyId, query) => $http.post(`${base_url}/get-showings/${propertyId}`, JSON.stringify(query)).then(r => r.data),
            getAllBulk: (propertyId, query) => $http.post(`${base_url}/get-showings?ids=${propertyId}`, JSON.stringify(query)).then(r => r.data),
            checkTimes: (propertyId, date, managerId = null, timeZoneOffset = null) => {
                let url = `${base_url}/property/${propertyId}/check/${date}`;
                if (managerId) {
                    url += `?manager_id=${managerId}`;
                }
                if (timeZoneOffset){
                    url += `?time_zone_offset=${timeZoneOffset}`;
                }
                return $http.get(url).then(r => r.data.data);
            },
            // getVirtualMethods: () => ['In Person', 'WhatsApp', 'Zoom', 'FaceTime', 'Duo', 'Skype', 'Other'] //moved to controller to use langservice
        };

    }

}());
