(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/audit/';

        $stateProvider
			.state('main.audit', {
				url: '/audit-log',
				views: {
					"body@": {
						templateUrl: url + 'index/audit.index.html',
						controller: 'AuditLogController',
                        controllerAs: 'vm'
					}
				}
			});
	}
}());
