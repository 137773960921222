(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/merchant-account/';

        $stateProvider
			.state('main.merchantAccountCreate', {
				url: '/companies/:companyId/merchant-accounts/create',
				views: {
					"body@": {
						templateUrl: url + 'create/merchant-account.create.html',
						controller: 'MerchantAccountCreateController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.merchantAccountSummary', {
				url: '/merchant-account-summary/:terminal',
				views: {
					"body@": {
						templateUrl: url + 'summary/merchant-account.summary.html',
						controller: 'MerchantAccountSummaryController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.merchantAccountEdit', {
				url: '/merchant-accounts/:merchantAccountId/edit',
				views: {
					"body@": {
						templateUrl: url + 'edit/merchant-account.edit.html',
						controller: 'MerchantAccountEditController',
                        controllerAs: 'vm',
                        resolve: {
                            resolvedMerchantAccountData: getMerchantAccountData
                        }
					}
				}
			});

        function getMerchantAccountData(merchantAccountData, $stateParams)
        {
            return merchantAccountData.load($stateParams.merchantAccountId);
        }
	}

}());
