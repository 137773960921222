(function() {

	'use strict';

    angular
		.module('app')
		.controller('AuditLogController', auditLogController);

	function auditLogController($scope,$state) {

        var vm = this;

      

    }

}());
