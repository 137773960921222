(function () {
    "use strict";

    /**
     * Data Service object to retrieve data for
     * properties-data Controller
     */
    angular
        .module('pq.property')
        .factory('propertiesData', propertiesDataFactory);

    /**
     * Retrieves data for properties-data Controller
     * @param $q
     * @param propertyDataService
     * @returns {{load: load}}
     * @ngInject
     */
    function propertiesDataFactory($q, propertyDataService)
    {
        return {
            load: load
        };

        // Loads all data into this.data and returns a promise
        function load() {
            var properties = propertyDataService.getProperties();

            // Retrieve all promised response and package it into an object
            return $q.all([properties]).then(
                function (results) {
                    return {
                        properties: results[0]
                    }
                });
        }
    }
})();