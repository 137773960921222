(function () {
    "use strict";

    angular
        .module('app.fieldSorter')
        .controller('FieldSorterController', FieldSorterController);

    /* @ngAnnotate */
    function FieldSorterController($scope) {

        var internalSortValue;
        var sortOrder;

        $scope.tempShow = false;

        $scope.updateSortPredicate = function(sortPredicate, sortFieldIndex) {
            if (sortPredicate === getInternalSortValue()) {
                toggleSortOrder();
            } else {
                resetSortOrder();
                setSortFieldIndex(sortFieldIndex);
                setInternalSortValue(sortPredicate);
            }
            updateSortPredicateValue();
        };

        if (angular.isDefined($scope.defaultSortField) && angular.isArray($scope.defaultSortField)) {
            $scope.updateSortPredicate($scope.defaultSortField[0], $scope.defaultSortField[1]);
        }

        $scope.isDescending = function() {
            if (sortOrder === '-') {
                return true;
            } else if (sortOrder === '+') {
                return false;
            } else {
                // console.log('sortOrder is ', sortOrder);
            }
        };

        // Exposed Sort Predicate, combines Sort Order and Sort Value
        function updateSortPredicateValue() {
            $scope.sortPredicate = getSortOrder()+getInternalSortValue();
        }

        // Internal Sort Value
        function setInternalSortValue(sortValue) {
            internalSortValue = sortValue;
        }

        function getInternalSortValue() {
            return internalSortValue;
        }

        // Sort Field Index functions
        function setSortFieldIndex(sortFieldIndex) {
            $scope.currentSortFieldIndex = sortFieldIndex;
        }

        // Private Sort Order functions
        function setSortOrder(sortOrderr) {
            sortOrder = sortOrderr;
        }

        function getSortOrder() {
            return sortOrder;
        }

        function resetSortOrder() {
            setSortOrder('-');
        }

        function toggleSortOrder() {
            if (getSortOrder() === '+') {
                setSortOrder('-');
            } else if (getSortOrder() === '-') {
                setSortOrder('+');
            } else {
                //console.log('toggleSortOrder messed up');
            }
        }
    }

})();