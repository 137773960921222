(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageGroupingListController', MessageGroupingListController);

    function MessageGroupingListController($scope,$http,$state,$rootScope, langService) {

        $scope.t = langService.t('messages');

        $scope.templates = [];

        $scope.getGroupings = function(){
            $http.post('/api/v1/message-grouping/get-message-groupings').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Groupings");
                }

                // lets populate stuff
                $scope.groupings = data.data.groupings;

                setTimeout(function () {
                    $('#groupingDataTable').dataTable({
                        retrieve: true,
                        responsive: true
                    });

                    $('body').on('click', '#groupingDataTable .template-edit-button', function (event) {
                        if($(this).data("grouping")) {
                            event.preventDefault();
                            $state.go('main.messagesGroupingEdit', { id: $(this).data("grouping") });
                        }
                    });
                });
            });
        }

        if (!$rootScope.authUser.pro_messaging) {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        $scope.getGroupings();
    }
}());
