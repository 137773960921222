(function () {
    "use strict";

    angular.module('pq.property',
        [
            'pq.property.data',
            // Widget Dependencies
            'app.pagination',
            'app.filterBar',
            'app.fieldSorter'
        ]);

})();
