(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqSignPad', pqSignPad);

    angular
        .module('app')
        .controller('PqSignPadController', pqSignPadController);

    function pqSignPad() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/sign-pad-popup/sign-pad/sign-pad.html',
            controller: 'PqSignPadController',
            scope: {
                signId: '=',
                ref: '=',
                signModel: '=',
                signWidth: '=',
                signHeight: '='
            }
        };
    }


    function pqSignPadController($scope, $timeout) {

        $timeout(() => {
            $scope.canvas = document.getElementById(`${$scope.signId}-sign`);
            $scope.signaturePad = new SignaturePad($scope.canvas, {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                penColor: 'rgb(0, 0, 0)',
                onEnd: () => {
                    $scope.signModel = $scope.signaturePad.toDataURL('image/png');
                }
            });
        }, 0);

        $scope.ref.clearSignature = () => {
            $scope.signaturePad.clear();
            $scope.signModel = null;
        };

        $scope.ref.scale = ratio => {
            $scope.canvas.getContext('2d').scale(ratio, ratio);
            $scope.signaturePad.clear();
        };
    }

})();
