(function() {

    'use strict';

    angular
        .module('app')
        .factory('notificationData', notificationDataFactory);

    function notificationDataFactory($q, $rootScope, notificationService)
    {
        return {
            load: load
        };

        function load() {

            var notifications = notificationService.getDashboardNotifications();

            return $q.all([notifications]).then(
                function (results) {
                    return {
                        notifications: results[0]
                    }
                });

        }
    }

})();