(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqTenantNotes', pqTenantNotes);

    function pqTenantNotes() {
        return {
            restrict: 'E',
            controller: 'PqTenantNotesController',
            templateUrl: 'scripts/modules/tenant/edit/tenant-notes/tenant-notes.html',
            scope: {
                tenant: '='
            }
        };
    }

    angular
        .module('app')
        .controller('PqTenantNotesController', function ($scope, $rootScope, tenantService) {
            $scope.note = {
                note: ''
            };

            $scope.addNote = () => {
                if ($scope.note.note.length > 65535) {
                    toastr.error('Note cannot be longer than 65535 characters');
                    return;
                }
                if($scope.note.note.length){
                    tenantService.addNote($scope.tenant.id, $scope.note).success(() => {
                        $scope.tenant.notes.push({
                            note: $scope.note.note,
                            created_at: (new Date()).toMysqlFormat(),
                            manager: {
                                first_name: $rootScope.authUser.first_name,
                                last_name: $rootScope.authUser.last_name
                            }
                        });
                        $scope.note.note = '';
                    });
                }
            }
        });
})();
