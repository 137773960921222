(function() {
    'use strict';

    angular
    .module('app')
    .controller('EmailConfirmationController', emailConfirmationController);

    function emailConfirmationController($scope,$http,$state,$rootScope,$timeout,$stateParams) {

        $scope.checkConfirmation = function(){
            $http.post('/api/v1/check-email-confirmation/' + $stateParams.user_id + '/' + $stateParams.code).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors, "error verifying email");
                    // $state.go('main.login');
                    $rootScope.goToAngular2Login();

                    return;
                }

                toastr.success('Your email has been verified');
                // $state.go('main.login');
                $rootScope.goToAngular2Login();

            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
                // $state.go('main.login');
                $rootScope.goToAngular2Login();

            });
        };

        $scope.checkConfirmation();
    }
}());
