(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageUnsentListController', MessageUnsentListController);

    function MessageUnsentListController($scope,$http,$state,$rootScope, langService) {

        $scope.t = langService.t('messages');

        $scope.messages = [];
        $scope.currentPage = 1;
        $scope.itemPerPage = 15;
        $scope.getMessages = function(){
            $http.post('/api/v1/dashboard-message/get-unsent-messages').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Failed Messages");
                }
                $scope.messages = data.data;
            });
        };

        $scope.pendingDetails = function(index){

            var message = $scope.messages[($scope.currentPage-1)*$scope.itemPerPage + index];
            $('#msg_subject').html(message.subject);
            $('#msg_email').html(message.email);
            $('#msg_state').html(message.state);
            $('#msg_date').html(message.date);
            $('#msg_reason').html(message.reason);

            // show the moddal
            $('#unsent-mail-details').appendTo('body').modal('show');
        };


        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        $scope.getMessages();
    }
}());
