(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqShowingTimeSelection', pqShowingTimeSelection);

    function pqShowingTimeSelection() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/prospect/prospect-showing-form/showing-time-selection/showing-time-selection.html',
            scope: {
                times: '=',
                isOpen: '=',
                callback: '=',
                index: '='
            }
        };
    }
})();
