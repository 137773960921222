(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageDashboardListController', MessageDashboardListController);

    function MessageDashboardListController($scope,$http,$state,$rootScope, langService) {

        $scope.t = langService.t('messages');

        $scope.dashboard_messages = [];

        $scope.getDashboardMessages = function(){
            $http.post('/api/v1/dashboard-message/get-list').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Dashboard Messages");
                }

                // lets populate stuff
                $scope.dashboard_messages = data.data.messages;
            });
        }

        $http.post('/api/v1/dashboard-message/is-enabled').success(function(data) {
            if (!data.success) {
                // not enabled for this user
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            if ($rootScope.authUser.permissions == 'tenant') {
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            $scope.getDashboardMessages();
        });
    }
}());
