(function() {
    
    'use strict';

	angular
		.module('app.routes')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/services/views/';

		$stateProvider
			.state('main.services', {
				url: '/services',
				views: {
					"body@": {
						templateUrl: url + 'services.index.html',
						controller: 'InsuranceController',
                         controllerAs: 'vm'
					}
				}
			})
		.state('main.insurance', {
				url: '/services/insurance',
				views: {
					"body@": {
						templateUrl: url + 'insurance.index.html',
						controller: 'InsuranceController',
                        controllerAs: 'vm'
					}
				}
			});

	}

}());
