(function () {

    'use strict';

    angular
        .module('app')
        .controller('NG2Controller', NG2Controller)


    function NG2Controller($scope, $state, $rootScope, $location, routerService) {
        $rootScope.isAngular2 = true;
        if ($location.path() === '/n') {
            if ($rootScope.authUser.permissions === 'applicant') {
                $state.go('main.leaseApplicationDashboard');
            } else {
                const isOnlyOnePermission = routerService.userHasOnlyOnePermission();
                console.log('routerService.getStateByPermission(isOnlyOnePermission)', routerService.getStateByPermission(isOnlyOnePermission), isOnlyOnePermission)
                if (isOnlyOnePermission) {
                    $state.go(routerService.getStateByPermission(isOnlyOnePermission));
                } else {
                    if (
                        $rootScope.authUser['permissions'] === 'payquad_admin'
                        || $rootScope.authUser['permissions'] === 'junior_manager'
                        || $rootScope.authUser['permissions'] === 'admin_manager'
                    ) {
                        console.log('goToAngular2 dash')
                        $rootScope.goToAngular2('dashboard');
                    } else {
                        $state.go('main.dashboard');
                    }
                }
            }
        }
        if ($location.path().includes('/n/') && $location.path().includes('login')){
            $rootScope.goToAngular2Login();
        }
        // for go back browser buttons or window.history.back();
        if ($location.path().includes('/n/')){
            console.log('goToAngular2 back', $location.path())
            $rootScope.goToAngular2($location.path().replace('/n/',''));
        }

    }

}());
