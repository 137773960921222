(function() {

	'use strict';

    angular
		.module('app')
		.controller('MessageTemplateController', MessageTemplateController);

	function MessageTemplateController($rootScope, messageService,$log,$http,$scope, langService) {

        $scope.t = langService.t('messages');

	}

}());
