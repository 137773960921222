(function() {
    'use strict';

    angular
    .module('app')
    .controller('CreditChecksIndexController', creditChecksIndexController);

    function creditChecksIndexController($scope,$http,$state,$rootScope,langService) {
        console.log('CreditChecksIndexController');
        $scope.t = langService.t('credit_check');
        $scope.credit_checks = [];

        $scope.getCreditChecks = function(){
            $http.post('/api/v1/credit-check/get-list').success(function(data) {
                if (!data.success) {
                    if (typeof data.message !== 'undefined') {
                        // if data.message contains 'junior manager'
                        if (data.message.indexOf('junior manager') > -1) {
                            toastr.error('You\'re account does not have access to credit checks');
                            $state.go('main.dashboard', { redirect : true });
                            return 1;
                        } else {
                            toastr.error(data.message);
                        }
                    } else {
                        toastr.error("Error retrieving credit checks");
                    }
                    return 1;
                }

                // lets populate stuff
                $scope.credit_checks = data.data;
                $(document).ready(function () {
                    setTimeout(function () {
                        $('#dataTable').dataTable({
                            buttons: [],
                            responsive: true,
                        });

                        $('body').on('click', '#dataTable .template-edit-button', function (event) {
                            if($(this).data("amenity")) {
                                event.preventDefault();
                                $state.go('main.creditCheckEdit', { id: $(this).data("credit-check") });
                            }
                        });
                    }, 100);
                });
            });
        };
        $scope.getCreditChecks();

        //$http.post('/api/v1/amenity/is-enabled').success(function(data) {
            //if (data.data.enabled == 0) {
                //// not enabled for this user
                //$state.go('main.dashboard', { redirect : true });
                //return 1;
            //}

            //if ($rootScope.authUser.permissions == 'tenant') {
                //$state.go('main.amenityBook');
                //return 1;
            //}

            //$scope.getAmenities();
        //});
    }
}());
