(function () {
    'use strict';

    angular
        .module('pq.property')
        .controller('PropertyEditController', PropertyEditController);

    /* @ngInject */
    function PropertyEditController(
        propertyEditData, $scope, propertyDataService, $state, $rootScope, $upload,
        propertyService, $http, $timeout, helperService, resolvedTriggers, resolvedManagers, langService
    ) {
        $scope.t = langService.t('lease_app');
        
        $scope.active_tab = 'terms';
        $scope.subdomainPattern = /^[a-zA-Z0-9]*$/;

        $scope.auto_respond = {
            auto_responder_template_opened: {
                triggers: []
            }
        };

        $scope.addNewAutoRespondTemplate = () => {
            $scope.property.auto_responder_templates.push({
                id: null,
                property_id: 280,
                template: "",
                title: "",
                local_id: Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1),
                triggers: []
            })
            $scope.auto_respond.auto_responder_template_opened = $scope.property.auto_responder_templates[$scope.property.auto_responder_templates.length - 1]
        }

        $scope.deleteAutoRespondTemplate = () => {
            console.log($scope.property.auto_responder_templates)
            console.log($scope.auto_respond.auto_responder_template_opened)
            $scope.property.auto_responder_templates = $scope.property.auto_responder_templates.filter(i => {
                if (i.local_id && $scope.auto_respond.auto_responder_template_opened.local_id) {
                    return i.local_id != $scope.auto_respond.auto_responder_template_opened.local_id;
                } else {
                    return i.id != $scope.auto_respond.auto_responder_template_opened.id;
                }
            });
            // $scope.property.auto_responder_templates.push({
            //     id: null,
            //     property_id: 280,
            //     template: "",
            //     title: "",
            //     triggers: []
            // })
            // $scope.auto_respond.auto_responder_template_opened = $scope.property.auto_responder_templates[$scope.property.auto_responder_templates.length - 1]
        }

        $scope.setActiveTab = function(tab) {
            $scope.active_tab = tab;
        };

        $rootScope.newunit = {number: ''};
        $rootScope.removeunit = {number: ''};

        $scope.property = propertyEditData.property;
        $scope.dashboard_settings = $scope.property.dashboard_settings ? $scope.property.dashboard_settings : {};

        // var custumOrder = Object.keys($scope.dashboard_settings);
        //
        // var ul = $("#sortable");
        // var items = $("#sortable .list-item");
//
// Changing li according custumOrder
        // for (var item of custumOrder) {
        //     ul.append($('#' + item + ''));
        // }
        //
        // $("#sortable").sortable();

        // let arr = $("#sortable").sortable("toArray");
        // let set = $scope.dashboard_settings;
        //
        // this.property.dashboard_settings = arr.reduce((o, key) => ({
        //     ...o,
        //     [key]: set[key] ? set[key] : false
        // }), {});

        $scope.auto_respond = {
            auto_responder_template_opened: ''
        };

        $scope.openManageTriggersModal = () => {
            $('#auto_responder_triggers_modal').appendTo('body').modal('show');
        }

        $scope.autoResponderDefaultChecked = () => {
            if ($scope.auto_respond.auto_responder_template_opened && $scope.auto_respond.auto_responder_template_opened.id) {
                $scope.property.auto_responder_templates = $scope.property.auto_responder_templates.map(i => {
                    if (i.id != $scope.auto_respond.auto_responder_template_opened.id) {
                        i.is_default = 0;
                    }
                    return i;
                })
            }
        }

        $scope.addAutoResponderTrigger = () => {
            console.log($scope.auto_respond)
            $scope.auto_respond.auto_responder_template_opened.triggers.push({
                "id": null,
                "key": "unit_type",
                "value": "",
                "auto_responder_template_id": null,
                "created_at": null,
                "updated_at": null
            });
        }

        $scope.removeAutoResponderTrigger = id => {
            $scope.auto_respond.auto_responder_template_opened.triggers = $scope.auto_respond.auto_responder_template_opened.triggers.filter(item => {
                return item.$$hashKey !== id;
            });
        }

        $scope.timezone = [{label: 'EDT(Eastern Time)', value: 'Eastern Standard Time'},
            {label: 'HDT(Hawaii Time)', value: 'Hawaiian Standard Time'},
            {label: 'AKDT(Alaska Time)', value: 'Alaskan Standard Time'},
            {label: 'PDT(Pacific time)', value: 'Pacific Standard Time'},
            {label: 'MDT(Mountain Time)', value: 'Mountain Standard Time'},
            {label: 'CDT(Central Time)', value: 'Central Standard Time'},
            {label: 'AST(Atlantic Time)', value: 'Atlantic Standard Time'},
            {label: 'NST(Newfoundland Time)', value: 'Newfoundland Standard Time'}];

        if (typeof $scope.property.vars.key_deposit_multiply !== 'undefined' && $scope.property.vars.key_deposit_multiply == 'yes') {
            $scope.property.vars.key_deposit_multiply = 1;
        } else {
            $scope.property.vars.key_deposit_multiply = 0;
        }
        if (typeof $scope.property.vars.key_laundry_deposit_addable !== 'undefined' && $scope.property.vars.key_laundry_deposit_addable == 'yes') {
            $scope.property.vars.key_laundry_deposit_addable = 1;
        } else {
            $scope.property.vars.key_laundry_deposit_addable = 0;
        }

        $scope.new_deposit = {
            name: '',
            code: '',
            amount: ''
        }
        $scope.lease_deposits = $scope.property.lease_deposits;

        $scope.addNewDeposit = function(){
            if ($scope.new_deposit.name == '' || $scope.new_deposit.amount == '') {
                toastr.error('Name and Amount are required');
                return;
            }
            if (isNaN($scope.new_deposit.amount)) {
                toastr.error('Amount must be a number');
                return;
            }
            $scope.lease_deposits.push({
                name: $scope.new_deposit.name,
                code: $scope.new_deposit.code,
                amount: $scope.new_deposit.amount,
            });

            $scope.new_deposit.name = '';
            $scope.new_deposit.code = '';
            $scope.new_deposit.amount = '';
        };

        $scope.removeDeposit = function(key) {
            $scope.lease_deposits.splice(key, 1);
        };

        $scope.original_amenity_enabled = $scope.property.amenity_enabled;

        let oldRecurringAmountsOtherName = $scope.property.recurring_amounts_other_name;

        $scope.lease_supporting_document_types;
        $scope.new_doc_type = {
            name: '',
            required: 0
        };

        $scope.rentable_items;
        $scope.new_rentable_item = {
            name: '',
            amount: ''
        };

        $scope.unit_types = [];
        $scope.new_unit_type = {
            name: '',
            rent_amount: '',
            available: 1
        };

        //if ($scope.property.country === 'Canada') {
            //$scope.postal_display_name  = 'Postal Code';
        //} else {
            //$scope.postal_display_name = 'Zip Code';
        //}
        $scope.postal_display_name = 'Postal/Zip';
        $scope.triggers = resolvedTriggers;
        $scope.managers = resolvedManagers;

        if (!$scope.property.escalation_rules) {
            $scope.property.escalation_rules = [];
        }

        $timeout(() => {
            $('select.mr-managers').multipleSelect({filter: true, width: 150, placeholder: 'Managers'});
        }, 1500);

        $timeout(function(){
            $('select#payment-methods').multipleSelect({filter: true});
            $('#term-time-end-date').datepicker();
            $('#first-partial-payment-date').datepicker();
            $('#first-partial-payment-start-date').datepicker();
            $('#first-partial-payment-end-date').datepicker();
        }, 1500);

        $scope.addRule = () => {
            $scope.property.escalation_rules.push({
                maintenance_request_trigger_id: null,
                mr_overdue_managers: []
            });
            $timeout(() => {
                $('select.mr-managers:last').multipleSelect({filter: true, width: 150, placeholder: 'Managers'});
            }, 0);
        };

        $scope.removeRule = index => {
            $scope.property.escalation_rules.splice(index, 1);
        };

        // https://github.com/angular-slider/angularjs-slider#my-slider-is-not-rendered-correctly-on-load
        $scope.isShowingsOpen = {};
        var isOpened = false;
        Object.defineProperty($scope.isShowingsOpen, 'IsOpened', {
            get: function () {
                return isOpened;
            },
            set: function (newValue) {
                isOpened = newValue;
                if (isOpened) {
                    $timeout(function () {
                        $scope.$broadcast('rzSliderForceRender');
                    });
                }
            }
        });
        $scope.showingsValidation = {isValid: true, error: ''};

        $scope.copyShowingLink = () => {
            let link = $state.href('main.showingsBooking', {propertyId: $scope.property.id} , { relative: true });
            link = 'https://' + $scope.property.company.subdomain  + '.leasing.payquad.com/' + link

            helperService.copyTextToClipboard(link);
            toastr.success('The showings booking link was copied to the clipboard.');
        };

        $scope.copyProspectLink = () => {
            let link = $state.href('main.prospects.new', {propertyId: $scope.property.id}, {relative: true});
            link = 'https://' + $scope.property.company.subdomain  + '.leasing.payquad.com/' + link

            helperService.copyTextToClipboard(link);
            toastr.success('The prospect register link was copied to the clipboard.');
        };

        $scope.copyNewLeaseApplicationLink = () => {
            let link = $state.href('main.leaseApplicationCreate', {propertyId: $scope.property.id}, {relative: true});
            link = 'https://' + $scope.property.company.subdomain  + '.leasing.payquad.com/' + link

            helperService.copyTextToClipboard(link);
            toastr.success('Link was copied to the clipboard.');
        };

        if ($scope.property === undefined) {

        } else {
            $scope.requiresFileUpload = false;
            $scope.merchantAccounts = $scope.property.company.merchant_accounts;
            $scope.getSampleCSV = propertyDataService.getSampleCSV;

            $scope.addUnit = function () {

                for (var i in $scope.property.property_units) {
                    if ($scope.property.property_units[i].unit == $rootScope.newunit.number) {
                        toastr.warning('Unit Number ' + $rootScope.newunit.number + ' has already been added to this property');
                        $rootScope.newunit.number = '';
                        return;
                    }
                }
                $http.post('/api/v1/properties/' + $scope.property.id + '/add-unit', {
                    'unit': $rootScope.newunit.number
                }).then(function (r) {
                    toastr.success('Unit Number ' + $rootScope.newunit.number + ' Added');
                    $scope.property.property_units.push(r.data.data);
                    $rootScope.newunit.number = '';
                });

            };

            $scope.handleCountryChange = function () {
                //if ($scope.property.country == 'Canada') {
                    //$scope.postal_display_name = 'Postal Code';
                //} else {
                    //$scope.postal_display_name = 'Zip Code';
                //}
                $scope.postal_display_name = 'Postal/Zip';
            };

            $scope.removeUnit = function () {

                var unit_exists = 0;
                var unit_index;
                for (var i in $scope.property.property_units) {
                    if ($scope.property.property_units[i].unit == $rootScope.removeunit.number) {
                        unit_exists = 1;
                        unit_index = i;
                        break;
                    }
                }

                if (unit_exists == 0) {
                    toastr.warning('Unit Number ' + $rootScope.removeunit.number + ' does not currently exist in this property');
                    $rootScope.removeunit.number = '';
                    return;
                }

                $http.post('/api/v1/properties/' + $scope.property.id + '/remove-unit', {'unit': $rootScope.removeunit.number}).success(function (data) {
                    if (data.success) {
                        toastr.success('Unit Number ' + $rootScope.removeunit.number + ' Removed');
                        $scope.property.property_units.splice(unit_index, 1);
                    } else {
                        toastr.error(data.errors);
                    }
                    $rootScope.removeunit.number = '';
                });

            };

            //console.log('ma',$scope.activeMerchantAccount,$scope.merchantAccounts);

            $scope.updateMerchantAccount = function () {
                $scope.activeMerchantAccount = false;
                for (var i = 0; i < $scope.merchantAccounts.length; i++) {
                    if ($scope.merchantAccounts[i].id == $scope.property.merchant_account_id) {
                        $scope.activeMerchantAccount = $scope.merchantAccounts[i];
                    }
                }
            };
            $scope.updateMerchantAccount();
            $scope.$watch(function (scope) {
                return scope.property.merchant_account_id;
            }, $scope.updateMerchantAccount);

            $scope.updateDocumentMerchantAccount = function () {
                $scope.activeDocumentMerchantAccount = false;
                for (var i = 0; i < $scope.merchantAccounts.length; i++) {
                    if ($scope.merchantAccounts[i].id == $scope.property.document_merchant_account_id) {
                        $scope.activeDocumentMerchantAccount = $scope.merchantAccounts[i];
                    }
                }
            };
            $scope.updateDocumentMerchantAccount();
            $scope.$watch(function (scope) {
                return scope.property.document_merchant_account_id;
            }, $scope.updateDocumentMerchantAccount);

            $scope.updateHoldsMerchantAccount = function () {
                $scope.activeHoldsMerchantAccount = false;
                for (var i = 0; i < $scope.merchantAccounts.length; i++) {
                    if ($scope.merchantAccounts[i].id == $scope.property.holds_merchant_account_id) {
                        $scope.activeHoldsMerchantAccount = $scope.merchantAccounts[i];
                    }
                }
            };
            $scope.updateHoldsMerchantAccount();
            $scope.$watch(function (scope) {
                return scope.property.holds_merchant_account_id;
            }, $scope.updateHoldsMerchantAccount);

            $scope.updateHoldsFeeMerchantAccount = function () {
                $scope.activeHoldsFeeMerchantAccount = false;
                for (var i = 0; i < $scope.merchantAccounts.length; i++) {
                    if ($scope.merchantAccounts[i].id == $scope.property.holds_fee_merchant_account_id) {
                        $scope.activeHoldsFeeMerchantAccount = $scope.merchantAccounts[i];
                    }
                }
            };
            $scope.updateHoldsFeeMerchantAccount();
            $scope.$watch(function (scope) {
                return scope.property.holds_fee_merchant_account_id;
            }, $scope.updateHoldsFeeMerchantAccount);

            $scope.deleteProperty = function () {
                if (confirm('Are you sure you want to delete this property, stopping all transactions ' +
                    'and blocking all residents from logging in?'))
                    propertyDataService.deleteProperty($scope.property.id)
                        .then(function () {
                            $state.go('main.dashboard');
                            toastr.success('Property deleted');
                        });
            };

            $scope.uploadCSVFile = function (file) {
                $scope.property.csv_file = file;
            };

            $scope.uploadLogoFile = function (file) {
                $scope.property.logo_file = file;
            };

            $scope.uploadNavFile = function (file) {
                $scope.property.nav_file = file;
            };

            $scope.uploadFaviconFile = function (file) {
                $scope.property.favicon_file = file;
            };

            $scope.uploadLeaseImageFile = function (file) {
                $scope.property.lease_image_file = file;
            };

            $scope.uploadBackgroundFile = function (file) {
                $scope.property.background_file = file;
            };

            $scope.phoneNumberPattern = (function () {
                var regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
                return {
                    test: function (value) {
                        if ($scope.requireTel === false) {
                            return true;
                        }
                        return regexp.test(value);
                    }
                };
            })();


            $scope.submit = function () {
                let arr = $("#sortable").sortable("toArray");
                let set = $scope.dashboard_settings;

                this.property.dashboard_settings = arr.reduce((o, key, currentIndex) => {
                    return  {
                        ...o,
                        [currentIndex]: {
                            title: key,
                            enabled: this.dashboard_settings.some(i => i.enabled && i.title === key),
                        }
                    };
                }, {});

                if (!$scope.showingsValidation.isValid) {
                    toastr.error($scope.showingsValidation.error);
                    return;
                }


                var number_error = 0;
                angular.forEach($scope.lease_deposits, function(deposit, key){
                    if (isNaN(deposit.amount)) {
                        number_error = 1;
                        return;
                    }
                });
                if (number_error == 1) {
                    toastr.error('Lease Deposit Amounts must be a number');
                    return;
                }


                if(oldRecurringAmountsOtherName.length && !$scope.property.recurring_amounts_other_name.length){
                    if(!confirm('Are you sure? Deleting of Other Label will affect the sum of Lease charges for a tenant.')){
                        return;
                    }
                }

                if ($scope.original_amenity_enabled == 1 && $scope.property.amenity_enabled == 0) {
                    if(!confirm('You are about to disable amenities bookings for this property, any amenities with this property assigned will have it removed.')){
                        return;
                    }
                    $scope.original_amenity_enabled == 0;
                }

                var files = [];
                var names = [];
                if ($scope.property.csv_file !== undefined) {
                    files.push($scope.property.csv_file);
                    names.push('csv_file');
                }
                if ($scope.property.logo_file !== undefined) {
                    files.push($scope.property.logo_file);
                    names.push('logo_file');
                }
                if ($scope.property.favicon_file !== undefined) {
                    files.push($scope.property.favicon_file);
                    names.push('favicon_file');
                }
                if ($scope.property.nav_file !== undefined) {
                    files.push($scope.property.nav_file);
                    names.push('nav_file');
                }
                if ($scope.property.lease_image_file !== undefined) {
                    files.push($scope.property.lease_image_file);
                    names.push('lease_image_file');
                }
                if ($scope.property.background_file !== undefined) {
                    files.push($scope.property.background_file);
                    names.push('background_file');
                }

                $scope.property.provided_documents = $scope.provided_documents;
                $scope.property.lease_deposits = $scope.lease_deposits;
                $scope.property.lease_terms = $scope.lease_terms;


                $upload.upload({
                    url: 'api/v1/properties/' + $scope.property.id,
                    method: 'POST',
                    fileFormDataName: names,
                    file: files,
                    data: $scope.property
                }).success(function (data) {
                    if (data.success) {
                        // if ($rootScope.authUser.permissions != 'payquad_admin')
                        // $state.go("main.properties", {});
                        // else
                        // $state.go("main.companyEdit", {companyId: $scope.property.company_id});
                        toastr.success('Property Updated');
                        oldRecurringAmountsOtherName = $scope.property.recurring_amounts_other_name;
                        $state.go("main.properties", {});

                    } else {
                        toastr.error(data.errors);
                    }
                }).error(function(data){
                    console.log(data)
                    if (data.error.message) {
                        toastr.error(data.error.message);
                    } else {
                        toastr.error('fails');

                    }
                });
            };

            $scope.sync_prospects_status_button_label = $scope.property.extension_prospects_auto_sync ? 'Disable' : 'Enable';
            $scope.switchExtensionProspectsAutoSyncStatus = function () {
                $http.post('/api/v1/properties/' + $scope.property.id + '/switch-extension-prospects-sync-status', {}).then(function (r) {
                    toastr.success('Prospects auto sync has been ' + $scope.sync_prospects_status_button_label + 'd');
                    $scope.sync_prospects_status_button_label = $scope.sync_prospects_status_button_label == 'Disable' ? 'Enable' : 'Disable';
                });
            };

            $scope.syncProspects = function () {
                $scope.syncing_prospects = true;
                propertyService.syncProspects($scope.property.id)
                    .success(function (data) {
                        if (data.date !== null && typeof data.data.extension_prospects_last_synced_at !== 'undefined')
                            $scope.property.extension_prospects_last_synced_at = data.data.extension_prospects_last_synced_at;
                        $scope.syncing_prospects = false;
                        toastr.success('Property Prospects Synced');
                    });
            };

            $scope.sync_yardimr_status_button_label = $scope.property.extension_yardimr_auto_sync ? 'Disable' : 'Enable';
            $scope.switchExtensionYardimrAutoSyncStatus = function () {
                $http.post('/api/v1/properties/' + $scope.property.id + '/switch-extension-yardimr-sync-status', {}).then(function (r) {
                    toastr.success('Yardi Maintenance Request auto sync has been ' + $scope.sync_yardimr_status_button_label + 'd');
                    $scope.sync_yardimr_status_button_label = $scope.sync_yardimr_status_button_label == 'Disable' ? 'Enable' : 'Disable';
                });
            };

            $scope.syncYardimr = function () {
                $scope.syncing_yardimr = true;
                propertyService.syncYardimr($scope.property.id)
                    .success(function (data) {
                        if (data.date !== null && typeof data.data.extension_yardimr_last_synced_at !== 'undefined')
                            $scope.property.extension_yardimr_last_synced_at = data.data.extension_yardimr_last_synced_at;
                        $scope.syncing_yardimr = false;
                        toastr.success('Property Yardi Maintenance Requests Synced');
                    });
            };

            $scope.sync_units_status_button_label = $scope.property.extension_units_auto_sync ? 'Disable' : 'Enable';
            $scope.switchExtensionUnitsAutoSyncStatus = function () {
                $http.post('/api/v1/properties/' + $scope.property.id + '/switch-extension-units-sync-status', {}).then(function (r) {
                    toastr.success('Units auto sync has been ' + $scope.sync_units_status_button_label + 'd');
                    $scope.sync_units_status_button_label = $scope.sync_units_status_button_label == 'Disable' ? 'Enable' : 'Disable';
                });
            };

            $scope.syncUnits = function () {
                $scope.syncing_units = true;
                propertyService.syncUnits($scope.property.id).success(function (data) {
                    if (data.date !== null && typeof data.data.extension_units_last_synced_at !== 'undefined') {
                        $scope.property.extension_units_last_synced_at = data.data.extension_units_last_synced_at;
                    }
                    $scope.property.property_units = data.data.property_units_parent;
                    $scope.syncing_units = false;
                    toastr.success('Property Units Synced');
                });
            };

            $scope.sync_status_button_label = $scope.property.extension_auto_sync ? 'Disable' : 'Enable';
            $scope.switchExtensionAutoSyncStatus = function () {
                $http.post('/api/v1/properties/' + $scope.property.id + '/switch-extension-sync-status', {}).then(function (r) {
                    toastr.success('Auto sync has been ' + $scope.sync_status_button_label + 'd');
                    $scope.sync_status_button_label = $scope.sync_status_button_label == 'Disable' ? 'Enable' : 'Disable';
                });
            };

            $scope.sync = function () {
                $scope.syncing = true;
                propertyService.sync($scope.property.id)
                    .success(function (data) {
                        if (data.date !== null && typeof data.data.extension_last_synced_at !== 'undefined')
                            $scope.property.extension_last_synced_at = data.data.extension_last_synced_at;
                        $scope.syncing = false;
                        toastr.success('Property Synced');
                    });
            };
            $scope.notification = {};
            $scope.addEmail = function () {
                if ($scope.notification.newEmail === undefined || $scope.notification.newEmail.indexOf('@') === false)
                    toastr.error('please enter a valid email address');
                else if ($scope.notification.newSend == '')
                    toastr.error('please select if the email should be sent in daily summaries or right away');
                else {
                    $http.post('/api/v1/maintenance-requests/add-notification-for/' + $scope.property.id, {
                        'email': $scope.notification.newEmail,
                        'send_asap': $scope.notification.newSend == 'Daily Summary' ? 0 : 1
                    }).then(function (r) {
                        $scope.notification = {};
                        $scope.property.maintenance_request_notifications.push(r.data.data);
                        toastr.success($scope.notification.newEmail + ' added for email notifications of maintenance requests');
                    });

                }
            };

            $scope.addTrigger = function () {

            };

            $scope.removeEmail = function (id) {
                $http.post('/api/v1/maintenance-requests/remove-notification/' + id).then(function (r) {
                    var i = 0;
                    for (i in $scope.property.maintenance_request_notifications) {
                        if ($scope.property.maintenance_request_notifications[i].id == id)
                            $scope.property.maintenance_request_notifications.splice(i, 1);
                    }
                    toastr.success('The email address will no longer get notifications of maintenance requests for this property');
                });
            };

            $scope.property.available_steps = [
                {
                    name: 'Tenants',
                    slug: 'tenants',
                    order: 1,
                    original_order: 1
                }, {
                    name: 'Personal Info',
                    slug: 'personal',
                    order: 2,
                    original_order: 2
                }, {
                    name: 'Employment',
                    slug: 'employment',
                    order: 3,
                    original_order: 3
                }, {
                    name: 'Guarantors',
                    slug: 'guarantors',
                    order: 4,
                    original_order: 4
                }, {
                    name: 'Emergency Contacts',
                    slug: 'emergency-contact',
                    order: 5,
                    original_order: 5
                }, {
                    name: 'References',
                    slug: 'references',
                    order: 6,
                    original_order: 6
                }, {
                    name: 'Supporting Documents',
                    slug: 'supporting-docs',
                    order: 7,
                    original_order: 7
                }, {
                    name: 'Legal',
                    slug: 'legal',
                    order: 8,
                    original_order: 8
                }, {
                    name: 'Deposit Payment',
                    slug: 'payment',
                    order: 9,
                    original_order: 9
                }, {
                    name: 'Recurring Payments',
                    slug: 'recurring-payments',
                    order: 10,
                    original_order: 10
                }, {
                    name: 'Questions',
                    slug: 'questions',
                    order: 11,
                    original_order: 11
                }
            ];

            $scope.property.active_steps = [];

            $scope.getActiveSteps = function () {
                $http.post('/api/v1/lease-application/get-property-steps/' + $scope.property.id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    var steps = data.data.steps;

                    for (var i = 0; i < steps.length; i++) {
                        for (var x = 0; x < $scope.property.available_steps.length; x++) {
                            if ($scope.property.available_steps[x].slug === steps[i].slug) {
                                $scope.addLeaseApplicationStep($scope.property.available_steps[x]);
                            }
                        }
                    }

                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };
            $scope.getActiveSteps();

            $scope.templates = {
                templates: {},
                lease_application_templates: {},
            };
            $scope.getLeaseApplicationTemplates = function () {
                $http.post('/api/v1/lease-application/get-templates/' + $scope.property.company_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.templates.templates = data.data.templates
                    $scope.templates.lease_application_templates = data.data.lease_application_templates;
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };
            $scope.getLeaseApplicationTemplates();

            $scope.getLeaseDocTypes = function () {
                $http.post('/api/v1/lease-application/get-supporting-document-types', {property_id: $scope.property.id}).success(function (data) {
                    $scope.lease_supporting_document_types = data.data.types;
                });
            };
            $scope.getLeaseDocTypes();

            $scope.addLeaseDocType = function () {
                if ($scope.new_doc_type.name == '') {
                    return;
                }

                var post_data = {
                    name: $scope.new_doc_type.name,
                    required: $scope.new_doc_type.required,
                    property_id: $scope.property.id
                };

                $http.post('/api/v1/lease-application/supporting-document-type-create', post_data).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error creating Document Type');
                        return;
                    }

                    $scope.lease_supporting_document_types = data.data.types;
                    $scope.new_doc_type.name = '';
                    $scope.new_doc_type.required = 0;

                    toastr.success('Document Type Added');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.updateLeaseDocType = function (type) {
                if (!confirm('Are you sure you want to update this Document Type?')) {
                    return;
                }

                $http.post('/api/v1/lease-application/supporting-document-type-update/' + type.id, type).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error updating Document Type');
                        return;
                    }

                    $scope.lease_supporting_document_types = data.data.types;

                    toastr.success('Document Type Updated');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.deleteLeaseDocType = function (type) {
                if (!confirm('Are you sure you want to delete this document type?')) {
                    return;
                }

                $http.post('/api/v1/lease-application/supporting-document-type-delete/' + type.id).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error deleting document type');
                        return;
                    }

                    $scope.lease_supporting_document_types = data.data.types;

                    toastr.success('Document Type Deleted');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.getUnitTypes = function () {
                $http.post('/api/v1/property/get-unit-types', {property_id: $scope.property.id}).success(function (data) {
                    $scope.unit_types = data.data.unit_types;
                });
            };
            $scope.getUnitTypes();

            $scope.addUnitType = function () {
                if ($scope.new_unit_type.name == '') {
                    return;
                }

                var post_data = {
                    name: $scope.new_unit_type.name,
                    rent_amount: $scope.new_unit_type.rent_amount,
                    available: $scope.new_unit_type.available,
                    property_id: $scope.property.id
                };

                $http.post('/api/v1/property/unit-type-create', post_data).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error creating Unit Type');
                        return;
                    }

                    $scope.unit_types = data.data.unit_types;
                    $scope.new_unit_type.name = '';
                    $scope.new_unit_type.rent_amount = '';
                    $scope.new_unit_type.available = 1;

                    toastr.success('Unit Type Added');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };


            $scope.updateUnitType = function (unit_type) {
                if (!confirm('Are you sure you want to update this Unit Type?')) {
                    return;
                }

                $http.post('/api/v1/property/unit-type-update/' + unit_type.id, unit_type).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error updating Unit Type');
                        return;
                    }

                    $scope.unit_types = data.data.unit_types;

                    toastr.success('Unit Type Updated');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.deleteUnitType = function (unit_type) {
                if (!confirm('Are you sure you want to delete this unit type?')) {
                    return;
                }

                $http.post('/api/v1/property/unit-type-delete/' + unit_type.id).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error deleting unit type');
                        return;
                    }

                    $scope.unit_types = data.data.unit_types;

                    toastr.success('Unit Type Deleted');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.getRentableItems = function () {
                $http.post('/api/v1/lease-application/get-rentable-items', {property_id: $scope.property.id}).success(function (data) {
                    $scope.rentable_items = data.data.rentable_items;
                });
            };
            $scope.getRentableItems();

            $scope.addRentableItem = function () {
                if ($scope.new_rentable_item.name == '') {
                    return;
                }

                var post_data = {
                    name: $scope.new_rentable_item.name,
                    amount: $scope.new_rentable_item.amount,
                    property_id: $scope.property.id
                };

                $http.post('/api/v1/lease-application/rentable-item-create', post_data).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error creating Rentable Item');
                        return;
                    }

                    $scope.rentable_items = data.data.rentable_items;
                    $scope.new_rentable_item.name = '';
                    $scope.new_rentable_item.amount = '';

                    toastr.success('Rentable Item Added');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.updateRentableItem = function (rentable_item) {
                if (!confirm('Are you sure you want to update this Rentable Item?')) {
                    return;
                }

                $http.post('/api/v1/lease-application/rentable-item-update/' + rentable_item.id, rentable_item).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error updating Rentable Item');
                        return;
                    }

                    $scope.rentable_items = data.data.rentable_items;

                    toastr.success('Rentable Item Updated');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.deleteRentableItem = function (rentable_item) {
                if (!confirm('Are you sure you want to delete this rentable item?')) {
                    return;
                }

                $http.post('/api/v1/lease-application/rentable-item-delete/' + rentable_item.id).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error deleting rentable item');
                        return;
                    }

                    $scope.rentable_items = data.data.rentable_items;

                    toastr.success('Rentable Item Deleted');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.addLeaseApplicationStep = function (step) {
                var key = null;
                for (var i = 0; i < $scope.property.available_steps.length; i++) {
                    if ($scope.property.available_steps[i] === step) {
                        key = i;
                    }
                }

                if (key == null) {
                    return;
                }

                $scope.property.available_steps[key].order = $scope.property.active_steps.length;
                $scope.property.active_steps.push($scope.property.available_steps[key]);
                $scope.property.available_steps.splice(key, 1);
            };

            $scope.removeLeaseApplicationStep = function (step) {
                var key = null;
                for (var i = 0; i < $scope.property.active_steps.length; i++) {
                    if ($scope.property.active_steps[i] === step) {
                        key = i;
                    }
                }

                if (key == null) {
                    return;
                }

                var removed_order = $scope.property.active_steps[key].order;

                $scope.property.available_steps.push($scope.property.active_steps[key]);
                $scope.property.active_steps.splice(key, 1);

                for (var i = 0; i < $scope.property.active_steps.length; i++) {
                    if ($scope.property.active_steps[i].order > removed_order) {
                        $scope.property.active_steps[i].order--;
                    }
                }
            };

            $scope.moveUpLeaseApplicationStep = function (step) {
                $scope.moveLeaseApplicationStep(step, 'up');
            };

            $scope.moveDownLeaseApplicationStep = function (step) {
                $scope.moveLeaseApplicationStep(step, 'down');
            };

            $scope.moveLeaseApplicationStep = function (step, direction) {
                var current_order = step.order;
                if (direction == 'up') {
                    var new_order = current_order - 1;
                } else {
                    var new_order = current_order + 1;
                }

                var key = null;
                var toswitch_key = null;
                for (var i = 0; i < $scope.property.active_steps.length; i++) {
                    if ($scope.property.active_steps[i] === step) {
                        key = i;
                    }

                    if ($scope.property.active_steps[i].order == new_order) {
                        toswitch_key = i;
                    }
                }

                if (key == null || toswitch_key == null) {
                    return;
                }

                $scope.property.active_steps[key].order = new_order;
                $scope.property.active_steps[toswitch_key].order = current_order;
            };

            //PROVIDED DOCUMENTS
            $scope.provided_documents = $scope.property.lease_application_provided_documents;

            $timeout(() => {
                $scope.initDropzone();
            }, 3000);

            $scope.initDropzone = function(){
                var uploadUrl = '/api/v1/property/provided-document-upload/' + $scope.property.id;
                var headers = {};
                if(!!window.Capacitor.isNativePlatform()) {
                    uploadUrl = pqDomain + uploadUrl + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken;
                    headers = {
                        'Cache-Control': null,
                        'X-Requested-With': null,
                    }
                }
                $('#real-dropzone').dropzone({
                    uploadMultiple: false,
                    parallelUploads: 1,
                    maxFilesize: 8,
                    addRemoveLinks: false,
                    dictRemoveFile: 'Remove',
                    dictFileTooBig: 'File is bigger than 8MB',
                    headers: headers,
                    url: uploadUrl,

                    // The setting up of the dropzone
                    error: function(file, response) {
                        var message = '';
                        if ($.type(response) === "string") {
                            message = response; //dropzone sends it's own error messages in string
                        } else {
                            message = response.message;
                        }

                        toastr.error(message);

                        var _ref;
                        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                    },
                    success: function(file, response, done) {
                        //$('#photos-list').html(response.photos_table);
                        $scope.$apply(function(){
                            $scope.provided_documents = response.data.documents;
                        });
                        //toastr.success('Photo has been added to gallery');
                        var _ref;
                        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                    },
                    uploadprogress: function(file, progress, bytesSent) {
                        //$('#instruction').html('Uploading '+file.name);
                    }
                });
            }

            $scope.deleteProvidedDocument = function(document_id) {
                if (!confirm('Are you sure you want to delete this document?')) {
                    return;
                }

                $http.post('/api/v1/property/delete-provided-document/' + document_id).success(function(data) {
                    if (!data.success) {
                        toastr.error('Error deleting document');
                        return;
                    }

                    $scope.provided_documents = data.data.provided_documents;

                    toastr.success('Document Deleted');
                }).error(function(data){
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            }

            $scope.openProvidedDocument = function(doc) {
                var url = '/lease-application-provided-document-download/' + doc.id;
                if(window.Capacitor.isNativePlatform()) {
                    try {
                        window.Capacitor.Plugins.Browser.open({url: (pqDomain + url + '?cordova='+(!!window.Capacitor.isNativePlatform()? '1':'0') + '&token=' + pqCachedToken)});
                    } catch (e) {
                        alert(e)
                    }
                } else {
                    window.open(url, '_blank');
                }
            };
            //END OF PROVIDED DOCUMENTS

            // lease terms stuff
            if ($scope.property.lease_terms.length > 0) {
                $scope.lease_terms = $scope.property.lease_terms;
            } else {
                $scope.lease_terms = [{
                    id: 0,
                    name: 'Terms of Service',
                    content: 'Terms of Service go here',
                    checkbox_label: 'I agree',
                    signature_required: 0
                }];
            }

            $scope.active_lease_terms = $scope.lease_terms[0];
            $scope.active_terms_tab = 0;

            $scope.setActiveTerms = function(key) {
                $scope.active_lease_terms = $scope.lease_terms[key];
                $scope.active_terms_tab = key;
            };

            $scope.addNewTerms = function() {
                $scope.lease_terms.push({
                    id: 0,
                    name: 'Terms of Service',
                    content: 'Terms of Service go here',
                    checkbox_label: 'I agree',
                    signature_required: 0,
                    type: 'applicant'
                });
                $scope.active_terms_tab = $scope.lease_terms.length - 1;
                $scope.active_lease_terms = $scope.lease_terms[$scope.active_terms_tab];
            }

            $scope.removeTerms = function(key) {
                $scope.lease_terms.splice(key, 1);
            };

            $scope.newLeaseQuestion = {
                question: '',
                answer_type: 'string',
                is_required: 0,
            }

            // lease questions
            $scope.leaseQuestionisLoading = {
                savingNew: false,
            }
            propertyService.getQuestions($scope.property.id).success((response) => {
                $scope.questions = response.data;
            });

            $scope.addNewLeaseQuestion = () => {
                $scope.leaseQuestionisLoading.savingNew = true;
                $http.post('/api/v1/lease-application/questions/' + $scope.property.id, $scope.newLeaseQuestion).success(response => {
                    $scope.questions.push({...$scope.newLeaseQuestion});
                    $scope.newLeaseQuestion.question = '';
                    $scope.newLeaseQuestion.is_required = 0;
                    $scope.leaseQuestionisLoading.savingNew = false;
                }).error(response => {
                    alert('Error saving new lease question');
                    $scope.leaseQuestionisLoading.savingNew = false;
                })
            }

            $scope.updateLeaseQuestion = (question) => {
                question.updating = true;
                $http.put('/api/v1/lease-application/questions/' + $scope.property.id + '/' + question.id, question).success(response => {
                    question.updating = false;
                }).error(response => {
                    alert('Error updating lease application question');
                    question.updating = false;
                })
            }

            $scope.deleteLeaseQuestion = (question) => {
                question.deleting = true;
                $http.delete('/api/v1/lease-application/questions/' + $scope.property.id + '/' + question.id, question).success(response => {
                    $scope.questions = $scope.questions.filter(item => item.id !== question.id)
                    question.deleting = false;
                }).error(response => {
                    alert('Error deleting lease application question');
                    question.deleting = false;
                })
            }

            //MR add custom questions
            $scope.newMRQuestion = {
                question: '',
                response_type: 'Free text',
                is_required: 0,
                manager_only: 0
            }

            $scope.MRQuestionisLoading = {
                savingNew: false,
            }
            propertyService.getMRQuestions($scope.property.id).success((response) => {
                $scope.MRQuestions = response.data;
            });

            $scope.addNewMRQuestion = () => {
                $scope.MRQuestionisLoading.savingNew = true;
                $http.post('/api/v1/maintenance-requests/custom-questions/' + $scope.property.id, $scope.newMRQuestion).success(response => {
                    toastr.success('Success saving maintenance request question');
                    $scope.MRQuestions.push({...response.data});
                    $scope.newMRQuestion.question = '';
                    $scope.newMRQuestion.response_type = 'Free text';
                    $scope.newMRQuestion.is_required = 0;
                    $scope.newMRQuestion.manager_only = 0;
                    $scope.MRQuestionisLoading.savingNew = false;
                }).error(response => {
                    toastr.error('Error saving maintenance request question');
                    $scope.MRQuestionisLoading.savingNew = false;
                })
            }

            $scope.updateMRQuestion = (question) => {
                question.updating = true;
                $http.put('/api/v1/maintenance-requests/custom-questions/' + $scope.property.id + '/' + question.id, question).success(response => {
                    toastr.success('Success updating maintenance request question');
                    question.updating = false;
                }).error(response => {
                    toastr.error('Error updating maintenance request question');
                    question.updating = false;
                })
            }

            $scope.deleteMRQuestion = (question) => {
                question.deleting = true;
                $http.delete('/api/v1/maintenance-requests/custom-questions/' + $scope.property.id + '/' + question.id, question).success(response => {
                    $scope.MRQuestions = $scope.MRQuestions.filter(item => item.id !== question.id)
                    question.deleting = false;
                }).error(response => {
                    toastr.error('Error deleting maintenance request question');
                    question.deleting = false;
                })
            }

            $scope.alertIfChecked = () => {
                if($scope.property.auto_cancel_inactive_lease_app){
                    if(!confirm('Are you sure you want to cancel all lease applications that have been inactive for longer than 14 days? \nThis will affect all lease appliations older than 14 days.')){
                        $scope.property.auto_cancel_inactive_lease_app = 0;
                    }
                }
            }

            $scope.lock_fields = [
                {slug: 'landlord_name', name: 'Landlord\'s Name'},
                {slug: 'landlord_email', name: 'Landlord\'s Email'},
                {slug: 'landlord_address', name: 'Landlord\'s Address'},
                {slug: 'smoking', name: 'Smoking'},
                {slug: 'smoking_rules', name: 'Smoking Rules'},
            ];

            $timeout(function () {
                $('select#lease-application-locked-fields').multipleSelect({filter: true, width: 350});
            }, 500);
        }

        $scope.calculateTotalRent = () => {
            $scope.property.vars.total_rent = Number($scope.property.vars.base_rent || 0) + Number($scope.property.vars.parking_amount || 0) + Number($scope.property.vars.additional_service1_amount || 0) + Number($scope.property.vars.additional_service2_amount || 0);
        }


        $scope.addPropertyEmail = function () {
            if ($scope.property.emails.indexOf($scope.newEmail) === -1) {
                $scope.property.emails.push({
                    'email': '',
                    'is_enabled': 1,
                    'friendly_name': '',
                    'email_name': '',
                    'email_domain': 'inbound.payquad.com',
                });
                $scope.newEmail = '';
            }
        }

        $scope.deletePropertyEmail = function (index) {
            $scope.property.emails.splice(index, 1);
        }

        $scope.activeEmailSetting = [];
        $scope.setUpForwardEmail = function (item) {
            $scope.activeEmailSetting = item;
            $('#email-forward').appendTo('body').modal('show');
            if (!item.managers) {
                return;
            }

            $scope.managers.map(manag => {
                manag.is_selected = false;
                item.managers.map(manager => {
                    if (manag.id == manager.id) {
                        manag.is_selected = true;
                    }
                });
            });
        }

        $scope.toggleForwardEmailManager = function (manager) {
          if (!manager.is_selected) {
              manager.is_selected = true;
              $scope.activeEmailSetting.managers.push(manager)
          } else {
              manager.is_selected = false;
              $scope.managers.filter((manag, index) => {
                  if (manag.id == manager.id) {
                      $scope.activeEmailSetting.managers.splice(index, 1);
                  }
              });
          }
        }

    }

})();
