(function () {

    'use strict';

    angular
        .module('app')
        .factory('propertyService', propertyService);

    function propertyService($http, $q) {

        var base_url = '/api/v1/';
        var local_url = 'properties';

        return {

            getAll: function () {
                return $http.get(base_url + local_url);
            },

            getById: function (id) {
                return $http.get(base_url + local_url + "/" + id);
            },

            create: function (data) {
                return $http({
                    method: 'POST',
                    url: base_url + local_url,
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    data: $.param(data)
                });
            },

            update: function (id, data) {
                return $http.put(base_url + local_url + "/" + id, data);
            },

            delete: function (id) {
                return $http.delete(base_url + local_url + "/" + id);
            },

            searchProperties: function (searchTerm) {
                return $http.get(base_url + local_url + '/properties-search?search=' + searchTerm);
            },

            getPropertyPaymentServices: function () {
                var deferred = $q.defer();
                $http.get(base_url + local_url + '/payment-services').success(function (object, status) {
                    deferred.resolve(object);
                });
                return deferred.promise;
            },

            getRevenue: function (id) {
                var revenue_url = '/api/v1/' + 'properties/' + id + '/revenues';
                return $http.get(revenue_url);
            },

            sync: function (id) {
                return $http.get(base_url + local_url + "/" + id + '/sync');
            },

            syncProspects: function (id) {
                return $http.get(base_url + local_url + "/" + id + '/syncProspects');
            },

            syncYardimr: function (id) {
                return $http.get(base_url + local_url + "/" + id + '/syncYardimr');
            },

            syncUnits: function (id) {
                return $http.get(base_url + local_url + "/" + id + '/syncUnits');
            },

            getUnits: propertyId => $http.post(`${base_url}${local_url}/get-property-units-for-manager-payments/${propertyId}`).then(r => r.data.data),

            getQuestions: propertyId => $http.get(`${base_url}lease-application/questions/${propertyId}`),

            getQuestionsApplicant: propertyId => $http.get(`${base_url}lease-application/questions-applicant/${propertyId}`),

            getMRQuestions: propertyId => $http.get(`${base_url}maintenance-requests/custom-questions/${propertyId}`),

            getUnitsForProperties: propertyIds => {
                const params = propertyIds.map(item => `properties[]=${item}`).join('&');
                return $http.get(`${base_url}${local_url}/get-properties-units-for-manager?${params}`)
                    .then(r => r.data.data);
            }

        };

    }

}());
