(function() {
    'use strict';

    angular
    .module('app')
    .controller('DocumentsDownloadsController', documentsDownloadsController);

    function documentsDownloadsController($scope,$state,$rootScope,$http,$timeout, langService) {
        $scope.t = langService.t('documents');

        angular.element($('body')).scope().myscope = $scope;

        $scope.documentEmail = function(id,unit_id){
            var url = '/api/v1/document/email/'+id;
            if(unit_id != null)
                url += "/"+unit_id;
            $http.post(url, {}).success(function(data) {
                if (!data.success) {
                    toastr.error('Error emailing document. You are not authorized to download this file.');
                    return;
                }
                toastr.success('The requested document has been emailed to you');
            });
        };
    }
}());
