(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqTerms', pqTerms);

    function pqTerms()
    {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/terms/terms.html'
        }
    }

})();