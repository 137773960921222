(function() {
    'use strict';

    angular
    .module('app')
    .controller('QuickPayCompletedController', quickPayCompletedController);

    function quickPayCompletedController($scope,$http,$state,$rootScope) {
        $scope.quick_pays = [];

        $scope.getQuickPays = function(){
            $(document).ready(function () {
                setTimeout(function () {
                    var columnDefs = [
                        {                        
                        'targets': [11], //status column
                        'render': function (data, type, row) {
                            if (row[11].includes('paid')) {
                                return '<strong style="color:#97d629; text-transform: capitalize">' + data + '</strong>';
                            }
                            if (row[11].includes('pending')) {
                                return '<strong style="color:#ffa500; text-transform: capitalize">' + data + '</strong>';
                            }
                            if (row[11].includes('declined')) {
                                return '<strong style="color:#ea501b; text-transform: capitalize">' + data + '</strong>';
                            }
                            if (row[11].includes('deleted')) {
                                return '<strong style="color:#ea1b1b; text-transform: capitalize">' + data + '</strong>';
                            }
                            if (row[11].includes('expired')) {
                                return '<strong style="color:#2F4F4F; text-transform: capitalize">' + data + '</strong>';
                            }
                            return '<b style="text-transform: capitalize" class="' + row[11] + '">' + data + '</b>';
                         }
                        },
                        {
                        'targets': [12], //payment reference #
                        'render': function (data, type, row) {
                            if (row[12] == 0) {
                                //return data;
                                return '<a href="#/payments/receipt/' + row[15] + '">' + data + '</a>';
                            } else {
                                return '<a href="#/payments/receipt/' + row[15] + '">' + row[12] + '</a>';
                            }
                          }
                        },       
                        {  
                        'targets': [14],
                        'render': function (data, type, row) {
                            if(row[11].includes('expired')){
                                return '<a class="template-edit-button" href="/#/quick-pay/edit/'+row[14]+'?reopen=true" data-quick-pay="'+ row[14]+'">[ Reopen ]</a>';
                            }else{
                                return '';
                            }
                            
                        }
                        }
                    ];

                    $('#quickPayCompletedDataTable').dataTable({
                        responsive: $(window).width() < 1200,
                        'processing': true,
                        'order': [[7, 'desc']],
                        'serverSide': true,
                        'columnDefs': columnDefs,
                        'ajax': {
                            'url': pqDomain + '/api/v1/quick-pay/get-completed' + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken,
                            'type': 'POST',
                            error: function(jqXHR, textStatus, errorThrown) {
                                if (jqXHR.status != 0) {
                                    alert('There was an error retrieving your data.');
                                }
                            },
                        },

                        buttons: ['copy']
                    });
                }, 100);
            });
        };
        $scope.getQuickPays();

        $scope.doExport = function() {
            var filter_tags = localStorage.getItem('filter_tagsquick-pays-completed');
            if (filter_tags == null) {
                var url = '/api/v1/quick-pay/get-completed-csv';
            }else{
                var params = JSON.parse(filter_tags);
                var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    
                var url = '/api/v1/quick-pay/get-completed-csv?' + queryString;
            }

            if (window.Capacitor.isNativePlatform()) {
                try {
                    window.Capacitor.Plugins.Browser.open({url: (pqDomain + url + '?cordova=' + (!!window.Capacitor.isNativePlatform() ? '1' : '0') + '&token=' + pqCachedToken)});
                } catch (e) {
                    alert(e)
                }
            }else{
                window.open(url, '_blank');
            }
        };
    }
}());
