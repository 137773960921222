var pqDomain = 'https://dev.payquad.com';
var pqCachedToken = '';
/*
    Switch to 'manager-app' to build managers mobile app (changed log in page, no registration).
 */
const pqAppType = null;
/*
    Set company subdomain here to enable company branding and deny non-company users to login.
 */
const pqAppCompanySubdomain = '';

/* Set the mixpanel token, for cordova apps only. Initialized on deviceready */
const pqAppMixpanelToken = '';

/* variable to identify cordova build as a tenant or manager or medallion */
const pqAppId = 'com.payquad.manager';

const pqAppGroup = 'manager';

/* version number for cordova builds */
const appVersion = '1.0.16';

(function () {

    'use strict';

    angular
        .module('app', [
            'ui.router',
            'app.routes',
            'app.core',
            'app.filters',
            // PQ Feature Modules
            'pq.property',
            'angularUtils.directives.dirPagination',
            'ui.bootstrap',
            'textAngular',
            'ngTagsInput',
            'ngSlider',
            'validation.match',
            'credit-cards',
            'nl2br',
            'ngAnimate',
            'rzSlider',
            'angularMoment'
        ]).factory('JWTInterceptor', function (jwtService) {
            return {
                request:  function (config) {
                    const token = jwtService.getToken(true);

                    if (token) {
                        config.headers['Authorization'] = 'Bearer ' + token;
                    }

                    return config;
                }
            }
        }).factory('mobileAppInterceptor', function (appValues) {
            return {
                request: config => {
                    if (pqAppType) {
                        config.headers['QPAppType'] = pqAppType;
                    }
                    if (pqAppCompanySubdomain) {
                        config.headers['PQCompanySubdomain'] = pqAppCompanySubdomain;
                    }
                    return config;
                }
            };
        })
        .run(function ($rootScope, jwtService, $state, $http, helperService) {
            initApp($rootScope, jwtService, $state, $http, helperService);
        })
        .config(function ($httpProvider, $compileProvider) {
            $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|file|blob|ionic):|data:image/);

            if (Boolean(window.Capacitor.isNativePlatform())) {
                $httpProvider.interceptors.push('JWTInterceptor');
            }
            $httpProvider.interceptors.push('mobileAppInterceptor');

            $httpProvider.interceptors.push(function ($q) {
                return {
                    'request': function (config) {
                        if (!!window.Capacitor.isNativePlatform() && !config.url.includes('://') && config.url.includes('api/v')) {
                            if (config.url.charAt(0) != '/')
                                config.url = '/' + config.url;
                            config.url = pqDomain + config.url;
                        }
                        return config || $q.when(config);
                    }
                };
            });
        })
        .config(function ($provide) {
            $provide.decorator('taOptions', ['taRegisterTool', '$delegate', 'taSelection', function (taRegisterTool, taOptions, taSelection) {
                let isVariablesOpen = false;
                let isShowWarning = false;
                taRegisterTool('variables', {
                    buttontext: 'variables',
                    disabled: function () {
                        return !window.editorVariablesIsEnabled;
                    },
                    action: function () {
                        isVariablesOpen = !isVariablesOpen;
                        const currentSelection = taSelection.getSelection();

                        const hideUl = () => {
                            $('.ta-variables-list').remove();
                        };

                        const ins = tag => () => {
                            taSelection.setSelection(currentSelection.container, currentSelection.start.offset, currentSelection.start.offset);
                            taSelection.insertHtml(`<span>{{${tag}}}</span>`);
                            hideUl();
                            isVariablesOpen = !isVariablesOpen;
                        };
                        if (isVariablesOpen) {
                            const ul = angular.element('<ul class="ta-variables-list"></ul>');
                            ul.append(angular.element('<li>First name</li>').on('click', ins('firstname')));
                            ul.append(angular.element('<li>Last name</li>').on('click', ins('lastname')));
                            ul.append(angular.element('<li>Property</li>').on('click', ins('property')));
                            ul.append(angular.element('<li>Rent amount</li>').on('click', ins('rentamount')));
                            ul.append(angular.element('<li>Lease start</li>').on('click', ins('leasestart')));
                            ul.append(angular.element('<li>Lease end</li>').on('click', ins('leaseend')));
                            this.$element.after(ul);
                        } else {
                            hideUl();
                        }

                        if (!isShowWarning) {
                            alert('Some of the variables could be not applicable to the message recipients. Please, use it in the right way.');
                            isShowWarning = true;
                        }
                    }
                });

                if (!location.hash.includes('companies')) {
                    taOptions.toolbar[3].push('variables');
                }
                return taOptions;
            }]);
            $provide.decorator("$exceptionHandler", ["$delegate", "$window", function($delegate, $window) {
                return function (exception, cause) {
                    console.error('PQ exception handler:', exception.message + " |", cause)
                    if (exception.message && exception.message.indexOf("$digest() iterations reached") !== -1) {
                        console.error("Cyclic dependency detected: ", exception.stack);
                    }
                    $delegate(exception, cause);
                };
            }]);
        });

}());

const initApp = function ($rootScope, jwtService, $state, $http, helperService) {
    $rootScope.pqDomain = !!window.Capacitor.isNativePlatform() ? pqDomain : '.';
    $rootScope.pqAppType = pqAppType;
    $rootScope.pqAppCompanySubdomain = pqAppCompanySubdomain;
    $rootScope.pqAppGroup = pqAppGroup;
    $rootScope.appVersion = appVersion;
    $rootScope.pqAppId = pqAppId;
    pqDomain = $rootScope.pqDomain;

    $rootScope.platform = window.Capacitor.getPlatform();

    $rootScope.sideNavOpened = !helperService.isMobileOrTablet();
    $rootScope.newNavProfilePopupOpened = false;
    $rootScope.toggleNavBar = () => {
        window['fireAngularEvent']('pq.angular-js-sidenav-toggle');
        $rootScope.sideNavOpened = !$rootScope.sideNavOpened;
    };
    $rootScope.newMessagesEvent = (messages) => {
        window['fireAngularEvent']('pq.angular-js-new-messages', messages);
        $rootScope.newMessages = messages;
    }

    $rootScope.currentPaymentTab = 1;

    $rootScope.goToAngular2 = alias => {
        const keys = ['a', 'b', 'c', 'd', 'e'];
        const values = alias.split('/');

        let res = {};

        values.forEach((element, index) => {
            res[keys[index]] = element;
        })

        $state.go('main.ng2', res, {notify: false}).then(() => {
            $rootScope.isAngular2 = true;
            window.routerSubject.next(alias);
        });
    }
    $rootScope.goToAngular2Login = () => {
        $state.go('main.ng2Login', {}, {notify: true}).then(() => {
            $rootScope.isAngular2 = true;
            window.routerSubject.next('login');
        });
    }

    $rootScope.switchPaymentTabs = function (tabIndex) {
        var urls = ['',
            'main.paymentScheduleCreate',
            'main.paymentMethods',
            'main.paymentScheduleIndex'
        ];
        $rootScope.currentPaymentTab = tabIndex;
        $state.go(urls[parseInt(tabIndex)]);
    }

    document.addEventListener("deviceready", function () {
        $rootScope.platform = window.Capacitor.getPlatform();
        if(pqAppMixpanelToken) {
            mixpanel.init(pqAppMixpanelToken, {batch_requests: true});
        }else{
            mixpanel.init();
        }
        jwtService.initCordovaToken();

        window.Capacitor.Plugins.StatusBar.setStyle({ style: "DEFAULT" })
        if ( window.Capacitor.getPlatform() === 'android') {
            window.Capacitor.Plugins.StatusBar.setBackgroundColor({color: '#FFFFFF'})
        }

    }, false);

    window.addEventListener('keyboardWillShow', (event) => {
        if ($rootScope.platform === 'ios') {
            $(".navbar-nav.nav-mobile").hide();
        }
    });

    window.addEventListener('keyboardWillHide', () => {
        if ($rootScope.platform === 'ios') {
            $(".navbar-nav.nav-mobile").show();
        }
    });

    $.extend( $.fn.dataTable.defaults, {
        "lengthMenu": [ 10, 25, 50, 100, 150 ],
    } );

    $rootScope.fullcalendardefaults = {
        'localeButtonText': {
            'En': {
                today: 'today',
                year: 'year',
                month: 'month',
                week: 'week',
                day: 'day',
                list: 'list'
                }, // default value
            'Fr': {
                today: 'Aujour\'dhui',
                year: 'Semaine',
                month: 'Mois',
                week: 'Semaine',
                day: 'Jour',
                list: 'Mon planning'
            },
        },
        'noEventsMessage': {
            'En': "No events to display",
            'Fr': "Aucun événement à afficher"
        }
    }
    const multipleSelectDefaults = {};
    multipleSelectDefaults['Fr'] = {
        selectAllText: 'Choisir Tout',
        allSelected: 'Tout choisi',
        countSelected: '# de % sélectionné',
        noMatchesFound: 'Aucun résultat'
    };
    multipleSelectDefaults['En'] = {
        selectAllText: 'Select all',
        allSelected: 'All selected',
        countSelected: '# of % selected',
        noMatchesFound: 'No matches found'
    };

    $http.get('../assets/datatables/i18n/fr-FR.json').then(function(data) {
        $rootScope.dataTablesFr = data.data;
    })
    $http.get('../assets/datatables/i18n/en-GB.json').then(function(data) {
        $rootScope.dataTablesEn = data.data;
    })

    $rootScope.$watch('localeButtonString', (newValue, oldValue) => {

        $.extend( $.fn.dataTable.defaults, {
            language: newValue == 'Fr' ? $rootScope.dataTablesFr : $rootScope.dataTablesEn
        } );

        $.extend( $.fn.multipleSelect.defaults, multipleSelectDefaults[newValue]);

        moment.locale(newValue);
    });

    window['subscribeToAngularEvent']('pq.angular-js-lang-changed', args => {
        $rootScope.setLocalization(args);
    });

    window['subscribeToAngularEvent']('pq.user-loaded', data => {
        jwtService.setToken(data.data.access_token, data.data.token_type, data.data.expired_in);
    });
}


function setPaymentTab(i) {
    $('.tab-current').removeClass('tab-current');
    $('.tabs li:nth-child(' + i + ')').addClass('tab-current');

    var urls = ['',
        '#/payments/schedule/create/',
        '#/payments/methods',
        '#/payments/schedules'
    ];
    setTimeout('window.location = \'' + urls[parseInt(i)] + '\';', 500);
}

function printPage(selector) {
    const data = $(selector).html();
    var mywindow = window.open('', 'my div', 'height=400,width=600');
    mywindow.document.write('<html><head><style>.modal-footer{display:none;}.modal-content{max-height:none !important;}</style></head><body >');
    mywindow.document.write(data);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    mywindow.print();
    // mywindow.close();
    // return true;
}
