(function() {

    'use strict';

    angular
        .module('app')
        .factory('RewardService', RewardService);

    function RewardService($http) {

        var base_url = '/api/v1/';
        var local_url = 'rewards';

    }

}());