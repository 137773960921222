(function() {

    'use strict';

    angular
        .module('app')
        .controller('MerchantAccountCreateController', merchantAccountCreateController);

    function merchantAccountCreateController($scope, $state, $stateParams, merchantAccountService,$http) {

        // for the slider
        $scope.options = {
            from: 0,
            to: 100,
            step: 1,
            dimension: "%",
            scale: [
                0, '|', 10, '|', 20, '|', 30, '|', 40, '|',
                50, '|', 60, '|', 70, '|', 80, '|', 90, '|', 100
            ]
        };

        var vm = this;

        vm.merchantAccountData = {
            credit_tenant_percent: 50,
			credit_landlord_percent: 50,
            debit_tenant_percent: 50,
			debit_landlord_percent: 50,
			debit_visa_landlord_percent: 50,
            debit_visa_fee_amount: 0,
            company_id : $stateParams.companyId,
            country: 'canada',
            provider: 'payrock',
        };

        vm.createMerchantAccount = function()
        {
            var data = {
                company_id: Number($stateParams.companyId),
                friendly_name: vm.merchantAccountData.friendly_name,
                terminal_identification: vm.merchantAccountData.terminal_identification,

                institution_number: vm.merchantAccountData.institution_number,
                transit_number: vm.merchantAccountData.transit_number,
                account_number: vm.merchantAccountData.account_number,

                debit_visa: vm.merchantAccountData.debit_visa > 0 ? 1: 0,
				debit_visa_fee_amount: ''+vm.merchantAccountData.debit_visa_fee_amount,
                debit_visa_landlord_percent: ''+vm.merchantAccountData.debit_visa_landlord_percent,

                credit_visa: vm.merchantAccountData.credit_visa  > 0 ? 1: 0,
                credit_visa_fee_percent: ''+vm.merchantAccountData.credit_visa_fee_percent,

                credit_master:vm.merchantAccountData.credit_master > 0 ? 1: 0,
                credit_master_fee_percent: ''+vm.merchantAccountData.credit_master_fee_percent,

                credit_amex: vm.merchantAccountData.credit_amex > 0 ? 1: 0,
                credit_amex_fee_percent: ''+vm.merchantAccountData.credit_amex_fee_percent,

                credit_tenant_percent: 100 -vm.merchantAccountData.credit_landlord_percent,
                credit_landlord_percent:  vm.merchantAccountData.credit_landlord_percent,

                debit: vm.merchantAccountData.debit > 0 ? 1: 0,
                debit_fee_amount: ''+vm.merchantAccountData.debit_fee_amount,
                debit_tenant_percent: 100 -vm.merchantAccountData.debit_landlord_percent,
                debit_landlord_percent: ''+ vm.merchantAccountData.debit_landlord_percent,

                cardknox_xkey: vm.merchantAccountData.cardknox_xkey,
                cardknox_ifields_key:  vm.merchantAccountData.cardknox_ifields_key,
                cardknox_ach_cut_off_time:  vm.merchantAccountData.cardknox_ach_cut_off_time,
                cardknox_fee_counting_type:  vm.merchantAccountData.cardknox_fee_counting_type,

                country:  vm.merchantAccountData.country,

                provider:  vm.merchantAccountData.provider,
                payrix_merchant_id:  vm.merchantAccountData.payrix_merchant_id,
            };

			$http.post('/api/v1/merchant-accounts',data)
                .success(function(data) {
                    if(!data.success)
                        toastr.error(data.errors,"Error saving changed");
                    else{
                        $state.go('main.companyEdit', { companyId: $stateParams.companyId });
                        toastr.success('merchant account created!');
                    }
                });

        };

    }

}());
