(function() {

    'use strict';

    angular
        .module('app')
        .controller('TenantApprovalController', TenantApprovalController);

    function TenantApprovalController($state,  tenantService, $scope,$http,$rootScope, langService) {
		$scope.t = langService.t('tenants');
        var vm = this;

        vm.currentPage = 0;
        vm.cancelAcc = $scope.t("Click to Remove Resident Account");
        vm.approveAcc = $scope.t("Click to Approve Resident Account");

        $http.get('/api/v1/tenants/approvals').success(function(data) {
            if(data.code != 200)
                toastr.error("Error retrieving data");
            else {
                vm.approvals = data.data;
                $rootScope.tenants_waiting_approval = data.data.length;
            }
        });

        $scope.goToResidents = function(filter) {
            $rootScope.tenants_manage_filter = filter;
            $state.go('main.tenants.manage');
        }

        vm.cancel = function(id)
        {
			if(!confirm($scope.t('Are you sure you want to delete this resident?')))
				return true;
			var approvalsLeft = [];
			for(var i = 0; i < vm.approvals.length; i++){
				if(vm.approvals[i].id == id){
					$http.delete('/api/v1/tenants/'+id).
						success(function(data) {
							if(data.code != 200)
								toastr.error("Error deleting resident");
							else {
								toastr.success($scope.t("Resident registration cancelled"));
							}
						});

				}
				else
					approvalsLeft.push(vm.approvals[i]);
			}
			vm.approvals = approvalsLeft;
			$rootScope.tenants_waiting_approval--;
        };

        vm.approve = function(id)
        {
            var approvalsLeft = [];
			for(var i = 0; i < vm.approvals.length; i++){
				if(vm.approvals[i].id == id){
					$http.post('/api/v1/tenants/approve/'+id).
						success(function(data) {
							if(data.code != 200)
								toastr.error("Error approving resident");
							else {
								toastr.success($scope.t("Resident registration approved"));
							}
						});

				}
				else
					approvalsLeft.push(vm.approvals[i]);
			}
			vm.approvals = approvalsLeft;
			$rootScope.tenants_waiting_approval--;
        };
    }

}());
