(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqProspectInvite', pqProspectInvite);

    function pqProspectInvite() {
        return {
            restrict: 'E',
            controller: 'PqProspectInviteController',
            templateUrl: 'scripts/modules/prospect/prospect-invite/prospect-invite.html',
            scope: {
                prospect: '=',
                control: '='
            }
        };
    }

    angular
        .module('app')
        .controller('PqProspectInviteController', function ($scope, $http, $state, $rootScope, $timeout, langService) {
            $scope.t = langService.t('lease_app');
            $scope.agents = [];
            $scope.ref = {
                agent: {},
                rentable_items: []
            };

            $scope.rent_amount_editable = 1;
            $scope.rent_amount_editable_type = 'both';
            $scope.original_rent_amount = 0;

            $scope.invite = {
                prospect_id: $scope.prospect.id,
                type: 'email',
                email: '',
                no_email: 0,
                property: 0,
                property_id: 0,
                unit: '',
                rent_amount: '',
                mark_paid: 0,
                agent_first_name: '',
                agent_last_name: '',
                rentable_items: []
            };

            $scope.control.openInviteWindow = function () {
                console.log('open invite window');
                $scope.getProperties();
                $scope.invite.email = $scope.prospect.email;

                if($scope.prospect.email.indexOf('@payquad.com') > 0){
                    $scope.invite.registration_code = $scope.prospect.email.split("@")[0];
                    $scope.invite.no_email = 1;
                }else{
                    $scope.getRegistrationCode();
                }

                $scope.invite.property_id = $scope.prospect.property_id;
                $scope.getPropertyUnits('invite');
                if (typeof $scope.prospect.unit != 'undefined' && $scope.prospect.unit != null) {
                    $scope.invite.unit = $scope.prospect.unit.unit;
                }

                if ($scope.prospect.move_in_at == 'Invalid date' || $scope.prospect.move_in_at == null || $scope.prospect.move_in_at == '') {
                    $scope.invite.move_in_date = moment().format('MM/DD/YYYY');
                } else {
                    $scope.invite.move_in_date = moment($scope.prospect.move_in_at).format('MM/DD/YYYY');
                }

                if (moment($scope.invite.move_in_date).format('DD') == '01') {
                    $scope.invite.start_date = moment($scope.invite.move_in_date).format('MM/DD/YYYY');
                    $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').subtract(1, 'day').format('MM/DD/YYYY');
                } else {
                    $scope.invite.start_date = moment($scope.invite.move_in_date).add(1, 'month').startOf('month').format('MM/DD/YYYY');
                    $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').endOf('month').format('MM/DD/YYYY');
                }

                $('#invite-move-in-date').datepicker();
                $('#invite-start-date').datepicker();
                $('#invite-end-date').datepicker();
                $('#invite-modal').appendTo('body').modal('show');
                $timeout(() => {
                    $('.modal-backdrop').hide();
                })
            };

            $scope.getProperties = function () {
                $http.post('/api/v1/prospect/get-properties/' + $scope.prospect.company_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.properties = data.data.properties;
                    $scope.getAvailableUnitList();
                });
            };

            $scope.getAgents = function () {
                $http.post('/api/v1/prospect/get-agents/' + $scope.invite.property_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.agents = data.data.agents;

                    if ($scope.agents.length > 0) {
                        $scope.agents.unshift({
                            first_name: $scope.t('Select Agent'),
                            last_name: ''
                        });
                    }

                    $scope.ref.agent = $scope.agents[0];
                    if ($scope.prospect.agent_first_name !== null && $scope.prospect.agent_first_name !== '') {
                        angular.forEach($scope.agents, function (agent, key) {
                            if ($scope.prospect.agent_first_name == agent.first_name && $scope.prospect.agent_last_name == agent.last_name) {
                                $scope.ref.agent = $scope.agents[key];
                            }
                        });
                    }
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };

            $scope.getRentableItems = function () {
                $http.post('/api/v1/prospect/get-rentable-items/' + $scope.invite.property_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.rentable_items = data.data.rentable_items;
                    $scope.invite.rentable_items = [];
                    $scope.ref.rentable_items = [];

                    $timeout(function () {
                        $('select#invite-rentable-items').multipleSelect({filter: true});
                    });
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };

            $scope.availablePropertyUnits = [];
            $scope.selectedPropertyExtentionType = '';
            $scope.selectedPropertyLeaseExtentionEnabled = 0;
            $scope.hide_no_email = 1;
            $scope.show_rentable_items = 0;
            $scope.getAvailableUnitList = function () {
                if (!$scope.properties) {
                    return;
                }
                let chosenProperty = $scope.properties.filter(i => i.id === $scope.invite.property_id)

                chosenProperty = chosenProperty ? chosenProperty[0] : null;
                $scope.selectedPropertyExtentionType = chosenProperty.company.extension_type;
                $scope.selectedPropertyLeaseExtentionEnabled = chosenProperty.lease_applications_extension_enabled;
                $scope.show_rentable_items = chosenProperty.lease_application_pull_rentable_items_enabled;
                $scope.hide_no_email = chosenProperty.hide_lease_no_email;

                $http.get(`/api/v1/prospect/get-unit-rent-amount-list/${$scope.invite.property_id}?start_date=${$scope.invite.move_in_date}`).success(function (data) {
                    if (!data.success) {
                        return;
                    }
                    $scope.availablePropertyUnits = data.data.available_units;
                });
            }

            $scope.getPropertyUnits = function () {
                $scope.showUnitList = false;
                $scope.getAgents();
                $scope.getRentableItems();
                $scope.getAvailableUnitList();


                $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + $scope.invite.property_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.property_units = [];
                    angular.forEach(data.data, function (unit, key) {
                        if (!$scope.property_units.includes(unit.unit)) {
                            $scope.property_units.push(unit.unit);
                        }
                    });
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };

            $scope.moveInAtChanged = function () {
                if (moment($scope.invite.move_in_date).format('DD') == '01') {
                    $scope.invite.start_date = moment($scope.invite.move_in_date).format('MM/DD/YYYY');
                    $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').subtract(1, 'day').format('MM/DD/YYYY');
                } else {
                    $scope.invite.start_date = moment($scope.invite.move_in_date).add(1, 'month').startOf('month').format('MM/DD/YYYY');
                    $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').endOf('month').format('MM/DD/YYYY');
                }

                $scope.getAvailableUnitList();
                $scope.checkForRentAmount
            };

            $scope.checkForRentAmount = function () {
                //$scope.invite.end_date = moment($scope.invite.move_in_date, 'MM/DD/YYYY').add(1, 'year').endOf('month').format('MM/DD/YYYY');
                $http.post('/api/v1/prospect/get-unit-rent-amount/' + $scope.invite.property_id + '/' + $scope.invite.unit, {start_date: $scope.invite.move_in_date}).success(function (data) {
                    if (!data.success) {
                        $scope.rent_amount_editable = 1;
                        return;
                    }

                    if (data.data.available == 0 && data.data.rent_amount != 0) {
                        toastr.error('This unit is not available');
                        return;
                    }

                    if (data.data.rent_amount == 0) {
                        $scope.rent_amount_editable = 1;
                        return;
                    }

                    $scope.invite.rent_amount = data.data.rent_amount;
                    $scope.original_rent_amount = data.data.rent_amount;
                    if ($rootScope.authUser.permissions == 'payquad_admin' || $rootScope.authUser.lease_rent_editable == 1) {
                        $scope.rent_amount_editable = 1;
                    } else {
                        $scope.rent_amount_editable = 0;
                    }
                    if ($scope.rent_amount_editable == 1 && $rootScope.authUser.permissions != 'payquad_admin') {
                        $scope.rent_amount_editable_type = $rootScope.authUser.lease_rent_editable_type;
                    }
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };

            $scope.$watch('invite.unit', function (newValue, oldValue) {
                if (+$scope.invite.property_id !== 0) {
                    $scope.checkForRentAmount();
                }
            });

            $scope.getRegistrationCode = () => {
                $http.post('/api/v1/prospect/get-unique-registration-code').then(function success(data) {
                    if (!data,data.success) {
                        return;
                    }
                    $scope.invite.registration_code = data.data.data.registration_code;
                }, function error(data){
                    if (typeof data.data.stopaction != 'undefined') {
                        return;
                    }
                });
            }

            $scope.sendInvite = function(){
                if ($scope.invite.email && $scope.invite.no_email == 0) {
                    // pre check if the email is already attached to an applicant, if so, confirm if they want to do this
                    $http.post('/api/v1/prospect/check-invite-email', {email: $scope.invite.email}).success(function(data){
                        if (!data.success) {
                            return;
                        }

                        if (data.data.exists) {
                            if (confirm('This email is already attached to an applicant. Are you sure you want to continue?')) {
                                $scope.doSendInvite();
                            }
                        } else {
                            $scope.doSendInvite();
                        }
                    }).error(function(data){
                        if (typeof data.stopaction != 'undefined') {
                            return;
                        }
                    });
                } else {
                    $scope.doSendInvite();
                }
            }

            $scope.doSendInvite = function () {
                if ($scope.agents.length > 0) {
                    if ($scope.ref.agent.first_name == 'Select Agent' || $scope.ref.agent.first_name == 'Sélectionner agent' || $scope.ref.agent.first_name == $scope.t('Select Agent')) {
                        toastr.error($scope.t('Please select an agent'));
                        $scope.control.openInviteWindow();
                        return;
                    }

                    $scope.invite.agent_first_name = $scope.ref.agent.first_name;
                    $scope.invite.agent_last_name = $scope.ref.agent.last_name;
                }
                $scope.invite.rentable_items = $scope.ref.rentable_items;

                if ($scope.rent_amount_editable == 1 && $scope.rent_amount_editable_type == 'up' && parseFloat($scope.original_rent_amount) > parseFloat($scope.invite.rent_amount)) {
                    toastr.error('You do not have permission to reduce the rent amount');
                    $scope.control.openInviteWindow();
                    return;
                }
                if ($scope.rent_amount_editable == 1 && $scope.rent_amount_editable_type == 'down' && parseFloat($scope.original_rent_amount) < parseFloat($scope.invite.rent_amount)) {
                    toastr.error('You do not have permission to increase the rent amount');
                    $scope.control.openInviteWindow();
                    return;
                }

                if($scope.invite.no_email == 1){
                    $scope.invite.email = $scope.invite.registration_code + '@payquad.com';
                }
    
                if(!$scope.invite.email){
                    toastr.error($scope.t('email_empty'));
                    return;
                }

                if(!$scope.invite.email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)){
                    toastr.error($scope.t('email_invalid'));
                    return;
                }

                $rootScope.showLoader = true;
                $http.post('/api/v1/lease-application/send-invite', $scope.invite).success(function (data) {
                    $rootScope.showLoader = false;
                    if (!data.success) {
                        var message = 'Error converting prospect';
                        if (typeof data.message != 'undefined') {
                            message = data.message;
                        }
                        toastr.error(message);
                        return;
                    }

                    if ($scope.invite.type === 'email') {
                        toastr.success('Prospect Invite Sent');
                        $state.go('main.leaseApplicationList');
                    } else {
                        var application_id = data.data.application.id;
                        toastr.success('Application created');
                        $state.go('main.leaseApplicationContinue', {id: application_id});
                    }
                }).error(function (data) {
                    $rootScope.showLoader = false;
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            };

            $scope.setInviteType = function (type) {
                $scope.invite.type = type;
            };

            $scope.showUnitList = false;

            $scope.unitClicked = () => {
                if ($scope.selectedPropertyExtentionType === 'yardi' && $scope.selectedPropertyLeaseExtentionEnabled == 1) {
                    $scope.showUnitList = true;
                }
            }

        });




})();
