(function () {

    'use strict';

    angular
        .module('app.pagination')
        .directive('pqPagination', pqPagination);

    function pqPagination()
    {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/pagination/pagination.html',
            scope: {
                items: '=',
                itemsPerPage: '=',
                currentPage: '='
            },
            link: function($scope) {

                $scope.currentPage = 1;

                $scope.$watch('items', function(newVal, oldVal) {
                    if (newVal!=oldVal)
                    {
                        $scope.resetPagination();
                    }
                });

                $scope.resetPagination = function() {
                    $scope.currentPage = 1;
                }
            }
        }
    }

})();