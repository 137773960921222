(function () {

    'use strict';

    angular
        .module('app')
        .controller('TenantIndexController', tenantIndexController);

    function tenantIndexController($state, tenantService, $scope, $http, $timeout, $rootScope, langService) {
        $scope.t = langService.t('tenants');
        var vm = this;
        angular.element($('body')).scope().state = $state;

        $scope.company = {};

        $scope.ref = {current_tab_filter: 'enabled'};
        if (typeof $rootScope.tenants_manage_filter != 'undefined') {
            $scope.ref.current_tab_filter = $rootScope.tenants_manage_filter;
        }
        $timeout(function () {
            $('#' + $scope.ref.current_tab_filter).click();
        }, 500);

        $scope.setTabFilter = function (filter) {
            $scope.ref.current_tab_filter = filter;
            $rootScope.tenants_manage_filter = filter;
        };

        $http.get('/api/v1/tenants/approvals').success(function (data) {
            if (data.code != 200)
                toastr.error($scope.t("Error retreiving data"));
            else {
                vm.approvals = data.data;
                $rootScope.tenants_waiting_approval = data.data.length;
            }
        });

        $http.get('/api/v1/companies/0').success(function (data) {
            if (data.code != 200) {
                toastr.error($scope.t("Error retreiving data"));
            } else {
                $scope.company = data.data;
            }
        });

        var columns = [
            {title:$scope.t('ID') },
            {title:$scope.t('Last Name')},
            {title:$scope.t('First Name')},
            {title:$scope.t('Status')},
            {title:$scope.t('Unit')},
            {title:$scope.t('Property')},
            {title:$scope.t('Company')},
            {title:$scope.t('Phone')},
            {title:$scope.t('Email')},
            {title:$scope.t('Active')},
            {title:$scope.t('Recurring Payment')},
        ];

        var columnDefs = [{'orderable': false, 'targets': [9, 10]}];
        if ($rootScope.authUser.permissions !== 'payquad_admin') {
            columnDefs = [
                {'visible': false, 'targets': 6},
                {'orderable': false, 'targets': [9, 10]}
            ];
        }
        $(document).ready(function () {
            setTimeout(function () {
                $('#dataTable').dataTable({
                    responsive: $(window).width() < 1200,
                    columns: columns,
                    'processing': true,
                    'serverSide': true,
                    'columnDefs': columnDefs,
                    dom: 'l<"pull-right"B>frtip',
                    buttons: window.Capacitor.isNativePlatform() ? [] : [
                        'copy', 'csv', 'excel', 'print'
                    ],
                    'ajax': {
                        'url': pqDomain + '/api/v1/tenants/manage' + '?cordova=' + (!!window.Capacitor.isNativePlatform() ? '1' : '0') + '&token=' + pqCachedToken,
                        'type': 'POST',
                        error: function (jqXHR, textStatus, errorThrown) {
                            if (jqXHR.status != 0) {
                                alert($scope.t("Error retreiving data"));
                            }
                        },
                    }
                });
                $('#dataTable tbody').on('click', 'tr', function (e) {
                    if ($(e.target).parent().children().index(e.target)) {
                        let id;
                        if ($(this).hasClass('child')) {
                            id = $('td', $(this).prev()).eq(0).text();
                        } else {
                            id = $('td', this).eq(0).text();
                        }
                        angular.element($('body')).scope().state.go('main.tenantEdit', {tenantId: id});
                    }
                });
            })
        })
    }
}());
