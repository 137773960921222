(function() {

	'use strict';

    angular
		.module('app')
		.controller('PaymentMethodCreateController', PaymentMethodCreateController);

	function PaymentMethodCreateController($scope, $rootScope, $state, paymentMethodService, $http, $stateParams, langService) {

		$scope.t = langService.t('payments');

		if ('country' in $rootScope.authUser.property && $rootScope.authUser.property.country.toLowerCase() !== 'canada'){
			window.location.href = '/#/n/payments/create-us';
		}

        $scope.is_submitting = false;
        $scope.pad_link_manual = 0;

        $scope.doc_key = $stateParams.doc_key;

        $scope.plaid_submitting = 0;

		 paymentMethodService.getAvailable()
		 	.success(function(data) {
		 		$scope.available = data.data.methods;
		 		$scope.cards = data.data.credit_cards;
		 		$scope.availableAll = data.data;
				$scope.availablecards = [];
				for(var i = 0; i <  $scope.cards.length; i++){
					if ($scope.cards[i] =='amex')
						$scope.availablecards.push("American Express");
					else if ($scope.cards[i] =='mastercard')
						$scope.availablecards.push("MasterCard");
					else if ($scope.cards[i] =='visa')
						$scope.availablecards.push("Visa");
				}
		 	})
			.error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

				$log.log(data.error + ' ' + status);
			});

        $scope.padDoManual = function(){
            $scope.pad_link_manual = 1;
        };

        $scope.padCancelManual = function(){
            $scope.pad_link_manual = 0;
        };

        $scope.link_accounts = [];
        $scope.link_item_id = 0;
        $scope.display_choose_link_account = 0;
        $scope.displayLinkAccounts = function(){
            $scope.display_choose_link_account = 1;
        };

        $scope.createPaymentMethodFromLink = function(account_id) {
            $http.post('/api/v1/plaid/create-payment-method', {account_id: account_id, item_id: $scope.link_item_id}).success(function(data) {
                if (!data.success) {
                    $scope.plaid_submitting = 0;
                    toastr.error(data.errors,"Error creating Payment Method");
                    return;
                }

                toastr.success('Payment Method created -' + data.data.friendly_name);
                toastr.options = {
                    "closeButton": true
                };
                $scope.methodId = data.data.id;
                console.log('scope.methodId ',data.data)
                $scope.is_submitting = false;
                $scope.plaid_submitting = 0;
                $state.go('main.paymentMethodCreate.step_3');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.plaid_client_name = '';
        $scope.plaid_env = '';
        $scope.plaid_key = ''

		$scope.plaidOpen = function(){
			$http.post('/api/v1/plaid/create-link-token').success(function(data) {
				if (!data.success) {
					return;
				}

				const configs = {
					// 1. Pass a new link_token to Link.
					token: data.data.link_token,
					onSuccess:  function (public_token, metadata) {
						$http.post('/api/v1/plaid/create-payment-method', {public_token: public_token, account_id: metadata.account_id}).success(function(data) {
							if (!data.success) {
								$scope.plaid_submitting = 0;
								toastr.error(data.errors,"Error creating Payment Method");
								return;
							}

							toastr.success('Payment Method created -' + data.data.friendly_name);
							toastr.options = {
								"closeButton": true
							};
							$scope.methodId = data.data.id;
							$scope.is_submitting = false;
							$scope.plaid_submitting = 0;
							$state.go('main.paymentMethodCreate.step_3');
						}).error(function(data){
							if (typeof data.stopaction != 'undefined') {
								return;
							}

							toastr.error(data.error.message);
						});
					},
					onExit: function (err, metadata) {
						console.log('onExit', err, metadata)
					},
				};

				var linkHandler = Plaid.create(configs);
				linkHandler.open();

			});
        }

		$scope.checkfee = function(val){
		 	for(var i = 0; $scope.available.length; i++){
				 if(val == $scope.available[i].value)
					{
					 	return i
					 }
			}
		};
		$scope.getType = function(type) {
            if ($scope.paymentMethodData.pre == type){
                return "active"
            } else {
                return ""
            }
        };

        $scope.chequeTooltipClick = function(){
            toastr.info('Please enter the exact digits that you see on the bottom of your cheque as shown in the image or as shown on your online banking account. If you require help to link your account, please send us a <a target="_blank" href="https://payquad.com/support-centre/">support ticket</a> and someone will be happy to help.');
        };

		$scope.cardType = function (val) {
			var variable;
			if ($scope.availablecards.length == 3) {
				return;
			} else {
				for (var i = 0; i < $scope.availablecards.length; i++) {
					if ($scope.availablecards[i] == $scope.paymentMethodData.pre) {
						variable = $scope.availablecards[i];
						console.log(variable);
					} else {
						variable = $scope.availablecards[0];
					}
				}

				return variable;
			}
		}

		$scope.ckAvaliable = function(chk){
			for(var i = 0; i <  $scope.availablecards.length; i++){
				if(chk === $scope.availablecards[i])
					return true;
				}
				return false;
		};
        $scope.cvvInfo = '<img src="assets/images/cvv.jpg"/> ';
		$scope.paymentMethodData = {'friendly_name':'My Payment Method'};
		$scope.checkcard = false;


		// steps
		$scope.step = 1;
		$scope.stepOne = function()
		{
            $scope.paymentMethodData = {};
			$scope.step = 1;
		};
		$scope.stepTwo = function()
		{
			$scope.step = 2;
		};
		$scope.stepThree = function()
		{
			$scope.step = 3;
			/*
			if($scope.paymentMethodData.pad)
			{
				$scope.errors.push('Please accept the pad aggreement');
			}
			*/
		};

		$scope.paymentMethodData.billing_same_as_mailing = 1;

		$scope.setType = function(val){

			if (!angular.isUndefined($scope.paymentMethodData.pre)){

				if ($scope.paymentMethodData.pre == 'American Express'){
					$scope.paymentMethodData.type = 'amex';
				}
				else if ($scope.paymentMethodData.pre =='MasterCard'){
					$scope.paymentMethodData.type = "mastercard";
				}
				else if ($scope.paymentMethodData.pre =='Visa'){
					$scope.paymentMethodData.type = "visa";
				}
				//console.log($scope.paymentMethodData.type);
			}
			else {
				// do nothing
				console.log("undefined in sendType()");
			}

		};
		$scope.years = [];
		var currentYear = new Date().getFullYear();
		for (var i = 0; i < 10 ; i++){
			$scope.years.push((currentYear + i).toString());
		}

		$scope.methodId = '';
		$scope.validationErrorMessage = [];
		$scope.confirmPaymentMethod = () =>
		{
                toastr.warning($scope.t('double_check'));
				$scope.confirmationPage = true;
				return;
		};
		$scope.correction = function()
		{
				$scope.confirmationPage = undefined;
				return;
		};

		$scope.createPaymentMethod = function()
		{
            $scope.is_submitting = true;
			if( $scope.paymentMethodData.type == 'direct_debit'){
				if($scope.paymentMethodData.institution_number != $scope.paymentMethodData.bank && $scope.paymentMethodData.bank != 'other'){
					toastr.error( $rootScope.trans('payments.bank_institution', {number: $scope.paymentMethodData.bank}), $rootScope.trans('payments.invalid_bank_institution'));
                            toastr.options = {
                                "closeButton": false,
                                "showDuration": "10000",
                                "extendedTimeOut": "10000"
                     };
					 $scope.paymentMethodData.institution_number = '';
					 $scope.confirmationPage = undefined;
                     $scope.is_submitting = false;
					 return;
				}
			}

			var data_for_send = {
				type: $scope.paymentMethodData.type,
				friendly_name: $scope.paymentMethodData.friendly_name,
				card_holder_name: $scope.paymentMethodData.card_holder_name,
				card_number: $scope.paymentMethodData.card_number,
				cvv: $scope.paymentMethodData.cvv,
				expiration_year: $scope.paymentMethodData.expiration_year,
                expiration_month: $scope.paymentMethodData.expiration_month,
                institution_number: $scope.paymentMethodData.institution_number,
                account_holder: $scope.paymentMethodData.account_holder,
                transit_number: $scope.paymentMethodData.transit_number,
                account_number: $scope.paymentMethodData.account_number,
				billing_same_as_mailing: $scope.paymentMethodData.billing_same_as_mailing,
				address: $scope.paymentMethodData.address,
				unit: $scope.paymentMethodData.unit,
				city: $scope.paymentMethodData.city,
				province: $scope.paymentMethodData.province,
				postal_code: $scope.paymentMethodData.postal_code,
				country: $scope.paymentMethodData.country,
                pad: 1
			};
			paymentMethodService.create(data_for_send)
                .success(function(data) {
                    if (data.success){
                    	toastr.success('Payment Method created -' + data.data.friendly_name);
                            toastr.options = {
                                "closeButton": true
                            };
                        $scope.methodId = data.data.id;
                    	console.log('scope.methodId ',data.data);
                        $scope.is_submitting = false;
                        $state.go('main.paymentMethodCreate.step_3');

					}
					else{
						$scope.validationErrorMessage = data.errors;
						toastr.error(data.errors[0], 'Error!');
                            toastr.options = {
                                "closeButton": false,
                                "showDuration": "10000",
                                "extendedTimeOut": "10000"
                            };
                            $scope.is_submitting = false;
                    }
				});
		};
    }
}());
