(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/document/';

        $stateProvider
			.state('main.documents', {
				url: '/documents',
				views: {
					"body@": {
						templateUrl: url + 'index/index.html',
						controller: 'DocumentsIndexController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.documentsDownloads', {
				url: '/documents/downloads',
				views: {
					"body@": {
						templateUrl: url + 'downloads/downloads.html',
						controller: 'DocumentsDownloadsController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.documentRequests', {
				url: '/documents/requests',
				views: {
					"body@": {
						templateUrl: url + 'requests/requests.html',
						controller: 'DocumentRequestsController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.documentFulfill', {
				url: '/documents/fulfill/:id',
				views: {
					"body@": {
						templateUrl: url + 'fulfill/fulfill.html',
						controller: 'DocumentFulfillController',
                        controllerAs: 'vm'
					}
				}
			})
            .state('main.documentCreate', {
                url: '/documents/create',
                views: {
                    "body@": {
                        templateUrl: url + 'create/create.html',
                        controller: 'DocumentCreateController',
                        controllerAs: 'vm'
                    }
                }
            })
			.state('main.documentEdit', {
				url: '/documents/:id',
				views: {
					"body@": {
						templateUrl: url + 'edit/edit.html',
						controller: 'DocumentEditController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.documentRequest', {
				url: '/documents/request/:id',
				views: {
					"body@": {
						templateUrl: url + 'request/request.html',
						controller: 'DocumentRequestController',
                        controllerAs: 'vm'
					}
				}
			})
            .state('main.documentCategories', {
                url: '/document/categories',
                views: {
                    "body@": {
                        templateUrl: url + 'categories/categories.html',
                        controller: 'DocumentCategoriesController',
                        controllerAs: 'vm'
                    }
                }
            })
			;
	}
}());
