(function () {

    'use strict';

    angular
        .module('app')
        .controller('DashboardController', DashboardController);

    function DashboardController($scope, $rootScope, $http, $state, $timeout,  $sce, accountService, routerService, langService, jwtService, helperService) {
        if(
            $rootScope.authUser['permissions'] === 'payquad_admin'
            || $rootScope.authUser['permissions'] === 'junior_manager'
            || $rootScope.authUser['permissions'] === 'admin_manager'
        ) {
            console.log('redirecting from old to new dash');
            // window.location = '/#/n/dashboard';
            $rootScope.goToAngular2('dashboard');
        }

        $scope.isMobile = helperService.isMobileOrTablet();
        $scope.isCordova = !!window.Capacitor.isNativePlatform();

        $scope.t = langService.t('dashboard');

        $rootScope.isAngular2 = false;
        $rootScope.showLoader = false;
        window['fireAngularEvent']('pq.angularjs-route');

        const isOnlyOnePermission = routerService.userHasOnlyOnePermission();
        if (isOnlyOnePermission) {
            $state.go(routerService.getStateByPermission(isOnlyOnePermission));
        }

        if ($rootScope.authUser.permissions === 'realtor') {
            $state.go('main.documents');
            return;
        }

        if ($rootScope.authUser.permissions === 'applicant') {
            $state.go('main.leaseApplicationDashboard');
            return;
        }

        var vm = this;

        // recurring amounts stuff
        vm.recurring_amount = $rootScope.authUser.recurring_amount;
        vm.recurring_amount_other = $rootScope.authUser.recurring_amount_other;
        vm.recurring_amounts_enabled = $rootScope.authUser.recurring_amounts_enabled;
        vm.recurring_amounts_name = $rootScope.authUser.recurring_amounts_name;
        vm.recurring_amounts_other_name = $rootScope.authUser.recurring_amounts_other_name;

        $rootScope.total_declines = 0;
        vm.checkLastPayment = function (empty2) {
            return empty2.length == 0;
        };
        vm.getPaymentStatus = function (status) {
            return status != 'paid' && '';
        };
        vm.checkUpcomingPayment = function (empty) {
            if (empty === undefined)
                return false;
            return empty.length == 0;
        };
        vm.checkFirstPaymentDate = function (date1, date2) {
            return date1 == date2 || date2 == 'December 31, 1969';
        };
        vm.aprroveTenants = function (tenant) {
            return tenant == 0;
        };
        vm.getBalance = function () {
            $http.get('/api/v1/tenants/balance_details').success(function (result) {
                if (result.data) {
                    vm.extension_balance_details = [];

                    if (result.data.details === false) {
                        vm.balance = false;
                        return;
                    }

                    var extension_balance_details = result.data.details;
                    var tempBal = 0;
                    if (extension_balance_details.length > 0) {
                        for (var i in extension_balance_details) {
                            if (tempBal === null) {
                                tempBal = 0.00;
                            }
                            tempBal += (parseFloat(extension_balance_details[i][2]) - parseFloat(extension_balance_details[i][3]));
                        }
                    }

                    vm.balance = tempBal == 0 ? 0 : tempBal.toFixed(2);
                    vm.extension_balance_details = [];
                    if (result.data.show == true) {
                        for (var i in extension_balance_details) {
                            vm.extension_balance_details[i] = vm.toObject(extension_balance_details[i]);
                        }
                    }
                }
            });
        };
        vm.isNaN = function(x){
            return isNaN(x);
        }
        vm.payBalance = function () {
            $('#extension_balance_details').modal('hide');
            $timeout(function () {
                $rootScope.amountToPay = vm.balance;
                $state.go('main.paymentScheduleCreate');
            }, 500);
        };
        vm.parseFloat = function (value) {
            return parseFloat(value);
        };
        vm.toObject = function (arr) {
            var rv = {};
            for (var i = 0; i < arr.length; ++i)
                rv[i] = arr[i];
            return rv;
        };

        vm.dashboard_messages = [];
        vm.dashboard_messages_show_count = 2;
        vm.getDashboardMessages = function () {
            $http.post('/api/v1/dashboard-message/get-dashboard-messages-new').success(function (data) {
                if (data.success) {
                    vm.dashboard_messages = data.data.dashboard_messages;
                }
            });
        };
        vm.getDashboardMessages();

        $scope.showMoreDashboardMessages = function() {
            vm.dashboard_messages_show_count = 100;
        }

        $scope.trustSrc = function(src) {
            return $sce.trustAsResourceUrl(src);
          }

        $scope.selected_message = {};
        $scope.openDashboardMessage = function(message) {
            $scope.selected_message = message;
            const fileUrl = $scope.selected_message['file_url'];
            if($rootScope.platform !== 'web' && fileUrl && fileUrl !== '' && (fileUrl.indexOf("http://") === -1 && fileUrl.indexOf("https://") === -1 )) {
                if($rootScope.platform === 'ios' || $scope.selected_message['file_is_image'] === 1) {
                    $scope.selected_message['file_url'] = $rootScope.pqDomain + '/' + fileUrl + '?token=' + jwtService.getToken();
                } else if($rootScope.platform === 'android' && $scope.selected_message['file_is_pdf'] === 1) {
                    $scope.selected_message['file_url'] = "https://docs.google.com/gview?embedded=true&url=" + $rootScope.pqDomain + '/' + fileUrl + '?token=' + jwtService.getToken();
                }
            }

            $('#notice-message').appendTo('body').modal('show');
        };

        if ($rootScope.authUser.pro_message_alert_sent === 0) {
            $timeout(function () {
                $('.sms-notification-popup').appendTo('body').modal('show');
            }, 1000);
            $rootScope.authUser.pro_message_alert_sent = 1;
            accountService.updateSawProMessage();
        }
        

        $rootScope.$broadcast('check-new-messages');

        var day_of_month = '';
        var friendly_name = '';

        var pending_requests = '';
       $scope.completed_requests = '';

        vm.getDashboardMessages = function () {
            $http.get('/api/v1/notifications').success(function (data) {
                if (data.success) {
                    vm.notifications = data.data;

                    if (typeof vm.notifications.last_payment != 'undefined') {
                        day_of_month = vm.notifications.last_payment.date;
                        friendly_name = vm.notifications.last_payment.friendly_name;
                    }
                    if (typeof vm.notifications.maintenance_requests != 'undefined') {
                        pending_requests = vm.notifications.maintenance_requests.open;
                        $scope.completed_requests = vm.notifications.maintenance_requests.closed;
                    }

                    if (vm.notifications.tenants_waiting_approval !== undefined)
                        $rootScope.tenants_waiting_approval = vm.notifications.tenants_waiting_approval;
                    if (vm.notifications.total_declines !== undefined)
                        $rootScope.total_declines = vm.notifications.total_declines;
                }
            });
        };
        vm.getDashboardMessages();
    }

}());
