(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        const url = 'scripts/modules/showing/';

        $stateProvider
            .state('main.showings', {
                url: '/showings',
                views: {
                    'body@': {
                        templateUrl: url + 'index/showing.index.html',
                        controller: 'ShowingIndexController',
                        controllerAs: 'vm',
                    }
                }
            })
            .state('main.showings.edit', {
                url: '/edit/:id',
                views: {
                    'sidebar@': {
                        controller: 'ShowingEditController',
                        templateUrl: url + 'edit/ html',
                        resolve: {
                            resolvedShowing: function ($q, showingService, $stateParams) {
                                return $q.when(showingService.get($stateParams.id));
                            },
                            resolvedProspect: () => null
                        }
                    },
                    'top-showing@main.showings.edit': {
                        templateUrl: 'scripts/modules/prospect/prospect-showing-form/showing-form.html',
                    }
                }
            })
            .state('main.showings.create', {
                url: '/create/:prospectId',
                views: {
                    'sidebar@': {
                        controller: 'ShowingEditController',
                        templateUrl: url + 'edit/showing.edit.html',
                        resolve: {
                            resolvedShowing: () => null,
                            resolvedProspect: function ($q, $stateParams, prospectService) {
                                return $q.when(prospectService.get($stateParams.prospectId));
                            }
                        }
                    },
                    'top-showing@main.showings.create': {
                        templateUrl: 'scripts/modules/prospect/prospect-showing-form/showing-form.html',
                    }
                }
            })
            .state('main.showings.createBlank', {
                url: '/create',
                views: {
                    'sidebar@': {
                        controller: 'ShowingEditController',
                        templateUrl: url + 'edit/showing.edit.html',
                        resolve: {
                            resolvedShowing: () => null,
                            resolvedProspect: () => null
                        }
                    },
                    'bottom-showing@main.showings.createBlank': {
                        templateUrl: 'scripts/modules/prospect/prospect-showing-form/showing-form.html',
                    }
                }
            })
            .state('main.showingsBooking', {
                url: '/showings/booking/:propertyId',
                views: {
                    'body@': {
                        templateUrl: url + 'booking/showing.booking.html',
                        controller: 'ShowingBookingController',
                        resolve: {
                            resolvedProperty: function ($q, $stateParams, showingService) {
                                return $q.when(showingService.getProperty($stateParams.propertyId));
                            }
                        }
                    }
                }
            })
            .state('main.showingsProperties', {
                url: '/showings/properties/:companyId',
                views: {
                    'body@': {
                        templateUrl: url + 'create-properties/create-properties.html',
                        controller: 'ShowingCreatePropertiesController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.showingsBooking.step2', {
                templateUrl: url + 'booking/showing.booking.step2.html',
            });
    }

}());
