(function () {
    "use strict";

    angular
        .module('pq.property')
        .controller('PropertiesController', PropertiesController);

    /* @ngInject */
    function PropertiesController(propertiesData, propertyDataService, $state, $scope) {
        var vm = this;
        vm.search = '';
        vm.filteredProperties = [];

        vm.filterProperties = () => {
            vm.filteredProperties = vm.properties.filter(item => {
                const s = vm.search.toLowerCase();
                return ~item.name.toLowerCase().indexOf(s) || ~item.address.toLowerCase().indexOf(s);
            })
        };

        // Expose backend api to backend autocompleter
        vm.getProperties = function (searchKey) {
            return propertyDataService.getPropertiesQuery(searchKey);
        };

        activate();

        function activate() {
            vm.properties = propertiesData.properties;
            vm.filteredProperties = propertiesData.properties;
            $scope.viewbyCan = 50;
            $scope.totalItemsCan = vm.filteredProperties.length;
            $scope.currentPageCan = 1;
            $scope.itemsPerPageCan = $scope.viewbyCan;
            $scope.maxSizeCan = 50; //Number of pager buttons to show

            $scope.setPageCan = function (pageNo) {
                $scope.currentPageCan = pageNo;
            };

            $scope.setItemsPerPageCan = function (num) {
                $scope.itemsPerPageCan = num;
                $scope.currentPageCan = 1; //reset to first page
            };
        }

        vm.viewProperty = function (property) {
            $state.go('main.property', {property_id: property.id});
        }
    }

})();
