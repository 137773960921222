(function() {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/lease/';

        $stateProvider
            .state('main.lease', {
                url: '/lease/bulk-notice',
                views: {
                    "body@": {
                        templateUrl: url + 'bulk-notice/bulk-notice.html',
                        controller: 'BulkNoticeController',
                        controllerAs: 'vm'
                    }
                }
            });

    }

}());
