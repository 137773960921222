(function() {
    'use strict';

    angular
    .module('app')
    .controller('InvoiceListController', invoiceListController);

    function invoiceListController($scope,$state,$rootScope,$http,$timeout, helperService) {
        $scope.companies = [];
        $scope.ref = {company_id: 0};
        $scope.invoices = [];

        $scope.getInvoices = function(){
            if ($scope.ref.company_id === 0) {
                return;
            }

            $http.post('/api/v1/billing/get-invoices/' + $scope.ref.company_id).success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Invoices");
                }

                $scope.invoices = data.data.invoices;
                $scope.invoices.forEach(item => item.created_at = helperService.mysqlDatetimeToDate(item.created_at));
            });
        };

        $scope.getCompanies = function(){
            $http.post('/api/v1/billing/get-companies').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Companies");
                }

                // lets populate stuff
                $scope.companies = data.data.companies;
                $scope.ref.company_id = +$scope.companies[0].id;
                $scope.getInvoices();
            });
        };

        if(!!$rootScope.authUser.company_id){
            $scope.ref.company_id = +$rootScope.authUser.company_id;
            $scope.getInvoices();
        }else{
            $scope.getCompanies();
        }

    }
}());
