/**
 * Created by jack on 28/01/15.
 */
(function () {
    "use strict";

    angular
        .module('app.filters')
        .filter('multiPropertyCompleteMatching', multiPropertyPartialMatchingFilter);

    /**
     * Applies multiple partial filters to item in items
     * @param _
     * @returns {Function}
     * @ngInject
     */
    function multiPropertyPartialMatchingFilter(_) {
        return function (items, filters) {

            var filteredProperties = [];

            _.forEach(items, function (item) {

                var itemPassesAllFilters = true;

                // Check if item passes all filters
                _.forEach(filters, function (filterItem) {

                    var itemContainsPropertyWithFilterValue = false;

                    // Check if at least one property in item contains filter
                    _.forEach(item, function (value) {
                        if (_.isString(value)) {
                            if (_.contains(value.toLowerCase(), filterItem.toLowerCase())) {
                                itemContainsPropertyWithFilterValue = true;
                                return false; // Don't need to check the remaining item properties
                            }
                        } else if (_.isNumber(value)) {
                            if (_.contains(value, filterItem)) {
                                itemContainsPropertyWithFilterValue = true;
                                return false; // Don't need to check the remaining item properties
                            }
                        }
                    });

                    if (!itemContainsPropertyWithFilterValue) {
                        itemPassesAllFilters = false; // Indicate the item shouldn't be pushed
                        return false; // No need to check other filters
                    }
                });

                if (itemPassesAllFilters) {
                    filteredProperties.push(item);
                }

            });

            return filteredProperties;
        }
    }

})();