(function () {

        'use strict';

        angular
            .module('app')
            .controller('ManagerEditController', managerEditController);

        function managerEditController($state, $scope, resolvedManagerData, managerService, propertyService, $rootScope, $timeout, $http) {
            
            var vm = this;

            vm.manager = resolvedManagerData.manager.data;
            vm.manager.originalEmail = vm.manager.email;
            if (vm.manager.deleted_at == null) {
                vm.manager.deleted_at = 1; // enabled
            } else {
                vm.manager.deleted_at = 0; // disabled
            }
            vm.selectedProperty = 0;
            vm.property_options = vm.manager.property_options;
            vm.select_all_properties = 1;
            vm.properties = vm.manager.properties;
            vm.portfolios = vm.manager.portfolios;
            vm.leasingChecked = Boolean(vm.manager.all_email_groups.filter(el => el.group === 'leasing' && el.selected === 1).length);
            vm.paymentChecked = Boolean(vm.manager.all_email_groups.filter(el => el.group === 'payments' && el.selected === 1).length);
            vm.amenitiesChecked = Boolean(vm.manager.all_email_groups.filter(el => el.group === 'amenities' && el.selected === 1).length);

            vm.emailSettingChange = function (selected) {
                if (!selected && vm.manager.id == $rootScope.authUser.id) {
                    toastr.warning('Please note by changing this setting you understand and agree to not receive important email notifications regarding your account.');
                }
            };

            $scope.groupChecking = () => {
                if (vm.leasingChecked){
                    $timeout(function () {
                        $('#leasing_emails_filter').multipleSelect({
                            filter: true,
                            placeholder: 'Click Here To Set Up',
                            selectAll: false,
                            onClick: (e) => {
                                vm.manager.all_email_groups.map(el => {
                                    if (el.name === e.value.trim()){
                                        el.selected = Number(e.checked)
                                        return el;
                                    }
                                })
                            }
                        })
                    });
                } else {
                    vm.manager.all_email_groups = vm.manager.all_email_groups.map(el => {
                        if (el.group === 'leasing') {
                            el.selected = 0;
                        }
                        return el;
                    })
                }

                if(vm.paymentChecked){
                    $timeout(function () {
                        $('#payment_emails_filter').multipleSelect({
                            filter: true,
                            placeholder: 'Click Here To Set Up',
                            selectAll: false,
                            onClick: (e) => {
                                vm.manager.all_email_groups.map(el => {
                                    if (el.name === e.value.trim()){
                                        el.selected = Number(e.checked)
                                        return el;
                                    }
                                })
                            }
                        })
                    });
                }else {
                    vm.manager.all_email_groups = vm.manager.all_email_groups.map(el => {
                        if (el.group === 'payments') {
                            el.selected = 0;
                        }
                        return el;
                    })
                }

                if(vm.amenitiesChecked){
                    $timeout(function () {
                        $('#amenities_emails_filter').multipleSelect({
                            filter: true,
                            placeholder: 'Click Here To Set Up',
                            selectAll: false,
                            onClick: (e) => {
                                vm.manager.all_email_groups.map(el => {
                                    if (el.name === e.value.trim()){
                                        el.selected = Number(e.checked)
                                        return el;
                                    }
                                })
                            }
                        })
                    });
                }else {
                    vm.manager.all_email_groups = vm.manager.all_email_groups.map(el => {
                        if (el.group === 'amenities') {
                            el.selected = 0;
                        }
                        return el;
                    })
                }
            }
            $scope.groupChecking();

            $scope.selectAllProperties = function () {
                var checked = vm.select_all_properties;

                vm.property_options = vm.property_options.map(item => {
                    item.checked = vm.select_all_properties;
                    return item;
                });
            };

            $scope.resetTwoFactorAuthentication = () => {
                if (confirm('This will disable two factor authentication for this user. Are you sure you want to proceed?')) {
                    $http.post(`/api/v1/managers/${vm.manager.id}/reset-two-factor`, {id: vm.manager.id})
                        .then(() => {
                            toastr.success('Two-factor authentication has been reset');
                            vm.manager['2-fa-enabled'] = false;
                        })
                        .catch(() => {
                            toastr.error('Error resetting two factor authentication');
                        });
                }
            };

            vm.property_options.forEach(item => {
                for (let i = 0; i < vm.properties.length; i++) {
                    if (vm.properties[i].id == item.id) {
                        item.checked = 1;
                    } else {
                        vm.select_all_properties = 0;
                    }
                }

                for (let i = 0; i < vm.portfolios.length; i++) {
                    if ('portfolio_id' + vm.portfolios[i].portfolio_id === item.id) {
                        item.checked = 1;
                    } else {
                        vm.select_all_properties = 0;
                    }
                }
            });

            vm.property_options.forEach(item => {
                if (item.checked == 0) {
                }
            });

            $scope.propChanged = (id, newValue) => {
                const index = ('' + id).indexOf('portfolio_id');
                if (index > -1) {
                    const propertyIds = vm.property_options.find(i => i.id === id).properties_ids.split(',');
                    propertyIds.forEach(item => {
                        vm.property_options.find(p => +p.id === +item).checked = newValue ? 1 : 0;
                    });
                } else {
                    if (!newValue) {
                        vm.property_options = vm.property_options.map(item => {
                            if (item.properties_ids) {
                                if (item.properties_ids.split(',').find(i => +i === +id)) {
                                    item.checked = 0;
                                }
                            }
                            return item;
                        });
                    } else {
                        vm.property_options.forEach(item => {
                            if (item.properties_ids) {
                                let isAllChecked = true;
                                item.properties_ids.split(',').forEach(pItem => {
                                    const property = vm.property_options.find(i => '' + i.id === pItem);
                                    if (!property.checked) {
                                        isAllChecked = false;
                                    }
                                });
                                if (isAllChecked) {
                                    item.checked = 1;
                                }
                            }
                        });
                    }
                }
            };

            vm.getPropertyIds = function () {
                var ids = [];
                $('.property_assignment:checked').each(function () {
                    ids.push($(this).attr('id').replace('property_', '')); // eslint-disable-line angular/controller-as-vm
                });
                return ids;
            };

            vm.sendPasswordReset = function () {
                $http.post('/api/v1/manager/remind', {email: vm.manager.originalEmail});
                toastr.success('A password reset email was sent to ' + vm.manager.originalEmail);

            };
            vm.sendWelcome = function () {
                $http.post('/api/v1/manager/welcome', {email: vm.manager.originalEmail});
                toastr.success('A welcome email was sent to ' + vm.manager.originalEmail);

            };

            vm.updateManager = function (id) {
                if (vm.manager.deleted_at == 0) {
                    managerService.deleteManager(id)
                        .success(function (data) {
                            $state.go('main.managers');
                            toastr.success('Manager successfully deleted');
                        })
                        .error(function (data) {
                            if (typeof data.stopaction != 'undefined') {
                                return;
                            }

                            toastr.error('Error deleting manager');
                        });
                } else {

                    let showingsError = '';
                    vm.manager.properties.forEach(item => {
                        if (item.validation && !item.validation.isValid) {
                            showingsError = item.validation.error;
                        }
                    });
                    if (showingsError.length) {
                        toastr.error(showingsError);
                        return;
                    }
                    $scope.$apply(); // unchecking subdropdowns from jr permissions wasn't updating vm.manager.user_permissions
                    var data = {
                        first_name: vm.manager.first_name,
                        last_name: vm.manager.last_name,
                        email: vm.manager.email,
                        permissions: vm.manager.permissions,
                        user_permissions: vm.manager.user_permissions,
                        property_ids: vm.getPropertyIds(),
                        manager_email_groups: vm.manager.all_email_groups,
                        properties: vm.manager.properties,
                        lease_rent_editable: vm.manager.lease_rent_editable,
                        lease_rent_editable_type: vm.manager.lease_rent_editable_type,
                        remove_from_lease_message: vm.manager.remove_from_lease_message,
                        can_edit_tenant_email: vm.manager.can_edit_tenant_email,
                        can_be_assigned_to_mr: vm.manager.can_be_assigned_to_mr,
                        can_disable_property: vm.manager.can_disable_property,
                        can_create_tenant: vm.manager.can_create_tenant,
                        is_billing: vm.manager.is_billing,
                        lease_approve_enabled: vm.manager.lease_approve_enabled,
                        lease_messages_enabled: vm.manager.lease_messages_enabled,
                        messages_access_type: vm.manager.messages_access_type,
                        lease_create_from_prospect_enabled: vm.manager.lease_create_from_prospect_enabled,
                        credit_check_mandatory_for_application_approval: vm.manager.credit_check_mandatory_for_application_approval,
                    };

                    managerService.updateManager(id, data)
                        .success(function (data) {
                            if (!data.success)
                                toastr.error(data.errors[0]);
                            else {
                                $state.go('main.managers');
                                toastr.success('Manager successfully updated');
                            }
                        })
                        .error(function (data) {
                            if (typeof data.stopaction != 'undefined') {
                                return;
                            }
                            toastr.error('Error saving data');
                        });
                }
            };
        }
    }
    ()
)
;
