(function () {

    'use strict';

    angular
        .module('app')
        .controller('PadAgreementController', padAgreement);

    function padAgreement($scope, $modal) {

        $scope.open = function (size) {

            var modalInstance = $modal.open({
                templateUrl: 'scripts/modules/payment/method/create/payment-method.pad.agreement.html',
                size: size,
                controller: 'AgreementInstanceController',
                resolve: {
                    items: function () {
                        return 'ok';
                    }
                }


            });

            modalInstance.result.then(
                function (ischeck) {
                    // if (ischeck){

                    $('#padcheck').prop('checked', true);
                    console.log('ckecked');
                }, function () {

                    $('#padcheck').attr('checked', false);
                });
        };


    }

    angular
        .module('app')
        .controller('AgreementInstanceController', agreementInstanceCtrl);

    function agreementInstanceCtrl($scope, $modalInstance, items, $http) {

        console.log(items);
        console.log($modalInstance);

        $scope.ok = function () {
            console.log('run ok');
            $modalInstance.close(true);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
        $scope.body = '';
        $http.get('/api/v1/pad-agreement').success(function (data) {
            $scope.body = data.result.body;
        });

    }

}());
