(function () {

    'use strict';
    angular
        .module('app')
        .controller('PaymentsUpdateController', paymentsUpdateController);

    function paymentsUpdateController($state, tenantService, $scope, $http, $timeout, $rootScope, $location) {

        $scope.moment = window.moment;

        var vm = this;
        vm.isUploading = false;
        vm.tenantInfo = false;
        vm.billing_same_as_mailing = true;
        vm.tenantData = {paymentType: false, paymentTypeOther: 'cash'};
        vm.tenants = [];
        vm.show_tenants_list = false;
        $scope.url_parms_searched = false;
        $scope.downloadPads = function () {
            if (vm.selectedPropertyBulk === undefined || vm.selectedPropertyBulk.id === undefined)
                toastr.error('Please select a valid property first');
            else
                window.location = '/api/v1/tenants/bulk/existing-pads-csv/' + vm.selectedPropertyBulk.id;
        };


        $scope.vars = {payment_fill: 1};
        $scope.offline_payment = 0;
        $scope.loading_extension_charges = 0;


        vm.properties = [];
        vm.units = [];
        $scope.getProperties = function () {
            $http.post('/api/v1/properties/get-all-for-manager-payments').success(function (data) {
                if (!data.success) {
                    toastr.error('', data.errors);
                    return;
                }

                vm.properties = data.data;
                if($location.search().property_id && ! $scope.url_parms_searched){
                    vm.selectedProperty = vm.properties.find(property => property.id == $location.search().property_id);
                    if(vm.selectedProperty && vm.tenantData.unit){
                        $scope.fetchTenantFromUnit();
                        $scope.url_parms_searched = true;
                    }
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error getting properties');
            });
        };
        $scope.getProperties();

        $scope.getPropertyUnits = function (property_id) {
            $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + property_id).success(function (data) {
                if (!data.success) {
                    toastr.error('', data.errors);
                    return;
                }

                angular.forEach(data.data, function (unit, key) {
                    if (!vm.units.includes(unit.unit)) {
                        vm.units.push(unit.unit);
                    }
                });

                if($location.search().unit && ! $scope.url_parms_searched){
                    vm.tenantData.unit = vm.units.find(unit => unit == $location.search().unit);
                    if(vm.selectedProperty && vm.tenantData.unit){
                        $scope.fetchTenantFromUnit();
                        $scope.url_parms_searched = true;
                    }
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error getting properties');
            });
        };

        $scope.$watch('vm.selectedProperty', function () {
            if (typeof (vm.selectedProperty) == 'object') {
                vm.tenantData.unit = '';
                vm.units = [];

                // console.log(vm.selectedProperty);

                if (vm.selectedProperty.country != 'Canada') {
                    $http.get('/api/v1/payments/ifields-token?property_id=' + vm.selectedProperty.id).success(
                        function (data) {
                            console.log('iFields token recived');
                            setAccount(data.data.token, data.data.software_name, data.data.software_version);
                            console.log('iFields account setted');
                        }
                    )
                }

                $scope.getPropertyUnits(vm.selectedProperty.id);
            }

        });


        $scope.holdModeChanged = () => {
            if (vm.tenantData.is_hold) {
                vm.tenantData.amount += Number(vm.selectedProperty.payment_hold_fee);
            } else {
                vm.tenantData.amount -= Number(vm.selectedProperty.payment_hold_fee);
            }
        }

        $scope.skipMonth = function (schedule) {
            if (!confirm('Are you sure you want to pause your recurring payment for 1 month?')) {
                return;
            }

            $http.post('/api/v1/payments/pause-for-month/' + schedule.id).success(function (data) {
                if (data.success) {
                    schedule.skipped = 1;
                    toastr.info('Schedule has been paused for 1 month');
                } else {
                    toastr.error('', data.errors);
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error, unable to pause schedule for 1 month');
            });
        };

        $scope.resumeMonth = function (schedule) {
            if (!confirm('Are you sure you want to cancel pausing your recurring payment for 1 month?')) {
                return;
            }

            $http.post('/api/v1/payments/resume-for-month/' + schedule.id).success(function (data) {
                if (data.success) {
                    schedule.skipped = 0;
                    toastr.info('Schedule 1 month pause has been cancelled');
                } else {
                    toastr.error('', data.errors);
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error, unable to cancel pausing schedule for 1 month');
            });
        };

        $scope.suffix = function (n) {
            var s = ['th', 'st', 'nd', 'rd'],
                v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        };

        vm.cancel = function () {
            vm.tenantInfo = false;
            vm.clearTenantData();
            vm.selectedProperty = undefined;
            vm.tenantData.unit = undefined;
        };

        $scope.isCardknoxLoading = false;
        $scope.cardknox = function () {
            toastr.info('Tokenizing data. Please wait.');
            $scope.isCardknoxLoading = true;
            console.log('Starting tokenizing data....')
            getTokens(() => {
                    toastr.info('Data Tokenized. Please continue.');
                    console.log('Data Tokenized');
                    $scope.$apply(function () {
                        if (vm.tenantData.paymentType === 'debit' || vm.tenantData.paymentType === 'credit') {
                            vm.tenantData.card_number = $scope.changeCardknoxCCConfirmed ? $('#card-number-token-cardknox').val() : vm.tenantData.card_number;
                            vm.tenantData.cvv = $scope.changeCardknoxCCConfirmed ? $('#cvv-token-cardknox').val() : vm.tenantData.cvv;
                        }
                        if (vm.tenantData.paymentType === 'pad') {
                            vm.tenantData.account_number = $scope.changeCardknoxAccountConfirmed ? $('#ach-token-cardknox').val() : vm.tenantData.account_number;
                        }
                        vm.tenantData.provider = 'cardknox';
                        $scope.isCardknoxLoading = false;

                        $scope.displayModal();
                    });
                },
                function () {
                    //onError
                    toastr.error('Error tokenizing data');
                    console.error('Error tokenizing data');
                },
                10000 //10 second timeout
            );
        }

        vm.handleReturnedTenant = function (data) {
            if(vm.selectedProperty.merchant_account.provider === 'cardknox') {
                const iFieldStyle = {
                    padding: '6px 12px',
                    'font-size': '14px',
                    'line-height': 1.42,
                    color: '#555',
                    'background-color': '#f2f2f2',
                    'background-image': 'none',
                    border: '1px solid #024953',
                    'border-radius': '4px',
                    width: '230px',
                    'font-family': '"Open Sans", sans-serif',
                    height: '37px'
                };
                setIfieldStyle('card-number', iFieldStyle);
                setIfieldStyle('cvv', iFieldStyle);
                setIfieldStyle('ach', iFieldStyle);
            }
            vm.tenantData.tenant_id = data.data.tenant.id;
            vm.tenantInfo = data.data;
            vm.tenantInfo.hasCredit = false;
            vm.tenantInfo.hasDebitVisa = false;
            vm.tenantInfo.hasDirectDebit = false;

            for (var i in vm.tenantInfo.methods) {
                if (vm.tenantInfo.methods[i].value == 'credit')
                    vm.tenantInfo.hasCredit = true;
                else if (vm.tenantInfo.methods[i].value == 'debit_visa')
                    vm.tenantInfo.hasDebitVisa = true;
                else if (vm.tenantInfo.methods[i].value == 'direct_debit')
                    vm.tenantInfo.hasDirectDebit = true;
            }

            vm.clearTenantData();
            $scope.checkForRecurringCharges();
        };

        vm.handleMultipleTenants = function (data) {
            toastr.info('This unit has multiple tenants, please select one from the list');
            // more than one tenant in the unit, lets display them for selection
            vm.tenants = data.data.tenants;
            vm.show_tenants_list = true;
        };

        vm.fetchTenantFromUnit = function () {
            if (vm.selectedProperty === undefined) {
                toastr.error('Please select a property');
                return false;
            }
            if (vm.tenantData.unit === undefined || vm.tenantData.unit == '') {
                toastr.error('Please select a unit');
                return false;
            }
            vm.tenantInfo = false;
            var url = '/api/v1/tenants/find-by-unit';
            $http.post(url, {
                property_id: vm.selectedProperty.id,
                unit_number: vm.tenantData.unit
            }).success(function (data) {
                if (data.success) {
                    if (typeof data.data.tenants !== 'undefined') {
                        vm.handleMultipleTenants(data);
                        return;
                    }

                    vm.handleReturnedTenant(data);
                } else
                    toastr.error(data.data.error);
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('Error finding tenant');
            });
        };

        vm.fetchTenantFromUnitId = function (unit_id) {
            vm.tenantInfo = false;
            var url = '/api/v1/tenants/find-by-unit-id/' + unit_id;
            $http.get(url).success(function (data) {
                if (data.success) {
                    vm.handleReturnedTenant(data);
                } else
                    toastr.error(data.data.error);
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('Error finding tenant');
            });
        };

        $scope.fetchTenantFromUnit = function () {
            vm.fetchTenantFromUnit();
        };

        $scope.fetchTenantFromUnitId = function (unit_id) {
            vm.fetchTenantFromUnitId(unit_id);
        };

        vm.getServiceFee = function () {
            if ($scope.offline_payment == 1) {
                return 0;
            }
            if (!vm.tenantData.paymentType)
                return '';
            if (vm.tenantData.paymentType == 'pad') {
                for (var i in vm.tenantInfo.methods)
                    if (vm.tenantInfo.methods[i].value == 'direct_debit')
                        return vm.tenantInfo.methods[i].tenant_fee;
            } else if (vm.tenantData.paymentType == 'credit') {
                var cardType = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();

                for (var i in vm.tenantInfo.methods) {
                    if (vm.tenantInfo.methods[i].value == cardType)
                        return vm.tenantInfo.methods[i].tenant_fee;
                }
                return 'Unkown Fee';
            } else if (vm.tenantData.paymentType == 'debit')
                for (var i in vm.tenantInfo.methods)
                    if (vm.tenantInfo.methods[i].value == 'debit_visa')
                        return vm.tenantInfo.methods[i].tenant_fee;

            return vm.tenantData.paymentType;
            return 'Unkown Fee';
        };

        vm.getServiceCharge = function () {
            if (!vm.tenantData.paymentType)
                return '';
            var fee = vm.getServiceFee();
            fee = '' + fee;
            if (fee == 'Unkown Fee')
                return 0;
            if (fee[0] == '$')
                return parseFloat(fee.replace('$', ''));
            var percent = parseFloat(fee.replace('%', ''));
            var amount = parseFloat(vm.tenantData.amount);
            amount = amount * (percent / 100);
            return Math.round(amount * 100) / 100;
        };

        vm.getExistingManagementScheduleAmount = function () {
            for (var i in vm.tenantInfo.schedules)
                if (vm.tenantInfo.schedules[i].created_by == 'Management')
                    return vm.tenantInfo.schedules[i].amount;
            return false;
        };


        vm.clearTenantData = function () {
            vm.tenantData.institution_number = '';
            vm.tenantData.transit_number = '';
            vm.tenantData.account_number = '';
            vm.tenantData.day_of_month = '';
            vm.tenantData.end_at = '';
            vm.tenantData.next_at = '';
            vm.tenantData.amount = '';
            vm.tenantData.starting_amount = '';
            vm.tenantData.description = '';
            vm.tenantData.editing_schedule_id = 0;
            vm.tenantData.paymentType = false;
            vm.tenantData.payment_recurring = 'one_time';
            vm.tenantData.billingunit = '';
            vm.tenantData.address = '';
            vm.tenantData.city = '';
            vm.tenantData.province = '';
            vm.tenantData.country = '';
            vm.tenantData.postal_code = '';
            vm.billing_same_as_mailing = true;
            vm.tenantData.card_holder_name = '';
            vm.tenantData.card_number = '';
            vm.tenantData.expiration_year = '';
            vm.tenantData.expiration_month = '';
            vm.tenantData.cvv = '';
            vm.tenantData.is_hold = false;
            vm.tenantData.is_pay_other = 1;
            vm.tenantData.paymentTypeOther = 'cash';

            vm.tenants = [];
            vm.show_tenants_list = false;
        };

        $scope.editScheduleIfReady = function (schedule, count) {
            $scope.changeCardknoxAccountConfirmed = false;
            $scope.changeCardknoxCCConfirmed = false;
            if ($scope.loading_extension_charges == 1 && count < 80) {
                $timeout(function () {
                    $scope.editScheduleIfReady(schedule, count + 1);
                }, 100);
                return;
            }

            vm.editSchedule(schedule);
        };

        $scope.selected_created_by = 'Management';
        vm.editSchedule = function (s) {
            $scope.selected_created_by = s.created_by;

            vm.tenantData.institution_number = s.institution_number;
            vm.tenantData.transit_number = s.transit_number;
            vm.tenantData.account_number = s.account_number;
            vm.tenantData.routing = s.routing;

            vm.tenantData.day_of_month = parseInt(s.day);
            vm.tenantData.end_at = s.end_at ? moment(s.end_at).format('MMM Do YYYY') : '';
            vm.tenantData.next_at = s.next_at ? moment(s.next_at).format('MM/DD/YYYY') : '';
            vm.tenantData.amount = s.amount;
            vm.tenantData.starting_amount = s.amount;
            vm.tenantData.description = s.description;
            vm.tenantData.paymentType = s.method == 'Direct Debit' ? 'pad' : 'credit';
            vm.tenantData.editing_schedule_id = s.id;
            vm.tenantData.payment_recurring = 'recurring';
            vm.tenantData.billingunit = s.unit;
            vm.tenantData.address = s.address;
            vm.tenantData.city = s.city;
            vm.tenantData.province = s.province;
            vm.tenantData.country = s.country;
            vm.tenantData.postal_code = s.postal_code;
            vm.tenantData.card_holder_name = s.card_holder_name;
            vm.tenantData.card_number = s.card_number;
            vm.tenantData.expiration_year = s.expiration_year;
            vm.tenantData.expiration_month = s.expiration_month;
            vm.tenantData.cvv = s.cvv;
            vm.tenantData.is_hold = s.is_hold;
            vm.tenantData.is_pay_other = s.is_pay_other;
            if (vm.tenantData.paymentType == 'cash' || vm.tenantData.paymentType == 'check' || vm.tenantData.paymentType == 'etransfer') {
                vm.tenantData.paymentTypeOther = vm.tenantData.paymentType;
                vm.tenantData.paymentType = 'other';
            }

            vm.billing_same_as_mailing = false;

            angular.forEach($scope.extension_charges, function (charge, key) {
                if (typeof s.extension_keys[charge.ChargeCode] !== 'undefined') {
                    $scope.extension_charges[key].selected = true;
                    $scope.extension_charges[key].percentage = s.extension_keys[charge.ChargeCode].percentage;
                } else {
                    $scope.extension_charges[key].selected = false;
                    $scope.extension_charges[key].percentage = 100;
                }
            });

            if (vm.tenantData.is_pay_other == 1) {
                $scope.vars.payment_fill = 1;
            } else {
                $scope.vars.payment_fill = 2;
            }
            if (vm.selectedProperty.recurring_amounts_enabled) {
                $scope.setAmount();
            }
            if ($scope.extension_recurring_charges == 1) {
                $scope.setExtensionAmount();
            }
        };

        vm.removeSchedule = function (s) {
            if (!confirm('Are you sure you want to stop this monthly schedule?'))
                return false;
            $http.delete('/api/v1/payments/payment-schedules/' + s.id).success(function (data) {
                if (!data.success)
                    toastr.error(data.errors);
                else {
                    $scope.fetchTenantFromUnit();
                    toastr.success('Schedule Deleted');
                }
            });
        };

        if (FileReader.prototype.readAsBinaryString === undefined) {
            console.log('ie11 fix');
            FileReader.prototype.readAsBinaryString = function (fileData) {
                var binary = '';
                var pt = this; // eslint-disable-line angular/controller-as-vm
                var reader = new FileReader();
                reader.onload = function (e) {
                    var bytes = new Uint8Array(reader.result);
                    var length = bytes.byteLength;
                    for (var i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    //pt.result  - readonly so assign content to another property
                    pt.content = binary;
                    pt.onloadend(); // thanks to @Denis comment
                };
                reader.readAsArrayBuffer(fileData);
            };
        }


        vm.tenantData = {};

        vm.upload = function () {
            var f = document.getElementById('csv').files[0];
            if(!f){
                toastr.error('Please, select file.');
                return;
            }else{
                $scope.inProgress = true;
            }

            vm.isUploading = true;

            const r = new FileReader();
            r.readAsBinaryString(f);
            r.onloadend = function (e) {
                //var data = e.target.result;
                if (!e) {
                    var data = r.content;
                } else {
                    var data = e.target.result;
                }
                toastr.info('Please wait a moment, we are processing the bulk upload.', 'Processing...');
                $http.post('/api/v1/tenants/update-by-admin-bulk', {csv: data})
                    .success(function (data) {
                        if (data.success) {
                            $('.toast').remove();
                            toastr.success('Bulk upload processed');
                            vm.isUploading = false;
                            var i = 0;
                            var html = '<div style="margin-left:20px;text-align:left;">\
						<div class="alert alert-info no-print " role="alert">This information will be lost when you leave the page.<br>\
						Please consider <b><u><a href="javascript:printResults()">printing</a></u></b> or copying it for your records</div>\
						<h2>Bulk Upload Results</h2>\
						<b>New Pads: ' + data.data.counts.added + '<br>\
						   Updated Pads: ' + data.data.counts.updated + '<br>\
						   Removed Pads: ' + data.data.counts.removed + '<br>';

                            if (data.data.counts.errored > 0) {
                                html += 'Errored Pads: ' + data.data.counts.errored;
                            }
                            html += '</b></br>';

                            html += '<table class="table table-hover align-left"><tr><th>Row</th><th>Status</th></tr>';
                            for (i in data.data.results) {
                                html += '<tr><td>' + i + '</td><td>' + data.data.results[i] + '</td></tr>';
                            }
                            html += '</table></div>';
                            $('#bulkUploadContaier').html(html);
                            $scope.inProgress = false;
                        } else {
                            $('.toast').remove();
                            toastr.error(data.errors);
                            $scope.inProgress = false;
                        }
                    })
                    .error(function (data) {
                        if (typeof data.stopaction != 'undefined') {
                            return;
                        }

                        vm.isUploading = false;
                        $('.toast').remove();
                        toastr.error('Error updating pad agreements');
                        $scope.inProgress = false;
                    });
            };

        };


        $scope.payrixValidationErrors = {};

        $scope.updateSinglePAD = function () {
            if ($scope.inProgress) {
                return;
            }


            if (vm.selectedProperty === undefined) {
                toastr.error('Please select a property');
                return false;
            }

            vm.tenantData.property_id = vm.selectedProperty.id;

            vm.tenantData.credit_card_type = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();

            if ($scope.extension_recurring_charges == 1) {
                vm.tenantData.extension_charges = $scope.extension_charges;
            }

            if (vm.tenantData.paymentType == 'other') {
                vm.tenantData.paymentType = vm.tenantData.paymentTypeOther;
            }

            /*if(!confirm('Are you sure you want to create a '+vm.tenantData.payment_recurring.replace('_',' ')+' payment for $'+vm.tenantData.amount+
            '? Service fees may apply.'))
                return false;
                */
            $scope.inProgress = true;
            toastr.info('Please wait a moment, we are processing the payment.', 'Processing...');
            if (vm.tenantData.is_hold) {
                vm.tenantData.amount -= Number(vm.selectedProperty.payment_hold_fee);
            }

            $http.post('/api/v1/tenants/update-by-admin-single', vm.tenantData)
                .success(function (result) {
                    $scope.inProgress = false;
                    $('.toast').remove();
                    if (result.success) {
                        $scope.updateResident.$setPristine();
                        vm.clearTenantData();
                        $scope.fetchTenantFromUnit();
                        toastr.success(result.data.message);
                    } else {
                        if (!result.success && result.message){
                            toastr.error(result.message[0])
                        }
                        if (vm.tenantData.paymentType == vm.tenantData.paymentTypeOther) {
                            vm.tenantData.paymentType = 'other';
                            $scope.offline_payment = 1;
                            vm.tenantData.payment_recurring = 'one_time';
                        }
                        $('.toast').remove();

                        if (result.errors) {
                            if (Array.isArray(result.errors)) {
                                result.errors.forEach(msg => toastr.error(msg));
                            } else {
                                toastr.error(result.errors)
                            }
                        } else {
                            toastr.error(result.message);
                        }

                        if (result.extra && result.extra.validation) {
                            console.log(result.extra.validation)
                            $scope.payrixValidationErrors = result.extra.validation;
                        }
                    }
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                    if (vm.tenantData.is_hold) {
                        vm.tenantData.amount += Number(vm.selectedProperty.payment_hold_fee);
                    }
                    if (vm.tenantData.paymentType == vm.tenantData.paymentTypeOther) {
                        vm.tenantData.paymentType = 'other';
                        $scope.offline_payment = 1;
                        vm.tenantData.payment_recurring = 'one_time';
                    }
                    $scope.inProgress = false;
                    $('.toast').remove();
                    toastr.error('Error updating or creating a payment for this tenant');
                });
        };

        $scope.setAmount = function () {
            if ($scope.vars.payment_fill == 1) {
                vm.tenantData.amount = '';
            } else {
                vm.tenantData.amount = parseFloat(vm.tenantInfo.tenant.recurring_amount);
                if (vm.selectedProperty.recurring_amounts_other_name) {
                    vm.tenantData.amount += parseFloat(vm.tenantInfo.tenant.recurring_amount_other);
                }
            }
        };

        // stuff related to pulling recurring charges from third party extensions
        $scope.setExtensionAmount = function () {
            if ($scope.vars.payment_fill == 1) { //Pay other amount
                vm.tenantData.amount = vm.tenantData.starting_amount;
            } else {
                var amount = 0;
                angular.forEach($scope.extension_charges, function (charge, key) {
                    if (charge.selected == 1 && charge.percentage > 0) {
                        amount = amount + (parseFloat(charge.Amount) * (charge.percentage / 100));
                    }
                });

                amount = Math.round(amount * 100) / 100;
                vm.tenantData.amount = amount;

                if(vm.tenantData.is_hold){
                    vm.tenantData.amount += Number(vm.selectedProperty.payment_hold_fee);
                }
            }
        };

        $scope.checkForRecurringCharges = function () {
            $scope.loading_extension_charges = 1;
            $http.post('/api/v1/extension/charges/' + vm.tenantData.tenant_id)
                .success(function (data) {
                    $scope.extension_recurring_charges = data.data.enabled;
                    $scope.extension_disable_pay_other = data.data.disable_pay_other;
                    $scope.extension_charges = data.data.charges;

                    angular.forEach($scope.extension_charges, function (charge, key) {
                        $scope.extension_charges[key].selected = true;
                        $scope.extension_charges[key].percentage = 100;
                    });

                    $scope.vars.payment_fill = 2;
                    if ($scope.extension_recurring_charges == 1) {
                        $scope.setExtensionAmount();
                    } else {
                        $scope.setAmount();
                    }
                    $scope.loading_extension_charges = 0;
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.extension_recurring_charges = 0;
                    $scope.extension_disable_pay_other = 0;
                    $scope.extension_charges = [];
                    $scope.loading_extension_charges = 0;
                });
        };

        $scope.cardTypeChange = function () {
            //$scope.checkForRecurringCharges();
            if (vm.tenantData.paymentType == 'cash' || vm.tenantData.paymentType == 'check' || vm.tenantData.paymentType == 'etransfer' || vm.tenantData.paymentType == 'other') {
                $scope.offline_payment = 1;
                vm.tenantData.payment_recurring = 'one_time';
            } else {
                $scope.offline_payment = 0;
            }
        };

        $scope.extension_recurring_charges = 0;
        //$scope.checkForRecurringCharges();


        $scope.getLast4Digits = () => {
            if (!vm.tenantData.paymentType){
                return '';
            }

            if (vm.selectedProperty.merchant_account.provider == 'cardknox') {
                if (vm.tenantData.paymentType != 'pad') {
                    return vm.tenantData.card_number.split(';')[0].substr(-4);
                } else {
                    return vm.tenantData.account_number.split(';')[0].substr(-4);
                }
            } else if(vm.selectedProperty.merchant_account.provider == 'payrock') {
                if (vm.tenantData.paymentType != 'pad') {
                    return vm.tenantData.card_number ? vm.tenantData.card_number.substr(-4) : '';
                } else {
                    return vm.tenantData.account_number.split(';')[0].substr(-4);
                }
            }else if(vm.selectedProperty.merchant_account.provider == 'payrix') {
                if (vm.tenantData.paymentType != 'pad') {
                    return vm.tenantData.card_number;
                } else {
                    return vm.tenantData.account_number.substr(-4);
                }
                debugger
            }
        }

        $scope.changeCardknoxAccountConfirmed = true;
        $scope.cardknoxAccountClicked = () => {
            $timeout(function () {
                // angular.element(document.activeElement).blur();
                $('#cardknoxAccountIFrame').blur();
                $(':focus').blur();

                let r = confirm('If you want to change account number - you need to enter full number. Partial editing is not available.')
                if (r) {
                    $scope.changeCardknoxAccountConfirmed = true;
                }
            });
        }

        $scope.changeCardknoxCCConfirmed = true;
        $scope.cardknoxCCClicked = () => {
            $timeout(function () {
                // angular.element(document.activeElement).blur();
                // $('#cardknoxAccountIFrame').blur();
                $(':focus').blur();

                let r = confirm('If you want to change Card Number or CVV - you need to enter full card number and CVV. Partial editing is not available.')
                if (r) {
                    $scope.changeCardknoxCCConfirmed = true;
                }
            });
        }

        $scope.last4Digits = '';
        $scope.displayModal = () => {
            $scope.last4Digits = $scope.getLast4Digits();
            $('.finalize').appendTo('body').modal('show');
        }


        $rootScope.$on('$stateChangeStart', destroyTheModal);

        function destroyTheModal() {
            $('.finalize').modal('hide').remove();
        }

    }

}());
