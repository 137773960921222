(function () {

    'use strict';

    angular
        .module('app')
        .controller('SurveyViewController', function ($scope, resolvedSurvey, langService, resolvedFilters, $timeout, surveyService, $http) {
            $scope.t = langService.t('surveys');
            $scope.name = resolvedSurvey.name;
            $scope.is_public = resolvedSurvey.is_public;
            $scope.id = resolvedSurvey.id;
            $scope.answers = resolvedSurvey.answers;
            $scope.isCordova = Boolean(window.Capacitor.isNativePlatform())

            $scope.dateFrom = '';
            $scope.dateTo = '';
            $scope.filters = resolvedFilters;
            $scope.filter = null;

            $timeout(function () {
                $('#report_filter').multipleSelect({
                    filter: true,
                    placeholder: 'Select property or prospect'
                });
                $('#fromDate,#toDate,#fromDate2,#toDate2').datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg'
                });
            });

            $scope.applyFilters = () => {
                $scope.answers = resolvedSurvey.answers;
                surveyService.getWithAnswers(resolvedSurvey.id, $scope.filter, $scope.dateFrom, $scope.dateTo).then(r => {
                    $scope.answers = r.answers;
                    $scope.renderPlot();
                })
            }

            $scope.renderPlot = () => {
                $scope.answers.forEach((item, index) => {
                    if(item.type !== 'Free text' && item.type !== 'Cost Adder'){
                        const data = [];

                        item.answers.forEach(answer => {
                            data.push({'label': answer.answer, data: answer.count});
                        });

                        setTimeout(() => {
                            $.plot($('#plot_' + index), data, {
                                series: {
                                    pie: {
                                        show: true
                                    }
                                }
                            });
                        }, 100);
                    }
                });
            };

            $scope.renderPlot();

            $scope.export = () => {
                if ($scope.isCordova && cordova.plugins && cordova.plugins.printer) {
                    // Use the Cordova Printer plugin to print the current page content
                    let pageContent = document.documentElement.innerHTML; // or any specific content you wish to print
                    cordova.plugins.printer.print(pageContent, { name: 'DocumentName', duplex: true }, (res) => {
                        console.log('Print finished or canceled? ' + !res);
                    });
                } else {
                    // Fallback to default print function for non-Cordova environments
                    window.print();
                }
            }

            $scope.exportCSV = () => {
                const url = new URL(
                    !!window.Capacitor.isNativePlatform() ?  pqDomain : (`${window.location.protocol}//${window.location.host}/response/`)
                );

                const params = url.searchParams;
                Object.entries($scope.filter||{})
                    .forEach(([k, v]) => params.set(`filter[${k}]`, v));
                if ($scope.dateFrom) {
                    params.set('fromDate', $scope.dateFrom);
                }
                if ($scope.dateTo) {
                    params.set('toDate', $scope.dateTo);
                }

                $http.post(`/api/v1/survey/csv/${$scope.id}`,
                    Object.fromEntries(url.searchParams.entries()),
                    {
                        headers: {
                            'Content-Type':
                                'application/x-www-form-urlencoded; charset=UTF-8'
                        },
                        transformRequest: params =>
                            Object.entries(params).map(([k,v]) =>
                                encodeURIComponent(k) + "="
                                + encodeURIComponent(v)
                            ).join('&')
                    }).then(response => response.data).then(resp => {
                        console.log(resp)
                        const blob = new Blob([resp], { type: 'text/csv' });
                        const url  = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = $scope.name + `.csv`;
                        link.click();
                });
            }
        });



}());
