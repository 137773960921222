(function() {

	'use strict';

    angular
		.module('app')
		.controller('PaymentScheduleEditController', PaymentScheduleEditController);

	function PaymentScheduleEditController($log,
        $scope, $state, $stateParams, $timeout, $location, $window, $q,
        paymentScheduleService, paymentMethodService,
        accountService, propertyService,$http,$rootScope, langService){

	    $scope.t = langService.t('payments');


        /* -------------------------- START --- Wizard & Steps ------------------------ */
        $scope.vars = {payment_fill:1};
        $scope.valid_dates = [];
        $scope.ref = {choose_end_date:false};
        $scope.extension_charges = [];
        $scope.editing = 0;
        $scope.ref = {choose_end_date:false};
        $scope.datepicker_inited = 0;
        $scope.last_pay_other_amount = 0;

        $scope.cancelEditing = function(){
            $scope.editing = 0;
        };

        $scope.enableEditing = function(){
            $scope.editing = 1;
            if ($scope.datepicker_inited == 0) {
                $timeout(function(){
                    $("#datepicker").datepicker("setDate", $scope.paymentSchedule.next_payment_formatted);
                    $("#datepicker2").datepicker("setDate", $scope.paymentSchedule.end_at_formatted);
                    $scope.datepicker_inited = 0;
                },500);
            }
            stepThreeCalculations();
        };

        $scope.setAmount = function(){
            if($scope.vars.payment_fill == 1){
                $scope.services[0].amount = $scope.paymentSchedule.services[0].amount;
            } else {
                $scope.services[0].amount = parseFloat($rootScope.authUser.recurring_amount) + parseFloat($rootScope.authUser.recurring_amount_other);
            }
            stepThreeCalculations();
        };

        $scope.setExtensionAmount = function(){
            if($scope.vars.payment_fill == 1){
                if ($scope.last_pay_other_amount == 0) {
                    $scope.last_pay_other_amount = $scope.paymentSchedule.services[0].amount;
                }
                $scope.services[0].amount = $scope.last_pay_other_amount;
            } else {
                var amount = 0;
                angular.forEach($scope.extension_charges, function(charge, key){
                    if (charge.selected == 1 && charge.percentage > 0) {
                        amount = amount + (parseFloat(charge.Amount) * (charge.percentage/100));
                    }
                });

                amount = Math.round(amount*100)/100;
                $scope.services[0].amount = amount;
            }
            stepThreeCalculations();
        };

        $scope.checkDate = function(){
            if ($scope.valid_dates.length == 0) {
                return;
            }

            var dt = new Date($scope.paymentScheduleData.start_date);
            if ($scope.valid_dates.indexOf(dt.getDate()) === -1){
                $scope.paymentScheduleData.start_date = '';
                toastr.error('Invalid Date');
            }
        };

		$scope.stepOne = function()
		{
			$scope.step = 1;
		};
		$scope.stepTwo = function()
		{
            if($scope.paymentScheduleData.recurring == true)
            {
                $state.go('main.paymentScheduleCreate.step_2');
                $scope.step = 2;
                $scope.outOfStep = 3;
            }
            else if($scope.paymentScheduleData.recurring == false)
            {
                if($state.current.name == 'main.paymentScheduleCreate')
                {
                    stepThreeCalculations();
                    $scope.step = 2;
                    $scope.outOfStep = 2;
                }
                else if($state.current.name == 'main.paymentScheduleCreate.step_3')
                {
                    $scope.step = 1;
                    $scope.outOfStep = 3;
                    $state.go('main.paymentScheduleCreate');
                }
            }
		};
		$scope.stepThree = function()
		{
            $scope.step = 3;
            $scope.outOfStep = 3;
            stepThreeCalculations();
		};
        /* -------------------------- END --- Wizard & Steps ------------------------ */


        /* -------------------------- START --- POST API calls ------------------------ */

        init();

        /**
         * Starting everything
         */
        function init()
        {
            console.log('init');
            initForm();
            initGetApi();
        }

        /**
         * Initialize form and services
         * - paymentScheduleData
         * - services
         */
        function initForm()
        {
            $scope.showFormButtons = false;
            
            /* -------------------------- Form Inputs ------------------------ */
            $scope.paymentScheduleData = {
              //  payment_method_id : null,
                recurring: 1,
                start_date: '',//moment().format("MM/DD/YY")
                end_date: ''//moment().format("MM/DD/YY")
            };

            /* -------------------------- Payment Services ------------------------ */
            $scope.services = [];
            $scope.count = $scope.services.length;
            $scope.add = function()
            {
                $scope.services.push({
                    id: "",
                    amount: "",
                    name: ""
                });
            };
            $scope.removeService = function(index)
            {
                console.log(index);
                $scope.services.splice(index, 1);
            };
        }

        $scope.getExtensionCharges = function() {
            /* -------------------------- get Extension Charges ------------------------ */
            $http.post('/api/v1/extension/charges')
            .success(function(data) {
                $scope.extension_recurring_charges = data.data.enabled;
                if ($scope.paymentScheduleData.recurring == 0) {
                    $scope.extension_disable_pay_other = 0;
                } else {
                    $scope.extension_disable_pay_other = data.data.disable_pay_other;
                }
                $scope.extension_charges = data.data.charges;

                angular.forEach($scope.extension_charges, function(charge, key){
                    $scope.extension_charges[key].selected = true;
                    $scope.extension_charges[key].percentage = 100;
                });

                angular.forEach($scope.extension_charges, function(charge, key){
                    if (typeof $scope.paymentSchedule.extension_keys[charge.ChargeCode] !== 'undefined') {
                        $scope.extension_charges[key].selected = true;
                        $scope.extension_charges[key].percentage = parseInt($scope.paymentSchedule.extension_keys[charge.ChargeCode].percentage);
                    } else {
                        $scope.extension_charges[key].selected = false;
                        $scope.extension_charges[key].percentage = 100;
                    }
                });

                if ($scope.paymentSchedule.is_pay_other == 1) {
                    $scope.vars.payment_fill = 1;
                } else {
                    $scope.vars.payment_fill = 2;
                }
                if ($scope.extension_recurring_charges == 1) {
                    $scope.setExtensionAmount();
                } else {
                    stepThreeCalculations();
                }
            })
            .error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.extension_recurring_charges = 0;
                $scope.extension_disable_pay_other = 0;
                $scope.extension_charges = [];
            });
        };

        /**
         * API calls
         *
         * - paymentMethodService.getAll()
         * - propertyService.getPropertyPaymentServices()
         * - accountService.getAccountInformation()
         */
        function initGetApi()
        {
            /* -------------------------- Payment Methods all ------------------------ */
            //paymentMethodService.getAll()
			$http.get('/api/v1/payments/payment-methods/?filter=usable')
                .success(function(data)
                {
                    console.log(data);
                    $scope.paymentMethods = data.data;
                    $scope.paymentMethodCount = (data.data).length;
                    console.log('-----------------------------------');
                    console.log('----- Payment Methods -------------');
                    console.log('-----------------------------------');
                    console.log(data.data);
                    if($stateParams.paymentMethodId != '' && $stateParams.paymentMethodId != 'recurring')
                    {
                        //$scope.paymentScheduleData.payment_method_id = Number($stateParams.paymentMethodId);
                    }

                    else
                    {
                        //$scope.paymentScheduleData.payment_method_id = data.data[0].id;
                    }

                })
                .error(function(data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });

            /* -------------------------- Property Payment Services all ------------------------ */
            propertyService.getPropertyPaymentServices()
                .then(function(data)
                {
                    $scope.items = data.data;
                    console.log('-----------------------------------');
                    console.log('----- Drop down items -------------');
                    console.log('-----------------------------------');
                    console.log(data.data);

                    // if rent exists then done
                    // if rent dne then condo
                    // if rent dne and condo dne then its first key
                    // if both rent and condo exist then pick rent of course
                    var rentAndCondoExistenceArray = [];
                    _.forEach(data.data, function(n, key)
                    {
                        if (n.name == "Rent" || n.name == "Condo")
                        {
                            rentAndCondoExistenceArray.push({
                                id: n.id,
                                name: n.name,
                                amount: ''
                            });
                        }
                    });

                })
                .then(function() {
                    $scope.showFormButtons = true;
                });


            $timeout(function(){
                $http.post('/api/v1/valid-schedule-dates')
                .success(function(data){
                    $scope.valid_dates = data.data.valid_dates;
                });
            },100);


            /* -------------------------- Account Information ------------------------ */
            accountService.getAccountInformation()
                .success(function(data)
                {
                    $scope.property = data.data.property;
                    $scope.company_name = data.data.company_name;
                    console.log('-----------------------------------');
                    console.log('----- Tenant Property -------------');
                    console.log('-----------------------------------');
                    console.log(data.data.property);

                    $scope.tenant = data.data;
                    console.log('-----------------------------------');
                    console.log('----- Tenant Details --------------');
                    console.log('-----------------------------------');
                    console.log(data.data);
                });
        }

        function findServiceNames()
        {
            for(var x = 0; x < $scope.services.length; x++)
            {
                for(var y = 0; y < $scope.items.length; y++)
                {
                    if($scope.services[x].id == $scope.items[y].id)
                    {
                        $scope.services[x].name = $scope.items[y].name;
                    }
                }
            }
        }

        function sumOfServices()
        {
            var services = $scope.services;
            var total_amount = 0;

            _.forEach(services , function(n, key) {
                console.log(n.amount);
                total_amount += n.amount;
            });

            $scope.total_amount = total_amount;
        }

        function getConvenienceFees()
        {
            var input = {
                payment_method_id: $scope.paymentSchedule.payment_method_id,
                total_amount: $scope.total_amount
            };
            paymentScheduleService.getConvenienceFees(input)
                .success(function(data) {
                    console.log('-----------------------------------');
                    console.log('----- Service Fees ------------');
                    console.log('-----------------------------------');
                    console.log(data.data);
                    $scope.convenience_fees = data.data.tenant_convenience_fees;
                    $scope.fee_percent = data.data.fee_percent;
                });
        }

        function paidWithPaymentMethod()
        {
            $scope.paymentMethod = $scope.paymentSchedule.payment_method_record;
        }

        $scope.reCalculate = function(){
            $scope.last_pay_other_amount = $scope.paymentSchedule.services[0].amount;
            stepThreeCalculations();
        };
        
        function stepThreeCalculations()
        {
            if ($scope.paymentScheduleData.end_date) { // if there is an end date, ensure it is after start date
                var start_moment = moment($scope.paymentScheduleData.start_date);
                var end_moment = moment($scope.paymentScheduleData.end_date);
                if (start_moment.diff(end_moment) >= 0) {
                    toastr.error('End Date must be after start date');
                    return;
                }
            }


            var defer = $q.defer();

            defer.promise
                .then(function(test) {
                    findServiceNames();
                })
                .then(function() {
                    sumOfServices();
                })
                .then(function() {
                    getConvenienceFees();
                })
                .then(function() {
                    paidWithPaymentMethod();
                    $scope.start_date = moment($scope.paymentScheduleData.start_date).get('date');
                    $scope.end_date = $scope.paymentScheduleData.end_date ? moment($scope.paymentScheduleData.end_date).format('MMM Do YYYY') : 0;
                });

            defer.resolve('test');
        }

        /* -------------------------- Watchers ------------------------ */
        $scope.$watchCollection('services', function(newValue, oldValue)
        {
            $scope.count = newValue.length;
            console.log($scope.count);
        });



        $scope.getSchedule = function(){
            paymentScheduleService.getById($stateParams.paymentScheduleId).success(function(data) {
                $scope.paymentSchedule = data.data;
                $scope.services = $scope.paymentSchedule.services;
                $scope.paymentSchedule.payment_method_id = $scope.paymentSchedule.payment_method.id;
                $scope.paymentSchedule.old_next_payment = $scope.paymentSchedule.next_payment;

                //stepThreeCalculations();
                $scope.getExtensionCharges();
            }).error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $log.log(data.error + ' ' + status);
            });
        };
        $scope.getSchedule();

        $scope.skipMonth = function(){
            if (!confirm($rootScope.trans('payments.pause_recur'))) {
                return;
            }

            $http.post('/api/v1/payments/pause-for-month/' + $stateParams.paymentScheduleId).success(function(data){
                if(data.success){
                    $scope.getSchedule();
                    toastr.info('Your schedule has been paused for 1 month');
                } else {
                    toastr.error('',data.errors);
                }
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error, unable to pause your schedule for 1 month');
            });
        }

        $scope.resumeMonth = function(){
            if (!confirm('Are you sure you want to cancel pausing your recurring payment for 1 month?')) {
                return;
            }

            $http.post('/api/v1/payments/resume-for-month/' + $stateParams.paymentScheduleId).success(function(data){
                if(data.success){
                    $scope.getSchedule();
                    toastr.info('Your schedule 1 month pause has been cancelled');
                } else {
                    toastr.error('',data.errors);
                }
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error, unable to cancel pausing your schedule for 1 month');
            });
        }

        $scope.updatePaymentSchedule = function() {

            var next_payment = $scope.paymentSchedule.old_next_payment == $scope.paymentSchedule.next_payment ? 0 : $scope.paymentSchedule.next_payment;

			var input = {
				payment_method_id: $scope.paymentSchedule.payment_method_id,
				description: $scope.paymentSchedule.description,
				payment_services: $scope.services,
                next_payment: next_payment,
                end_at: $scope.paymentSchedule.end_at
			};

            if ($scope.extension_recurring_charges == 1) {
                input.extension_charges = $scope.extension_charges;

                var hasone = 0;
                angular.forEach(input.extension_charges, function(charge, key){ 
                    if (charge.selected == true && charge.percentage > 0) {
                        hasone = 1;
                    }
                });

                if (hasone == 0 && $scope.vars.payment_fill == 2) {
                    toastr.error('At least one charge must be selected and the total can not be 0');
                    return;
                }
            }

            paymentScheduleService.update($stateParams.paymentScheduleId, input)
                .success(function(data) {
                    if (!data.success) {
                        if (typeof data.errors != 'undefined') {
                            angular.forEach(data.errors, function(error, key){
                                toastr.error(error);
                            });
                        } else {
                            toastr.error('Error updating schedule');
                        }

                        return;
                    }

                    $scope.paymentSchedules = data.data;
					    toastr.success('', 'Payment Schedule Updated');
                        toastr.options = {
                            "closeButton": true,
                            "debug": false,
                            "newestOnTop": false,
                            "progressBar": false,
                            "positionClass": "toast-top-right",
                            "preventDuplicates": true,
                            "onclick": null,
                            "showDuration": "300",
                            "hideDuration": "1000",
                            "timeOut": 0,
                            "extendedTimeOut": 0,
                            "showEasing": "swing",
                            "hideEasing": "linear",
                            "showMethod": "fadeIn",
                            "hideMethod": "fadeOut",
                            "tapToDismiss": false
                        };
                   		$state.go('main.paymentScheduleIndex');
                })
                .error(function(data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $log.log(data.error + ' ' + status);
                });
        };

        $scope.deletePaymentSchedule = function() {
			if(!confirm($rootScope.trans('payments.delete_sched')))
				return;
            paymentScheduleService.delete($stateParams.paymentScheduleId)
                .success(function(data) {
					if(data.success){
                        toastr.success('', 'Payment Schedule Deleted');                      
                        $state.go('main.paymentScheduleIndex');
					} else {
						toastr.error(data.errors);
                    }
                })
                .error(function(data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $log.log(data.error + ' ' + status);
                });
        };
		
		$scope.goBack = function(){window.history.back(); };
    }

}());
