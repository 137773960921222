(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyPaymentServices', pqPropertyPaymentServices);

    function pqPropertyPaymentServices() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-payment-services/property-payment-services.html',
            scope: {
                fee: '=',
                services: '=',
            }
        }
    }

})();
