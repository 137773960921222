(function () {

    'use strict';

    angular.module('app')
        .directive('validatedFromController', function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.vfc = function (modelValue, viewValue) {
                        return true;
                    };
                }
            };
        });

})();
