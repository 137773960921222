(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqEditCircle', pqEditCircle);

    function pqEditCircle()
    {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/edit-circle/edit-circle.html'
        }
    }

})();