(function () {

    'use strict';

    angular
        .module('app')
        .controller('MessageEditController', MessageEditController)
        .config(function ($animateProvider) {
            $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
        });

    function MessageEditController($scope, $http, $state, messageService, $stateParams, $rootScope, $timeout, langService) {
        $scope.t = langService.t('messages');
        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform());
        $scope.isMobileSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && $(window).width() < 768;
        $scope.messageData = {};
        $scope.scheduled_dates = [''];

        if($rootScope.authUser.permissions === 'tenant'){
            $state.go('main.messagesInbox');
        }

        $http.get('/api/v1/messages/scheduled/' + $stateParams.id).success(function (data) {
            if (!data) {
                toastr.error('Error retrieving Single Scheduled Message');
                return;
            }
            $scope.messageData = data.data;
            try {
                $scope.messageData.body = JSON.parse(data.data.message).body;
            } catch (e) {
                $scope.messageData.body = data.data.message;
            }
            $scope.messageData.dates.forEach((scheduled_date, key) => {
                $scope.scheduled_dates[key] = scheduled_date.date;
            });
        });

        //object with error messages
        var Errors = {
            'subject': 'Please enter subject for message',
            'body': 'Please enter body for message',
            'date': 'Please specify the date for message'
        };
        const vm = this;

        $scope.openScheduled = () => {
            $('#scheduled-modal').modal('show');
            $('.modal-backdrop').hide();
            $scope.initScheduledPickers();
        };

        $timeout(function () {
            $scope.initScheduledPickers();
        });

        $scope.initScheduledPickers = function(){
            $scope.scheduled_dates.forEach((scheduled_date, key) => {
                $('#scheduledDate' + key).daterangepicker({
                        startDate: Date.now(),
                        singleDatePicker: true,
                        autoUpdateInput: false,
                        timePicker: true,
                        drops: key > 3 ? 'up' : 'down',
                        autoApply: true,
                        timePickerIncrement: 15,
                        locale: {
                            format: 'YYYY-MM-DD hh:mm A'
                        }
                    },
                    function (start, end, label) {
                        if ($scope.scheduled_dates[key] !== start.format('YYYY-MM-DD HH:mm:ss')) {
                            $scope.scheduled_dates[key] = start.format('YYYY-MM-DD HH:mm:ss');
                            $('#scheduledDate' + key).val(start.format('YYYY-MM-DD hh:mm A'));
                        }
                    }
                ).val(scheduled_date);
            });
        }

        $scope.toggleDatePicker = function(key) {
            $('#scheduledDate' + key).data('daterangepicker').toggle();
        }

        $scope.addDate = function() {
            $scope.scheduled_dates.push('');
            $timeout(function () {
                $scope.initScheduledPickers();
            });
        }

        $scope.removeDate = function(key) {
            $scope.scheduled_dates.splice(key, 1);
            $scope.scheduled_dates.forEach((scheduled_date, key) => {
                $('#scheduledDate' + key).val(scheduled_date);
            });
        }

        $scope.closeDate = () => {
            $('#scheduled-modal').modal('hide');
        };

        $scope.handleKeypress = function(e) {
            if(e.keyCode === 13) {
                e.preventDefault();
                var messageArea = angular.element("#message-textarea .placeholder-text");
                messageArea.focus();
            }
        }

        //validation all information for message
        $scope.dataValidation = function () {
            var confirm_message = 'Are you sure you want to save changes';
            if (!confirm(confirm_message)) {
                return;
            }

            $scope.mailError = [];
            //check subject text
            if ($scope.messageData.subject === '') {
                $scope.mailError.push(Errors['subject']);
            }
            //check body text
            if ($scope.messageData.body === '') {
                $scope.mailError.push(Errors['body']);
            }
            //check for errors
            if ($scope.mailError.length === 0) {
                var has_dates = false;
                $scope.scheduled_dates.forEach((scheduled_date, key) => {
                    if (scheduled_date !== '') {
                        has_dates = true;
                    }
                });
                if (!has_dates) {
                    $scope.mailError.push(Errors['date']);
                    return;
                }
                $scope.saveMessage();
            }
        };

        $scope.saveMessage = function () {
            var data = {
                id: $scope.messageData.id,
                subject: $scope.messageData.subject,
                body: $scope.messageData.body,
                scheduled_dates: $scope.scheduled_dates
            };

            messageService.saveMessage(data).success(function(data2) {
                if (!data2.success) {
                    toastr.error('Save scheduled message error');
                    return;
                }
                toastr.info('Save scheduled message');
                $state.go('main.messagesScheduled');
            });
        };
    }
}());
