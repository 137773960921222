(function () {

    'use strict';

    angular
        .module('app')
        .controller('SurveyIndexController', function (langService, $scope, $state, helperService, $http) {
            $scope.t = langService.t('surveys');
            
            // if ($state.params.isPublished) {
            //     const msg = $state.params.isEmail ? 'Survey has been published and emailed to all selected tenants.'
            //         : 'Survey has been published.';
            //     toastr.success(msg);
            // }

            $scope.copy = (id, isPublic, slug) => {
                var url = '/api/v1/survey/get-url/' + id;
                $http.get(url).success(function (data) {
                    if (!data.success) {
                        toastr.error('error copying survey link to clipboard', '', {timeOut:0, extendedTimeOut:0});
                        return;
                    }

                    helperService.copyTextToClipboard(data.data.url);
                    toastr.success('The survey link was copied to the clipboard.');
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error('error copying survey link to clipboard', '', {timeOut:0, extendedTimeOut:0});
                });
            };
        });

}());
