(function () {

    'use strict';

    angular
        .module('app')
        .controller('LayoutController', LayoutController);

    function LayoutController($scope,$rootScope, $location, $state, helperService)
    {
        $scope.$state = $state;
        $scope.isMobile = helperService.isMobileOrTablet();
        $scope.getClass = function () {
            var classes = '';
            if($rootScope.pqAppGroup == 'white-label') {
                classes += ' wl';
            }

            var tmp = $location.path();
            if (tmp.substr(0, tmp.length) == '/dashboard') {
                return "wrapper main-container active" + classes
            } else {
                return "wrapper main-container non-active" + classes
            }
        };

        $scope.getStyle = function () {
            if ($rootScope.branding === undefined) {
                return;
            }

            if ($rootScope.branding.background_file_url.length < 3) {
                return;
            }

            return {
                'background' : 'url(' + $rootScope.branding.background_file_url + ')',
                'background-size': 'cover',
            };
        };

        $scope.isSidebar = false;

        $scope.isSidebarOpen = () => {
            let isOpened = !!$state.$current.views['sidebar@'];
            $scope.isSidebar = isOpened;

            if (isOpened) {
                $('#angularjs-container').css('overflow', 'hidden');
            } else {
                $('#angularjs-container').css('overflow', 'unset');
            }
            return isOpened;
        };

        $scope.back = e => {
            if (e.target === e.currentTarget) {
                window.history.back();
            }
        };
    }

}());
