(function() {
    'use strict';

    angular
    .module('app')
    .controller('DocumentRequestsController', documentRequestsController);

    function documentRequestsController($scope,$state,$rootScope,$http) {
        $scope.requests = [];

        $scope.getRequests = function(){
            $http.post('/api/v1/document/get-requests').success(function(data) {
                if (!data.success) {
                    $state.go('main.documents');
                }

                $scope.requests = data.data.requests.map(item => {
                    item.created_at = moment(item.created_at).format('MMMM DD YYYY, h:mm a');
                    return item;
                });
            });
        };

        $scope.cancelRequest = function(request){
            if (!confirm('Are you sure you want to cancel this Document Request?')) {
                return;
            }
            $http.post('/api/v1/document/cancel-request/' + request.id).success(function(data) {
                if (!data.success) {
                    toastr.error('Error cancelling request');
                    return
                }

                $scope.requests = data.data.requests.map(item => {
                    item.created_at = moment(item.created_at).format('MMMM DD YYYY, h:mm a');
                    return item;
                });
            });
        };

        $scope.getRequests();

        $scope.goToRequest = function(request) {
            $state.go('main.documentFulfill', {id: request.id});
        };
    }
}());
