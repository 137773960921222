(function() {
    'use strict';

    angular
    .module('app')
    .controller('AmenityBookController', amenityBookController);

    function amenityBookController($scope,$http,$state,$stateParams,$rootScope,$timeout, langService, jwtService) {
        $scope.t = langService.t('amenities');
        $scope.amenities = [];
        $scope.bookings = [];

        $scope.token = jwtService.getToken() ? jwtService.getToken() : '';

        $scope.ref = {
            property_id: 0
        };

        $scope.properties = [];

        $scope.getProperties = function() {
            $http.post('/api/v1/amenity/get-properties').success(function(data) {
                $scope.properties = data.data.properties;
                $scope.ref.property_id = $scope.properties[0].id;

                $scope.getAmenities();
            });
        }

        $scope.getAmenities = function(){
            $http.post('/api/v1/amenity/get-list-for-booking', {property_id: $scope.ref.property_id}).success(function(data) {
                if (!data.success) {
                    toastr.error('Error getting amenities');
                    return 1;
                }

                $scope.amenities = data.data.amenities;

            });
        }

        $scope.getBookings = function(){
            $http.post('/api/v1/amenity/booking/list').success(function(data) {
                if (!data.success) {
                    toastr.error('Error getting bookings');
                }

                $scope.bookings = data.data;

                if ($stateParams.ticket_booking_id > 0) {
                    angular.forEach($scope.bookings, function(booking, key){
                        if (booking.id == $stateParams.ticket_booking_id) {
                            $scope.showBookingTicket(key);
                        }
                    });
                }
            });
        }

        $scope.cancelBooking = function(index){
            if(!confirm($rootScope.trans('amenities.are_you_sure'))) {
                return;
            }

            $http.post('/api/v1/amenity/booking/cancel/' + $scope.bookings[index].id).success(function(data) {
                if (!data.success) {
                    toastr.error('Error cancelling booking');
                }

                $scope.bookings.splice(index, 1);
            });
        }

        $scope.showBookingTicket = function(index){
            var booking = $scope.bookings[index];

            // name
            var name = booking.amenity.name;
            if (booking.amenity.quantity > 1) {
                if (booking.room.name) {
                    name = name + ' - ' + booking.room.name;
                } else {
                    name = name + ' - ' + booking.room.id;
                }
            }
            $('#mbooking_name').html(name);

            // date string
            var date_string = booking.date_string;
            $('#mbooking_date_string').html(date_string);

            // ticket code
            var ticket = booking.ticket_code;
            $('#mbooking_ticket_code').html(ticket);

            // show the moddal
            $('#booking-ticket').appendTo('body').modal('show');
        }


        $http.post('/api/v1/amenity/is-enabled').success(function(data) {
            if (data.data.enabled == 0) {
                // not enabled for this user
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            // amenities is enabled for this users property(s), lets proceed
            if ($rootScope.authUser.permissions != 'tenant') {
                $scope.getProperties();
            } else {
                $scope.getAmenities();
                $scope.getBookings();
            }
        });
    }
}());
