(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationCreateCompaniesController', leaseApplicationCreateCompaniesController);

    function leaseApplicationCreateCompaniesController($scope,$http,$state,$rootScope,$timeout) {
        $scope.companies = [];

        if (window.location.host.indexOf('leasing') == -1) {
            window.location.href = window.location.href.replace('.payquad', '.leasing.payquad');
        }

        $scope.getCompanies = function(){
            $http.post('/api/v1/lease-application/get-companies').success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Companies");
                }

                // lets populate stuff
                $scope.companies = data.data.companies;
            });
        }

        $scope.getCompanies();
    }
}());
