(function() {

    'use strict';

    angular
        .module('app')
        .controller('MerchantAccountSummaryController', MerchantAccountSummaryController);

    function MerchantAccountSummaryController($scope, $state, $stateParams) {
      console.log($stateParams);
    }

}());