(function () {
    'use strict';

    angular
        .module('app.filterBar')
        .directive('filterBarDataTables', filterBarDataTables);

    function filterBarDataTables() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/filter-bar/filter-bar/filter-bar.html',
            scope: {
                filterItems: '='
            },
            link: function ($scope) {

                $('#filterByPaymentStatus li').on('click', function () {
                    $scope.applyFilterToItems('filterByPaymentStatus_' + $(this).attr('id'));
                });

                $scope.applyFilterToItems = function (customFilter) {
                    var filters = $scope.filterItems.slice();
                    if (customFilter !== undefined) {
                        filters.push(customFilter);
                    } else if ($('#filterByPaymentStatus li.active').length > 0) {
                        filters.push('filterByPaymentStatus_' + $('#filterByPaymentStatus li.active').attr('id'));
                    }
                    var table = $('#dataTable').DataTable();
                    table.search(filters.join('^^')).draw();
                };

                $scope.addFilter = function (filter) {
                    // Add Filter
                    if (filter === undefined)
                        return;
                    if (!_.contains($scope.filterItems, filter)) {
                        $scope.filterItems.push(filter);
                    }
                    $scope.applyFilterToItems();
                    // Clear input box
                    $scope.filterItem = '';
                };

                $scope.removeFilter = function (filter) {
                    _.pull($scope.filterItems, filter);
                    $scope.applyFilterToItems();
                };
            }
        };
    }

})();
