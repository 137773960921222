(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqLeaseWidget', pqLeaseWidget);

    function pqLeaseWidget(helperService) {
        const isMobile = helperService.isMobileOrTablet();

        return {
            restrict: 'E',
            controller: 'PqLeaseWidgetController',
            templateUrl: function () {
                if (!isMobile) {
                    return 'scripts/modules/lease-application/lease-widget/lease-widget.directive.html';
                } else {
                    return 'scripts/modules/lease-application/lease-widget/lease-widget-mobile.directive.html';
                }
            },
        };
    }

    angular
        .module('app')
        .controller('PqLeaseWidgetController', function ($scope, $state, leaseApplicationService, $http, $timeout, $rootScope, langService) {
            $scope.t = langService.t('lease_app');
            $scope.stats = {
                prospects: 0,
                showings: 0,
                pending_approval: 0,
                pending_signature: 0
            };
            leaseApplicationService.stats().then(stats => $scope.stats = stats);

            $scope.goTo = (state, tag, statuses) => {
                let tags = [];
                for(var i = 0; i < statuses.length; i++){
                    tags.push(`${statuses[i]}:status`);
                }
                // tags.push(`active:status`);
                localStorage.setItem('filter_tags' + tag, JSON.stringify({
                    header: 'status',
                    tags: tags,
                }))
                $state.go(state, {}, {reload:true})
            };

            $scope.goToMobileLeaseForReviewStatus = () => {
                $rootScope.isFromCardBoxReview = true;
                $state.go('main.leaseApplicationList', {}, {reload:true});
            }

            $scope.goToMobileLeaseForAcceptedStatus = () => {
                $rootScope.isFromCardBoxAccepted = true;
                $state.go('main.leaseApplicationList', {}, {reload:true});
            }

            $scope.goToMobileShowingsForBookedStatus = () => {
                $rootScope.isFromShowingsCardBoxBooked = true;
                $state.go('main.showings', {}, {reload:true});
            }
        });
})();
