(function() {
    
    'use strict';

	angular
		.module('app.routes')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/tenant/';

		$stateProvider
            .state('main.tenants', {
                abstract: true,
                url: '/tenants'
            })
			.state('main.tenants.manage', {
				url: '/manage',
				views: {
					"body@": {
						templateUrl: url + 'index/tenant.manage.html',
                        controller: 'TenantIndexController',
                        controllerAs: 'vm'
					}
				}
			})
            .state('main.tenants.approvals', {
                url: '/approvals',
                views: {
                    "body@": {
                        templateUrl: url + 'index/tenant.approval.html',
                        controller: 'TenantApprovalController',
                        controllerAs: 'vm',
                        resolve: {
                            resolvedTenantData: getTenantData
                        }
                    }
                }
            })
            .state('main.tenantCreate', {
                url: '/tenants/create',
                views: {
                    "body@": {
                        templateUrl: url + 'create/tenant.create.html',
                        controller: 'TenantCreateController',
                        controllerAs: 'vm',
                        resolve: {
                            resolvedTenantData: getTenantData,
                            resolvedTenantProperties: getTenantProperties,
                        }
                    }
                },
                params: {receipt: null}
            })
			.state('main.tenantEdit', {
				url: '/tenants/:tenantId/edit',
				views: {
					"body@": {
						templateUrl: url + 'edit/tenant.edit.html',
						controller: 'TenantEditController',
                        controllerAs: 'vm',
                        resolve: {
                            resolvedTenantData: getTenantData,
                            resolvedTenants: function ($q, tenantService) {
                                return $q.when(tenantService.getMyTenants());
                            }
                        }
					}
				}
			});

        function getTenantData(tenantData, $stateParams)
        {
            return tenantData.load($stateParams.tenantId);
        }

        function getTenantProperties(tenantData, $stateParams)
        {
            return tenantData.loadProperties($stateParams.tenantId);
        }

	}

}());
