(function () {

    'use strict';

    angular
        .module('app')
        .controller('InspectionIndexController', function ($scope, $state, $http, helperService, propertyService, $location) {
            $scope.state = $state;

            $scope.filteredRequests = [];

            $scope.isMobile = helperService.isMobileOrTablet() || Boolean(window.Capacitor.isNativePlatform());
            $scope.propertiesFilterString = {value: ''};

            // filter

            $scope.isRecurringPage = $location.path().includes('recurring');

            $scope.badge_counts = {
                open: 0,
                in_progress: 0,
                completed: 0,
                scheduled: 0,
                approvals: 0,
                rejected: 0,
                canceled: 0
            };

            $scope.managerMobile = {
                filter: {
                    text: '',
                    timeline: {
                        today: false,
                        '1dayago': false,
                        '1weekago': false,
                        '2weeksago': false,
                        '1monthago': false,
                        '2monthago': false,
                        '4monthago': false,
                        '6monthago': false
                    },
                    status: {
                        inProgress: false,
                        completed: false,
                        scheduled: false,
                        canceled: false
                    },
                },
                loading: {
                    tickets: false,
                    properties: false
                }
            };

            $scope.requests = [];
            $scope.getManagerRequests = function () {
                $scope.managerMobile.loading.tickets = true;
                $http.get('/api/v1/inspection/manage-mobile').success(function (response) {
                    $scope.allRequests = response.data
                    $scope.filteredRequests = response.data

                    $scope.viewbyCan = 5;
                    $scope.totalItemsCan = $scope.filteredRequests.length;
                    $scope.currentPageCan = 1;
                    $scope.itemsPerPageCan = $scope.viewbyCan;
                    $scope.maxSizeCan = 5; //Number of pager buttons to show

                    $scope.setPageCan = function (pageNo) {
                        $scope.currentPageCan = pageNo;
                    };

                    $scope.setItemsPerPageCan = function (num) {
                        $scope.itemsPerPageCan = num;
                        $scope.currentPageCan = 1; //reset to first page
                    };
                    $scope.managerMobile.loading.tickets = false;
                });
            };

            $scope.getManagerRequests();

            angular.element($('body')).scope().state = $state;

            $scope.managerMobileFilterBy = () => {
                const t = $scope.managerMobile.filter.text.toLowerCase();
                const filteredResults = $scope.allRequests.filter(item => {
                    const filterByTextResult = ~item.property.toLowerCase().indexOf(t)
                        || (item.unit && ~item.unit.indexOf(t))
                        || ~item.status.toLowerCase().indexOf(t)
                        || ~item.description.toLowerCase().indexOf(t)
                        || ~item.name.toLowerCase().indexOf(t)
                        || ~item.id.toString().indexOf(t)

                    const filterByDate = ($scope.managerMobile.filter.timeline.today ? moment(item.date).startOf('day').isSame(moment().startOf('day')): false)
                        || ($scope.managerMobile.filter.timeline["1dayago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'days')) : false)
                        || ($scope.managerMobile.filter.timeline["1weekago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'weeks')) : false)
                        || ($scope.managerMobile.filter.timeline["2weeksago"] ? moment(item.date).isAfter(moment().startOf('day').add(-2, 'weeks')) : false)
                        || ($scope.managerMobile.filter.timeline["1monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'month')) : false)
                        || ($scope.managerMobile.filter.timeline["2monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-2, 'month')) : false)
                        || ($scope.managerMobile.filter.timeline["4monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-4, 'month')) : false)
                        || ($scope.managerMobile.filter.timeline["6monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-6, 'month')) : false);
                    const filterByStatus = ($scope.managerMobile.filter.status.inProgress ? item.status === 'In Progress' : false)
                        || ($scope.managerMobile.filter.status.completed ? item.status === 'Completed' : false)
                        || ($scope.managerMobile.filter.status.scheduled ? item.status === 'Scheduled' : false)
                        || ($scope.managerMobile.filter.status.canceled ? item.status === 'Canceled' : false);
                    const filterByProperty = $scope.properties.some(e => {
                        return e.selected && e.name.toLowerCase() === item.property.toLowerCase();
                    });
                    const filterByUnit = $scope.units.some(e => {
                        return e.selected && e.unit == item.unit;
                    });

                    return (Object.values($scope.managerMobile.filter.status).some(e => e) ? filterByStatus : true )
                        && (Object.values($scope.units).some(e => e.selected) ? filterByUnit : true )
                        && (Object.values($scope.managerMobile.filter.timeline).some(e => e) ? filterByDate : true )
                        && (Object.values($scope.properties).some(e => e.selected) ? filterByProperty : true )
                        && (filterByTextResult !== -1 ? filterByTextResult : true);
                });

                $scope.filteredRequests = filteredResults;
            }

            $scope.showFilterModal = (type) => {
                if (type === 'timeline') {
                    $('#mobileManagerFilterTimeline').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
                if (type === 'status') {
                    $('#mobileManagerFilterStatus').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
                if (type === 'unit') {
                    $('#mobileManagerFilterUnit').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
                if (type === 'properties') {
                    $scope.getProperties();
                    $('#mobileManagerFilterProperties').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
            }

            $scope.properties = [];
            $scope.propertiesFiltered = [];
            $scope.getProperties = function () {
                if ($scope.properties.length === 0){
                    $scope.managerMobile.loading.properties = true;
                    $http.post('/api/v1/get-filter-properties').success(function (data) {
                        $scope.properties = data.data.properties;
                        $scope.propertiesFiltered = data.data.properties;
                        $scope.managerMobile.loading.properties = false;
                    });
                }
            };

            $scope.filterProperties = (e) => {
                const search = $scope.propertiesFilterString.value.toLowerCase();
                $scope.propertiesFiltered = $scope.properties.filter(propeerty => ~propeerty.name.toLowerCase().indexOf(search));
            }

            $scope.units = [];

            $scope.getUnits = propertyIds => {
                propertyService.getUnitsForProperties(propertyIds).then(data => {
                    $scope.units = data;
                });
            };

            $scope.propertyChanged = () => {
                let propertyIds = [];
                $scope.properties.map(property => {
                    if (property.selected) {
                        propertyIds.push(property.id);
                    }
                });

                if (propertyIds) {
                    $scope.getUnits(propertyIds);
                }
            }

            $scope.getUnitBtnClass = () => {
                return {"active": Object.values($scope.units).some(e => e.selected)};
            }

            $scope.getStatusBtnClass = () => {
                return {"active": Object.values($scope.managerMobile.filter.status).some(e => e)};
            }

            $scope.getTimelineBtnClass = () => {
                return {"active": Object.values($scope.managerMobile.filter.timeline).some(e => e)};
            }

            $scope.getPropertyBtnClass = () => {
                return {"active": Object.values($scope.properties).some(e => e.selected)};
            }

        });

}());
