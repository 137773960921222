(function() {

	'use strict';

    angular
		.module('app')
		.controller('TenantCreateController', TenantCreateController);

	function TenantCreateController($state, tenantService, resolvedTenantProperties,  resolvedTenantData, $scope, $http, $rootScope, $stateParams) {

        if ($rootScope.authUser.permissions == 'junior_manager' && $rootScope.authUser.can_create_tenant == 0) {
            $state.go('main.tenants.manage', {redirect: true});
        }

        var vm = this;

        vm.properties = resolvedTenantProperties.properties;

        vm.tenantData = {};
        vm.isUploading = false;
		
	if (FileReader.prototype.readAsBinaryString === undefined) {
		console.log('ie11 fix');
		FileReader.prototype.readAsBinaryString = function (fileData) {
			var binary = "";
			var pt = this; // eslint-disable-line angular/controller-as-vm
			var reader = new FileReader();
			reader.onload = function (e) {
				var bytes = new Uint8Array(reader.result);
				var length = bytes.byteLength;
				for (var i = 0; i < length; i++) {
					binary += String.fromCharCode(bytes[i]);
				}
				//pt.result  - readonly so assign content to another property
				pt.content = binary;
				pt.onloadend(); // thanks to @Denis comment
			}
			reader.readAsArrayBuffer(fileData);
		}
	}
		
		vm.quickpays = [];
		vm.quickpays_selected = [];
		vm.checkquickpay = () => 
		{
			$http.get('/api/v1/quick-pay/email-exists/'+vm.tenantData.email)
				.then(function(data) {
					vm.quickpays   = data.data.quickpays;
				}).then(function() {
					if($stateParams.receipt){
						vm.quickpays_selected = Array(vm.quickpays.length).fill(true);
					}else{
						vm.quickpays_selected = Array(vm.quickpays.length).fill(false);
					}
				})
		}

		if($stateParams.receipt){ //if we want this linked with a quickpay receipt
			vm.tenantData.first_name 	= $stateParams.receipt.tenant.first_name;
			vm.tenantData.last_name  	= $stateParams.receipt.tenant.last_name;
			vm.tenantData.email 		= $stateParams.receipt.tenant.email;
			vm.selectedProperty  		= $stateParams.receipt.property;
			vm.checkquickpay();
		}
		
		vm.upload = function(){
            vm.isUploading = true;
		  var f = document.getElementById('csv').files[0],
			  r = new FileReader();
			  r.readAsBinaryString(f);
		  r.onloadend = function(e){
			//var data = e.target.result;
			 if (!e) {
				var data = r.content;
			}
			else {
				var data = e.target.result;
			}		
			$http.post('/api/v1/tenants/create-by-admin-bulk',{csv:data})
			.success(function(data){
                    if(data.success) {
                        toastr.success('Bulk upload processed');
                        vm.isUploading = false;
                        console.log("upload done",vm.isUploading);
						var i = 0;
                        var html = '<div style="margin-left:20px;text-align:left;">\
						<div class="alert alert-info no-print " role="alert">This information will be lost when you leave the page.<br>\
						Please consider <b><u><a href="javascript:printResults()">printing</a></u></b> or copying it for your records</div>\
						<h2>Bulk Upload Results</h2>\
						<b>New Residents: '+data.data.residents+'<br>\
						   New PAD Agreements: '+data.data.pads+'</b><br><br>\
						<table class="table table-hover align-left"><tr><th>Row</th><th>Error</th></tr>';
						for(i in data.data.errors){
							html += "<tr><td>"+i+"</td><td>"+data.data.errors[i][0]+"</td></tr>";
						}
						html +="</table></div>"
						$('#bulkUploadContaier').html(html);
                    }
                    else
                        toastr.error(data.errors);
				})
				.error(function(data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error('Error registering a new resident');
				});
		  }
		  
		};
		
		vm.creatingTenant = false;
        vm.createTenant = function()
        {
			if(vm.creatingTenant){
				return; //prevent double clicks
			}
			vm.creatingTenant = true;
			
            if(vm.selectedProperty === undefined){
                toastr.error('Please select a property');
				vm.creatingTenant = false;
                return false;
            }

            vm.tenantData.property_id = vm.selectedProperty.id;
            vm.tenantData.pad_agreement = vm.tenantData.pad;
			vm.tenantData.quickpay_ids = [];
			for(var i = 0; i < vm.quickpays.length; i++){
				if(vm.quickpays_selected[i]){
					vm.tenantData.quickpay_ids.push(vm.quickpays[i].id);
				}
			}

			tenantService.createTenant(vm.tenantData)
				.success(function(data)
                {
					vm.creatingTenant = false;
                    if(data.success) {
                        $scope.registerResident.$setPristine();
                        vm.tenantData = {};
						
                        toastr.success(data.message,'Resident Registered',{
							closeButton : true,
							timeOut :0,
							extendedTimeOut: 0
						});

                    }
                    else
                        toastr.error(data.errors);
				})
				.error(function(data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

					vm.creatingTenant = false;
                    toastr.error('Error registering a new resident');
				});
		};
    }
}());
