(function() {

    'use strict';

    angular
        .module('app')
        .factory('navbarService', navbarService);

    function navbarService($http) {

        var base_url = 'api/v1';
        var local_url = '/subdomain/message';

        return {
            getSubdomainMessage: getSubdomainMessage,
        };

        function getSubdomainMessage()
        {
            return $http.get(base_url + local_url);
        }
    }

}());
