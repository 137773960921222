(function () {

    'use strict';

    angular
        .module('app')
        .controller('ManagerCreateController', managerCreateController);

    function managerCreateController($state, managerService, $http, $scope, $rootScope) {

        var vm = this;
        $http.get('/api/v1/companies').success(function (data) {
            vm.companies = data.data;
        });

        $scope.companyPermissionsPresets = [];

        if ($rootScope.authUser.company_id) {
            $http.get('/api/v1/companies/0').success(function (data) {
                $scope.companyPermissionsPresets = data.data.manager_setting_presets;
            });
        }

        $scope.companySelected = (compny) => {
            if (vm.companies) {
                const company = vm.companies.filter(item => item.id == compny)
                $scope.companyPermissionsPresets = company[0].manager_setting_presets;
                vm.managerData.permission_preset_id = null;
            }
        }

        // creating a manager
        vm.managerData = {
            isWelcome: 1,
            permission_preset_id: null
        };

        vm.createManager = function () {
            managerService.createManager(vm.managerData)
                .success(function (data) {
                    if (data.success) {
                        $state.go('main.managerEdit', {managerId: data.data.id});
                        toastr.success('User account created');
                    } else {
                        toastr.error(data.errors[0]);
                    }
                })
                .error(function (data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error('failed to create user account');
                });
        };

    }

}());
