(function() {
    
    'use strict';

	angular
		.module('app.routes')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/management-resources/views/';

		$stateProvider
			.state('main.management-resources', {
				url: '/management-resources',
				views: {
					"body@": {
						templateUrl: url + 'management-resources.index.html'
					}
				}
			});

	}

}());
