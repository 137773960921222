(function() {
    
    'use strict';

	angular
		.module('app.routes')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/company/';

		$stateProvider
			.state('main.companyEdit', {
				url: '/companies/:companyId/edit',
				views: {
					"body@": {
						templateUrl: url + 'edit/company.edit.html',
						controller: 'CompanyEditController',
                        controllerAs: 'vm',
                        resolve: {
                            resolvedCompanyData: getCompanyData
                        }
					}
				}
			});

        /* @ngInject */
        function getCompanyData(companyData, $stateParams)
        {
            return companyData.load($stateParams.companyId);
        }

	}

}());
