(function () {
    'use strict';

    angular
        .module('app')
        .controller('QuickPayEditController', quickPayEditController);

    function quickPayEditController($scope, $http, $state, $stateParams, $rootScope, $timeout, $location) {
        $scope.quick_pay = {
            id: parseInt($stateParams.id),
        };

        $scope.getQuickPay = function () {
            $http.post('/api/v1/quick-pay/get/' + $scope.quick_pay.id).success(function (data) {
                if (!data.success) {
                    $state.go('main.quickPay');
                }

                const quick_pay = data.data.quick_pay;
                $scope.quick_pay = quick_pay;
                $scope.quick_pay.expires_at = moment(quick_pay.expires_at).format('MM/DD/YYYY');

                if($location.search().reopen == 'true'){
                    $scope.quick_pay.expires_at = moment().format('MM/DD/YYYY');
                }

                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        if ($scope.quick_pay.id > 0) {
            $scope.getQuickPay();
        }

        $scope.deleteQuickPay = function () {
            if (!confirm('Are you sure you want to delete this quick pay?')) {
                return; // they did not confirm
            }
            $scope.creatingQuickPay = true;

            $http.post('/api/v1/quick-pay/delete/' + $scope.quick_pay.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, 'Error deleting quick pay');
                    return;
                }

                $state.go('main.quickPay');
                toastr.success('quick pay deleted');
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingQuickPay = false;
                toastr.error(data.error.message);
            });
        };

        $scope.saveQuickPay = function () {
            $scope.updatingQuickPay = true;

            $http.post('/api/v1/quick-pay/update/' + $scope.quick_pay.id, $scope.quick_pay).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error updating quick pay");
                    return;
                }

                $scope.updatingQuickPay = false;
                $state.go('main.quickPay');
                toastr.success('quick pay updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.udpatingQuickPay = false;
                toastr.error(data.error.message);
            });
        };

        $scope.properties = [];
        $scope.getProperties = function(){
            $http.post('/api/v1/quick-pay/get-properties').success(function(data) {
                if (!data.success) {
                    toastr.error('Error getting properties');
                    return;
                }

                $scope.properties = data.data.properties;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getProperties();
    }
}());
