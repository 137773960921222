(function() {

    'use strict';

    angular
        .module('app')
        .factory('ReportsService', ReportsService);

    function ReportsService($http) {

        var base_url = '/api/v1/reports';

        return {
            getFilters: () => $http.get(`${base_url}/filter`).then(r => r.data),
            getTerminals: () => $http.get(`${base_url}/terminals`).then(r => r.data),
            getAmenities: () => $http.get(`${base_url}/amenity-filter`).then(r => r.data)
        }

    }

}());
