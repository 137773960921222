(function () {

    'use strict';

    angular
        .module('app')
        .controller('AccountInformationController', AccountInformationController);


    angular.module('app').directive('numbersOnly', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {
                function fromUser(text) {
                    if (text) {
                        var transformedInput = text.replace(/[^0-9]/g, '');

                        if (transformedInput !== text) {
                            ngModelCtrl.$setViewValue(transformedInput);
                            ngModelCtrl.$render();
                        }
                        return transformedInput;
                    }
                    return undefined;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    });

    function AccountInformationController($scope, $state, accountService, $http, $rootScope, $stateParams, $timeout, langService) {
        $scope.t = langService.t('profile');
        $scope.signRef = {};

        var vm = this;

        if ($stateParams.section === 'notification') {
            $('.modal-backdrop').remove();
            $timeout(function () {
                $('#panel5').collapse('toggle');
            }, 500);
        }

        $timeout(function () {
            let body = document.getElementsByTagName('body');
            if (body[0].classList.contains('modal-open')) {
                body[0].classList.remove('modal-open');
            }
        },500);

        vm.accountInformationData = {
            password_old: '',
            password_new: '',
            password_new_confirmation: ''
        };

        vm.selectedProperty = 0;
        $scope.forceRender = () => {
            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        };

        $scope.updateSignature = () => {
            $scope.openSignPopup();
        }

        $scope.signature = null;
        $scope.signSave = (sign, name) => {
            $scope.signature = sign;
            vm.accountInformation.signature_path = null;
            return true;
        }

        accountService.getAccountInformation()
            .success(function (data) {
                vm.accountInformation = data.data;


                if (vm.accountInformation['2_fa_mandatory'] && !vm.accountInformation.user['2_fa_activated_at']) {
                    $timeout(function () {
                        $('#panel4').collapse('toggle');
                        $('#panel2fa').collapse('toggle');
                    }, 100);
                }
                if (!vm.accountInformation.user.two_factor_auth) {
                    vm.accountInformation.user.two_factor_auth = 'disabled'
                }
            });

        // account/information/types/account.information.tenant.html
        vm.updateAccountInformation = function () {
            const input = {
                first_name: vm.accountInformation.first_name,
                last_name: vm.accountInformation.last_name,
                email: vm.accountInformation.user.email,
                phone_number: vm.accountInformation.phone_number,
                work_number: vm.accountInformation.work_number,
                cell_number: vm.accountInformation.cell_number,
                fax_number: vm.accountInformation.fax_number,
                management_news: vm.accountInformation.account.management_news,
                payquad_news: vm.accountInformation.account.payquad_news,
                reminders: vm.accountInformation.account.reminders,
                text_messages: vm.accountInformation.account.text_messages,
                payment_processes: vm.accountInformation.account.payment_processes,
                signature: $scope.signature
            };

            accountService.updateAccountInformation(input)
                .success(function (data) {
                    if (data.success) {
                        $state.go('main.dashboard');
                        toastr.success($rootScope.trans('profile.update_success'));
                    } else if (data.success === false) {

                        _(data.errors).forEach(function (n) {
                            toastr.error(n, $rootScope.trans('profile.update_fail'));
                        }).value();
                    }
                });
        };
        // account/information/types/account.information.manager.html
        vm.updateManager = function (id) {
            let showingsError = '';
            if (vm.accountInformation.manager != undefined)
                vm.accountInformation.manager.properties.forEach(item => {
                    if (item.validation && !item.validation.isValid) {
                        showingsError = item.validation.error;
                    }
                });
            if (showingsError.length) {
                toastr.error(showingsError);
                return;
            }

            console.log(vm.accountInformation)
            const input = {
                password_old: vm.accountInformationData.password_old,
                password_new: vm.accountInformationData.password_new,
                password_new_confirmation: vm.accountInformationData.password_new_confirmation,
                first_name: $rootScope.authUser.first_name,
                last_name: $rootScope.authUser.last_name,
                email: vm.accountInformation.user.email,
                properties: null,
                signature: vm.accountInformation.manager ? vm.accountInformation.manager.signature : null,
                calendars: {
                    showings_calendar_synced: vm.accountInformation.calendar_sync_settings?.showings_calendar_synced,
                    maintenance_requests_calendar_synced: vm.accountInformation.calendar_sync_settings?.maintenance_requests_calendar_synced,
                    amenities_calendar_synced: vm.accountInformation.calendar_sync_settings?.amenities_calendar_synced,
                },
                document_signature: $scope.signature,
            };

            if (vm.accountInformation.manager != undefined) {
                input.properties = vm.accountInformation.manager.properties
            }
            $http.post('/api/v1/accounts/update', input)
                .success(function (data) {
                    if (data.success) {
                        // $state.go('main.dashboard');
                        toastr.success($rootScope.trans('profile.update_success'));
                    } else if (data.success === false) {
                        _(data.errors).forEach(function (n) {
                            toastr.error(n, $rootScope.trans('profile.update_fail'));
                        }).value();
                    }
                });

        };
        vm.getOutlookPermission = function(e)
        {
            var flag = document.getElementById("outlookBtn").getAttribute('data-id');
            if (vm.accountInformation.outlook_enable==0 && flag!='1'){
                var myWin = window.open('//'+window.location.host+'/'+vm.accountInformation.outlook_auth_url, "", "width=800, height=600");
            } else {
                $http.get('//'+window.location.host+'/'+vm.accountInformation.outlook_signout)
                    .success(function (data) {
                        if (data.success) {
                            document.getElementById("outlookBtn").setAttribute('data-id','');
                            document.getElementById("outlookBtn").innerHTML="Enable Outlook integration";
                            vm.accountInformation.outlook_enable = 0;
                            toastr.error('Outlook integration has disabled');
                        }
                    });

            }
        };
        vm.changePassword = function () {
            var input = {
                password_old: vm.accountInformationData.password_old,
                password_new: vm.accountInformationData.password_new,
                password_new_confirmation: vm.accountInformationData.password_new_confirmation
            };


            accountService.changePassword(input)
                .success(function (data) {
                    if (data.success) {

                        toastr.success($rootScope.trans('profile.password_changed'));

                        $state.go('main.dashboard');
                    } else if (data.success === false) {

                        _(data.errors).forEach(function (n) {
                            toastr.error(n, $rootScope.trans('profile.password_error'));
                        }).value();
                    }
                });
        };

        $scope.openSignPopup = () => {
            $scope.signRef.open();
        };

        $scope.getManagerSignature = () => {
            return `${pqDomain}/api/v1/get-manager-signature?cordova=${(!!window.Capacitor.isNativePlatform() ? '1':'0')}&token=${pqCachedToken}`;
        }

        $scope.optQR = '';
        vm.otpcode = '';

        $scope.verifyOTP = () => {
            $http.post('/api/v1/users/verify-otp', {
                'code': vm.otpcode
            })
                .success(function (data) {
                    if (data) {
                        console.log(data)
                        if (data.data.success) {
                            toastr.success('Time-based One-time Password Enabled');

                            vm.accountInformation.user['2_fa_activated_at'] = new Date()
                        } else {
                            toastr.error('Verification Code is Incorrect');
                        }
                    }
                }).error(() => {
                toastr.error('Verification Code is Incorrect');

            });
        }

        $scope.disableOTP = () => {
            $http.post('/api/v1/users/otp-disable', {})
                .success(function (data) {
                    if (data) {
                        console.log(data)
                        if (data.data.success) {
                            toastr.success('Time-based One-time Password Disabled');
                            vm.accountInformation.user['2_fa_activated_at'] = null;
                            vm.accountInformation.user['two_factor_auth'] = null;
                            vm.accountInformation.user.two_factor_auth = 'disabled'
                        } else {
                        }
                    }
                });
        }

        $scope.$watch('vm.accountInformation.user.two_factor_auth', function(newValue, oldValue){
            if(newValue == 'time'){
                $http.get('/api/v1/users/opt-qr')
                    .success(function (data) {
                        if (data) {
                            $scope.optQR = data;
                        }
                    });
            } else {
                $scope.optQR = '';
            }
        });

        vm.emailPattern = (function () {
            var regexp = /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/;
            return {
                test: function (value) {
                    if (vm.requireTel === false) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();

        vm.phoneNumberPattern = (function () {
            var regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
            return {
                test: function (value) {
                    if (vm.requireTel === false) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();
    }

}());
function childCall(data) {
    document.getElementById("outlookBtn").innerHTML="Disable Outlook integration";
    document.getElementById("outlookBtn").setAttribute('data-id','1');
    toastr.success('Outlook integration has enabled');
}
