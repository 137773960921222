(function () {

    'use strict';

    angular
        .module('app')
        .controller('RewardsController', RewardsController);

    function RewardsController($scope, $state, $stateParams, $http, $rootScope, langService) {

        $scope.t = langService.t('rewards');
        $scope.perks = [];
        $scope.tab = 'property';


        $scope.setRewardsTab = tab => $scope.tab = tab;

        $http.get('api/v1/perks')
            .then(function (obj) {
                $scope.perks = obj.data;
            });

        $scope.openLink = function(url) {
            if(window.Capacitor.isNativePlatform()) {
                try {
                    window.Capacitor.Plugins.Browser.open({url: url});
                } catch (e) {
                    alert(e)
                }
            } else {
                window.open(url, '_blank');
            }
        }

    }

}());
