(function () {

    'use strict';

    angular
        .module('app')
        .factory('routerService', routerService);

    function routerService($rootScope) {

        const permissionToState = {
            'maintenance-requests':'main.maintenanceRequests',
            amenities: 'main.amenities',
            communications: 'main.messagesInbox',
            documents: 'main.documents',
            inspections: 'main.inspections',
            'lease-applications': 'main.leaseApplicationList',
            parcels: 'main.parcels',
            payments: 'main.payments',
            properties: 'main.properties',
            reports: 'main.reports',
            residents: 'main.tenants',
            surveys:'main.surveys'
        };

        return {
            getStateByPermission: permission => {
                return permissionToState[permission];
            },
            userHasOnlyOnePermission: () => {
                if(!$rootScope.authUser){
                    return false;
                }
                const permissions = $rootScope.authUser.user_permissions;
                let countOfPermissions = 0;
                let lastPermission = '';
                Object.keys(permissions).forEach(key => {
                    if (permissions[key]) {
                        lastPermission = key;
                        countOfPermissions++;
                    }
                });

                return countOfPermissions === 1 ? lastPermission : false;
            }
        };

    }

}());
