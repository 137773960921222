(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/quick-pay/';

        $stateProvider
            .state('main.quickPay', {
                url: '/quick-pays',
                views: {
                    'body@': {
                        templateUrl: url + 'index/index.html',
                        controller: 'QuickPayIndexController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.quickPayCompleted', {
                url: '/quick-pays-completed',
                views: {
                    'body@': {
                        templateUrl: url + 'completed/completed.html',
                        controller: 'QuickPayCompletedController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.quickPayEdit', {
                url: '/quick-pay/edit/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'edit/edit.html',
                        controller: 'QuickPayEditController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.quickPayForm', {
                url: '/quick-pay/form/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'form/form.html',
                        controller: 'QuickPayFormController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.quickPayReceipt', {
                url: '/quick-pay/receipt/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'receipt/receipt.html',
                        controller: 'QuickPayReceiptController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.quickPayStart', {
                url: '/quick-pay/start/:companyId',
                views: {
                    "body@": {
                        templateUrl: url + 'start/start.html',
                        controller: 'QuickPayStartController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
}());
