(function () {

    'use strict';

    angular
        .module('app')
        .factory('langService', langService);

    function langService($rootScope) {
        return {
            t: prefix => (key, params) => {
                if (params) {
                    return $rootScope.trans(`${prefix}.${key}`, params);
                } else {
                    return $rootScope.trans(`${prefix}.${key}`);
                }

            }
        };
    }

}());
