(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/vendor/';

        $stateProvider
            .state('main.vendorCreate', {
                url: '/companies/:companyId/vendors/create',
                views: {
                    "body@": {
                        templateUrl: url + 'create/create.html',
                        controller: 'VendorCreateController',
                        controllerAs: 'vm'
                    }
                }
            })
			.state('main.vendorEdit', {
				url: '/vendors/:id',
				views: {
					"body@": {
						templateUrl: url + 'edit/edit.html',
						controller: 'VendorEditController',
                        controllerAs: 'vm'
					}
				}
			})
			;

	}

}());
