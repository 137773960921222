(function() {

	'use strict';

    angular
		.module('app')
		.controller('PaymentMethodIndexController', PaymentMethodIndexController);

	function PaymentMethodIndexController($scope, $state, $location, paymentMethodService, $rootScope, langService) {

        $scope.t = langService.t('payments');

        $scope.goTo = id => {
            if(!$rootScope.authUser.login_as){
                $state.go('main.paymentMethodEdit', {paymentMethodId: id});
            }
        };

		paymentMethodService.getAll()
			.success(function(data) {
                console.log('-----------------------------------');
                console.log('----- All Payment Methods Success -');
                console.log('-----------------------------------');
                console.log(data.data);
				$scope.payment_methods = data;

                _.forEach($scope.payment_methods.data, function(n, index) {
                    if (n.type == 'amex') {
                        n.type = 'American Express';
                    }
                    else if (n.type == 'mastercard') {
                        n.type = 'MasterCard';
                    }
                    else if (n.type == 'visa') {
                        n.type = 'Visa';
                    }
                    else if (n.type == 'debit_visa') {
                        n.type = 'Visa/MasterCard Debit';
                    }
                    else if (n.type == 'direct_debit') {
                        n.type = 'Direct Debit';
                    }
                });
			})
			.error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                console.log('----------------------------------');
                console.log('----- All Payment Methods Failed -');
                console.log('----------------------------------');
				console.log(data);
			});

		$scope.getPaymentMethod = function(id) {
			$location.path('payments/methods/' + id + '/edit');
		};
    }

}());
