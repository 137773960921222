(function() {

    'use strict';

    angular
        .module('app')
        .controller('VendorEditController', vendorEditController);

    function vendorEditController($scope,$http,$state,$stateParams,$rootScope,$interval,$timeout) {
		if (!$rootScope.authUser.permissions == 'tenant') {
			 $state.go('main.dashboard');
        }

        $scope.properties = [];

        $scope.vendor = {
            id: $stateParams.id,
            company_id: 0,
            name: '',
            type: '',
            phone: '',
            email: '',
            email_2: '',
            email_3: '',
            properties: []
        };

        $scope.getProperties = function() {
            $http.post('/api/v1/vendor/get-properties/' + $scope.vendor.company_id).success(function(data) {
                $scope.properties = data.data.properties;

                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        }

        $scope.updateVendor = function() {
            $scope.updatingVendor = true;

            $http.post('/api/v1/vendor/update/' + $scope.vendor.id, $scope.vendor).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error updating vendor");
                    return;
                }

                $scope.updatingVendor = false;
                $state.go('main.companyEdit', { companyId: $scope.vendor.company_id });
                toastr.success('Vendor updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.udpatingVendor = false;
                toastr.error(data.error.message);
            });
        }

        $scope.getVendor = function(){
            $http.post('/api/v1/vendor/get/' + $scope.vendor.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.dashboard');
                }

                // lets populate stuff
                var vendor = data.data.vendor;

                $scope.vendor.name = vendor.name;
                $scope.vendor.type = vendor.type;
                $scope.vendor.email = vendor.email;
                $scope.vendor.email_2 = vendor.email_2;
                $scope.vendor.email_3 = vendor.email_3;
                $scope.vendor.phone = vendor.phone;
                $scope.vendor.company_id = vendor.company_id;

                angular.forEach(vendor.properties, function(property, key){
                    $scope.vendor.properties.push(property.id);
                });

                $scope.getProperties();
            });
        }

        $scope.deleteVendor = function() {
            if (!confirm('Are you sure you want to delete this vendor?')) {
                return; // they did not confirm
            }

            $http.post('/api/v1/vendor/delete/' + $scope.vendor.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error deleting vendor");
                    return;
                }

                $state.go('main.companyEdit', { companyId: $scope.vendor.company_dd });
                toastr.success('Vendor deleted');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }


        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.dashboard', { redirect : true });
            return 1;
        }

        $scope.getVendor();
    }
}());
