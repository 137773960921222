(function() {

    'use strict';

    angular
        .module('app')
        .factory('companyService', companyService);

    function companyService($http, $upload, _, $state) {

        var base_url = '/api/v1/';
        var local_url = 'companies';

        return {
            getAllCompanies: getAll,
            getCompanyById: getById,
            createCompany: create,
            updateCompany: update,
            deleteCompany: destroy,
            enableCompany: enable,
            getCompanyWelcomeEmail: getWelcomeEmail
        };

        function getAll()
        {
            return $http.get(base_url + local_url)
                .then(function (object) {
                    return object.data;
                });
        }

        function getById(id)
        {
            return $http.get(base_url + local_url + "/" +id)
                .then(function (object) {
                    return object.data;
                });
        }

        function create(data)
        {
            var files = [];

            // Setup files to be uploaded
            if (_.has(data, 'logo_file')) {
                files.push({
                    file: data['logo_file'],
                    fileName: 'logo_file'
                });
                data['logo_file'] = null;
            }
            if (_.has(data, 'nav_logo_file')) {
                files.push({
                    file: data['nav_logo_file'],
                    fileName: 'nav_logo_file'
                });
                data['nav_logo_file'] = null;
            }
            if (_.has(data, 'background_file')) {
                files.push({
                    file: data['background_file'],
                    fileName: 'background_file'
                });
                data['background_file'] = null;
            }

            var file = _.map(files, function (val) {
                return val.file;
            });

            var fileName = _.map(files, function (val) {
                return val.fileName;
            });

            console.log(data);
            console.log(file);
            console.log(fileName);

            $upload.upload({
                url: base_url + 'companies',
                file: file,
                method: 'POST',
                //headers: { 'put': {'Content-Type': 'application/x-www-form-urlencoded'}}
                //fileName: fileName,
                fileFormDataName: fileName,
                data: data
            }).progress(function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
            }).success(function (data, status, headers, config) {
                if(data.success == true)
                {
                    console.log('file ' + config.file.name + 'uploaded. Response: ' + data);
                    $state.go('main.companyEdit', {companyId: data.data.id}, {reload: true});
                    toastr.success('Company has been created!', 'Success!');
                }
                else if(data.success == false)
                {
                    console.log('--------------------------------------');
                    console.log('----- Company Create Failed ----------');
                    console.log('--------------------------------------');
                    console.log(data);

                    _(data.errors).forEach(function(n) {
                        toastr.error(n, 'Failed to create the company.');
                    }).value();
                }

            });
        }

        function update(id, data)
        {
            console.log('factory',data);
            var files = [];

            // Setup files to be uploaded
            if (_.has(data, 'logo_file')) {
                files.push({
                    file: data['logo_file'],
                    fileName: 'logo_file'
                });
                data['logo_file'] = null;
            }
            if (_.has(data, 'favicon_file')) {
                files.push({
                    file: data['favicon_file'],
                    fileName: 'favicon_file'
                });
                data['favicon_file'] = null;
            }
            if (_.has(data, 'nav_logo_file')) {
                files.push({
                    file: data['nav_logo_file'],
                    fileName: 'nav_logo_file'
                });
                data['nav_logo_file'] = null;
            }
            if (_.has(data, 'background_file')) {
                files.push({
                    file: data['background_file'],
                    fileName: 'background_file'
                });
                data['background_file'] = null;
            }
            if (_.has(data, 'css_file')) {
                files.push({
                    file: data['css_file'],
                    fileName: 'css_file'
                });
                data['css_file'] = null;
            }

            var file = _.map(files, function (val) {
                return val.file;
            });

            var fileName = _.map(files, function (val) {
                return val.fileName;
            });

            if (typeof data.extension_values != 'undefined') {
                data.extension_values = Object.assign({},data.extension_values);
            }

            data = JSON.parse(JSON.stringify(data));
            delete data.properties;

            console.log(data);
            console.log(file);
            console.log(fileName);

            $upload.upload({
                url: base_url + 'companies/' + id,
                file: file,
                method: 'POST',
                //headers: { 'put': {'Content-Type': 'application/x-www-form-urlencoded'}}
                //fileName: fileName,
                fileFormDataName: fileName,
                data: data
            }).progress(function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
            }).success(function (data, status, headers, config) {
                if(data.success == true)
                {
                    console.log('file ' + config.file.name + 'uploaded. Response: ' + data);
                    //$state.go($state.current, {}, {reload: true});
                    toastr.success('Company has been updated!', 'Success!');
                }
                else if(data.success == false)
                {
                    console.log('--------------------------------------');
                    console.log('----- Company Create Failed ----------');
                    console.log('--------------------------------------');
                    console.log(data);

                    _(data.errors).forEach(function(n) {
                        toastr.error(n, 'Failed to update the company.');
                    }).value();
                }
            });
        }

        function destroy(id)
        {
            return $http.delete(base_url + local_url + "/" + id);
        }

        function enable(id)
        {
            return $http.post(base_url + local_url+ "/" + id + '/enable');
        }

        function getWelcomeEmail(template, text, companyId)
        {
            return $http.post('/email-preview/' + template, {
                'text': text,
                'companyId': companyId,
            });
        }

    }

}());
