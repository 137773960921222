(function () {

	'use strict';

	angular
		.module('app')
		.controller('InsuranceController', insuranceController);

	function insuranceController($scope, $state, $stateParams, $http, $rootScope, langService, $sce) {

		$scope.t = langService.t('resident_services');

        //$scope.insurance_url = 'https://bedrock.brokerlift.net/004-tenants';
        $scope.insurance_url = $sce.trustAsResourceUrl($rootScope.authUser.insurance_url);

		$scope.insurance = {};
		$http.get('/api/v1/insurance').success(function (data) {
			$scope.insurance = data.data;
			if($scope.insurance === null)
				$scope.insurance = {};	
			   	
			$("#datepicker1").datepicker("setDate", $scope.insurance.start_date);
			$("#datepicker2").datepicker("setDate", $scope.insurance.expiry_date);
		});

		var f2, r2;
		$scope.submit = function () {
			f2 = document.getElementById('file').files[0];
			if (f2 === undefined && !$scope.insurance.file_url) {
				toastr.error("Please upload insurance documentation");
				return;
			} else if (f2 === undefined) {
				$http.post('/api/v1/insurance', $scope.insurance).success(function (data) {
					$scope.creatingMR = false;
					if (data.success) {

						$state.go('main.dashboard');
						toastr.success($rootScope.trans('services.uploaded'));
					} else
						toastr.error(data.errors);
				}).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

					$scope.creatingMR = false;
					toastr.error(data.error.message);
				});
			} else {


				$scope.insurance.filename = f2.name;
				r2 = new FileReader();

				r2.readAsDataURL(f2);
				r2.onloadend = function (e) {
					$scope.insurance.file = e.target.result;
					$http.post('/api/v1/insurance', $scope.insurance).success(function (data) {
						$scope.creatingMR = false;
						if (data.success) {

							$state.go('main.dashboard');
							toastr.success($rootScope.trans('resident_services.uploaded'));
						} else
							toastr.error(data.errors);
					}).error(function (data) {
                        if (typeof data.stopaction != 'undefined') {
                            return;
                        }

						$scope.creatingMR = false;
                        if (typeof data.error == 'undefined') {
                            if (typeof data == 'string' && data.includes('413') == true) {
                                toastr.error('Error uploading document, to large.');
                            } else {
                                toastr.error('Error updating insurance details.');
                            }

                            return;
                        }

						toastr.error(data.error.message);
					});
				};

			}

		};

	}

}());
