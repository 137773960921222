(function () {

    "use strict";


    angular
        .module('pq.property')
        .factory('propertyAllData', propertyAllDataFactory);

    function propertyAllDataFactory($q, propertyDataService)
    {
        return {
            load: load
        };


        function load() {
            var properties = propertyDataService.getAllProperties();

            return $q.all([properties]).then(
                function (results) {
                    return {
                        properties: results[0]
                    }
                });
        }
    }
})();