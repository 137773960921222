(function() {

    'use strict';

    angular
    .module('app')
    .controller('MaintenanceRequestScheduleEditController', maintenanceRequestScheduleEditController);

    function maintenanceRequestScheduleEditController($scope,$http,$state,$stateParams,$rootScope,$interval,$timeout) {
        if(!$rootScope.authUser.user_permissions['maintenance-requests'] || $rootScope.authUser.permissions === 'tenant') {
            $state.go('main.dashboard');
        }

        $scope.selectedProperty = {
            name:''
        };
        $scope.properties = [];
        $scope.schedule_loaded=0;
        $scope.schedule = {};

        $scope.current_unit_check = '';
        $scope.selectedPropertyUnits = [];
        $scope.selectedUnit = {
            unit:''
        };
        $scope.creatingMR = false;
        $scope.residents = [];
        $scope.tenant_name = '';
        $scope.mr = {
            id: $stateParams.id,
            unit_specific: 1,
            recurring: 1,
            interval: '',
            interval_count: 1,
            properties: [],
            reminder: '',
            reminder_days_before: ''
        };

        $scope.loading_page_spinner=1;
        $timeout(function(){
            $scope.loading_page_spinner=0;
        }, 8000);


        $('#next-created-at').datepicker();

        $http.post('/api/v1/properties/get-all-for-manager-payments?portfolios=1').success(function(data){
            $scope.properties =  data.data;
            $scope.getSchedule();
        });

        $scope.unitSpecificChange = function(){
            if ($scope.mr.unit_specific == 0) {
                //$timeout(function(){
                    //$('select#properties').multipleSelect({filter: true, width: 350 });
                //}, 500);
            }
        };

        $scope.intervals = [];
        $scope.getIntervals = function(){
            $http.post('/api/v1/maintenance-requests/get-intervals').success(function(data){
                $scope.intervals = data.data;
                $scope.mr.interval = $scope.intervals[0].slug;
            });
        };
        $scope.getIntervals();

        $scope.categories = {};
        $scope.getCategories = function(property_id){
            $http.post('/api/v1/maintenance-requests/get-categories/' + property_id).success(function(data){
                $scope.categories = data.data;
                if (property_id != 0) {
                    $scope.loading_page_spinner=0;
                }
            });
        };
        $scope.getCategories(0);

        $scope.priorities = [];
        $scope.getPriorities = function(property_id){
            $http.post('/api/v1/maintenance-requests/get-priorities/' + property_id).success(function(data){
                $scope.priorities = data.data;
                if (property_id != 0) {
                    $scope.loading_page_spinner=0;
                }
            });
        };
        $scope.getPriorities(0);

        $scope.$watch('selectedProperty.name', function() {
            if ($scope.schedule_loaded == 0) {
                return;
            }
            if($scope.selectedProperty.name !== undefined && $scope.selectedProperty.name.name !== undefined) {
                if($scope.selectedProperty.name.maintenance_request_module_enabled < 1){
                    $scope.selectedProperty = false;
                    toastr.error("The Maintenance Requeste module is not enabled for that property");
                } else {
                    $http.get('/api/v1/units-in-property/'+$scope.selectedProperty.name.id).success(function(data) {
                        $scope.selectedPropertyUnits =  data.data;
                        if ($scope.schedule_loaded == 1) {
                            $scope.selectedUnit.unit = $scope.schedule.selected_unit;
                            $scope.mr.tenant_name = $scope.schedule.tenant_name;
                            $scope.tenant_name = $scope.schedule.tenant_name;

                            if ($scope.schedule.unit_specific == 0) {
                                $scope.schedule_loaded=2;
                                $scope.mr.unit_specific = $scope.schedule.unit_specific;
                                $scope.mr.phone = $scope.schedule.phone;
                                $scope.mr.problem_in_unit = $scope.schedule.problem_in_unit == 'yes' ? 1 : 0;
                                $scope.mr.permission_to_enter_unit_alone = $scope.schedule.permission_to_enter_unit_alone == 'yes' ? 1 : 0;
                                $scope.mr.subject = $scope.schedule.subject;
                                $scope.mr.details = $scope.schedule.details;
                                $scope.mr.additional_info = $scope.schedule.additional_info;
                                $scope.mr.priority = $scope.schedule.priority;
                                $scope.mr.extension_priority = $scope.schedule.extension_priority;
                                $scope.mr.private_notes = $scope.schedule.private_notes;
                                $scope.mr.interval = $scope.schedule.interval;
                                $scope.mr.interval_count = $scope.schedule.interval_count;
                                $scope.mr.reminder = $scope.schedule.reminder;
                                $scope.mr.reminder_days_before = $scope.schedule.reminder_days_before;
                                $scope.mr.images = $scope.schedule.images;
                                $scope.mr.documents = $scope.schedule.documents;

                                $scope.mr.next_created_at = moment($scope.schedule.next_created_at).format('MM/DD/YYYY');

                                //$timeout(function(){
                                    //$('select#properties').multipleSelect({filter: true, width: 350 });
                                //}, 500);
                                $scope.loading_page_spinner=0;
                            } else {
                                $scope.getCategories($scope.selectedProperty.name.id);
                                $scope.getPriorities($scope.selectedProperty.name.id);
                            }
                        }
                    });
                }
            }

        });

        $scope.$watch('selectedUnit.unit', function() {
            if ($scope.schedule_loaded == 0) {
                return;
            }

            if($rootScope.authUser.permissions == 'tenant') {
                return;
            }

            if (typeof $scope.selectedUnit.unit == 'undefined') {
                return;
            }

            if($scope.current_unit_check == $scope.selectedUnit.unit.unit){
                return;
            }
            $scope.current_unit_check = $scope.selectedUnit.unit.unit;

            $scope.residents = [];
            $scope.mr.tenant_id = 0;
            $scope.mr.phone = '';
            $scope.tenant_name = '';
            if($scope.selectedUnit.unit !== undefined && $scope.selectedUnit.unit.unit !== undefined) {
                for(var i in $scope.selectedPropertyUnits.tenants){
                    var t = $scope.selectedPropertyUnits.tenants[i];
                    if(t.property_unit_id == $scope.selectedUnit.unit.id){
                        $scope.mr.tenant_id = t.id;
                        $scope.mr.phone = t.phone_number;
                        $scope.tenant_name = t.first_name+' '+t.last_name;
                        $scope.mr.tenant_name = $scope.tenant_name;
                    }

                    if(t.property_unit != null && t.property_unit.unit == $scope.selectedUnit.unit.unit){
                        $scope.residents.push(t.first_name + ' ' + t.last_name);
                    }
                }
                if($scope.tenant_name === undefined || $scope.tenant_name == '') {
                    toastr.error("There is no active tenant in unit "+$scope.selectedUnit.unit.unit);
                    $scope.selectedUnit = {unit:''};
                }
            }

            if ($scope.schedule_loaded == 1) {
                $scope.schedule_loaded=2;
                $scope.mr.unit_specific = $scope.schedule.unit_specific;
                $scope.mr.phone = $scope.schedule.phone;
                $scope.mr.problem_in_unit = $scope.schedule.problem_in_unit == 'yes' ? 1 : 0;
                $scope.mr.permission_to_enter_unit_alone = $scope.schedule.permission_to_enter_unit_alone == 'yes' ? 1 : 0;
                $scope.mr.subject = $scope.schedule.subject;
                $scope.mr.details = $scope.schedule.details;
                $scope.mr.additional_info = $scope.schedule.additional_info;
                $scope.mr.priority = $scope.schedule.priority;
                $scope.mr.extension_priority = $scope.schedule.extension_priority;
                $scope.mr.private_notes = $scope.schedule.private_notes;
                $scope.mr.interval = $scope.schedule.interval;
                $scope.mr.interval_count = $scope.schedule.interval_count;
                $scope.mr.reminder = $scope.schedule.reminder;
                $scope.mr.reminder_days_before = $scope.schedule.reminder_days_before;
                $scope.mr.images = $scope.schedule.images;
                $scope.mr.documents = $scope.schedule.documents;

                $scope.mr.next_created_at = moment($scope.schedule.next_created_at).format('MM/DD/YYYY');
                if ($scope.mr.unit_specific == 0) {
                    //$timeout(function(){
                        //$('select#properties').multipleSelect({filter: true, width: 350 });
                    //}, 500);
                }
                $scope.loading_page_spinner=0;
            }
        });

        $scope.residentChange = function() {
            for(var i in $scope.selectedPropertyUnits.tenants){
                var t = $scope.selectedPropertyUnits.tenants[i];

                if(t.first_name + ' ' + t.last_name == $scope.mr.tenant_name){
                    $scope.mr.tenant_id = t.id;
                    $scope.mr.phone = t.phone_number;
                    $scope.tenant_name = t.first_name+' '+t.last_name;
                }
            }
        };

        $scope.getSchedule = function(){
            $http.post('/api/v1/maintenance-requests/get-schedule/' + $scope.mr.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.maintenanceRequests');
                }

                // lets populate stuff
                $scope.schedule = data.data.schedule;
                var schedule = data.data.schedule;
                $scope.schedule_loaded=1;

                $scope.selectedProperty.name = $scope.schedule.property;

                angular.forEach(schedule.properties, function(property, key){
                    $scope.mr.properties.push(property.id);
                });
                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                } ,500);
            });
        };

        $scope.updateSchedule = function(){
            if($scope.creatingMR){
                toastr.warning('Please wait while we process your maintenance request');
                return;
            }

            var errors = 1;
            if($scope.mr.unit_specific == 1 && ($scope.mr.tenant_id === undefined || $scope.mr.tenant_id < 1)) {
                toastr.error("Please select a property and unit first. If the tenant field is empty then the selected unit is invalid or empty.");
            } else if($scope.mr.subject === undefined || $scope.mr.subject == '') {
                toastr.error("Please enter a subject");
            } else if($scope.mr.unit_specific == 1 && ($scope.mr.phone === undefined || $scope.mr.phone == '')) {
                toastr.error("Please enter your phone number");
            } else if($scope.mr.details === undefined || $scope.mr.details == '') {
                toastr.error("Please provide some details first");
            } else if($scope.priorities.length == 0 && ($scope.mr.priority === undefined || $scope.mr.priority == '')) {
                toastr.error("Please select the priority");
            } else if($scope.priorities.length > 0 && ($scope.mr.extension_priority === undefined || $scope.mr.extension_priority == '')) {
                toastr.error("Please select the priority");
            } else {
                $scope.creatingMR = true;
                errors = 0;
            }

            if (errors == 1) {
                return;
            }

            $scope.mr.property_id = $scope.selectedProperty.name.id;

            $http.post('/api/v1/maintenance-requests/update-schedule/' + $scope.mr.id, $scope.mr).success(function(data) {
                $scope.creatingMR = false;

                if (!data.success) {
                    toastr.error(data.errors,"Error updating Schedule");
                    return;
                }

                $state.go('main.maintenanceRequestSchedules');
                toastr.success('Maintenance Request Schedule Updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingMR = false;
                toastr.error(data.error.message);
            });
        };

        $scope.isUploading = false;
        $scope.upload = function(){
            $scope.isUploading = true;
            var f = document.getElementById('file').files[0],
                r = new FileReader();
            try {
                r.readAsDataURL(f);
                r.onloadend = function (e) {
                    var data = e.target.result;
                    $http.post('/api/v1/maintenance-requests/upload-schedule/' + $stateParams.id, {image: data})
                        .success(function (data) {
                            if (data.success) {
                                toastr.success('Image Added');
                                $scope.isUploading = false;
                                if (data.data.content_type.includes('image')) {
                                    $scope.mr.images.push('/recurring-mr-document/' + data.data.id);
                                } else {
                                    if (!$scope.mr.documents) {
                                        $scope.mr.documents = [];
                                    }

                                    $scope.mr.documents.push(data.data);
                                }

                            } else
                                toastr.error(data.errors);

                            document.getElementById('file').value = "";

                        })
                        .error(function (data) {
                            if (typeof data.stopaction != 'undefined') {
                                return;
                            }

                            toastr.error('Error uploading image');
                        });
                }
            } catch (e) {
                console.log('e', e)
                toastr.error('No file selected');
            }
        };

        $scope.deleteImage = function(url, image){
            if(!confirm("Are you sure you want to remove this image?")){
                return;
            }

            if (image) {
                for(var i in $scope.mr.images) {
                    if( $scope.mr.images[i] == url){
                        $scope.mr.images.splice(i,1);
                        let id = url.split('/');
                        $http.post('/api/v1/maintenance-requests/delete-image-schedule',{'id':id[id.length - 1]}).success(function(data) {
                            toastr.success('Image Removed');
                        });
                    }
                }
            } else {
                $http.post('/api/v1/maintenance-requests/delete-image-schedule',{'id':url}).success(function(data) {
                    toastr.success('Document Removed');
                    for(var i in $scope.mr.documents) {
                        if( $scope.mr.documents[i].id == url){
                            $scope.mr.documents.splice(i,1);
                        }
                    }
                });
            }

        };

        $scope.deleteSchedule = function(){
            if (!confirm('Are you sure you want to delete this recurring maintenance request')) {
                return;
            }

            $http.post('/api/v1/maintenance-requests/delete-schedule/' + $scope.mr.id).success(function(data) {
                if (!data.success) {
                    toastr.error("Could not delete schedule");
                }

                toastr.success('Maintenance Request Schedule deleted');
                $state.go('main.maintenanceRequestSchedules');
            });
        }
    }
}());
