(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyFileUpload', pqPropertyFileUpload);

    function pqPropertyFileUpload() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-file-upload/property-file-upload.html',
        }
    }

})();