(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqPhotosPopup', pqPhotosPopup);

    angular
        .module('app')
        .controller('PqPhotosPopupController', pqPhotosPopupController);

    function pqPhotosPopup() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/photos-popup/photos-popup.html',
            controller: 'PqPhotosPopupController',
            scope: {
                ref: '=',
                pqDomain: '='
            }
        };
    }

    function pqPhotosPopupController($scope, $timeout, helperService, jwtService, $http, langService) {
        $scope.t = langService.t('inspections');
        $scope.token = jwtService.getToken();
        $scope.lastPhoto = '';
        $scope.animatedPhoto = '';
        $scope.showedPhoto = '';
        $scope.isMobile = helperService.isMobileOrTablet();
        $scope.photos = [];
        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform());

        $scope.cameraOptions = function(srcType) {
            var options = {
                quality: 75,
                destinationType: Camera.DestinationType.FILE_URI,
                sourceType: srcType,
                encodingType: Camera.EncodingType.JPEG,
                mediaType: Camera.MediaType.PICTURE,
                allowEdit: false,
                correctOrientation: true,
                targetWidth: 1000,
                targetHeight: 1000
            }
            return options;
        }

        const hasGetUserMedia = () => !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
        const video = document.querySelector('#pq-photos-modal video');
        const canvas = document.querySelector('#pq-photos-modal canvas');
        const animatedPhoto = $('#pq-photos-modal .animated-photo');
        const fileInput = document.getElementById('photosPopupFiles');
        const availableFormats = ['image/png', 'image/jpeg'];
        

        $scope.ref.open = (item) => {
            if (!item.photos) {
                item.photos = [];
            }
            $scope.photos = item.photos;
            $('#pq-photos-modal').appendTo('body').modal('show');
            $timeout(() => {
                $('.modal-backdrop').hide();
            }, 0);

            if ($scope.photos.length) {
                $scope.lastPhoto = $scope.photos[$scope.photos.length - 1];
            } else {
                $scope.lastPhoto = '';
            }

            if ($scope.isMobile && !$scope.isCordova && hasGetUserMedia()) {
                navigator.mediaDevices
                    .getUserMedia({video: { facingMode: { exact: "environment" } }})
                    .then(stream => video.srcObject = stream);
            }
        };

        $scope.addCameraPhoto = function() {
            var srcType = Camera.PictureSourceType.CAMERA;
            var options = $scope.cameraOptions(srcType);
            var func = $scope.createNewFileEntry();

            navigator.camera.getPicture(function cameraSuccess(imageUri) {
                $scope.getImageBlobFromUrl(window.Ionic.WebView.convertFileSrc(imageUri));
            }, function cameraError(error) {
                console.log('error', error)
            }, options);
        }

        $scope.choosePhoto = function(event) {
            if($scope.isCordova) {
                event.preventDefault();
                var srcType = Camera.PictureSourceType.PHOTOLIBRARY;
                var options = $scope.cameraOptions(srcType);
                var func = $scope.createNewFileEntry();

                navigator.camera.getPicture(function cameraSuccess(imageUri) {
                    $scope.getImageBlobFromUrl(window.Ionic.WebView.convertFileSrc(imageUri));
                }, function cameraError(error) {
                    console.log('error', error)
                }, options);
            }
        }

        $scope.createNewFileEntry = function(imgUri) {
            window.resolveLocalFileSystemURL(cordova.file.cacheDirectory, function success(dirEntry) {
                dirEntry.getFile("tempFile.jpeg", { create: true, exclusive: false }, function (fileEntry) {
                    // console.log("got file: " + fileEntry.fullPath);
                }, $scope.onErrorCreateFile);
            }, $scope.onErrorResolveUrl);
        }

        $scope.onErrorCreateFile = function() {
        }

        $scope.onErrorResolveUrl = function() {
        }

        $scope.takePhoto = function(event) {
            if($scope.isCordova) {
                event.preventDefault();
                $scope.addCameraPhoto();
            }
        }

        $scope.photoUploaded = () => {
            const filesLength = fileInput.files.length;
            if (filesLength) {
                for (let i = 0; i < filesLength; i++) {
                    let fileReader = new FileReader();
                    fileReader.onload = () => {
                        $timeout(() => {
                            $scope.photos.push(fileReader.result);
                            $scope.lastPhoto = fileReader.result;
                        }, 0);
                    };
                    const file = fileInput.files.item(i);
                    if (availableFormats.includes(file.type)) {
                        fileReader.readAsDataURL(file);
                    } else {
                        toastr.error('Only JPG and PNG are available.');
                    }
                }
                fileInput.value = '';
            }
        };

        fileInput.addEventListener('change', $scope.photoUploaded, true);

        const animatePhoto = photo => {
            $scope.animatedPhoto = photo;
            const videoRect = video.getBoundingClientRect();
            animatedPhoto.css({
                width: `${videoRect.width}px`,
                height: `${videoRect.height}px`,
                display: 'block',
                left: `${videoRect.left}px`,
                top: `${videoRect.top}px`
            });
            const previewRect = document.querySelector('#pq-photos-modal .pq-photos-preview').getBoundingClientRect();
            animatedPhoto.animate({
                width: `${previewRect.width}px`,
                height: `${previewRect.height}px`,
                display: 'block',
                left: `${previewRect.left}px`,
                top: `${previewRect.top}px`
            }, 600, () => {
                animatedPhoto.hide();
            });
            $scope.photos.push(photo);
            $scope.lastPhoto = photo;

        };

        $scope.doPhoto = () => {
            if($scope.isCordova) {
                $scope.addCameraPhoto($scope.photos.length - 1);
            } else {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0);
                const photo = canvas.toDataURL('image/png');
                animatePhoto(photo);
            }
        };

        $scope.getImageBlobFromUrl = function(url) {
            var url = url.replace('http:', 'https:'); //temp fix for CORS issue?
            $http.get(url, { responseType: 'blob' })
                .then(function(data) {
                    var r1 = new FileReader();
                    r1.readAsDataURL(data.data);
                    r1.onloadend = function (e) {
                        var photoBlob = null;

                        if(e.target['result']) {
                            photoBlob = e.target['result'];
                        } else if(e.currentTarget['result']) {
                            photoBlob = e.currentTarget['result']
                        }

                        if(photoBlob) {
                            $scope.photos.push(photoBlob);
                            $scope.lastPhoto = $scope.photos[$scope.photos.length - 1];
                        }
                    }
                })
                .catch(function(error) {
                });
        }

        $scope.openGallery = () => {
            $('#pq-photos-modal-gallery').appendTo('body').modal('show');
            $timeout(() => {
                $('.modal-backdrop').hide();
            }, 0);
        };

        $scope.removePhoto = index => {
            if ($scope.photos[index] === $scope.lastPhoto) {
                $scope.lastPhoto = $scope.photos[index - 1] ? $scope.photos[index - 1] : '';
            }
            $scope.photos.splice(index, 1);
            if (!$scope.photos.length) {
                $scope.closeGallery();
            }
        };

        $scope.showPhoto = index => {
            $scope.showedPhoto = $scope.photos[index];
            $scope.openShowedPhoto();
        };

        $scope.openShowedPhoto = () => {
            $('#pq-photos-modal-photo').appendTo('body').modal('show');
            $timeout(() => {
                $('.modal-backdrop').hide();
            }, 0);
        };

        $scope.close = () => {
            if (video.srcObject){
                video.srcObject.getTracks().forEach(function(track) {
                    track.stop();
                });
            }

            $('#pq-photos-modal').modal('hide');
        };

        $scope.closeGallery = () => {
            $('#pq-photos-modal-gallery').modal('hide');
        };

        $scope.closeShowedPhoto = () => {
            $('#pq-photos-modal-photo').modal('hide');
        };


    }

})();
