(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyUnitTypes', pqPropertyUnitTypes);

    function pqPropertyUnitTypes() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-edit/property-unit-types/property-unit-types.html',
        }
    }

})();
