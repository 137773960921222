(function () {

    'use strict';

    angular
        .module('app')
        .controller('PresetEditController', presetEditController);

    function presetEditController($state, $scope, resolvedData, managerService, propertyService, $rootScope, $timeout, $http) {
        let vm = this;

        vm.submitting = false;
        vm.manager = resolvedData.data.data;
        vm.manager.manager_permission = 'junior_manager';
        vm.manager.originalEmail = vm.manager.email;
        vm.selectedProperty = 0;
        vm.property_options = vm.manager.property_options;
        vm.select_all_properties = 0;
        vm.properties = vm.manager.properties;
        vm.portfolios = vm.manager.portfolios;
        vm.leasingChecked = Boolean(vm.manager.all_email_groups.filter(el => el.group === 'leasing' && el.selected == 1).length);

        $scope.leasingChecking = () => {
            if (vm.leasingChecked) {
                $timeout(function () {
                    $('#leasing_emails_filter').multipleSelect({
                        filter: true,
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => {
                            vm.manager.all_email_groups.map(el => {
                                if (el.name === e.value.trim()) {
                                    el.selected = Number(e.checked)
                                    return el;
                                }
                            })
                        }
                    })
                });
            } else {
                vm.manager.all_email_groups = vm.manager.all_email_groups.map(el => {
                    if (el.group === 'leasing') {
                        el.selected = 0;
                    }
                    return el;
                })
            }
        }
        $scope.leasingChecking();

        vm.createPreset = function () {
            vm.submitting = true;
            var data = {
                name: vm.manager.name,
                manager_permission: vm.manager.manager_permission,
                user_permissions: vm.manager.user_permissions,
                manager_email_groups: vm.manager.all_email_groups,
                lease_rent_editable: vm.manager.lease_rent_editable,
                lease_rent_editable_type: vm.manager.lease_rent_editable_type,
                company_id: vm.manager.company_id,
                can_edit_tenant_email: vm.manager.can_edit_tenant_email,
                can_be_assigned_to_mr: vm.manager.can_be_assigned_to_mr,
                can_disable_property: vm.manager.can_disable_property,
                can_create_tenant: vm.manager.can_create_tenant,
                is_billing: vm.manager.is_billing,
                remove_from_lease_message: vm.manager.remove_from_lease_message,
                lease_approve_enabled: vm.manager.lease_approve_enabled,
                lease_create_from_prospect_enabled: vm.manager.lease_create_from_prospect_enabled,
                credit_check_mandatory_for_application_approval: vm.manager.credit_check_mandatory_for_application_approval,
                lease_messages_enabled: vm.manager.lease_messages_enabled,
                messages_access_type: vm.manager.messages_access_type
            };

            $http.post('api/v1/companies/manager-presets', data).success(resp => {
                toastr.success('Preset successfully updated');
                vm.submitting = false;
                $state.go('main.managerPresetEdit', {id: resp.data.id});
            });
        }

        vm.updateManager = function (id) {
            vm.submitting = true;
            var data = {
                name: vm.manager.name,
                manager_permission: vm.manager.manager_permission,
                user_permissions: vm.manager.user_permissions,
                manager_email_groups: vm.manager.all_email_groups,
                lease_rent_editable: vm.manager.lease_rent_editable,
                lease_rent_editable_type: vm.manager.lease_rent_editable_type,
                can_edit_tenant_email: vm.manager.can_edit_tenant_email,
                can_be_assigned_to_mr: vm.manager.can_be_assigned_to_mr,
                can_disable_property: vm.manager.can_disable_property,
                can_create_tenant: vm.manager.can_create_tenant,
                is_billing: vm.manager.is_billing,
                remove_from_lease_message: vm.manager.remove_from_lease_message,
                lease_approve_enabled: vm.manager.lease_approve_enabled,
                lease_create_from_prospect_enabled: vm.manager.lease_create_from_prospect_enabled,
                credit_check_mandatory_for_application_approval: vm.manager.credit_check_mandatory_for_application_approval,
                lease_messages_enabled: vm.manager.lease_messages_enabled,
                messages_access_type: vm.manager.messages_access_type
            };

            $http.put('api/v1/companies/manager-presets/' + id, data).success(resp => {
                toastr.success('Preset successfully updated');
                vm.submitting = false;
            });
        };

    }

}());
