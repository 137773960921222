(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageTemplateListController', MessageTemplateListController);

    function MessageTemplateListController($scope,$http,$state,$rootScope, langService) {

        $scope.templates = [];

        $scope.t = langService.t('messages');

        $scope.getTemplates = function(){
            $http.post('/api/v1/message-template/get-message-templates', {forlist: 1}).success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Message Templates");
                }

                // lets populate stuff
                $scope.templates = data.data.templates;

                setTimeout(function () {
                    $('#templatesDataTable').dataTable({
                        retrieve: true,
                        responsive: true
                    });

                    $('body').on('click', '#templatesDataTable .template-edit-button', function (event) {
                        if($(this).data("template")) {
                            event.preventDefault();
                            $state.go('main.messagesTemplateEdit', { id: $(this).data("template") });
                        }
                    });
                });
            });
        }

        if (!$rootScope.authUser.pro_messaging) {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        $scope.getTemplates();
    }
}());
