(function () {

    'use strict';

    angular
        .module('app')
        .controller('InspectionTemplatesController', function ($scope, $state, $rootScope) {
            $scope.state = $state;

            $scope.openEditHistory = () => {
                $rootScope.goToAngular2('inspections/edit-history')
            };
        });

}());
