(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationDashboardController', leaseApplicationDashboardController);

    function leaseApplicationDashboardController($scope,$http,$state,$rootScope,$timeout,langService) {
        $scope.t = langService.t('lease_app');

		$scope.status_messages = {
            'open': $scope.t('open'),
            'review': $scope.t('being_reviewed'),
            'approved': $scope.t('approved'),
            'rejected': $scope.t('declined'),
            'accepted': $scope.t('accepted_plz_sign'),
            'signed': $scope.t('awaiting_manager_signiture'),
            'complete': $scope.t('app_complete'),
            'converted': $scope.t('converted_to_tenant'),
            'canceled': $scope.t('app_cancelled')
        };

        if (typeof $rootScope.authUser == 'undefined') {
            $state.go('main.leaseApplicationCreateCompanies');
        }

        $scope.applications = [];

        $scope.getApplications = function(){
            $http.post('/api/v1/lease-application/get-list').success(function(data) {
                if (!data.success) {
                    toastr.error($scope.t('error_retrieving_apps'));
                }

                // lets populate stuff
                $scope.applications = data.data.applications;
            });
        }

        $scope.getApplications();

        $scope.goToCompanyCreate = function(){
            $state.go('main.leaseApplicationCreateProperties');
        }
    }
}());
