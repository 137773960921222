(function () {
    'use strict';

    angular
        .module('app')
        .controller('InvoiceEditController', invoiceEditController);

    function invoiceEditController($scope, $http, $state, $stateParams, $rootScope, $timeout) {

        $scope.loading_spinner = 0;
        $scope.managers = [];

        $scope.invoice = {
            id: $stateParams.id,
            company_id: $stateParams.companyId,
            name: '',
            manager_id: 0,
            number: 0,
            line_items: [
                {
                    label: '',
                    sub_label: '',
                    order: 1,
                    type: 'line',
                    tax_type: 'percentage',
                    amount: ''
                }
            ],
            tax_items: [
                {
                    label: '',
                    sub_label: '',
                    order: 1,
                    type: 'tax',
                    tax_type: 'percentage',
                    amount: ''
                }
            ]
        };


        $scope.getInvoice = function () {
            $http.post('/api/v1/billing/get-invoice/' + $scope.invoice.id).success(function (data) {
                if (!data.success) {
                    $state.go('main.billingInvoiceList');
                }

                // lets populate stuff
                var invoice = data.data.invoice;

                $scope.invoice.id = invoice.id;
                $scope.invoice.company_id = invoice.company_id;
                $scope.invoice.name = invoice.name;
                $scope.invoice.manager_id = invoice.manager_id;
                $scope.invoice.number = invoice.number;

                $scope.invoice.line_items = [];
                $scope.invoice.tax_items = [];
                $.each(invoice.items, function (index, item) {
                    var new_item = {
                        label: item.label,
                        sub_label: item.sub_label,
                        order: item.order,
                        type: item.type,
                        tax_type: item.tax_type,
                        amount: item.amount
                    };

                    if (new_item.type == 'line') {
                        $scope.invoice.line_items.push(new_item);
                    } else {
                        $scope.invoice.tax_items.push(new_item);
                    }
                });
            });
        };

        $http.get('/api/v1/billing/invoice-data/' + $scope.invoice.company_id).success(function (data) {
            $scope.managers = data.data.managers;
            if (+$scope.invoice.id === 0) {
                $scope.invoice.manager_id = data.data.managers[0].id;
                $scope.invoice.number = data.data.maxNumber;
            }

        });

        if ($scope.invoice.id != 0) {
            $scope.getInvoice();
        }

        $scope.addLineItem = function () {
            $scope.invoice.line_items.push({
                label: '',
                sub_label: '',
                order: 1,
                type: 'line',
                tax_type: 'percentage',
                amount: ''
            });
        };

        $scope.addTaxItem = function () {
            $scope.invoice.tax_items.push({
                label: '',
                sub_label: '',
                order: 1,
                type: 'tax',
                tax_type: 'percentage',
                amount: ''
            });
        };

        $scope.removeLineItem = function (key) {
            $scope.invoice.line_items.splice(key, 1);
        };

        $scope.removeTaxItem = function (key) {
            $scope.invoice.tax_items.splice(key, 1);
        };

        $scope.deleteInvoice = function () {
            if (!confirm('Are you sure you want to delete this invoice?')) {
                return; // they did not confirm
            }

            $http.post('/api/v1/billing/delete-invoice/' + $scope.invoice.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, "Error deleting invoice");
                    return;
                }

                $state.go('main.billingInvoiceList');
                toastr.success('Invoice deleted');
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingInvoice = false;
                toastr.error(data.error.message);
            });
        };

        $scope.saveInvoice = function () {
            $scope.loading_spinner = 1;

            $http.post('/api/v1/billing/update-invoice', $scope.invoice).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, "Error saving invoice");
                    $scope.loading_spinner = 0;
                    return;
                }

                $scope.creatingInvoice = false;
                $scope.loading_spinner = 0;
                $state.go('main.billingInvoiceList');
                toastr.success('Invoice saved');
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingInvoice = false;
                $scope.loading_spinner = 0;

                toastr.error(data.error.message);
            });
        };

        $scope.moveLineItemDown = function (key) {
            var newkey = key + 1;

            if (typeof $scope.invoice.line_items[newkey] == 'undefined') {
                return;
            }

            var temp = $scope.invoice.line_items[newkey];
            $scope.invoice.line_items[newkey] = $scope.invoice.line_items[key];
            $scope.invoice.line_items[key] = temp;
        };

        $scope.moveLineItemUp = function (key) {
            if (key == 0) {
                return;
            }

            var newkey = key - 1;

            var temp = $scope.invoice.line_items[newkey];
            $scope.invoice.line_items[newkey] = $scope.invoice.line_items[key];
            $scope.invoice.line_items[key] = temp;
        };

        $scope.moveTaxItemDown = function (key) {
            var newkey = key + 1;

            if (typeof $scope.invoice.tax_items[newkey] == 'undefined') {
                return;
            }

            var temp = $scope.invoice.tax_items[newkey];
            $scope.invoice.tax_items[newkey] = $scope.invoice.tax_items[key];
            $scope.invoice.tax_items[key] = temp;
        };

        $scope.moveTaxItemUp = function (key) {
            if (key == 0) {
                return;
            }

            var newkey = key - 1;

            var temp = $scope.invoice.tax_items[newkey];
            $scope.invoice.tax_items[newkey] = $scope.invoice.tax_items[key];
            $scope.invoice.tax_items[key] = temp;
        }
    }
}());
