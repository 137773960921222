(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        const url = 'scripts/modules/survey/';

        $stateProvider
            .state('main.surveys', {
                url: '/surveys',
                params: {
                    isPublished: false,
                    isSend: false
                },
                views: {
                    'body@': {
                        templateUrl: url + 'index/survey.index.html',
                        controller: 'SurveyIndexController'
                    }
                }
            })
            .state('main.surveysCreate', {
                url: '/surveys/create',
                views: {
                    'body@': {
                        templateUrl: url + 'edit/survey.edit.html',
                        controller: 'SurveyEditController',
                        resolve: {
                            resolvedProperties: resolvedProperties,
                            resolvedSurvey: resolveSurvey,
                            resolvedEvents: resolvedEvents,
                        }
                    }
                }
            })
            .state('main.surveysEdit', {
                url: '/surveys/:surveyId/edit',
                views: {
                    'body@': {
                        templateUrl: url + 'edit/survey.edit.html',
                        controller: 'SurveyEditController',
                        resolve: {
                            resolvedProperties: resolvedProperties,
                            resolvedSurvey: resolveSurvey,
                            resolvedEvents: resolvedEvents
                        }
                    }
                }
            })
            .state('main.surveysView', {
                url: '/surveys/:surveyId',
                views: {
                    'body@': {
                        templateUrl: url + 'view/survey.view.html',
                        controller: 'SurveyViewController',
                        resolve: {
                            resolvedSurvey: function resolveSurvey(surveyService, $stateParams, $q) {
                                return $q.when(surveyService.getWithAnswers($stateParams.surveyId));
                            },
                            resolvedFilters: function ($q, ReportsService) {
                                return $q.when(ReportsService.getFilters());
                            },
                        }
                    }
                }
            })
            .state('main.surveysViewIndividual', {
                url: '/surveys/:surveyId/individual',
                views: {
                    'body@': {
                        templateUrl: url + 'individual/survey.individual.html',
                        controller: 'SurveyIndividualController',
                        resolve: {
                            resolvedSurvey: function resolveSurvey(surveyService, $stateParams, $q) {
                                return $q.when(surveyService.getIndividuals($stateParams.surveyId));
                            },
                            resolvedFilters: function ($q, ReportsService) {
                                return $q.when(ReportsService.getFilters());
                            },
                        }
                    }
                }
            })
            .state('main.surveysPass', {
                url: '/surveys/:surveyId/pass',
                views: {
                    'body@': {
                        templateUrl: url + 'pass/survey.pass.html',
                        controller: 'SurveyPassController',
                        resolve: {
                            resolvedSurvey: function resolveSurvey(surveyService, $stateParams, $q, $location) {
                                return $q.when(surveyService.getForTenant($stateParams.surveyId, $location.search().mr_id, null));
                            }
                        }
                    }
                }
            })
            .state('main.surveysPassPublic', {
                url: '/surveys/:surveyId/:slug/pass-public',
                views: {
                    'body@': {
                        templateUrl: url + 'pass/survey.pass.html',
                        controller: 'SurveyPassController',
                        resolve: {
                            resolvedSurvey: function resolveSurvey(surveyService, $stateParams, $q) {
                                return $q.when(surveyService.getForTenant($stateParams.surveyId, null, $stateParams.slug ));
                            }
                        }
                    }   
                }
            });

        function resolvedProperties($q, surveyService) {
            return $q.when(surveyService.getProperties());
        }
        
        function resolveSurvey(surveyService, $stateParams, $q) {
            if (!$stateParams.surveyId) {
                return null;
            } else {
                return $q.when(surveyService.get($stateParams.surveyId));
            }
        }

        function resolvedEvents(surveyService, $q) {
            return $q.when(surveyService.getEvents());
        }
    }

}());
