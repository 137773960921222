(function() {

    'use strict';

    angular
        .module('app')
        .factory('notificationService', notificationService);

    function notificationService($http) {

        var base_url = 'api/v1';
        var local_url = '/notifications';

        return {
            getDashboardNotifications: getDashboardNotifications
        };

        /**
         *
         * @param permissions
         * @returns {HttpPromise}
         */
        function getDashboardNotifications()
        {
            // console.log(permissions);
            return $http.get(base_url + local_url)
                .then(function (object) {
                    console.log(object);
                    return object.data;
                });
        }
    }

}());