(function () {

    'use strict';

    angular
        .module('app')
        .factory('paymentHistoryData', paymentHistoryDataFactory);

    function paymentHistoryDataFactory($q, paymentScheduleService)
    {
        return {
            load: load
        };

        function load() {
            var properties = paymentScheduleService.getAllProperties();

            return $q.all([properties]).then(
                function (results) {
                    return {
                        properties: results[0]
                    }
                });
        }
    }
})();