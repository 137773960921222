(function() {

	'use strict';

    angular
		.module('app')
		.controller('ManagerIndexController', managerIndexController);

	function managerIndexController(resolvedManagerData,$scope,$state) {

        var vm = this;

        activate();

        function activate() {
            vm.managers = resolvedManagerData.managers.data;

            $scope.viewbyCan = 50;
            $scope.totalItemsCan = vm.managers.length;
            $scope.currentPageCan = 1;
            $scope.itemsPerPageCan = $scope.viewbyCan;
            $scope.maxSizeCan = 50; //Number of pager buttons to show

            $scope.setPageCan = function (pageNo) {
                $scope.currentPageCan = pageNo;
            };

            $scope.setItemsPerPageCan = function (num) {
                $scope.itemsPerPageCan = num;
                $scope.currentPageCan = 1; //reset to first page
            };
        }
		$scope.viewManager = function(manager){
			$state.go('main.managerEdit',{managerId: manager.id});
		}

    }

}());
