(function () {
    'use strict';

    angular
        .module('app')
        .controller('AmenityBookingsController', amenityBookingsController);

    function amenityBookingsController($scope, $http, $state, $rootScope, showingService, langService, $compile) {
        $scope.t = langService.t('amenities');
        $scope.calendar_initilized = 0;
        $scope.ref = {
            property_id: 0
        };
        $scope.hidden_amenities = [];
        $scope.m = {booking: {}};
        $scope.colours = {};
        $scope.current_calendar_view = 0;
        $scope.pending_bookings = [];
        $scope.calendar_bookings = [];
        $scope.ref = {
            isShowShowings: true,
            isShowShowingsCheckbox: true
        };
        $scope.showing = {};
        $scope.amenities_enabled = 0;
        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform())


        $scope.getProperties = function () {
            $http.post('/api/v1/amenity/get-properties').success(function (data) {
                $scope.properties = data.data.properties;
                $scope.ref.property_id = $scope.properties[0].id;

                setTimeout(function () {
                   let selector =  $('#property-selector').multipleSelect({
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => $scope.doChangeProperty(),
                    });
                    selector.multipleSelect('setSelects', [0])
                });
                $scope.getAmenityColours();
            });
        };

        $scope.getAmenityColours = function () {
            if ($scope.amenities_enabled == 0) {
                if ($scope.calendar_initilized) {
                    $scope.loadCalendarEvents();
                } else {
                    setTimeout(function () {
                        $scope.initCalendar();
                    }, 0);
                }
                return
            }

            $http.post('/api/v1/amenity/get-amenity-colours?ids=' + $scope.ref.property_id).success(function (data) {
                if (data.success) {
                    $scope.colours = data.data;
                    $scope.getPendingBookings();
                } else {
                    $scope.colours = {};
                }

                if ($scope.calendar_initilized) {
                    $scope.loadCalendarEvents();
                } else {
                    setTimeout(function () {
                        $scope.initCalendar();
                    }, 0);
                }
            });
        };

        $scope.cancelBooking = function(index){
            if(!confirm($scope.t('are_you_sure'))) {
                return;
            }

            $http.post('/api/v1/amenity/booking/cancel/' + index).success(function(data) {
                if (!data.success) {
                    toastr.error('Error cancelling booking');
                }

                $scope.loadCalendarEvents();
                $scope.getPendingBookings();
            });
        }

        $scope.table = false;
        $scope.getPendingBookings = function () {
            $http.post('/api/v1/amenity/get-pending-bookings?ids=' + $scope.ref.property_id + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken).success(function (data) {
                if (!data.success) {
                    return;
                }
                if ($scope.table != false) {
                    $scope.table.destroy();
                }

                data.data.forEach(item => {
                    item.payment_status = item.payment_status.charAt(0).toUpperCase() + item.payment_status.slice(1)
                });
                $scope.pending_bookings = data.data

                $(document).ready(function () {
                    setTimeout(function () {
                        $scope.table = $('#pending-approval-table').DataTable({
                            retrieve: true,
                            buttons: [],
                            'columnDefs': [],
                            responsive: true,
                            pageLength: 50,
                            "order": [ 1, 'desc' ]
                        });
                        $scope.table.on('responsive-display', function (e, datatable, row, showHide, update) {
                            $scope.index = $(row.node()).data('recordId');
                            $compile(row.child())($scope);
                        });
                    }, 300);
                });
            });
        };

        $scope.doChangeProperty = function () {
            $scope.hidden_amenities = [];
            $scope.getAmenityColours();
        };

        $scope.initDatePicker = function () {
            setTimeout(function () {
                $('#bookings-date').datepicker();
            }, 0);
        };

        $scope.bookings_call_count = 0;
        $scope.showings_call_count = 0;
        $scope.loadCalendarEvents = function () {
            if (!$scope.current_calendar_view) {
                return;
            }
            $scope.bookings_call_count++;
            $scope.showings_call_count++;

            var view = $scope.current_calendar_view;

            let postdata = {
                hidden_amenities: $scope.hidden_amenities,
                start: view.start.format('MM/DD/YYYY'),
                end: view.end.format('MM/DD/YYYY'),
                bookings_call_count: $scope.bookings_call_count,
                showings_call_count: $scope.showings_call_count
            };

            const events = [];

            $http.post('/api/v1/amenity/get-bookings?ids=' + $scope.ref.property_id, postdata).success(function (data) {
                if (!data.success) {
                    return;
                }

                if (data.message != $scope.bookings_call_count) {
                    return; // subsiquent calls have been done, so we can ignroe this one
                }

                $('#calendar').fullCalendar('removeEvents');
                $scope.calendar_bookings = data.data;


                $.each(data.data, function (index, booking) {
                    var start_date = moment(booking.start_at);
                    var end_date = moment(booking.end_at);
                    if (start_date.isValid() && end_date.isValid()) {
                        var event_title = 'Unit: N/A';
                        if (booking.tenant !== null && booking.tenant.property_unit !== null) {
                            event_title = 'Unit: ' + booking.tenant.property_unit.unit;
                        }
                        if (booking.room !== null && booking.room.name) {
                            event_title = event_title + ' - ' + booking.amenity.name + ' - ' + booking.room.name;
                        } else {
                            event_title = event_title + ' - ' + booking.amenity.name;
                        }
                        events.push({
                            title: event_title,
                            start: start_date,
                            end: booking.amenity.booking_type !== 'Hourly' ? end_date.add(1, 'days') : end_date,
                            allDay: booking.amenity.booking_type !== 'Hourly',
                            color: $scope.colours['amenity' + booking.amenity.id].colour,
                            booking_index: index
                        });
                    }
                });

                $('#calendar').fullCalendar('renderEvents', events);
            });

            if ($scope.ref.isShowShowings) {
                showingService.getAllBulk($scope.ref.property_id, postdata).then(data => {

                    if (data.message != $scope.showings_call_count) {
                        return; // subsiquent calls have been done, so we can ignroe this one
                    }

                    $('#calendar').fullCalendar('removeEvents');

                    data.data.forEach(item => {
                        const start = moment(`${item.start_date} ${item.start_time}`);
                        const end = moment(`${item.start_date} ${item.start_time}`).add(30, 'minutes');
                        const title = `${item.prospect.property.name} (${item.prospect.manager?.first_name} ${item.prospect.manager?.last_name})`;
                        events.push({
                            title: title,
                            start: start,
                            end: end,
                            allDay: false,
                            color: '#F1CE07',
                            showing: item
                        });
                    });
                    $('#calendar').fullCalendar('renderEvents', events);
                });
            }
        };

        $scope.setCalendarRatio = () => {
            let ratio = 1;
            if (window.innerWidth > 600) {
                ratio = 2;
            }
            $('#calendar').fullCalendar('option', 'aspectRatio', ratio);
        };

        $scope.initCalendar = function () {
            const calendarHeader = (( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ))
                ? {left: 'title' , center: 'month,agendaWeek,listWeek today prev,next', right: '' }
                : { center: 'month,agendaWeek,listWeek'};

            $('#calendar').fullCalendar({
                locale: $rootScope.localeButtonString,
                noEventsMessage: $rootScope.fullcalendardefaults['noEventsMessage'][$rootScope.localeButtonString],
                buttonText: $rootScope.fullcalendardefaults['localeButtonText'][$rootScope.localeButtonString],
                header: calendarHeader,
                viewRender: function (view) {
                    $scope.current_calendar_view = view;
                    $scope.loadCalendarEvents();
                },
                eventClick: function (calEvent, jsEvent, view) {
                    if (typeof calEvent.booking_index !== 'undefined') {
                        $scope.calendarBookingDetails(calEvent.booking_index);
                    } else if (calEvent.showing) {
                        $scope.calendarShowingDetails(calEvent.showing);
                    }
                },
                windowResize: $scope.setCalendarRatio
            });
            $scope.setCalendarRatio();
            $scope.calendar_initilized = 1;
        };


        $scope.rejectBooking = function (event, index) {
            if (!confirm($scope.t('Are you sure you want to reject this booking?'))) {
                return;
            }

            $http.post('/api/v1/amenity/booking/cancel/' + $scope.pending_bookings[index].id).success(function (data) {
                if (!data.success) {
                    toastr.error('Error rejecting booking');
                }

                $("#pending-approval-table").DataTable()
                    .row($(event.target.parentElement.parentElement))
                    .remove()
                    .draw();
            });
        };

        $scope.calendarBookingDetails = function (index) {
            $scope.m.booking = $scope.calendar_bookings[index];
            $scope.showBookingDetails();
        };

        $scope.calendarShowingDetails = showing => {
            $scope.$apply(function () {
                $scope.showing = showing;
                $('#showing-details').appendTo('body').modal('show');
            });
        };

        $scope.pendingBookingDetails = function (index) {
            $scope.m.booking = $scope.pending_bookings[index];
            $scope.showBookingDetails();
        };
        $scope.showBookingDetails = function () {
            if($scope.m.booking.is_cooldown){
                $scope.showCooldownDetails();
                return;
            }

            // title
            var name = $scope.m.booking.amenity.name;
            $('#mbooking_title').html(name);

            // name
            if ($scope.m.booking.amenity.quantity > 1) {
                if ($scope.m.booking.room.name) {
                    name = name + ' - ' + $scope.m.booking.room.name;
                } else {
                    name = name + ' - ' + $scope.m.booking.room.id;
                }
            }
            $('#mbooking_name').html(name);

            // date string
            var date_string = $scope.m.booking.date_string;
            $('#mbooking_date_string').html(date_string);
            
            // tenant + property + unit + phone
            var tenant, property, unit, phone;
            if($scope.m.booking.tenant){
                console.log('booking', $scope.m.booking.tenant);
                tenant = $scope.m.booking.tenant.first_name + ' ' + $scope.m.booking.tenant.last_name;
                property = $scope.m.booking.tenant.property.name;
                unit = $scope.t('unit')+': N/A';
                if ($scope.m.booking.tenant != null && $scope.m.booking.tenant.property_unit != null) {
                    unit = $scope.t('unit')+': ' + $scope.m.booking.tenant.property_unit.unit;
                }
                phone = $scope.m.booking.tenant.phone_number;   

                document.getElementById('mtenant_link').addEventListener('click', function(e) {
                    e.preventDefault(); // Prevent default anchor behavior
                    window.location = '#/tenants/' + $scope.m.booking.tenant.id + '/edit';
                    $scope.closePopUp();
                });
            } else {
                tenant = $scope.m.booking.creator.first_name + ' ' + $scope.m.booking.creator.last_name;
                property = null;
                unit = null;
                phone = null;
            }
            $('#mbooking_tenant').html(tenant);

            if (property != null) {
                $('#mbooking_property').html(' - ' + property);
            }
            if (unit != null) {
                $('#mbooking_unit').html(' - ' + unit);
            }
            if(phone != null){
                $('#mbooking_phone').html(' (Phone - ' + phone + ')');
            }

            // notes
            var notes = $scope.m.booking.notes;
            $('#mbooking_notes').html(notes);

            // receipt number/reference
            $('#mbooking_reference_div').addClass('hidden');
            if ($scope.m.booking.transaction_id) {
                $('#mbooking_reference_div').removeClass('hidden');
                var reference = `<a target="_blank" href="/#/payments/receipt/${$scope.m.booking.transaction_id}">${$scope.m.booking.transaction_id}</a>`;
                $('#mbooking_reference').html(reference);
            }

            // show paid info if relevant
            $('#mbooking-paid-and-booked').addClass('hidden');
            $('#mbooking-paid').addClass('hidden');
            $('#mbooking-booked-pay-later').addClass('hidden');
            $('#mbooking-pay-waived').addClass('hidden');
            $('#mbooking-paid-amount').addClass('hidden');
            $('#mbooking-pending-payment').addClass('hidden');
            var reservation_fee_string = $scope.m.booking.amenity.reservation_fee_amount > 0 ? ' + $'+parseFloat($scope.m.booking.amenity.reservation_fee_amount).toFixed(2) : '';
            let amenity_hold_fee_total = 0;
            if ($scope.m.booking.amenity.usage_fee_holds_enabled) {
                amenity_hold_fee_total += Number($scope.m.booking.tenant.property.amenity_hold_fee);
            }
            if ($scope.m.booking.amenity.reservation_fee_holds_enabled) {
                amenity_hold_fee_total += Number($scope.m.booking.tenant.property.amenity_hold_fee);
            }

            var hold_fee_string = amenity_hold_fee_total > 0 ? ' + $'+parseFloat(amenity_hold_fee_total).toFixed(2) : '';
            if ($scope.m.booking.transaction_id != 0) {
                if ($scope.m.booking.transaction) {
                    $('#mbooking-paid-amount').removeClass('hidden');
                    $('#mbooking-amount').html('$' + $scope.m.booking.transaction.total_amount.toFixed(2) + reservation_fee_string + hold_fee_string);

                    if (
                        $scope.m.booking.status == 'Pending' &&
                        $scope.m.booking.payment_status == 'Paid'
                    ) {
                        $('#mbooking-paid').removeClass('hidden');
                        console.log('#mbooking-paid')
                    } else if ($scope.m.booking.payment_status == 'Pending') {
                        $('#mbooking-pending-payment').removeClass('hidden');
                        console.log('#mbooking-pending-payment')
                    } else {
                        $('#mbooking-paid-and-booked').removeClass('hidden');
                        console.log('#mbooking-paid-and-booked')
                    }
                } else {
                    $('#mbooking_reference').html('<span class="text-danger">Payment deleted</span>');
                }
            } else {
                if ($scope.m.booking.amenity.payment_enabled == 1 && $scope.m.booking.amenity.payment_optional == 1 && $scope.m.booking.tenant) {
                    $('#mbooking-paid-amount').removeClass('hidden');
                    $('#mbooking-amount').html('$' + parseFloat($scope.m.booking.amenity.usage_fee_amount).toFixed(2) + reservation_fee_string + hold_fee_string);
                    $('#mbooking-booked-pay-later').removeClass('hidden');
                    console.log('#mbooking-booked-pay-later')
                }else if($scope.m.booking.amenity.payment_enabled == 1 && ! $scope.m.booking.tenant){
                    $('#mbooking-paid-amount').removeClass('hidden');
                    $('#mbooking-amount').html('$' + parseFloat($scope.m.booking.amenity.usage_fee_amount).toFixed(2) + reservation_fee_string + hold_fee_string);
                    $('#mbooking-pay-waived').removeClass('hidden');
                    console.log('#mbooking-pay-waived')
                }
            }
            // ticket code
            $('#ticket_code_div').addClass('hidden');
            if ($scope.m.booking.amenity.booking_tickets) {
                $('#ticket_code_div').removeClass('hidden');
                var ticket = $scope.m.booking.ticket_code;
                $('#mbooking_ticket_code').html(ticket);
            }

            // license plate
            $('#license_plate_div').addClass('hidden');
            if ($scope.m.booking.amenity.is_license_plate) {
                $('#license_plate_div').removeClass('hidden');
                const number = $scope.m.booking.license_plate;
                $('#mbooking_license_plate').html(number);
            }

            //Cooldown
            if($scope.m.booking.cooldown_interval_in_minutes === 0 ){
                $scope.showAddCooldownButton();
            }else{
                $scope.showCooldownInfo();
            }

            // show the modal
            $('#booking-details').appendTo('body').modal('show');
        };

        $scope.showCooldownInfo = () => {
            $("#cooldown-edit-div").addClass('hidden');
            $('#add-cooldown-button').addClass('hidden');

            $('#cooldown-info-div').removeClass('hidden');

            var cooldown_info = +$scope.m.booking.cooldown_interval_in_hours + " hours"
            $('#cooldown-info').html(cooldown_info)
        };

        $scope.showAddCooldownButton = () => {
            $('#cooldown-info-div').addClass('hidden');
            $("#cooldown-edit-div").addClass('hidden');

            $('#add-cooldown-button').removeClass('hidden');
        };

        $scope.showEditCooldown = () => {
            $('#add-cooldown-button').addClass('hidden');
            $('#cooldown-info-div').addClass('hidden');

            $scope.cooldown_interval_in_hours = +$scope.m.booking.cooldown_interval_in_hours;
            $("#cooldown-edit-div").removeClass('hidden');
        };

        $scope.removeCooldown = () => {
            $http.delete(`api/v1/amenity/booking/${$scope.m.booking.id}/cooldown`).then(data =>  {

                console.log(data, $scope.calendar_bookings);

                $scope.calendar_bookings = $scope.calendar_bookings.filter(booking => booking.id !== $scope.m.booking.id);
                $scope.calendar_bookings.push(data.data);
                $scope.m.booking = data.data;
                
                $scope.showAddCooldownButton();
                toastr.success('Success');
            },
            error => {
                toastr.error('Error removing cooldown');
            });
        }

        $scope.cooldown_interval_in_hours = 0;
        $scope.updateCooldown = () => {
            let postdata = {
                cooldown_interval_in_minutes: $scope.cooldown_interval_in_hours * 60
            }
            $http.post(`api/v1/amenity/booking/${$scope.m.booking.id}/cooldown`,  postdata).then(data =>  {
                console.log(data);
                $scope.calendar_bookings = $scope.calendar_bookings.filter(booking => booking.id !== $scope.m.booking.id);
                $scope.calendar_bookings.push(data.data);
                $scope.m.booking = data.data;

                console.log($scope.calendar_bookings);


                $scope.showCooldownInfo();
                toastr.success('Success');
            },
            error => {
                console.log(error);
                toastr.error(error.data.message, 'Error editing cooldown', {timeOut:0});
            });
        }

        $scope.showCooldownDetails = () => {
            // title
            var name = $scope.m.booking.amenity.name;
            $('#mcooldown_title').html(name);
            
            // name
            if ($scope.m.booking.amenity.quantity > 1) {
                if ($scope.m.booking.room.name) {
                    name = name + ' - ' + $scope.m.booking.room.name;
                } else {
                    name = name + ' - ' + $scope.m.booking.room.id;
                }
            }
            $('#mcooldown_name').html(name);

            // date string
            var date_string = $scope.m.booking.date_string;
            $('#mcooldown_date_string').html(date_string);

            // show the modal
            $('#cooldown-details').appendTo('body').modal('show');

        }

        $scope.approveBooking = function (event, index) {
            if (!confirm($scope.t("Are you sure you want to approve this booking"))) {
                return;
            }

            $http.post('/api/v1/amenity/booking/approve/' + $scope.pending_bookings[index].id).success(function (data) {
                if (!data.success) {
                    toastr.error('Error approving booking');
                }

                $("#pending-approval-table").DataTable()
                    .row($(event.target.parentElement.parentElement))
                    .remove()
                    .draw();
                $scope.loadCalendarEvents();
            });
        };

        $scope.flipEventsFromLegend = function (index) {
            if (index) {
                var key = $scope.hidden_amenities.indexOf($scope.colours[index].amenity_id);
                if (key >= 0) {
                    $scope.hidden_amenities.splice(key, 1);
                    $scope.colours[index].hidden = 0;
                } else {
                    $scope.hidden_amenities.push($scope.colours[index].amenity_id);
                    $scope.colours[index].hidden = 1;
                }
            } else {
                $scope.ref.isShowShowings = !$scope.ref.isShowShowings;
                $scope.ref.isShowShowingsCheckbox = $scope.ref.isShowShowings;
            }

            $scope.loadCalendarEvents();
        };

        $http.post('/api/v1/amenity/is-enabled').success(function (data) {
            if (data.data.enabled == 0 && data.data.showings_enabled == 0) {
                // not enabled for this user
                $state.go('main.dashboard', {redirect: true});
                return 1;
            }

            $scope.amenities_enabled = data.data.enabled;
            $scope.showings_enabled  = data.data.showings_enabled;

            if ($rootScope.authUser.permissions == 'tenant') {
                $state.go('main.amenityBook');
                return 1;
            }

            $scope.getProperties();
        });
        $scope.closePopUp = function () {
            $('.modal-backdrop').hide();
        }
    }
}());
