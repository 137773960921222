(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/authentication/views/';

        $stateProvider
            .state('main.register', {
                url: '/register',
                views: {
                    "body@": {
                        templateUrl: url + 'register.html',
                        controller: 'AuthenticationRegisterController'
                    }
                }
            })
            .state('main.register.step_2', {
                templateUrl: url + 'register.step_2.html'
            })
            .state('main.register.step_3', {
                templateUrl: url + 'register.step_3.html'
            })
            .state('main.login', {
                url: '/login-old',
                views: {
                    "body@": {
                        controller: 'AuthenticationLoginController',
                        templateProvider: function ($http, $rootScope, $templateCache) {
                            const templateUrl = $rootScope.pqAppType === 'manager-app'
                                ? url + 'login-managers-mobile.html'
                                : url + 'login.html';

                            const tplFromCache = $templateCache.get(templateUrl);

                            if (tplFromCache) {
                                // return tplFromCache;
                            }

                            return $http
                                .get(templateUrl)
                                .then((response) => {
                                    const tpl = response.data
                                    $templateCache.put(templateUrl, tpl);
                                    return tpl;
                                })
                        },
                    }
                }
            })
            .state('main.email', {
                url: '/email',
                views: {
                    "body@": {
                        templateUrl: url + 'email.html',
                        controller: 'AuthenticationLoginController'
                    }
                }
            })
            .state('main.emailConfirmation', {
                url: '/email-confirmation/:user_id/:code',
                views: {
                    "body@": {
                        templateUrl: 'scripts/modules/authentication/email_confirmation/email_confirmation.html',
                        controller: 'EmailConfirmationController',
                        controllerAs: 'vm'
                    }
                }
            });

    }

}());
