(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyHoldsFeeMerchant', pqPropertyHoldsFeeMerchant);

    function pqPropertyHoldsFeeMerchant() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-holds-fee-merchant/property-holds-fee-merchant.html'
        };
    }

})();
