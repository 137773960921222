(function () {

    'use strict';

    angular
        .module('app')
        .controller('PortfolioEditController', function (
            $scope, $state, resolvedCompany, resolvedPortfolio, resolvedProperties, $timeout, portfolioService
        ) {
            $scope.company = resolvedCompany;
            $scope.portfolio = resolvedPortfolio;
            $scope.properties = resolvedProperties.filter(property => property.company_id === $scope.company.id);
            if(+$scope.portfolio.id !== 0){
                $scope.portfolio.properties = $scope.portfolio.properties.map(item => item.id);
            }

            $timeout(function () {
                $('select#properties').multipleSelect({filter: true, width: '100%', placeholder: 'Properties'});
            });

            $scope.save = form => {
                form.name.$setTouched();
                form.description.$setTouched();
                form.properties.$setTouched();
                if (!$scope.portfolio.properties.length) {
                    form.properties.$setValidity('validatedFromController', false);
                    form.properties.$setTouched();
                } else {
                    form.properties.$setValidity('validatedFromController', true);
                }

                if (!form.$invalid) {
                    $scope.portfolio.properties = $scope.portfolio.properties.map(item => {
                        return {id: item};
                    });
                    if (+$scope.portfolio.id === 0) {
                        portfolioService.create($scope.portfolio);
                    } else {
                        portfolioService.update($scope.portfolio);
                    }
                    $state.go('main.companyEdit', {companyId: $scope.company.id});
                }
            };
        });

}());
