(function () {

    'use strict';

    angular
        .module('app')
        .controller('InspectionCreateController', function (
            $scope, $state, $timeout, resolvedProperties, propertyService, resolvedTemplates, inspectionService
        ) {
            window.scrollTo(0, 0);
            let body = document.querySelector('body');
            body.style.overflowY = 'hidden';

            $scope.$on("$destroy", function() {
                let body = document.querySelector('body');
                body.style.overflowY = 'unset';
            });

            $scope.inspection = inspectionService.getBlank();
            $scope.properties = resolvedProperties.filter(property => property.inspections_enabled === 1);
            $scope.units = [];
            $scope.templates = resolvedTemplates;
            $scope.isLoading = false;
            $scope.isSaving = false;
            $scope.recurringPeriods = {
                daily: [],
                weekly: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                monthly: [],
                yearly: ["January", "February", "March", "April", "May", "June", "July",
                    "August", "September", "October", "November", "December"],
            }

            $timeout(() => {
                $('#property').multipleSelect({
                    filter: true,
                    single: true,
                });
            }, 0);

            for (let hour = 0; hour < 25; hour++) {
                $scope.recurringPeriods.daily.push(moment({hour}).format('h:mm A'));
            }

            for (let day = 1; day <= 28; day++) {
                $scope.recurringPeriods.monthly.push(String(day));
            }

            $scope.propertyChanged = () => {
                $scope.isLoading = true;
                propertyService.getUnits($scope.inspection.property_id).then(units => {
                    $scope.units = units;
                    $scope.isLoading = false;
                    $timeout(() => {
                        $('#unit').multipleSelect({
                            filter: true,
                            single: true,
                        });
                    }, 0);
                });
            };

            $scope.selected_floorplan = null;
            $scope.checkForFloorplan = () => {
                // use $scope.inspection.unit_id to search $scope.units.  see if selected unit has a floorplan
                let unit = $scope.units.find(unit => unit.id == $scope.inspection.unit_id);
                $scope.selected_floorplan = unit && unit.floorplan ? unit.floorplan : null;
            }

            if ($scope.inspection.property_id) {
                $scope.propertyChanged();
            }

            $timeout(() => {
                $('#inspection-date').datepicker({
                    minDate: 0
                });
            }, 0);

            $scope.save = () => {
                $scope.isSaving = true;
                inspectionService.create($scope.inspection).then(() => {
                    toastr.success('Inspection was created successfully.');
                    $state.go('main.inspections', {}, {reload: true});
                });
            };

            $scope.$watch('inspection.period', function (newVal, oldVal) {
                $scope.inspection.cutoff = null;
            });

        });

}());
