(function() {

    'use strict';

    angular
        .module('app')
        .controller('MaintenanceRequestsChartsController', ReportController);

    function ReportController($scope, $state, $stateParams, $rootScope, $http, $timeout) {
        if (!$rootScope.authUser.maintenance_requests_pro) {
            $state.go('main.maintenanceRequests');
            return;
        }

        $scope.propertiesReceived = false;
        $scope.properties = [];
        $http.get('/api/v1/reports/filter?mr=true').then((data) => {
            $scope.properties = Object.fromEntries(data.data);
            $timeout(() => {
                $('#propertyFilter').multipleSelect({filter: true, selectAll: true });
                $scope.propertiesReceived = true;
            }, 0)
        }, err => console.log(err));

        $scope.managersReceived = false;
        $scope.managers = [];
        $http.get('/api/v1/maintenance-request-reports/managers').then((data) => {
            $scope.managers = data.data;
            $timeout(() => {
                $('#managerFilter').multipleSelect({filter: true, selectAll: true });
                $scope.managersReceived = true;
            }, 0)
        }, err => console.log(err));

    }
}());
