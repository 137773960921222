(function() {
    'use strict';

    angular
    .module('app')
    .controller('DocumentsIndexController', documentsIndexController);

    function documentsIndexController($scope,$state,$rootScope,$http,$timeout,paymentScheduleService, langService) {

        if ($rootScope.authUser.permissions == 'tenant' && !$rootScope.authUser.user_permissions.documents) {
            $state.go('main.dashboard');
        }

        $scope.t = langService.t('documents');
        $scope.p = langService.t('payments');

        $scope.requests_count = 0;
        $scope.requestable_count = 0;
        angular.element($('body')).scope().myscope = $scope;
        $scope.payable_documents = [];
        $scope.document_ids = [];
        $scope.finalize_payment = 0;
        $scope.show_reciept = 0;
        $scope.transaction = {};
        $scope.loading_spinner = 0;
        $scope.property_units = [];
        $scope.hide_lists = 0;

        // stuff for realtor version of list
        $scope.realtor = {
            property_id: 0,
            properties: [],
            property_search: '',
            document_unit_id: 0,
            selected_unit: {},
            unit_search: '',
            selected_property: {},
            documents: [{
                id: 0,
                document_fee: 0,
                name: ''
            }],
            document_fee_days: 0,
            service_fee: 0,
            service_fee_tax: 0,
            document_fee_tax: 0,
            document_tax_rate: 0,
            total_amount: 0,
            convenience_fees: 0,
            fee_percent: 0
        };

        $scope.document_message = '';
        $scope.show_document_message = 0;
        $scope.getDocumentMessage = function(){
            // if tenant, will be zero
            var property_id = $scope.realtor.property_id;
            $http.post('/api/v1/document/get-property-message/' + property_id, {}).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.document_message = data.data.message;
                $scope.show_document_message = data.data.enabled;
            });
        };
        if ($rootScope.authUser.permissions == 'tenant') {
            $scope.getDocumentMessage();
        }

        $scope.selectProperty = function(property) {
            $scope.realtor.property_id = property.id;
            $rootScope.realtor_property_id = property.id;
            $scope.getDocumentMessage();
            $scope.realtor.selected_property = property;
            $scope.realtor.properties = [];
            $scope.realtor.property_search = '';

            $scope.getPropertyUnits($scope.realtor.property_id);

            //var table = $('#dataTable').DataTable();
            //table.search( filters.join('^^') ).draw();
        };

        $scope.getPropertyUnits = function(property_id) {
            $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + property_id).success(function(data){
                if(!data.success){
                    toastr.error('',data.errors);
                    return;
                }

                $scope.property_units = [];
                angular.forEach(data.data, function(unit, key){
                    if (!$scope.property_units.includes(unit.unit)) {
                        $scope.property_units.push(unit.unit);
                    }
                });
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error getting property units');
            });
        };

        $scope.documentEmail = function(id){
            $http.post('/api/v1/document/email/'+id, {}).success(function(data) {
                if (!data.success) {
                    toastr.error('Error emailing document. You are not authorized to download this file.');
                    return;
                }
                toastr.success('The requested document has been emailed to you');
            });
        };

        $scope.doRealtorUnitSearch = function(){
            if ($scope.realtor.unit_search == '' && $rootScope.authUser.permissions != 'tenant') {
                toastr.error('Please input the unit');
            }

            var postdata = {
                property_id: $rootScope.authUser.permissions == 'tenant' ? '99' : $scope.realtor.property_id,
                search: $rootScope.authUser.permissions == 'tenant' ? '99' : $scope.realtor.unit_search,
                document_ids: $scope.document_ids
            };

            $http.post('/api/v1/document/realtor-unit-search', postdata).success(function(data) {
                if (!data.success) {
                    toastr.error('Could not find unit');
                    return;
                }

                if (data.data.payment_required == 0) {
                    if ($scope.payable_documents.length == 1) {
                        if(window.Capacitor.isNativePlatform()) {
                            window.Capacitor.InAppBrowser.open(pqDomain + '/document-download/' + $scope.payable_documents[0].id + '/' + data.data.unit.id + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken, '_system');
                        } else {
                            window.open('/document-download/' + $scope.payable_documents[0].id + '/' + data.data.unit.id, '_blank');
                        }
                    } else {
                        toastr.success('None of the selected docs require payment, please click on view link in list');
                    }
                    $('#realtor-payment-modal').modal('hide');
                    return;
                }

                $scope.realtor.service_fee       = data.data.service_fee;
                $scope.realtor.service_fee_tax   = data.data.service_fee_tax;
                $scope.realtor.documents         = data.data.document_fees;
                $scope.realtor.document_fee_tax  = data.data.document_fee_tax;
                $scope.realtor.document_tax_rate = data.data.document_tax_rate;
                $scope.realtor.document_fee_days = data.data.document_fee_days;
                $scope.realtor.total_amount      = data.data.total_amount;
                $scope.realtor.document_unit_id  = data.data.unit.id;
                $scope.realtor.selected_unit     = data.data.unit;
                $scope.realtor.unit_search       = '';
                $scope.lang.valid_for_days = $rootScope.trans('documents.valid_for_days', {document_fee_days : $scope.realtor.document_fee_days});
            });
        };

        $scope.doRealtorPropertySearch = function(){
            $scope.document_message = '';
            $scope.show_document_message = 0;
            if ($scope.realtor.property_search == '') {
                toastr.error('Please input something in the search box');
            }

            $http.post('/api/v1/document/realtor-property-search', {search: $scope.realtor.property_search}).success(function(data) {
                if (!data.success) {
                    toastr.error('Error performing search');
                    return;
                }

                $scope.document_ids = [];
                $scope.realtor.properties = data.data.properties;
                $scope.realtor.property_id = 0;
            });
        };

        $scope.getRequestsCount = function(){
            $http.post('/api/v1/document/get-requests-count').success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.requests_count = data.data.requests_count;
            });
        };
        if ($rootScope.authUser.permissions != 'realtor') {
            $scope.getRequestsCount();
        }

        $scope.getRequestableCount = function(){
            $http.post('/api/v1/document/get-requestable-count').success(function(data) {
                $scope.requestable_count = data.requestable_count;
            });
        };
        $scope.getRequestableCount();

        $scope.realtorShowPayModal = function(document_id){
            $scope.document_ids = [document_id];
            $scope.getDocumentsForPayment();
        };

        $scope.getDocumentsForPayment = function(){
            if ($scope.document_ids.length == 0) {
                toastr.error($rootScope.trans('documents.please_select'));
                return;
            }

            $scope.realtor.documents = [{
                id: 0,
                document_fee: 0,
                name: ''
            }];
            $scope.realtor.service_fee = 0;
            $scope.realtor.service_fee_tax = 0;
            $scope.realtor.document_fee_tax = 0;
            $scope.realtor.document_tax_rate = 0;
            $scope.realtor.document_fee_days = 0;
            $scope.realtor.total_amount = 0;
            $scope.realtor.document_unit_id = 0;
            $scope.realtor.selected_unit    = {};
            $scope.realtor.unit_search      = '';
            $scope.finalize_payment = 0;
            $scope.realtor.convenience_fees = 0;
            $scope.realtor.fee_percent = 0;

            $http.post('/api/v1/document/get-for-realtor-payment', {document_ids: $scope.document_ids}).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.payable_documents = data.data.documents;
                if ($rootScope.authUser.permissions == 'realtor') {
                    $scope.getPaymentMethods();
                } else {
                    $scope.getTenantPaymentMethods();
                }
            });
        };

        $scope.purchaseMultiple = function(){
            $scope.setDocumentIds();
            $scope.getDocumentsForPayment();
        };

        $scope.setDocumentIds = function(){
            // lets remove all that are displayed on this page
            $("input[name='document_ids[]']").each(function (){
                var value = parseInt($(this).val()); // eslint-disable-line angular/controller-as-vm
                for (var i=0; i < $scope.document_ids.length; i++) {
                    if ($scope.document_ids[i] === value) {
                        $scope.document_ids.splice(i,1);
                    }
                }
            });

            // now we can add back any that are checked
            $("input[name='document_ids[]']:checked").each(function (){
                $scope.document_ids.push(parseInt($(this).val())); // eslint-disable-line angular/controller-as-vm
            });
        };

        $scope.getDocumentIds = function(){
            return $scope.document_ids;
        };

        $scope.finalizePayment = function () {
            // if (!$scope.merchantAccount) {
            //     // for user type tenant
            //     $scope.merchantAccount.provider = ref.payment_method.provider;
            // }

            if ($scope.merchantAccount && $scope.merchantAccount.provider === 'payrix') {
                if ($scope.tenantData.paymentType === 'pad') {
                    $scope.tenantData.provider = 'payrix';
                    $scope.sendPayment();
                } else {
                    PayFields.submit()
                    PayFields.onSuccess = (r) => {
                        console.log('onSuccess', r)
                        $scope.tenantData.credit_card_type = r.data[0].payment.method;
                        $scope.tenantData.card_holder_name = `${r.data[0].customer.first} ${r.data[0].customer.last}`;
                        $scope.tenantData.card_number = r.data[0].payment.number;
                        $scope.tenantData.expiration_year = 20 + r.data[0].expiration.slice(-2);
                        $scope.tenantData.expiration_month = r.data[0].expiration.substring(0, 2);
                        $scope.tenantData.payrix = r;
                        $scope.tenantData.provider = 'payrix';
                        $scope.$apply();
                        $scope.sendPayment();
                    };
                }
            } else {
                $scope.sendPayment();
            }
        }

        $scope.sendPayment = function(){
            if ($rootScope.authUser.permissions == 'realtor') {
                if ($scope.merchantAccount.provider !== 'payrix') {
                    $scope.tenantData.credit_card_type = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();
                }
                var postdata = {
                    property_id: $scope.realtor.property_id,
                    total_amount: $scope.realtor.total_amount,
                    type: $scope.tenantData.paymentType,
                    credit_card_type: $scope.tenantData.credit_card_type,
                    provider: $scope.merchantAccount.provider
                };
                $http.post('/api/v1/document/get-convenience-fees', postdata).success(function(data) {
                    if (!data.success) {
                        toastr.error('Could not finalize Payment');
                    }

                    $scope.realtor.convenience_fees = data.data.tenant_convenience_fees;
                    $scope.realtor.fee_percent = data.data.fee_percent;
                    $scope.finalize_payment = 1;
                });
            } else {
                var input = {
                    payment_method_id: $scope.ref.payment_method.id,
                    total_amount: $scope.realtor.total_amount
                };
                paymentScheduleService.getConvenienceFees(input).success(function(data) {
                    $scope.realtor.convenience_fees = data.data.tenant_convenience_fees;
                    $scope.realtor.fee_percent = data.data.fee_percent;
                    $scope.finalize_payment = 1;
                });
            }
        };



        ////////////////////////
        //// Payment Stuff /////
        ////////////////////////
        $scope.tenantInfo = false;
        $scope.billing_same_as_mailing = true;
		$scope.tenantData = {paymentType :false};

        $scope.ref = {
            payment_method : {},
            tenant : {}
        };
        $scope.paymentMethods = {};
        $scope.paymentMethodCount = 0;

        $scope.getTenantPaymentMethods = function(){
            $scope.tenantInfo = false;
            // all docs are for a single property / company, so we can just pass the first one

			$http.get('/api/v1/payments/payment-methods?filter=usable').success(function(data) {
                $scope.paymentMethods = data.data;
                $scope.paymentMethodCount = (data.data).length;
                $scope.clearTenantData();
                $scope.doRealtorUnitSearch();
                $('#realtor-payment-modal').appendTo('body').modal('show');
            });
        };

        $scope.getPaymentMethods = function(){
            $scope.tenantInfo = false;
            var postdata = {
                property_id: $scope.realtor.property_id
            };
            // all docs are for a single property / company, so we can just pass the first one
            $http.post('/api/v1/document/payment-methods', postdata).success(function(data) {
                if (data.success) {
                    $scope.merchantAccount = data.data.merchant_account;

                    //$scope.tenantData.tenant_id = data.data.tenant.id;
                    //$scope.tenantData.amount = data.data.payment_amount * 1; //TODO
                    $scope.tenantInfo = data.data;
                    $scope.tenantInfo.hasCredit = false;
                    $scope.tenantInfo.hasDebitVisa = false;
                    $scope.tenantInfo.hasDirectDebit = false;

                    $scope.tenantInfo.CreditFeeString = 'Service Fees:<br />';
                    $scope.tenantInfo.DebitVisaFeeString = 'Service Fee: ';
                    $scope.tenantInfo.DirectDebitFeeString = 'Service Fee: ';

                    for(var i in $scope.tenantInfo.methods){
                        if($scope.tenantInfo.methods[i].value == 'credit') {
                            $scope.tenantInfo.hasCredit = true;
                        } else if($scope.tenantInfo.methods[i].value == 'debit_visa') {
                            $scope.tenantInfo.hasDebitVisa = true;
                            $scope.tenantInfo.DebitVisaFeeString+= '' + $scope.tenantInfo.methods[i].tenant_fee + ' per transaction';
                        } else if($scope.tenantInfo.methods[i].value == 'direct_debit') {
                            $scope.tenantInfo.hasDirectDebit = true;
                            $scope.tenantInfo.DirectDebitFeeString+= '' + $scope.tenantInfo.methods[i].tenant_fee + ' per transaction';
                        } else {
                            $scope.tenantInfo.CreditFeeString+= $scope.tenantInfo.methods[i].name + ': ' + $scope.tenantInfo.methods[i].tenant_fee + ' per transaction<br />';
                        }
                    }

                    $scope.clearTenantData();
                    $('#realtor-payment-modal').appendTo('body').modal('show');
                } else {
                    toastr.error(data.data.error);
                }
            });
        };

        $scope.clearTenantData = function(){
            $scope.tenantData.institution_number = '';
            $scope.tenantData.transit_number = '';
            $scope.tenantData.account_number = '';
            $scope.tenantData.day_of_month = '';
            $scope.tenantData.description ='';
            $scope.tenantData.editing_schedule_id = 0;
            $scope.tenantData.paymentType = false;
            $scope.tenantData.payment_recurring = 'one_time';
            $scope.tenantData.billingunit = '';
            $scope.tenantData.address = '';
            $scope.tenantData.city = '';
            $scope.tenantData.province = '';
            $scope.tenantData.country = '';
            $scope.tenantData.postal_code = '';
            $scope.billing_same_as_mailing = true;
            $scope.tenantData.card_holder_name = '';
            $scope.tenantData.card_number = '';
            $scope.tenantData.expiration_year = '';
            $scope.tenantData.expiration_month = '';
            $scope.tenantData.cvv = '';
        };

        $scope.submitRequest = function(){
            if ($rootScope.authUser.permissions == 'tenant') {
                $scope.tenantData.tenant_payment_method_id = $scope.ref.payment_method.id;
            } else {
                $scope.tenantData.credit_card_type = jQuery('#card_type').text().replace("American Express","amex").toLowerCase();
            }
            $scope.tenantData.realtor = $scope.realtor;
            $scope.loading_spinner = 1;

			$http.post('/api/v1/document/submit-requests', $scope.tenantData).success(function(result){
                $('.toast').remove();
                $scope.loading_spinner = 1;
                if(result.code == 200 && result.success) {
                    $scope.clearTenantData();
                    $('#realtor-payment-modal').modal('hide');
                    toastr.success('Request submitted, you will be notified when they are fullfilled.');
                    $scope.realtor.property_id = 0;
                    $scope.hide_lists = 1;
                    $timeout(function(){
                        $scope.hide_lists = 0;
                    },500);
                }

            });
        };

        $scope.processPayment = function(){

            if ($rootScope.authUser.permissions == 'tenant') {
                $scope.tenantData.tenant_payment_method_id = $scope.ref.payment_method.id;
            } else {
                $scope.tenantData.credit_card_type = jQuery('#card_type').text().replace("American Express","amex").toLowerCase();
            }
            $scope.tenantData.realtor = $scope.realtor;
            $scope.loading_spinner = 1;

			$http.post('/api/v1/document/process-payment', $scope.tenantData).success(function(result){
                $('.toast').remove();
                if(result.code == 200 && result.success) {
                    $scope.clearTenantData();
                    $('#realtor-payment-modal').modal('hide');
                    $scope.transaction = result.data.transaction;
                    $scope.show_reciept = 1;
                    if ($scope.payable_documents.length == 1) {
                        if ($scope.payable_documents[0].type == 'request') {
                            toastr.success('Your payment has been processed. You will be notified once your request is fulfilled');
                        } else {
                            toastr.success('Your payment has been processed.');
                            //$timeout(function(){
                                //window.open('/document-download/' + $scope.payable_documents[0].id + '/' + $scope.realtor.selected_unit.id, '_blank');
                                //return;
                            //},500);
                        }
                    } else {

                        toastr.success('Your payment has been processed. For any Requestable Documents, you will be notified when they are fullfilled.  For other documents, please click the view link and enter the unit number');
                    }
                    $timeout(function(){
                        $state.go('main.paymentReceipt', {id: $scope.transaction.id});
                    },500);
                } else {
                    $('.toast').remove();
                    if (result.extra && result.extra.validation) {
                        console.log(result.extra.validation)
                        $scope.finalize_payment = 0;
                        $scope.payrixValidationErrors = result.extra.validation;
                    }
                    if(typeof result.message != 'undefined') {
                        toastr.error(result.message);
                    } else if (result.errors.length > 0) {
                        result.errors.forEach(msg => toastr.error(msg));
                    } else {
                        toastr.error('error processing payment method');
                    }
                }
                $scope.loading_spinner = 0;

            })
            .error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $('.toast').remove();
                toastr.error('Error creating a payment method');
            });
        };

        $scope.goToPaymentMethod = function(){
            if (!confirm($rootScope.trans('documents.new_payment_method_warning'))) {
                return;
            }

            $('#realtor-payment-modal').modal('hide');
            $timeout(function(){
                $state.go('main.paymentMethodCreate');
            },500);
        };

    }
}());
