(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        const url = 'scripts/modules/payment/payments/';

        $stateProvider
            .state('main.payments', {
                url: '/payments',
                views: {
                    'body@': {
                        templateUrl: url + 'index/payments.index.html',
                        controller: 'PaymentsIndexController',
                        resolve: {}
                    }
                }
            })
            .state('main.paymentsTenant', {
                url: '/payments/list/:tenantId',
                views: {
                    'body@': {
                        templateUrl: url + 'index/payments.index.html',
                        controller: 'PaymentsIndexController',
                        resolve: {}
                    }
                }
            })
            .state('main.paymentsUpdate', {
                url: '/payments/update',
                views: {
                    "body@": {
                        templateUrl: url + 'update/payments.update.html',
                        controller: 'PaymentsUpdateController',
                        controllerAs: 'vm'
                    }
                }
            });

    }

}());
