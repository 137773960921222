(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/credit-check/';

        $stateProvider
            .state('main.creditChecks', {
                url: '/credit-checks',
                views: {
                    'body@': {
                        templateUrl: url + 'index/index.html',
                        controller: 'CreditChecksIndexController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.creditCheckEdit', {
                url: '/credit-check/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'edit/edit.html',
                        controller: 'CreditCheckEditController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
}());
