(function() {

    'use strict';

    angular
        .module('app')
        .controller('FormController', controller);

    function controller() {

        var vm = this;

        $( "#datepicker" ).datepicker({
            minDate: 0,
            beforeShowDay: function (date) {
                //getDate() returns the day (0-31)
                if (date.getDate() <= 28) {
                    return [true, ''];
                }
                return [false, ''];
            },
            dateFormat : "MM/DD/YY",
            defaultDate: moment().format("MM/DD/YY")
        });

        vm.day = moment().format("MM/DD/YY");
    }

}());