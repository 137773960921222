(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageDashboardCreateController', MessageDashboardCreateController);

    function MessageDashboardCreateController($scope,$http,$state,$rootScope,$timeout) {

        $scope.submitting = false;

        $scope.message = {
            message: '',
            notice_message: '',
            is_notice: 0,
            properties: [],
            start_at: '',
            end_at: '',
            company_id: 0,
            email_alerts: 0
        };

        $scope.dropdowns = {
            companies: {},
            properties: []
        };

        $scope.getProperties = function() {
            $http.post('/api/v1/dashboard-message/get-properties', {company_id: $scope.message.company_id}).success(function(data) {
                $scope.dropdowns.properties = data.data.properties;
                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        }

        $scope.createDashboardMessage = function() {
            if ($scope.message.email_alerts == true) {
                if (!confirm('You are about to send emails to the tenants of all selected properties.  Are you ready to send?')) {
                    return;
                }
            }
            $scope.submitting = true;

            var form_data = new FormData();
            if (document.getElementById('file1').files.length > 0) {
                form_data.append('document', document.getElementById('file1').files[0]);
            }

            $.each($scope.message, function (index, value) {
                if (index == 'properties') {
                    var properties = [];
                    $.each(value, function (pindex, property) {
                        properties[pindex] = property;
                    });
                    form_data.append(index, properties);
                } else {
                    form_data.append(index, value);
                }
            });

            $http.post('/api/v1/dashboard-message/create', form_data, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            }).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error creating dashboard message");
                    return;
                }

                $scope.submitting = false;
                $state.go('main.messagesDashboardList');
                toastr.success('Dashboard Message Created');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        }

        $scope.companyChanged = function(){
            $scope.getProperties();
        }

        $scope.initDatePickers = function(){
            setTimeout(function(){
                $('#start-date').datepicker();
                $('#end-date').datepicker();
            }, 0);
        }

        $http.post('/api/v1/dashboard-message/is-enabled').success(function(data) {
            if (!data.success) {
                // not enabled for this user
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            if ($rootScope.authUser.permissions == 'tenant') {
                $state.go('main.dashboard', { redirect : true });
                return 1;
            }

            if ($rootScope.authUser.permissions == 'payquad_admin') {
                $http.post('/api/v1/dashboard-message/get-companies').success(function(data) {
                    $scope.dropdowns.companies = data.data.companies
                    $scope.message.company_id = $scope.dropdowns.companies[0].id;
                    $scope.getProperties();
                });
            } else {
                $scope.getProperties();
            }

            $scope.initDatePickers();
        });
    }
}());
