(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyExtension', pqPropertyExtension);

    function pqPropertyExtension() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-edit/property-extension/property-extension.html'
        }
    }

})();