(function () {
    'use strict';

    angular
        .module('app')
        .controller('AmenityBookCreateController', amenityBookCreateController);

    function amenityBookCreateController($scope, $http, $state, $stateParams, $rootScope, $timeout, paymentScheduleService, langService, jwtService) {
        $scope.loaded = false;

        $scope.token = jwtService.getToken();
        $scope.property_id = $stateParams.property_id;
        $scope.show_tenants = 0;

        $scope.t = langService.t('amenities');
        // for manager payments
        var vm = this;
        vm.tenantInfo = false;
        vm.billing_same_as_mailing = true;
        vm.tenantData = {paymentType: false};

        $scope.quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        $scope.times = [];
        $scope.durations = [];
        $scope.closingTimes = [];
        $scope.available = 0;
        $scope.amenity = {
            id: $stateParams.id
        };
        $scope.rooms = [];
        $scope.choose = 0;
        $scope.booking = {
            amenity_id: $stateParams.id,
            room_id: 0,
            //start_date: moment().format('MM/DD/YYYY'),
            start_date: '',
            end_date: moment().format('MM/DD/YYYY'),
            start_time: '',
            duration: '',
            notes: '',
            opening_time: '',
            closing_time: '',
            terms: 0,
            tenant_id: 0,
            transaction_id: 0,
            recuringType: 'Daily',
            recurringEndDate: moment().format('MM/DD/YYYY'),
            reccuringDates: [],
            reccuringEnabled: false,
            pay_now: 1,
            license_plate: ''
        };
        $scope.ref = {
            payment_method: {},
            tenant: {}
        };
        $scope.paymentMethods = [];
        $scope.paymentMethodCount = 0;
        $scope.submitting = false;

        $scope.startDatepicker = null;
        $scope.endDatepicker = null;

        $scope.total_usage_fee = $scope.amenity.usage_fee_amount;


        if (!$rootScope.authUser.user_permissions['create-bookings']){
            $state.go('main.amenityBook');
        }

        $scope.recuringType = 'Daily';
        $scope.reccuringEnabled = false;
        $scope.checkBulkAvailability = () => {
            $http.post('/api/v1/amenity/bulk-check-availability/' + $scope.amenity.id, $scope.booking).success(function (data) {
                if (data.success) {
                    if (data.data.selected_dates.length > 0) {
                        $scope.booking.reccuringDates = data.data.selected_dates
                        toastr.success('Dates available')
                    } else {
                        toastr.error('Not available')
                    }
                } else {
                    if (typeof(data.message) != 'undefined') {
                        toastr.error(data.message);
                    } else {
                        toastr.error('Not Available');
                    }
                }
            });
        }

        $scope.startProcessing = function () {
            toastr.info($scope.t('Please wait, your booking is being created'), $scope.t('Processing'));
            $scope.submitting = true;
        };

        $scope.endProcessing = function () {
            $scope.submitting = false;
        };

        $scope.handleReturnedTenant = function (data) {
            vm.tenantData.tenant_id = data.data.tenant.id;
            vm.tenantData.amount = $scope.total_usage_fee * 1;
            vm.tenantInfo = data.data;
            vm.tenantInfo.hasCredit = false;
            vm.tenantInfo.hasDebitVisa = false;
            vm.tenantInfo.hasDirectDebit = false;
            console.log('handleReturnedTenant', vm.tenantData.amount);

            for (var i in vm.tenantInfo.methods) {
                if (vm.tenantInfo.methods[i].value == 'credit')
                    vm.tenantInfo.hasCredit = true;
                else if (vm.tenantInfo.methods[i].value == 'debit_visa')
                    vm.tenantInfo.hasDebitVisa = true;
                else if (vm.tenantInfo.methods[i].value == 'direct_debit')
                    vm.tenantInfo.hasDirectDebit = true;
            }

            $scope.clearTenantData();
        };

        $scope.fetchTenantFromUnitId = function (unit_id) {
            vm.tenantInfo = false;
            var url = '/api/v1/tenants/find-by-unit-id/' + unit_id;
            $http.get(url).success(function (data) {
                if (data.success) {
                    $scope.handleReturnedTenant(data);
                } else {
                    toastr.error(data.data.error);
                }
            });
        };

        $scope.clearTenantData = function () {
            vm.tenantData.institution_number = '';
            vm.tenantData.transit_number = '';
            vm.tenantData.account_number = '';
            vm.tenantData.day_of_month = '';
            vm.tenantData.amount = $scope.total_usage_fee * 1;
            vm.tenantData.description = '';
            vm.tenantData.editing_schedule_id = 0;
            vm.tenantData.paymentType = false;
            vm.tenantData.payment_recurring = 'one_time';
            vm.tenantData.billingunit = '';
            vm.tenantData.address = '';
            vm.tenantData.city = '';
            vm.tenantData.province = '';
            vm.tenantData.country = '';
            vm.tenantData.postal_code = '';
            vm.billing_same_as_mailing = true;
            vm.tenantData.card_holder_name = '';
            vm.tenantData.card_number = '';
            vm.tenantData.expiration_year = '';
            vm.tenantData.expiration_month = '';
            vm.tenantData.cvv = '';
        };

        $scope.getPaymentMethods = function () {
            $http.get('/api/v1/payments/payment-methods?filter=usable').success(function (data) {
                $scope.paymentMethods = data.data;

                //can't use DD for holds
                if($scope.amenity.usage_fee_holds_enabled || $scope.amenity.reservation_fee_holds_enabled){
                    $scope.paymentMethods = $scope.paymentMethods.filter(function (item) {
                        return item.type !== 'direct_debit';
                    });
                }

                $scope.paymentMethodCount = $scope.paymentMethods.length;
            });
        };

        $scope.getConvenienceFees = function () {
            let is_tenant = $rootScope.authUser.permissions === 'tenant';

            let payment_method_type = jQuery('#card_type').text().replace("American Express","amex").toLowerCase();
            if(vm.tenantData.paymentType == 'pad'){
                payment_method_type = 'direct_debit';
            }else if(vm.tenantData.paymentType == 'debit'){
                payment_method_type = 'debit_visa';
            }

            if ( vm.tenantInfo && vm.tenantInfo.tenant.providers.property === 'payrix') {
                switch (vm.tenantData.paymentType) {
                    case 'debit':
                        payment_method_type = 'debit_visa';
                        break;
                    case 'credit':
                        payment_method_type = 'visa';
                        break;
                    case 'pad':
                        payment_method_type = 'direct_debit';
                        break;
                }
            }

            if(!payment_method_type && !$scope.ref.payment_method.id){ return; }

            var input = {
                payment_method_id: $scope.ref.payment_method.id,
                property_id: $scope.property_id,
                total_amount: +$scope.total_usage_fee + +$scope.amenity.reservation_fee_amount,
                is_tenant: is_tenant,
                payment_method_type: payment_method_type
            };

            paymentScheduleService.getConvenienceFees(input).success(function (data) {
                $scope.convenience_fees = data.data.tenant_convenience_fees;
                $scope.fee_percent = data.data.fee_percent;
            });
        };

        $scope.$watch('vm.tenantData.paymentType', function (newValue, oldValue) {
            $scope.convenience_fees = null;
            $scope.fee_percent = 0;
            $scope.getConvenienceFees();
        });

        $scope.showTerms = function(show = false) {
            if ($scope.booking.terms == 0 && !show) {
                return;
            }

            $('.amenity-terms').appendTo('body').modal('show');
        };


        $scope.$on("$destroy", function(){
            $('.amenity-terms').remove();
        })

        $scope.okToSubmit = function () {
            var toreturn = true;
            if ($scope.submitting) {
                return false;
            }

            if ($scope.choose && $scope.booking.room_id == 0) {
                toastr.error($rootScope.trans('amenities.form_incomplete'), 'Room is required');
                return false;
            }

            if ($scope.booking.terms == 0) {
                toastr.error($rootScope.trans('amenities.form_incomplete'), 'Terms and Conditions');
                return false;
            }

            if ($scope.amenity.payment_enabled && $rootScope.authUser.permissions == 'tenant') {
                if(+$scope.booking.pay_now === 1 && (typeof $scope.ref.payment_method == 'undefined' || !$scope.ref.payment_method.id)){
                    toastr.error($rootScope.trans('amenities.form_incomplete'), 'Payment required');
                    return false;
                }
            }

            if($scope.amenity.is_license_plate && !$scope.booking.license_plate.length){
                toastr.error($rootScope.trans('amenities.form_incomplete'), 'License Plate is required');
                return false;
            }

            return true;
        };

        $scope.getAmenity = function () {
            $scope.loaded = false;

            $http.post('/api/v1/amenity/get-for-booking/' + $scope.amenity.id).success(function (data) {
                if (!data.success) {
                    $scope.loaded = true;
                    $state.go('main.amenities');
                }

                $scope.amenity = data.data.amenity;

                $scope.initDatePickers();

                if ($scope.amenity.booking_type === 'Hourly') {
                    $scope.booking.opening_time = $scope.amenity.opening_time;
                    $scope.setClosingTimes();
                } else {
                    $scope.booking.start_date = moment().format('MM/DD/YYYY');
                }

                if ($rootScope.authUser.permissions !== 'tenant') {
                    $scope.getTenants();
                }

                //if payment methods came back before amenity, filter again now
                if($scope.paymentMethods && $scope.amenity.usage_fee_holds_enabled || $scope.amenity.reservation_fee_holds_enabled){
                    $scope.paymentMethods = $scope.paymentMethods.filter(function (item) {
                        return item.type !== 'direct_debit';
                    });
                    $scope.paymentMethodCount = $scope.paymentMethods.length;
                }

                $scope.loaded = true;
            });
        };

        $scope.setClosingTimes = () => {
            const index = $scope.times.findIndex(item => item === $scope.booking.opening_time);
            const step = parseFloat($scope.amenity.booking_interval) / 0.25 + 1;
            $scope.closingTimes = $scope.times.slice(index, index + step);
            $scope.booking.closing_time = $scope.closingTimes[$scope.closingTimes.length - 1];
        };

        $scope.getTimes = function () {
            return $http.post('/api/v1/amenity/get-available-times/' + $scope.amenity.id, {check_date: $scope.booking.start_date}).success(function (data) {
                $scope.times = data.data;
                if(data.message){
                    toastr.warning(data.message);
                }
                if($scope.times.length == 0){
                    toastr.warning($scope.t('No available times for this date'));
                }
            }).error(function (error) {
                toastr.error($scope.t('Error!'));
            });
        };

        $scope.setBookingTime = time => {
            $scope.booking.start_time = time;
            $scope.booking.duration = '';
            $scope.available = 0;
            $scope.choose = 0;
            $scope.rooms = [];
            $scope.booking.reccuringEnabled = false;
            // now get the durations available
            $scope.getAvailableDurations();
        };

        $scope.setBookingDuration = duration => {
            $scope.booking.duration = duration;
            $http.post('/api/v1/amenity/check-availability/' + $scope.amenity.id, $scope.booking).success(function (data) {
                if (!data.success) {
                    toastr.error(data.message);
                    return;
                }

                toastr.success($rootScope.trans('amenities.is_avail'));
                $scope.choose = data.data.choose;
                $scope.rooms = data.data.rooms;
                $scope.available = 1;
                if ($scope.booking.pay_now) {
                    // let's pre-select payment method so payfilds can start working already
                    vm.tenantData.paymentType = 'credit';
                }
            });
        };

        $scope.getAvailableDurations = function(){
            let data = {
                check_date: $scope.booking.start_date,
                start_time: $scope.booking.start_time
            };
            $http.post('/api/v1/amenity/get-available-durations/' + $scope.amenity.id, data).success(function (data) {
                $scope.durations = data.data;
            });
        }

        $scope.initDatePickers = function () {
            setTimeout(function () {
                $('#start-date').datepicker({
                    minDate: 0

                });
                if ($scope.amenity.booking_type !== 'Hourly') {
                    let overnightOffset = $scope.amenity.booking_type == "Overnight" ? 1 : 0; // offset the end date by 1 day if overnight booking
                    $('#end-date').datepicker({
                        minDate: moment($scope.booking.start_date).add(overnightOffset, 'days').toDate(),
                        maxDate: `+${parseInt($scope.amenity.booking_interval) - 1 + overnightOffset}d`
                    });

                }
            }, 0);
        };

        $scope.makeReccuring = () => {
            setTimeout(function () {
                $('#recurring-end-date').datepicker({
                    minDate: moment($scope.booking.start_date).toDate(),
                    // maxDate: `+${parseInt($scope.amenity.booking_interval) - 1}d`
                });
            }, 10);
        }

        $scope.startDateChanged = () => {
            if ($scope.amenity.booking_type !== 'Hourly') {
                const days = parseInt($scope.amenity.booking_interval) - 1;
                $scope.booking.end_date = $scope.booking.start_date;

                let overnightOffset = $scope.amenity.booking_type == "Overnight" ? 1 : 0; // offset the end date by 1 day if overnight booking
                $('#end-date').datepicker('setDate', moment($scope.booking.start_date).add(overnightOffset, 'days').toDate());
                $('#end-date').datepicker('option', 'minDate', moment($scope.booking.start_date).add(overnightOffset, 'days').toDate());
                $('#end-date').datepicker('option', 'maxDate', moment($scope.booking.start_date).add(days + overnightOffset, 'days').toDate());
            } else {
                $scope.booking.start_time = '';
                $scope.booking.duration = '';
                $scope.getTimes();
            }
        };

        $scope.createBooking = function () {
            $scope.creatingBooking = true;

            $http.post('/api/v1/amenity/booking/create', $scope.booking).success(function (data) {
                if (!data.success) {
                    $scope.endProcessing();
                    toastr.error(data.errors, $scope.t('Error creating Booking'));
                    return;
                }

                // Make payment
                if (
                    $scope.amenity.payment_enabled && $scope.booking.pay_now == 1 &&
                    ($rootScope.authUser.permissions === 'tenant' || $scope.ref.tenant)
                ) {
                    if ($rootScope.authUser.permissions !== 'tenant') {
                        $scope.updateSinglePAD(data.data.id);
                    } else {
                        $scope.processPayment(); // to remove, IDK if it works and I'm not working on making it work
                    }
                } else {
                    toastr.success($scope.t("Booking Created"));
                    $scope.endProcessing();
                    if ($scope.amenity.booking_tickets == 1) {
                        $state.go('main.amenityBook', {'ticket_booking_id': data.data.id});
                    } else {
                        $state.go('main.amenityBook');
                    }
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.endProcessing();
                toastr.error(data.errors, $scope.t('Error creating Booking'));
                return;
            });
        };

        $scope.createBulkBooking = function () {
            $scope.creatingBooking = true;

            $http.post('/api/v1/amenity/booking/create-bulk', $scope.booking).success(function (data) {
                if (!data.success) {
                    $scope.endProcessing();
                    toastr.error(data.errors, $scope.t('Error creating Booking'));
                    return;
                }

                $scope.endProcessing();
                if ($scope.amenity.payment_enabled && $scope.booking.pay_now==1) {
                    toastr.success($scope.t('Booking Created and Paid'));
                } else {
                    toastr.success($scope.t("Booking Created"));
                }
                if ($scope.amenity.booking_tickets == 1) {
                    $state.go('main.amenityBook', {'ticket_booking_id': data.data.id});
                } else {
                    $state.go('main.amenityBook');
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.endProcessing();
                toastr.error(data.error.message);
            });
        };

        $scope.finalizeBooking = function () {
            $scope.startProcessing();

            $scope.createBooking();
        };

        $scope.checkAvailability = function () {
            $http.post('/api/v1/amenity/check-availability/' + $scope.amenity.id, $scope.booking).success(function (data) {
                if (!data.success) {
                    toastr.error(data.message);
                    return;
                }

                if ($scope.amenity.booking_type != 'Hourly') {
                    toastr.success($rootScope.trans('amenities.is_avail'));
                }
                $scope.choose = data.data.choose;
                $scope.rooms = data.data.rooms;
                $scope.available = 1;
            });
        };

        $scope.getTenants = function () {
            $http.post('/api/v1/amenity/get-tenants-for-booking/' + $scope.amenity.id, {property_id: $scope.property_id}).success(function (data) {
                if (!data.success) {
                    toastr.error($scope.t('Not available'));
                    return;
                }

                $scope.ref.tenant = {};
                $scope.booking.tenant_id = null;
                $scope.fetchTenantFromUnitId(data.data[0].property_unit_id);
                $scope.tenants = data.data;

                $timeout(function () {
                    $('#tenant-filter').multipleSelect({
                        single: true,
                        filter: true,
                        width: '401px',
                        dropWidth: '330px',
                    });
                    $scope.show_tenants = 1;
                }, 0);
            });
        };

        $scope.changeTenant = function () {
            if (! $scope.ref.tenant || typeof $scope.ref.tenant == 'undefined') {
                return;
            }
            $scope.booking.tenant_id = $scope.ref.tenant.id;
            $scope.fetchTenantFromUnitId($scope.ref.tenant.property_unit_id);
        };

        $scope.changeDates = function () {
            $scope.available = 0;
            $scope.choose = 0;
            $scope.rooms = [];
            $scope.booking.start_time = '';
            $scope.booking.duration = '';
            $scope.durations = [];
            $scope.times = [];
        };

        //for tenant
        $scope.processPayment = function () {
            var usage_payment = {
                payment_method_id: $scope.ref.payment_method.id,
                amenity_id: $scope.amenity.id,
                amenity_fee_type: 'usage',
                recurring: false,
                description: 'Payment for ' + $scope.amenity.name + " (Usage Fee)",
                start_date: moment().format('MM/DD/YY'),
                payment_services: [{
                    id: 1,
                    amount: $scope.total_usage_fee,
                    name: $scope.amenity.name
                }]
            };

            var reservation_payment = {
                payment_method_id: $scope.ref.payment_method.id,
                amenity_id: $scope.amenity.id,
                amenity_fee_type: 'reservation',
                recurring: false,
                description: 'Payment for ' + $scope.amenity.name + " (Reservation Fee)",
                start_date: moment().format('MM/DD/YY'),
                payment_services: [{
                    id: 1,
                    amount: $scope.amenity.reservation_fee_amount,
                    name: $scope.amenity.name
                }]
            };

            var promises = [
                $scope.amenity.usage_fee_amount > 0 ? paymentScheduleService.create(usage_payment) : null,
                $scope.amenity.reservation_fee_amount > 0 ? paymentScheduleService.create(reservation_payment) : null
            ]

            Promise.all(promises).then(function (data) {
                console.log('success paying',data);
                if (
                    (promises[0] === null ||  data[0]?.data.success == true) &&
                    (promises[1] === null || data[1]?.data.success == true)
                ) {
                    toastr.success($scope.t('Your payment is')+" " + data[0].data.data.payment_schedule_transaction.status, $scope.t('Your payment has been made!'));
                    toastr.options = {
                        'closeButton': true
                    };

                    $scope.booking.transaction_id = data[0].data.data.payment_schedule_transaction.id;
                } else {
                    $scope.endProcessing();
                    toastr.error(data[0].data.errors.error ?? data[1].data.errors.error, $scope.t('Error!'));
                    toastr.options = {
                        'closeButton': false,
                        'showDuration': '10000',
                        'extendedTimeOut': '10000'
                    };
                }
            }).catch(function (data) {
                console.log('error',data);
                $scope.endProcessing();
                toastr.error(data[0].data.errors.error ?? data[1].data.errors.error, $scope.t('Error!'));
                toastr.options = {
                    'closeButton': false,
                    'showDuration': '10000',
                    'extendedTimeOut': '10000'
                };
            });

        };

        // for manager
        $scope.updateSinglePAD = function (booking_id) {
            console.log('updateSinglePAD');
            vm.tenantData.property_id = $scope.ref.tenant.property_id;
            vm.tenantData.credit_card_type = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();
            vm.tenantData.amenity_id = $scope.amenity.id;
            vm.tenantData.paymentModuleType = 'amenity';
            vm.tenantData.amount = $scope.total_usage_fee * 1;
            vm.tenantData.description = 'Payment for ' + $scope.amenity.name;
            vm.tenantData.amenity_booking_id = booking_id;
            $scope.payrixValidationErrors = {};

            if (vm.tenantInfo.tenant.providers.property === 'payrix' && vm.tenantData.paymentType !== 'pad') {
                if (vm.tenantData.paymentType === 'pad') {
                    // PayFields.customizations.optionalFields = ["#number"];
                } else {
                    PayFields.submit();
                }
            } else {
                $http.post('/api/v1/tenants/update-by-admin-single', vm.tenantData)
                    .success(function (result) {
                        console.log('result', result);
                        $scope.inProgress = false;
                        toastr.clear()

                        if (result.code == 200 && result.success) {
                            $scope.clearTenantData();

                            if (result.data.payment_status === "paid") {
                                toastr.success($scope.t('Booking Created and Paid'));
                            } else if($scope.amenity.payment_optional == 1) {
                                toastr.success($scope.t("Booking Created"));
                            } else {
                                toastr.success($scope.t('Booking Held and Pending Payment'));
                            }

                            if ($scope.amenity.booking_tickets == 1) {
                                $state.go('main.amenityBook', {'ticket_booking_id': data.data.id});
                            } else {
                                $state.go('main.amenityBook');
                            }
                        } else {
                            $scope.endProcessing();
                            toastr.clear()
                            if (result.extra && result.extra.validation) {
                                console.log(result.extra.validation)
                                $scope.payrixValidationErrors = result.extra.validation;
                            }
                            if (result.errors.length > 0) {
                                result.errors.forEach(msg => toastr.error(msg));
                            } else if(result.errors){
                                toastr.error(result.errors, $scope.t('Error processing payment'));
                            } else {
                                toastr.error($scope.t('Error processing payment'));
                            }

                        }

                    })
                    .error(function (data) {
                        if (typeof data.stopaction != 'undefined') {
                            return;
                        }

                        toastr.clear()
                        toastr.error($scope.t('Error creating a payment for this booking'));
                        $scope.endProcessing();
                    });
            }

        };

        $http.post('/api/v1/amenity/is-enabled').success(function (data) {
            if (data.data.enabled == 0) {
                // not enabled for this user
                $state.go('main.dashboard', {redirect: true});
                return 1;
            }

            //$scope.getTimes().then(() => {
                //$scope.getAmenity();
            //});
            //$scope.getTimes();
            $scope.getAmenity();
            $scope.getPaymentMethods();
        });

        //Calculate payment amount for all days amenity is booked for
        $scope.$watch('booking.start_date', function(newValue, oldValue){
            $scope.calculateTotalCharge();
            console.log('$scope.total_usage_fee1', $scope.total_usage_fee);
        });
        $scope.$watch('booking.end_date', function(newValue, oldValue){
            $scope.calculateTotalCharge();
            console.log('$scope.total_usage_fee2', $scope.total_usage_fee);
        });

        $scope.calculateTotalCharge = ()=>{
            if($scope.amenity.booking_type == 'Hourly' || $scope.amenity.charged_per_booking == 1)
            {
                $scope.total_usage_fee = $scope.amenity.usage_fee_amount;
            }
            else if($scope.amenity.booking_type == 'Overnight')
            {
                //Eg. A booking from January 1 to January 3 will be charged for 2 days
                let number_of_days = moment($scope.booking.end_date).diff(moment($scope.booking.start_date), 'days');
                $scope.total_usage_fee = ($scope.amenity.usage_fee_amount * 100 * number_of_days)/100;
            }
            else if($scope.amenity.booking_type == 'Daily')
            {
                //Eg. A booking from January 1 to January 3 will be charged for 3 days
                let number_of_days = (moment($scope.booking.end_date).diff(moment($scope.booking.start_date), 'days')+1);
                $scope.total_usage_fee = ($scope.amenity.usage_fee_amount * 100 * number_of_days)/100;
            }
        };



        $scope.total = 0;
        $scope.calculateTotal = () => {
            $scope.total = +$scope.total_usage_fee + +$scope.convenience_fees + +$scope.amenity.reservation_fee_amount;

            if($scope.amenity.usage_fee_holds_enabled){
                $scope.total += +$scope.amenity.property.amenity_hold_fee;
            }
            if($scope.amenity.reservation_fee_holds_enabled){
                $scope.total += +$scope.amenity.property.amenity_hold_fee;
            }

            return $scope.total;

        }

        $scope.$watch('total_usage_fee', function(newValue, oldValue){
            $scope.calculateTotal();
            console.log('getTotal total_usage_fee', $scope.total);
        });
        $scope.$watch('convenience_fees', function(newValue, oldValue){
            $scope.calculateTotal();
            console.log('getTotal convenience_fees', $scope.total);
        });
        $scope.$watch('amenity', function(newValue, oldValue){
            $scope.calculateTotal();
            console.log('getTotal amenity', $scope.total);
        });
    }
}());
