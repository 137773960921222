(function() {
    'use strict';

    angular
    .module('app')
    .controller('DocumentCategoriesController', documentCategoriesController);

    function documentCategoriesController($scope,$state,$rootScope,$http) {
        if ($rootScope.authUser.permissions == 'tenant' || $rootScope.authUser.permissions == 'junior_manager') {
            $state.go('main.documents');
        }

        $scope.categories;
        $scope.companies = {};
        $scope.new_category = '';
        $scope.filter = {company_id:  0};

        $scope.getCategories = function(){
            $http.post('/api/v1/document/get-categories', {company_id: $scope.filter.company_id}).success(function(data) {
                $scope.categories = data.data.categories;
            });
        }

        if ($rootScope.authUser.permissions == 'payquad_admin') {
            $http.post('/api/v1/document/get-companies').success(function(data) {
                $scope.companies = data.data.companies
                $scope.filter.company_id = $scope.companies[0].id;
                $scope.getCategories();
            });
        } else {
            $scope.getCategories();
        }

        $scope.addCategory = function() {
            if ($scope.new_category == '') {
                return;
            }

            var data = new FormData();
            data.append('name', $scope.new_category);

            $http.post('/api/v1/document/category-create',{name: $scope.new_category, company_id: $scope.filter.company_id}).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error creating category");
                    return;
                }

                $scope.categories = data.data.categories;
                $scope.new_category = '';

                toastr.success('Category added');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.updateCategory = function(category) {
            if (!confirm('Are you sure you want to update this category?')) {
                return;
            }

            $http.post('/api/v1/document/category-update/' + category.id, category).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error updating category");
                    return;
                }

                $scope.categories = data.data.categories;

                toastr.success('Category updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.deleteCategory = function(category) {
            if (!confirm('Are you sure you want to delete this category?')) {
                return;
            }
            category.company_id = $scope.filter.company_id;

            $http.post('/api/v1/document/category-delete/' + category.id, {company_id: $scope.filter.company_id}).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error deleting category");
                    return;
                }

                $scope.categories = data.data.categories;

                toastr.success('Category deleted');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }
    }
}());
