(function () {

    'use strict';

    angular
        .module('app')
        .controller('SurveyIndividualController', function ($scope, $timeout, resolvedSurvey, surveyService, helperService, langService, resolvedFilters) {
            $scope.t = langService.t('surveys');
            $scope.activeTenant = 0;
            $scope.survey = resolvedSurvey.survey;
            $scope.isCordova = Boolean(window.Capacitor.isNativePlatform())

            $scope.dateFrom = '';
            $scope.dateTo = '';
            $scope.filters = resolvedFilters;
            $scope.filter = null;

            $scope.applyFilters = () => {
                surveyService.getIndividuals($scope.survey.id, $scope.filter, $scope.dateFrom, $scope.dateTo).then(r => {
                    console.log('r', r)
                    $scope.responses = r.responses.map(item => {
                        if(! resolvedSurvey.survey.is_public){
                            item.respondent.updated_at = helperService.mysqlDatetimeToDate(item.respondent.updated_at);
                        }
                        return item;
                    });
                })
            }

            $timeout(function () {
                $('#report_filter').multipleSelect({
                    filter: true,
                    placeholder: 'Select property or prospect'
                });
                $('#fromDate,#toDate,#fromDate2,#toDate2').datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg'
                });
            });

            $scope.responses = resolvedSurvey.responses.map(item => {
                if(! resolvedSurvey.survey.is_public){
                    item.respondent.updated_at = helperService.mysqlDatetimeToDate(item.respondent.updated_at);
                }
                return item;
            });
            $scope.export = () => {
                if ($scope.isCordova && cordova.plugins && cordova.plugins.printer) {
                    // Use the Cordova Printer plugin to print the current page content
                    let pageContent = document.documentElement.innerHTML; // or any specific content you wish to print
                    console.log(pageContent);
                    cordova.plugins.printer.print(pageContent, { name: 'DocumentName', duplex: true }, (res) => {
                        console.log(pageContent);
                        console.log('Print finished or canceled? ' + !res);
                    });
                } else {
                    // Fallback to default print function for non-Cordova environments
                    window.print();
                }
            }
        });
}());
