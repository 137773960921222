(function() {
    'use strict';

    angular
    .module('app')
    .controller('DocumentFulfillController', documentFulfillController);

    function documentFulfillController($scope,$http,$state,$rootScope,$timeout,$stateParams) {
        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.documents');
        }

        $scope.loading_spinner=0;

        $scope.request = {
            id: $stateParams.id
        };

        $scope.getRequest = function(){
            $http.post('/api/v1/document/get-request/' + $scope.request.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.documentRequests');
                }

                $scope.request = data.data.request;
            });
        };

        $scope.getRequest();

        $scope.fulfillDocument = function() {
            if (document.getElementById('file1').files.length == 0) {
                toastr.error("Please choose a file to upload");
                return;
            }

            $scope.request.document = document.getElementById('file1').files[0];

            $scope.creatingDocument = true;

            var data = new FormData();
            data.append('document', document.getElementById('file1').files[0]);

            $scope.loading_spinner=1;

            $http.post('/api/v1/document/fulfill-request/' + $scope.request.id,data, {transformRequest: angular.identity, headers: {'Content-Type': undefined}}).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error Fulfilling Document");
                    $scope.loading_spinner=0;
                    return;
                }

                $scope.loading_spinner=0;
                $scope.creatingDocument = false;
                $state.go('main.documents');
                toastr.success('Document Request Fulfilled');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingDocument = false;
                $scope.loading_spinner=0;

                if (typeof data == 'string' && data.includes('413') == true) {
                    toastr.error('Error uploading document, to large.');
                } else {
                    toastr.error(data.error.message);
                }
            });
        };
    }
}());
