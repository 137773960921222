(function () {

    'use strict';

    angular
        .module('app')
        .factory('companyData', companyDataFactory);

    function companyDataFactory($q, companyService)
    {
        return {
            load: load
        };

        // Loads all data into this.data and returns a promise
        function load(companyId) {

            var companies = companyService.getAllCompanies();

            if(companyId != null) {
                var company = companyService.getCompanyById(companyId);
            }

            // Retrieve all promised response and package it into an object
            return $q.all([companies, company]).then(
                function (results) {
                    return {
                        companies: results[0],
                        company: results[1]
                    }
                });

        }
    }

})();