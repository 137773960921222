(function() {

    'use strict';

    angular
        .module('app')
        .factory('propertyChartService', propertyChartService);

    function propertyChartService($http, $q) {


        var base_url = 'api/v1';

        return {
            getChart: getChart
        };

        /**
         *
         * @param permissions
         * @returns {HttpPromise}
         */
        function getChart(id)
        {
            var deferred = $q.defer();

            $http.get(base_url + getUrl(id)).success(function(object, status) {
                console.log(object);
                deferred.resolve(object.data);
            });
            return deferred.promise;
        }
        function getUrl(id){
            var local_url = '/properties/'+id+'/property-charts';
            return local_url;
        }
    }

}());