(function () {

    'use strict'

    angular
        .module('app')
        .controller('PasswordController', PasswordController);

    function PasswordController($scope, $state, $stateParams, passwordService, authenticationService, $rootScope) {

        $scope.emailPattern = (function () {
            var regexp = /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/;
            return {
                test: function (value) {
                    if ($scope.requireTel === false) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();

        $scope.passwordData = {
            "token": $stateParams.passwordToken
        };

        $scope.remindPassword = function () {
            var data = {
                "email": $scope.passwordData.email
            };
            passwordService.remind(data)
                .success(function (data) {

                    if(data.success == true) {

                        // $state.go('main.login');
                        $rootScope.goToAngular2Login();

                        $scope.message = data.message;

                        toastr.success('We have e-mailed your password reset link!', 'Email Sent!');
                        toastr.options = {
                            "closeButton": true,
                            "debug": false,
                            "positionClass": "toast-top-right",
                            "preventDuplicates": true
                        };

                    } else {
                        console.log('success: false');
                        $scope.message = data.message;
                    }
                })
                .error(function (data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log('password reminded didnt go through bro');
                    $log.log(data.error + ' ' + status);
                });

        };

        $scope.resetPassword = function() {
            var data = {
                "email": $scope.passwordData.email,
                "password": $scope.passwordData.password,
                "password_confirmation": $scope.passwordData.password_confirmation,
                "token": $stateParams.passwordToken
            };

            passwordService.reset(data)
                .success(function (data) {
                    if (data.success == true) {
                        console.log('success: true');

                        toastr.success('Redirecting to the portal...', 'Password Reset Success!');
                        toastr.options = {
                            "closeButton": true,
                            "debug": false,
                            "newestOnTop": false,
                            "progressBar": false,
                            "positionClass": "toast-top-right",
                            "preventDuplicates": true,
                            "onclick": null,
                            "showDuration": "300",
                            "hideDuration": "1000",
                            "timeOut": 0,
                            "extendedTimeOut": 0,
                            "showEasing": "swing",
                            "hideEasing": "linear",
                            "showMethod": "fadeIn",
                            "hideMethod": "fadeOut",
                            "tapToDismiss": false
                        };

                        window['fireAngularEvent']('pq.log-in-after-reset-password', {
                                "email": $scope.passwordData.email,
                                "password": $scope.passwordData.password,
                        });
                    } else {
                        console.log('success: false');
                        $scope.message = data.message;
                    }
                })
                .error(function (data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log('password reset didn\'t go through bro');
                    $log.log(data.error + ' ' + status);
                });

        };

    }

}());
