(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationEditController', leaseApplicationEditController);

    function leaseApplicationEditController($scope,$http,$state,$rootScope,$timeout,$stateParams) {

        $state.go('main.leaseApplicationContinue', {id: $stateParams.id});

        $scope.step = {slug: ''};
        $scope.validated_steps = [];

        $scope.formerrors = {};

        $scope.relationships = [
            'Parent',
            'Sibling',
            'Dependant',
            'Friend'
        ];

        $scope.legal_terms = '';
        $scope.required_documents = [];
        $scope.optional_documents = [];
        $scope.payment_message = '';

        $scope.application = {
            id                         : $stateParams.id,
            status                     : 'open',
            first_name                 : '',
            last_name                  : '',
            property_id                : 0,
            transaction_id             : 0,
            paid                       : 0,
            payment_method_name        : '',
            payment_method_id          : 0,
            phone                      : '',
            email                      : '',
            work_phone                 : '',
            mobile_phone               : '',
            applicants                 : [],
            occupants                  : [],
            guarantors                 : [],
            emergency_contacts         : [],
            documents                  : [],
            employments                  : [],
            born_at                    : '',
            address                    : '',
            suite                      : '',
            country                    : 'CA',
            province                   : '',
            city                       : '',
            postal                     : '',
            rent_or_own                : '',
            company_name               : '',
            manager_name               : '',
            manager_phone              : '',
            manager_email              : '',
            rent_amount                : '',
            agree_terms                : 0,
            last_step                  : '',
            email_confirmed            : 1
        };

        $scope.states = {};
        $scope.getStates = function() {
            // $http.post('/api/v1/get-states/CA').success(function(data) {
            //     $scope.states = data;
            // });
            $http.post('/api/v1/get-provinces-translated').success(function (data) {
                $scope.states = data;
            });
        };
        $scope.getStates();

        $scope.countries = {'CA':'Canada'};

        $scope.getSteps = function(){
            $http.post('/api/v1/lease-application/manager-get-application-steps/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error loading steps");
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                $scope.step.slug = data.data.steps[0].slug;
                $scope.steps = data.data.steps;

            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };

        $scope.getLegal = function(){
            $http.post('/api/v1/lease-application/manager-get-legal/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error Legal Terms");
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                $scope.legal_terms = data.data.legal_terms;

            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };
        $scope.getLegal();

        $scope.getDocumentTypes = function(){
            $http.post('/api/v1/lease-application/manager-get-supporting-document-types-for-application/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.required_documents = data.data.required_types;
                $scope.optional_documents = data.data.optional_types;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };
        $scope.getDocumentTypes();

        $scope.getPaymentMessage = function(){
            $http.post('/api/v1/lease-application/manager-get-payment-message/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.payment_message = data.data.payment_message;
            });
        };
        $scope.getPaymentMessage();

        $scope.steps = [];
        $scope.getSteps();

        $scope.addApplicant = function(){
            $scope.application.applicants.push({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                relationship: ''
            });
        };

        $scope.removeApplicant = function(key){
            $scope.application.applicants.splice(key, 1);
        };

        $scope.addOccupant = function(){
            $scope.application.occupants.push({
                first_name: '',
                last_name: '',
                age: '',
                relationship: ''
            });
        };

        $scope.removeOccupant = function(key){
            $scope.application.occupants.splice(key, 1);
        };

        $scope.addGuarantor = function(){
            $scope.application.guarantors.push({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                relationship: ''
            });
        };

        $scope.removeGuarantor = function(key){
            $scope.application.guarantors.splice(key, 1);
        };

        $scope.addEmergencyContact = function(){
            $scope.application.emergency_contacts.push({
                first_name: '',
                last_name: '',
                phone: '',
                relationship: ''
            });
        };

        $scope.removeEmergencyContact = function(key){
            $scope.application.emergency_contacts.splice(key, 1);
        };

        $scope.getApplication = function(){
            $http.post('/api/v1/lease-application/manager-get/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error loading application");
                    return;
                }

                if (data.data.application.status == 'signed') {
                    $state.go('main.leaseApplicationManagerSign', {id: $scope.application.id});
                }

                if (typeof data.data.next_step == 'undefined') {
                    toastr.error(data.errors,"Error loading application");
                    return;
                }

                //$scope.goToStep(data.data.next_step);

                $scope.populateApplication(data.data.application);
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };
        $scope.getApplication();

        $scope.populateApplication = function(application){
            $scope.application.first_name = application.user.applicant.first_name;
            $scope.application.last_name = application.user.applicant.last_name;
            $scope.application.email = application.user.email;
            $scope.application.phone = application.user.applicant.phone_number;
            $scope.application.work_phone = application.work_phone;
            $scope.application.mobile_phone = application.mobile_phone;

            $scope.application.email_confirmed = application.email_confirmed;

            $scope.application.property_id = application.property_id;
            $scope.application.transaction_id = application.transaction_id;
            $scope.application.paid = application.paid;
            if (application.payment_method_id != null) {
                $scope.application.payment_method_id = application.payment_method_id;
            }
            $scope.application.payment_method_name = application.payment_method_name;

            if (application.born_at != null) {
                $scope.application.born_at       = moment(application.born_at).format('MM/DD/YYYY');
            }
            $scope.application.address       = application.address;
            $scope.application.suite         = application.suite;
            //$scope.application.country       = application.country; // only have CA for now, so no point
            $scope.application.province      = application.province;
            $scope.application.city          = application.city;
            $scope.application.postal        = application.postal;
            $scope.application.rent_or_own   = application.rent_or_own;
            $scope.application.company_name  = application.company_name;
            $scope.application.manager_name  = application.manager_name;
            $scope.application.manager_phone = application.manager_phone;
            $scope.application.manager_email = application.manager_email;
            if (application.rent_amount > 0) {
                $scope.application.rent_amount   = application.rent_amount;
            }

            if (application.employment_status != null) {
                $scope.application.employment_status   = application.employment_status;
            }
            $scope.application.employments = application.user.employments;

            $scope.application.agree_terms = application.agree_terms;
            $scope.application.last_step = application.last_step;
            $scope.application.documents = application.documents;

            angular.forEach(application.applicants, function(applicant, key){
                $scope.application.applicants.push({
                    first_name   : applicant.first_name,
                    last_name    : applicant.last_name,
                    email        : applicant.email,
                    phone        : applicant.phone,
                    relationship : applicant.relationship
                });
            });

            angular.forEach(application.occupants, function(occupant, key){
                $scope.application.occupants.push({
                    first_name   : occupant.first_name,
                    last_name    : occupant.last_name,
                    age          : occupant.age,
                    relationship : occupant.relationship
                });
            });

            angular.forEach(application.guarantors, function(guarantor, key){
                $scope.application.guarantors.push({
                    first_name   : guarantor.first_name,
                    last_name    : guarantor.last_name,
                    email        : guarantor.email,
                    phone        : guarantor.phone,
                    relationship : guarantor.relationship
                });
            });

            angular.forEach(application.emergency_contacts, function(emergency_contact, key){
                $scope.application.emergency_contacts.push({
                    first_name   : emergency_contact.first_name,
                    last_name    : emergency_contact.last_name,
                    email        : emergency_contact.email,
                    phone        : emergency_contact.phone,
                    relationship : emergency_contact.relationship
                });
            });
        };

        $scope.handleValidationErrors = function(errors) {
            $scope.formerrors = {};

            angular.forEach(errors, function(field_errors, field){
                angular.forEach(field_errors, function(error, key){
                    var f = field.replace('application.', '');
                    if (typeof $scope.formerrors[f] == 'undefined') {
                        $scope.formerrors[f] = [];
                    }
                    $scope.formerrors[f].push(error);
                });
            });

            toastr.error('Some errors occured.  See the form for details.');
        };

        $scope.saveAndGoNext = function(){
            $scope.formerrors = {};
            $http.post('/api/v1/lease-application/manager-save/' + $scope.application.id, {step_slug: $scope.step.slug, application: $scope.application}).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    if (typeof data.errors.errors != 'undefined') {
                        $scope.handleValidationErrors(data.errors.errors);
                    } else {
                        angular.forEach(data.errors, function(error, key){
                            toastr.error(error);
                        });
                    }
                    if (typeof data.errors.goto_step != 'undefined') {
                        $scope.goToStep(data.errors.goto_step);
                    }
                    return;
                }

                if ($scope.step.slug == 'supporting-docs') {
                    $scope.application.documents = data.data.application.documents;
                }

                if (typeof data.data.new_email != 'undefined' && data.data.new_email == 1) {
                    toastr.success('You have been sent an email to verify your new email address');
                    $scope.application.email_confirmed = 0;
                }

                if (typeof data.data.next_step != 'undefined' && data.data.next_step == 'complete') {
                    $scope.doCompleteRedirect();
                    return;
                }

                if (typeof data.data.next_step != 'undefined') {
                    if (data.data.next_step == false) {
                        $scope.goToStep('review');
                    } else {
                        $scope.goToStep(data.data.next_step);
                    }
                }

                $scope.submitting = false;
                toastr.success($scope.t('Application Saved'));
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.saveStep = function(){
            $scope.formerrors = {};
            $http.post('/api/v1/lease-application/manager-save/' + $scope.application.id, {step_slug: $scope.step.slug, application: $scope.application}).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    if (typeof data.errors.errors != 'undefined') {
                        $scope.handleValidationErrors(data.errors.errors);
                    } else {
                        angular.forEach(data.errors, function(error, key){
                            toastr.error(error);
                        });
                    }
                    if (typeof data.errors.goto_step != 'undefined') {
                        $scope.goToStep(data.errors.goto_step);
                    }
                    return;
                }

                if ($scope.step.slug == 'review') {
                    $scope.doCompleteRedirect();
                }

                if (typeof data.data.new_email != 'undefined' && data.data.new_email == 1) {
                    toastr.success('You have been sent an email to verify your new email address');
                    $scope.application.email_confirmed = 0;
                }

                $scope.submitting = false;
                toastr.success($scope.t('Application Saved'));
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.goToStep = function(step) {
            if (step != $scope.step.slug) {
                $scope.step.slug = step;

                if ($scope.step.slug == 'supporting-docs') {
                    $timeout(function(){
                        $scope.initDropzone();
                        $scope.show_dropzone = 1;
                    }, 500);
                } else {
                    $scope.show_dropzone = 0;
                }
            }
        };

        $scope.show_dropzone = 0;
		$scope.initDropzone = function(){
            var uploadUrl = '/api/v1/lease-application/manager-supporting-document-upload/' + $scope.application.id;
            var headers = {};

            if(!!window.Capacitor.isNativePlatform()) {
                uploadUrl = pqDomain + uploadUrl + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken;
                headers = {
                    'Cache-Control': null,
                    'X-Requested-With': null,
                }
            }

			$('#real-dropzone').dropzone({
				uploadMultiple: false,
				parallelUploads: 1,
				maxFilesize: 8,
				addRemoveLinks: false,
				dictRemoveFile: 'Remove',
				dictFileTooBig: 'File is bigger than 8MB',
				url: uploadUrl,
                headers: headers,
				// The setting up of the dropzone
				error: function(file, response) {
					var message = '';
					if ($.type(response) === "string") {
						message = response; //dropzone sends it's own error messages in string
                    } else {
						message = response.message;
                    }

                    toastr.error(message);

					var _ref;
					return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
				},
				success: function(file, response, done) {
					//$('#photos-list').html(response.photos_table);
                    $scope.$apply(function(){
                        $scope.application.documents = response.data.documents;
                    });
					toastr.success('Photo has been added to gallery');
					var _ref;
					return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
				},
				uploadprogress: function(file, progress, bytesSent) {
					$('#instruction').html('Uploading '+file.name);
				}
			});
		};

        $scope.deleteDocument = function(document_id) {
            if (!confirm('Are you sure you want to delete this document?')) {
                return;
            }

            $http.post('/api/v1/lease-application/manager-delete-document/' + document_id).success(function(data) {
                if (!data.success) {
                    toastr.error('Error deleting document');
                    return;
                }

                $scope.application.documents = data.data.documents;

                toastr.success('Document Deleted');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        ////////////////////////
        //// Payment Stuff /////
        ////////////////////////
        var vm = this;
        vm.tenantInfo = false;
        vm.billing_same_as_mailing = true;
		vm.tenantData = {paymentType :false};

        $scope.ref = {
            payment_method : {},
            tenant : {}
        };
        $scope.paymentMethods = {};
        $scope.paymentMethodCount = 0;

        $scope.getApplicationPaymentMethods = function(){
            vm.tenantInfo = false;
            $http.post('/api/v1/lease-application/manager-payment-methods/' + $scope.application.id).success(function(data) {
                if (data.success) {
                    //vm.tenantData.tenant_id = data.data.tenant.id;
                    vm.tenantData.amount = data.data.payment_amount * 1; //TODO
                    vm.tenantInfo = data.data;
                    vm.tenantInfo.hasCredit = false;
                    vm.tenantInfo.hasDebitVisa = false;
                    vm.tenantInfo.hasDirectDebit = false;

                    for(var i in vm.tenantInfo.methods){
                        if(vm.tenantInfo.methods[i].value == 'credit')
                            vm.tenantInfo.hasCredit = true;
                        else if(vm.tenantInfo.methods[i].value == 'debit_visa')
                            vm.tenantInfo.hasDebitVisa = true;
                        else if(vm.tenantInfo.methods[i].value == 'direct_debit')
                            vm.tenantInfo.hasDirectDebit = true;
                    }

                    $scope.clearTenantData();
                } else {
                    toastr.error(data.data.error);
                }
            });
        }

        $scope.clearTenantData = function(){
            vm.tenantData.institution_number = '';
            vm.tenantData.transit_number = '';
            vm.tenantData.account_number = '';
            vm.tenantData.day_of_month = '';
            vm.tenantData.description ='';
            vm.tenantData.editing_schedule_id = 0;
            vm.tenantData.paymentType = false;
            vm.tenantData.payment_recurring = 'one_time';
            vm.tenantData.billingunit = '';
            vm.tenantData.address = '';
            vm.tenantData.city = '';
            vm.tenantData.province = '';
            vm.tenantData.country = '';
            vm.tenantData.postal_code = '';
            vm.billing_same_as_mailing = true;
            vm.tenantData.card_holder_name = '';
            vm.tenantData.card_number = '';
            vm.tenantData.expiration_year = '';
            vm.tenantData.expiration_month = '';
            vm.tenantData.cvv = '';
        };

        $scope.processPayment = function(gonext){
            if (!gonext) {
                gonext = 0;
            }
            vm.tenantData.property_id = $scope.application.property_id;
            vm.tenantData.credit_card_type = jQuery('#card_type').text().replace("American Express","amex").toLowerCase();

            $http.post('/api/v1/lease-application/process-payment-method/' + $scope.application.id, vm.tenantData).success(function(result){
                $('.toast').remove();
                if(result.code == 200 && result.success) {
                    $scope.application.payment_method_id = result.data.application.payment_method_id;
                    $scope.application.payment_method_name = result.data.application.payment_method_name;
                    $scope.clearTenantData();
                    if (gonext == 0) {
                        $scope.saveStep();
                    } else {
                        $scope.saveAndGoNext();
                    }
                } else {
                    $('.toast').remove();
                    if(result.code == 200) {
                        toastr.error(result.data.error);
                    } else {
                        toastr.error('error processing payment method');
                    }
                }
            })
            .error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $('.toast').remove();
                toastr.error('Error creating a payment method');
            });
        }

        $scope.completeApplication = function(){
            $scope.saveStep();
        };

        $scope.getApplicationPaymentMethods();

        $scope.openDocument = function(doc) {
            if(window.Capacitor.isNativePlatform()) {
                try {
                    window.Capacitor.Plugins.Browser.open({url: (pqDomain + '/lease-application-document-download/' + doc.id + '?cordova='+(!!window.Capacitor.isNativePlatform()? '1':'0') + '&token=' + pqCachedToken)});
                } catch (e) {
                    alert(e)
                }
            } else {
                window.open('lease-application-document-download/' + doc.id, '_blank');
            }
        }

        $scope.doCompleteRedirect = function(){
            toastr.success('Your application has been submitted');
            $state.go('main.leaseApplicationDashboard');
        };

        $scope.resendConfirmation = function(){
            $http.post('/api/v1/lease-application/manager-resend-confirmation/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error resending confirmation email");
                    return;
                }

                if (data.data.confirmed == 1) {
                    toastr.success('Your account has already been confirmed');
                    $scope.application.email_confirmed = 1;
                    return;
                }

                toastr.success('A new confirmation email has been sent');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.goBack = function(){
            var last_step = '';
            angular.forEach($scope.steps, function(step, key){
                if (step.slug == $scope.step.slug) {
                    if (last_step != '') {
                        $scope.goToStep(last_step);
                    }
                    return;
                }

                last_step = step.slug;
            });
        };

        $scope.changePaymentMethod = function(){
            $scope.application.payment_method_id = 0;
        }

        $scope.errorText = function(field){
            if (typeof $scope.formerrors[field] == 'undefined') {
                return '';
            }
            return $scope.formerrors[field][0];
        };

        $scope.hasError = function(field){
            return typeof $scope.formerrors[field] !== 'undefined';
        }

        $scope.sendForSignature = function(){
            if (!confirm('Are you sure you want to approve this application and send it to the applicant for their signature?')) {
                return;
            }

            $http.post('/api/v1/lease-application/send-for-signature/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    if (typeof data.errors.errors != 'undefined') {
                        $scope.handleValidationErrors(data.errors.errors);
                    } else {
                        angular.forEach(data.errors, function(error, key){
                            toastr.error(error);
                        });
                    }
                    if (typeof data.errors.goto_step != 'undefined') {
                        $scope.goToStep(data.errors.goto_step);
                    }
                    return;
                }

                $state.go('main.leaseApplicationList');
                toastr.success('Application has been approved and sent to applicant for their signature');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }
    }
}());
