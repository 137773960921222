(function() {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/account/';

        $stateProvider
            .state('main.accountManagerSettings', {
                url: '/account/settings',
                views: {
                    "body@": {
                        templateUrl: url + 'settings/account.manager.settings.html',
                        controller: 'AccountSettingController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.account', {
                url: '/account',
                views: {
                    "body@": {
                        templateUrl: url + 'information/account.information.main.html',
                        controller: 'AccountInformationController',
                        controllerAs: 'vm',
                        params: {
                            section: {
                                value: null,
                                squash: true
                            }
                        }
                    }
                }
            })
            .state('main.accountNotification', {
                url: '/account/notification',
                params: {
                    section: 'notification'
                },
                views: {
                    "body@": {
                        templateUrl: url + 'information/account.information.main.html',
                        controller: 'AccountInformationController',
                        controllerAs: 'vm'
                    }
                }
            });

    }

}());
