(function () {

    'use strict';

    angular
        .module('app')
        .controller('BulkNoticeController', BulkNoticeController);

    function BulkNoticeController(accountService, $state, $rootScope, $http) {

        ['38', '10', '19', '21', '14', '28', '5', '30', '26', '25', '20', '4', '24', '29', '33', '15', '6', '35', '31', '34', '22', '36', '3', '11', '27', '12', '18', '23', '16', '32'];

        $scope.leases = [];
        $http.get('/api/v1/lease/rent-roll?').
        success(function(data) {
            $scope.leases = data;
            console.log(data)
        });

    }

}());
