(function () {

    'use strict';

    angular
        .module('app')
        .controller('ProspectCreateController', ProspectCreateController);

    function ProspectCreateController ($scope, $state, $http, resolvedProperty, prospectService, $rootScope, brandingService, langService) {
        $scope.t = langService.t('lease_app');
        console.log('ProspectCreateController')
        $rootScope.branding['hideCompanyLogo'] = true;
        $scope.property = resolvedProperty;
        $scope.prospect = {
            property_id: resolvedProperty.id,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            unit_type: '',
            floorplan: '',
            move_in_at: '',
            isSent: false,
            // date: null,
            // time: null,
            prettyDate: null,
            virtual_showing_type: null,
            receive_marketing: 0,
            other_virtual_type: ''
        };
        if (window.location.host.indexOf('leasing') == -1) {
            window.location.href = 'https://' + $scope.property.company.subdomain  + '.leasing.payquad.com/' +     window.location.hash
        }
        $scope.show_floorplans = 0;
        $scope.floorplans = [];

        $scope.getBranding = function(){
            brandingService.get(null, $scope.property.id).then(function(data) {
                $rootScope.branding = data.data;
            });
        };
        $scope.getBranding();

        $scope.getFloorplans = function(){
            if ($scope.property.company.extension_type != 'yardi' || $scope.property.lease_applications_extension_enabled != 1 || $scope.property.lease_application_yardi_unit_types ==0) {
                return;
            }
            $http.post('/api/v1/lease-application/get-floorplans/' + $scope.property.id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.floorplans = data.data.floorplans;
                $scope.show_floorplans = 1;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getFloorplans();

        $scope.virtualMethods = prospectService.getVirtualMethods();

        $scope.save = prospect => {
            if (prospect.$invalid) {
                Object.keys(prospect.$error).forEach(field => prospect.$error[field].forEach(input => input.$setTouched()));
                toastr.error($scope.t('Please fill out the form correctly'));
            } else {
                $scope.prospect.isSent = true;
                prospectService.createByClient($scope.prospect);
            }
        };

        $scope.openDatepicker = () => {
            $('.ui-datepicker-trigger').click()
        };
    }

}());
