(function () {

    'use strict';

    angular
        .module('app')
        .controller('PaymentScheduleCreateController', PaymentScheduleCreateController);

    function PaymentScheduleCreateController(
        $scope, $state, $stateParams, $timeout, $location, $window, $q,
        paymentScheduleService, paymentMethodService,
        accountService, propertyService, $http, $rootScope, langService
    ) {
        $scope.t = langService.t('payments');

        $scope.company_name = '';
        $scope.showBalanceOption = false;
        $scope.loading_spinner = 0;
        $scope.step = 1;
        $scope.vars = {payment_fill: 1};
        $scope.outOfStep = 3;
        $scope.valid_dates = [];
        $scope.paynow_dates = [];
        $scope.paynow_dates_string = '';
        $scope.paynow_allowed = -1;
        $scope.ref = {choose_end_date: false};
        $state.doc_key = '';
        $scope.doc_name = '';
        $scope.items = [];

        $rootScope.currentPaymentTab = 1;

        $scope.setupServiceAmount = index => {
            const service = $scope.services[index];
            const item = $scope.items.find(item => item.id === service.id);
            $scope.services[index].amount = parseFloat(item.cost);

            $scope.getHoldNotification();
            $scope.getShowWrongPaymentMethodNotification();
        }

        $scope.showHoldNotification = false;

        $scope.getHoldNotification = () => {
            let t = $scope.services.map(serv => {
                const findItem = $scope.items.find(item => item.id === serv.id);
                serv.isHold = findItem ? findItem.is_hold === ' (Hold)' : false
                return serv;
            })
            $scope.showHoldNotification = t.some(service => service.isHold) &&  t.some(service => !service.isHold);
        }

        $scope.showShowWrongPaymentMethodNotification = false;

        $scope.getShowWrongPaymentMethodNotification = () => {
            let t = $scope.services.map(serv => {
                const findItem = $scope.items.find(item => item.id === serv.id);
                serv.isHold = findItem ? findItem.is_hold === ' (Hold)' : false
                return serv;
            })
            $scope.showShowWrongPaymentMethodNotification = t.some(service => service.isHold) && $scope.paymentMethods.find(item => item.id === $scope.paymentScheduleData.payment_method_id).type === 'direct_debit';
            console.log($scope.showShowWrongPaymentMethodNotification)
            console.log($scope.paymentScheduleData.payment_method_id, $scope.paymentMethods.find(item => item.id === $scope.paymentScheduleData.payment_method_id).type)
        }

        // doc payment stuff
        $scope.getDocumentAmount = function (document_id) {
            $http.post('/api/v1/document/get-amount/' + document_id).success(function (data) {
                if (!data.success) {
                    $state.go('main.documents');
                }

                if (data.data.payment_required == 0) {
                    toastr.info('There is already a pending payment for this document');
                    $state.go('main.documents');
                }

                // lets populate stuff
                $scope.document_amount = data.data.document_fee;
                $scope.document_amounts.document_fee = data.data.document_fee;
                $scope.document_amounts.document_fee_days = data.data.document_fee_days;
                $scope.document_amounts.document_fee_tax = data.data.document_fee_tax;
                $scope.document_amounts.document_tax_rate = data.data.document_tax_rate;
                $scope.document_amounts.service_fee = data.data.service_fee;
                $scope.document_amounts.service_fee_tax = data.data.service_fee_tax;
                $scope.document_amounts.total_amount = data.data.total_amount;
                $scope.document_name = data.data.document_name;

                if (typeof $scope.services[0] != 'undefined' && $scope.services[0].amount == 0) {
                    $scope.services[0].amount = parseFloat($scope.document_amount);
                }
            });
        };

        $scope.is_doc_payment = 0;
        $scope.document_id = 0;
        $scope.document_amount = 0;
        $scope.document_amounts = {
            document_fee: 0,
            document_fee_tax: 0,
            service_fee: 0,
            service_fee_tax: 0,
            total_amount: 0
        };
        if ($stateParams.paymentMethodId.match(/doc-\d+/) != null) {
            $scope.doc_key = $stateParams.paymentMethodId;
            var document_id = $stateParams.paymentMethodId.match(/doc-(\d+)/)[1];
            $scope.is_doc_payment = 1;
            $scope.document_id = document_id;
            $scope.getDocumentAmount(document_id);
        }

        $scope.setAmount = function () {
            if ($scope.vars.payment_fill == 1) {
                $scope.services[0].amount = '';
            } else {
                $scope.services[0].amount = parseFloat($rootScope.authUser.recurring_amount);
                if ($rootScope.authUser.recurring_amounts_other_name) {
                    $scope.services[0].amount += parseFloat($rootScope.authUser.recurring_amount_other);
                }
            }
        };

        $scope.setExtensionAmount = function () {
            if ($scope.vars.payment_fill == 1) {
                $scope.services[0].amount = '';
            } else {
                var amount = 0;
                angular.forEach($scope.extension_charges, function (charge, key) {
                    if (charge.selected == 1 && charge.percentage > 0) {
                        amount = amount + (parseFloat(charge.Amount) * (charge.percentage / 100));
                    }
                });

                amount = Math.round(amount * 100) / 100;
                $scope.services[0].amount = amount;
            }
        };

        $scope.getExtensionEnabled = function () {
            $scope.extension_charges_loaded = 0;
            $http.post('/api/v1/extension/charges-enabled').success(function (data) {
                $scope.extension_recurring_charges = data.data.enabled;
                if ($scope.paymentScheduleData.recurring == 0) {
                    $scope.extension_disable_pay_other = 0;
                } else {
                    $scope.extension_disable_pay_other = data.data.disable_pay_other;
                }

                $scope.vars.payment_fill = 2;
                $scope.setAmount();
                console.log('disable pay other: ' + $scope.extension_disable_pay_other);
                if ($scope.extension_recurring_charges == 1) {
                    $scope.getExtensionCharges();
                }
            })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.extension_recurring_charges = 0;
                    $scope.extension_disable_pay_other = 0;
                    $scope.extension_charges = [];
                });
        };

        $scope.leaseChargesMonth = null;
        $scope.getExtensionCharges = function () {
            $http.post('/api/v1/extension/charges').success(function (data) {
                $scope.extension_recurring_charges = data.data.enabled;
                $scope.extension_charges = data.data.charges;

                angular.forEach($scope.extension_charges, function (charge, key) {
                    $scope.extension_charges[key].selected = true;
                    $scope.extension_charges[key].percentage = 100;
                });
                $scope.leaseChargesMonth = data.data.month;

                if ($scope.extension_recurring_charges == 1) {
                    $scope.extension_charges_loaded = 1;
                    $scope.setExtensionAmount();
                }
            })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.extension_recurring_charges = 0;
                    $scope.extension_disable_pay_other = 0;
                    $scope.extension_charges = [];
                });
        };

        $scope.checkDate = function () {
            if ($scope.valid_dates.length == 0) {
                return;
            }

            var dt = new Date($scope.paymentScheduleData.start_date);
            if ($scope.valid_dates.indexOf(dt.getDate()) === -1) {
                $scope.paymentScheduleData.start_date = '';
                toastr.error('Invalid Date');
            }
        };

        $scope.hasHold = false;

        $scope.stepOne = function () {
            $scope.step = 1;
        };
        $scope.stepTwo = function () {
            let mappedServices = $scope.services.map(serv => {
                const findItem = $scope.items.find(item => item.id === serv.id);
                serv.isHold = findItem ? findItem.is_hold === ' (Hold)' : false
                return serv;
            })
            console.log('mappedServices', mappedServices)
            $scope.hasHold = mappedServices.some(service => service.isHold);
            console.log('hasHold',  $scope.hasHold)
            console.log('property',  $scope.property)
            if ($scope.paymentScheduleData.recurring == true) {
                $state.go('main.paymentScheduleCreate.step_2');
                $scope.step = 2;
                $scope.outOfStep = 3;
            } else if ($scope.paymentScheduleData.recurring == false) {
                if ($state.current.name == 'main.paymentScheduleCreate') {
                    stepThreeCalculations();
                    $scope.step = 2;
                    $scope.outOfStep = 2;
                } else if ($state.current.name == 'main.paymentScheduleCreate.step_3') {
                    $scope.step = 1;
                    $scope.outOfStep = 3;
                    $state.go('main.paymentScheduleCreate');
                }
            }
        };
        $scope.stepThree = function () {
            $scope.step = 3;
            $scope.outOfStep = 3;
            stepThreeCalculations();
        };
        /* -------------------------- END --- Wizard & Steps ------------------------ */



        /* -------------------------- START --- POST API calls ------------------------ */
        $scope.createPaymentSchedule = function (payNowOnlyOrPayNowAndCreateAnother, formValidity) {
            let mappedServices = $scope.services.map(serv => {
                const findItem = $scope.items.find(item => item.id === serv.id);
                serv.isHold = findItem ? findItem.is_hold === ' (Hold)' : false
                return serv;
            })

            $scope.loading_spinner = 1;
            $scope.disableFormButton = true;
            toastr.info($rootScope.trans('payments.processing'), $rootScope.trans('payments.wait'));
            var dt = new Date($scope.paymentScheduleData.start_date);
            dt = dt.toMysqlFormat();

            var input = {
                payment_method_id: $scope.paymentScheduleData.payment_method_id,
                recurring: $scope.paymentScheduleData.recurring,
                description: $scope.paymentScheduleData.description,
                start_date: $scope.paymentScheduleData.start_date,
                end_at: $scope.ref.choose_end_date ? $scope.paymentScheduleData.end_date : null,
                payment_services: $scope.services,
                is_doc_payment: $scope.is_doc_payment,
                document_amounts: $scope.document_amounts,
                document_id: $scope.document_id,
                hold: mappedServices.some(service => service.isHold),
            };

            // $scope.hasHold = mappedServices.some(service => service.isHold);

            if ($scope.extension_recurring_charges == 1) {
                input.extension_charges = $scope.extension_charges;
            }

            paymentScheduleService.create(input)
                .success(function (data) {
                    if ($scope.paymentScheduleData.recurring == true) {
                        if (data.success == false) {
                            toastr.error(data.errors.error, 'Error!');
                            toastr.options = {
                                "closeButton": false,
                                "showDuration": "100000",
                                "extendedTimeOut": "100000"
                            };
                            $scope.loading_spinner = 0;
                            $scope.disableFormButton = false;
                            return;
                        }

                        console.log('-----------------------------------');
                        console.log('----- Payment Schedule ------------');
                        console.log('-----------------------------------');
                        console.log(data.data);


                        // payNowOnlyOrPayNowAndCreateAnother
                        if (payNowOnlyOrPayNowAndCreateAnother == 'payNowOnly') {
                            $state.go('main.dashboard');
                            setTimeout("toastr.success('', 'Your Payment Schedule has been created');", 5000);
                        } else if (payNowOnlyOrPayNowAndCreateAnother == 'payNowAndCreateAnother') {
                            /*                            $scope.paymentScheduleData = {};
                                                        $scope.services = [
                                                            {}
                                                        ];*/
                            $state.go('main.paymentScheduleCreate');
                            toastr.success('', 'Your Payment Schedule has been created!');
                            $timeout(function () {
                                window.location.reload();
                            }, 1500);
                            //$window.location.reload();
                            //$location.path('#/payments/schedule/create/');
                        }
                    } else if ($scope.paymentScheduleData.recurring == false) {
                        console.log('-----------------------------------');
                        console.log('----- Payment Schedule ------------');
                        console.log('-----------------------------------');
                        console.log(data.errors);

                        if (data.success == true) {
                            //if ($scope.is_doc_payment == 1) {
                            //$state.go('main.documents');
                            //} else {
                            $state.go('main.paymentReceipt', {
                                id: data.data.payment_schedule_transaction.id
                            });
                            //}
                            var paymentMessage = 'Your payment is ' + data.data.payment_schedule_transaction.status + '.';
                            if (data.data.payment_schedule_transaction.status == 'paid')
                                paymentMessage += ' An email notification was sent to ' + $rootScope.authUser.email;
                            toastr.success(paymentMessage, $rootScope.trans('payments.payment_made'));
                            toastr.options = {
                                "closeButton": true
                            };

                        } else if (data.success == false) {

                            $state.go('main.paymentReceipt', {
                                id: data.errors.payment_schedule_transaction_id
                            });
                            toastr.error(data.errors.error, 'Error!');
                            toastr.options = {
                                "closeButton": false,
                                "showDuration": "100000",
                                "extendedTimeOut": "100000"
                            };
                        }
                    }

                })
                .error(function (data) {
                    $scope.loading_spinner = 0;
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });

        };
        /* -------------------------- END --- POST API calls ------------------------ */

        init();

        /**
         * Starting everything
         */
        function init() {
            console.log('init');
            initForm();
            initGetApi();
        }

        /**
         * Initialize form and services
         * - paymentScheduleData
         * - services
         */
        function initForm() {
            $scope.showFormButtons = false;

            /* -------------------------- Form Inputs ------------------------ */
            $scope.paymentScheduleData = {
                //  payment_method_id : null,
                recurring: (window.location.hash.indexOf('create/recurring') > 0) || ($stateParams.paymentMethodId && $stateParams.paymentMethodId === 'recurring') ? 1 : 0,
                start_date: '',//moment().format("MM/DD/YY")
                end_date: ''//moment().format("MM/DD/YY")
            };

            /* -------------------------- Payment Services ------------------------ */
            $scope.services = [];
            $scope.count = $scope.services.length;
            $scope.add = function () {
                $scope.services.push({
                    id: $scope.items[0].id,
                    amount: "",
                    name: ""
                });
                $scope.getHoldNotification();
            };
            $scope.removeService = function (index) {
                console.log(index);
                $scope.services.splice(index, 1);
            };
        }

        /**
         * API calls
         *
         * - paymentMethodService.getAll()
         * - propertyService.getPropertyPaymentServices()
         * - accountService.getAccountInformation()
         */
        function initGetApi() {
            /* -------------------------- Payment Methods all ------------------------ */
            //paymentMethodService.getAll()
            $http.get('/api/v1/payments/payment-methods?filter=usable')
                .success(function (data) {
                    console.log(data);
                    $scope.paymentMethods = data.data;

                    $scope.paymentMethodCount = (data.data).length;

                    console.log('-----------------------------------');
                    console.log('--------- Payment Methods ---------');
                    console.log('-----------------------------------');
                    console.log(data.data);

                    if ($stateParams.paymentMethodId != '' && $stateParams.paymentMethodId != 'recurring' && $scope.is_doc_payment == 0) {
                        $scope.paymentScheduleData.payment_method_id = Number($stateParams.paymentMethodId);
                    } else if (data.data.length == 1) {
                        $scope.paymentScheduleData.payment_method_id = data.data[0].id;
                    }

                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });

            $http.get('/api/v1/payments/recurring')
                .success(function (data) {
                    if (data.data.amount)
                        toastr.warning($rootScope.trans('payments.you_have_already_set', {amount: data.data.amount, next_date: data.data.next_date}), $rootScope.trans('payments.note'));

                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });


            /* -------------------------- Property Payment Services all ------------------------ */
            propertyService.getPropertyPaymentServices()
                .then(function (data) {
                    $scope.items = data.data;

                    console.log($scope.items)
                    console.log('-----------------------------------');
                    console.log('----- Drop down items -------------');
                    console.log('-----------------------------------');
                    console.log(data.data);

                    // if rent exists then done
                    // if rent dne then condo
                    // if rent dne and condo dne then its first key
                    // if both rent and condo exist then pick rent of course
                    var rentAndCondoExistenceArray = [];
                    var docExistenceArray = [];
                    var otherServices = [];
                    _.forEach(data.data, function (n, key) {
                        if (n.name == "Rent" || n.name == "Condo") {
                            rentAndCondoExistenceArray.push({
                                id: n.id,
                                name: n.name,
                                amount: ''
                            });
                        }

                        if (n.name.match(/document/i)) {
                            docExistenceArray.push({
                                id: n.id,
                                name: n.name,
                                amount: ''
                            });
                        }
                        otherServices.push({
                            id: n.id,
                            name: n.name,
                            amount: ''
                        });
                    });

                    // if at least 1 exists meaning there is at least 1 rent and/or condo
                    // if there is nothing and no existence of both rent and/or condo then we just show the first 1
                    $scope.services[0] = (rentAndCondoExistenceArray.length != 0) ? rentAndCondoExistenceArray[0] : otherServices[0];
                    if ($rootScope.amountToPay) {
                        if ($scope.items) {
                            for (var i in $scope.items)
                                if ($scope.items[i].name == 'Balance')
                                    $scope.services[0].id = $scope.items[i].id;
                        }
                        $scope.showBalanceOption = true;
                        $scope.services[0].amount = parseFloat($rootScope.amountToPay);
                        $rootScope.amountToPay = null;
                    }

                    if ($scope.is_doc_payment == 1) {
                        $scope.services[0] = (docExistenceArray.length != 0) ? docExistenceArray[0] : data.data[0];
                        $scope.services[0].amount = parseFloat($scope.document_amount);
                    }
                })
                .then(function () {
                    $scope.showFormButtons = true;
                });


            $timeout(function () {
                $http.post('/api/v1/valid-schedule-dates')
                    .success(function (data) {
                        $scope.valid_dates = data.data.valid_dates;
                        $scope.paynow_dates = data.data.paynow_dates;
                        $scope.paynow_dates_string = data.data.paynow_dates_string;

                        if (!$scope.paymentScheduleData.recurring) {
                            if ($scope.paynow_dates.length > 0) {

                                var dt = new Date();
                                if ($scope.paynow_dates.indexOf(dt.getDate()) === -1) {
                                    $scope.paynow_allowed = 0;
                                } else {
                                    $scope.paynow_allowed = 1;
                                }
                            } else {
                                $scope.paynow_allowed = 1;
                            }
                        }
                    });
            }, 500);

            /* -------------------------- get Extension Charges ------------------------ */
            $timeout(function () {
                if ($scope.showBalanceOption == true || $rootScope.amountToPay) {
                    return;
                }

                if ($scope.is_doc_payment == 1) {
                    $scope.extension_recurring_charges = 0;
                    return;
                }

                $scope.getExtensionEnabled();
            }, 500);

            /* -------------------------- Account Information ------------------------ */
            accountService.getAccountInformation()
                .success(function (data) {
                    $scope.property = data.data.property;
                    $scope.company_name = data.data.company_name;
                    console.log('-----------------------------------');
                    console.log('----- Tenant Property -------------');
                    console.log('-----------------------------------');
                    console.log(data.data.property);

                    $scope.tenant = data.data;
                    console.log('-----------------------------------');
                    console.log('----- Tenant Details --------------');
                    console.log('-----------------------------------');
                    console.log(data.data);
                });
        }

        function findServiceNames() {
            for (var x = 0; x < $scope.services.length; x++) {
                for (var y = 0; y < $scope.items.length; y++) {
                    if ($scope.services[x].id == $scope.items[y].id) {
                        $scope.services[x].name = $scope.items[y].name;
                        $scope.services[x].type = $scope.items[y].type;
                    }
                }
            }
        }

        function sumOfServices() {
            var services = $scope.services;
            var total_amount = 0;

            _.forEach(services, function (n, key) {
                console.log(n.amount);
                total_amount += n.amount;
            });

            $scope.total_amount = total_amount;
        }

        function getConvenienceFees() {
            var input = {
                payment_method_id: $scope.paymentScheduleData.payment_method_id,
                total_amount: $scope.total_amount,
                has_hold: $scope.hasHold
            };


            if ($scope.is_doc_payment == 1) {
                input.is_doc_payment = 1;
                input.total_amount = $scope.document_amounts.total_amount;
            }

            paymentScheduleService.getConvenienceFees(input)
                .success(function (data) {
                    console.log('-----------------------------------');
                    console.log('----- Service Fees ------------');
                    console.log('-----------------------------------');
                    console.log(data.data);
                    $scope.convenience_fees = data.data.tenant_convenience_fees;
                    $scope.fee_percent = data.data.fee_percent;
                });
        }

        function paidWithPaymentMethod() {
            var paymentMethods = $scope.paymentMethods;
            var payment_method = '';
            _.forEach(paymentMethods, function (n, key) {
                if (n.id == $scope.paymentScheduleData.payment_method_id) {
                    $scope.paymentMethod = n;
                    payment_method = $scope.paymentMethod.type;
                    $scope.these_charges = $rootScope.trans('payments.these_charges', {payment_method: payment_method});
                }
            });
        }

        function stepThreeCalculations() {
            if ($scope.paymentScheduleData.end_date) { // if there is an end date, ensure it is after start date
                var start_moment = moment($scope.paymentScheduleData.start_date);
                var end_moment = moment($scope.paymentScheduleData.end_date);
                if (start_moment.diff(end_moment) >= 0) {
                    toastr.error('End Date must be after start date');
                    return;
                }
            }


            $state.go('main.paymentScheduleCreate.step_3');

            var defer = $q.defer();

            defer.promise
                .then(function (test) {
                    findServiceNames();
                })
                .then(function () {
                    sumOfServices();
                })
                .then(function () {
                    getConvenienceFees();
                })
                .then(function () {
                    paidWithPaymentMethod();
                    $scope.start_date = moment($scope.paymentScheduleData.start_date).get('date');
                    $scope.end_date = $scope.paymentScheduleData.end_date ? moment($scope.paymentScheduleData.end_date).format('MMM Do YYYY') : 0;
                });

            defer.resolve('test');
        }

        /* -------------------------- Watchers ------------------------ */
        $scope.$watchCollection('services', function (newValue, oldValue) {
            $scope.count = newValue.length;
            console.log('services', $scope.count, newValue);
        });

    }

}());
