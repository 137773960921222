(function() {
    'use strict';

    angular
    .module('app')
    .controller('InvoiceCreateController', invoiceCreateController);

    function invoiceCreateController($scope,$http,$state,$rootScope,$timeout) {
        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.documents');
        }

        $scope.loading_spinner=0;

        $scope.document = {
            name: '',
            description: '',
            categories: [],
            company_id: 0,
            payment_required: 'no',
            type: 'regular',
            payment_amount: '',
            send_notifications: 0,
        };

        $scope.createDocument = function() {
            $scope.loading_spinner=1;

            $http.post('/api/v1/document/create',data, {transformRequest: angular.identity, headers: {'Content-Type': undefined}}).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error creating document");
                    $scope.loading_spinner=0;
                    return;
                }

                $scope.loading_spinner=0;
                $scope.creatingDocument = false;
                $state.go('main.documents');
                toastr.success('Document created');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingDocument = false;
                $scope.loading_spinner=0;

                if (typeof data == 'string' && data.includes('413') == true) {
                    toastr.error('Error uploading document, to large.');
                } else {
                    toastr.error(data.error.message);
                }
            });
        };
    }
}());
