(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyHoldsMerchant', pqPropertyHoldsMerchant);

    function pqPropertyHoldsMerchant() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-holds-merchant/property-holds-merchant.html'
        };
    }

})();
