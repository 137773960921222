(function () {

    'use strict';

    angular
        .module('app')
        .controller('PaymentsIndexController', PaymentsIndexController);

    function PaymentsIndexController($state, $scope, $stateParams,$timeout, $location, $http, helperService, propertyService, $rootScope) {
        $scope.isMobile = helperService.isMobileOrTablet() || Boolean(window.Capacitor.isNativePlatform());

        $scope.tenantId = $stateParams.tenantId;
        $scope.filterbartag = typeof $scope.tenantId === 'undefined' ? 'tenant-activity' : 'tenant-activity-' + $scope.tenantId;
        $scope.propertiesFilterString = {value: ''};

        if ($location.search().filter) {
            var filter = $location.search().filter;
            $('#dataTable').DataTable().search(`${filter}:status`).draw();
        }

        $scope.loadDataTable = () => {
            $scope.table = $('#dataTable').DataTable({
                responsive: $(window).width() < 1200,
                processing: true,
                'serverSide': true,
                'ajax': {
                    'url': pqDomain + '/api/v1/tenants/activity?tenant_id=' + $scope.tenantId + '?cordova=' + (!!window.Capacitor.isNativePlatform() ? '1' : '0') + '&token=' + pqCachedToken,
                    'type': 'POST',
                    error: function(jqXHR, textStatus, errorThrown) {
                        if (jqXHR.status != 0) {
                            alert('There was an error retrieving your data.');
                        }
                    },
                },
                dom: 'l<"pull-right"B>frtip',
                buttons: [
                    'copy',
                    {
                        extend: 'excelHtml5',
                        exportOptions: { orthogonal: 'export' }
                    },
                    {
                        extend: 'csvHtml5',
                        exportOptions: { orthogonal: 'export' }
                    },
                    'print'
                ],
                'columnDefs': [
                    {
                        'render': function (data, type, row) {
                            if (data.length > 20)
                                data = data.substring(0, 17) + '...';
                            return '<a href="#/tenants/' + row[10] + '/edit">' + data + '</a>';
                        },
                        'targets': [0, 1]
                    },
                    {
                        'render': function (data, type, row) {
                            if (type === 'display') {
                                if (data.length > 33) {
                                    return data.substring(0, 29) + '...';
                                }
                            }
                            return data;
                        },
                        'targets': [2]
                    },
                    {
                        'render': function (data, type, row) {
                            if (row[12] == 'recurring')
                                return '<img src="assets/svg/access-time.svg" class="recurring-icon" title="Scheduled to repeat monthly" />' + data;
                            return data;
                        },
                        'targets': [6]
                    },
                    {
                        'targets': [8],
                        'render': function (data, type, row) {
                            if (row[8].includes('Pending')) {
                                return '<strong style="color:#ffa500">' + data + '</strong>';
                            }
                            if (row[8].includes('Declined')) {
                                return '<strong style="color:#ea501b">' + data + '</strong>';
                            }
                            if (row[8].includes('Paid')) {
                                let r = '<strong style="color: #97d629">' + data;

                                if (row[13] == 'pending' || row[13] == 'pending') {
                                    r += '<span class="tooltip-pr" title="Pending Return"> (PR)</span>';
                                }

                                if (row[13] == 'confirmed') {
                                    r += '<span class="tooltip-r" title="Returned"> (R)</span>';
                                }

                                if (row[13] == 'declined') {
                                    r += '<span class="tooltip-dr" title="Declined Return"> (DR)</span>';
                                }

                                r += '</strong>';

                                return r;
                            }
                            if (row[8] == 'Deleted') {
                                return '<strong style="color: #ea1b1b">' + data + '</strong>';
                            }

                            return '<b class="' + row[8] + '">' + data + '</b>';
                        }
                    },
                    {
                        'render': function (data, type, row) {
                            if (row[9] == '') {
                                data = 'None';
                            }

                            if (row[9] == 0) {
                                //return data;
                                return '<a href="#/payments/receipt/' + row[11] + '">' + data + '</a>';
                            } else {
                                return '<a href="#/payments/receipt/' + row[11] + '">' + row[9] + '</a>';
                            }
                        },
                        'targets': [9]
                    }
                ],
            });
        }

        $scope.$on('$includeContentLoaded', () => {
            if (!$scope.isMobile) {
                $scope.loadDataTable();

                $('#dataTable').on('draw.dt', function () {
                    $timeout(function () {
                        $('.tooltip-dr').tooltip({boundary: 'window'})
                        $('.tooltip-r').tooltip({boundary: 'window'})
                        $('.tooltip-pr').tooltip({boundary: 'window'})
                    }, 500);
                });
            }
        });

        // filter

        $scope.managerMobile = {
            filter: {
                text: '',
                timeline: {
                    today: false,
                    '1dayago': false,
                    '1weekago': false,
                    '2weeksago': false,
                    '1monthago': false,
                    '2monthago': false,
                    '4monthago': false,
                    '6monthago': false
                },
                status: {
                    paid: false,
                    pending: false,
                    declined: false,
                    deleted: false
                },
            },
            loading: {
                tickets: false,
                properties: false
            }
        };

        $scope.pageChangedCan = (e) => {
            $scope.getManagerRequests()
        }
        $scope.currentPageCan = {
            value: 1
        };
        $scope.requests = [];
        $scope.paginateData = {};
        $scope.getManagerRequests = function () {
            $scope.managerMobile.loading.tickets = true;
            let url = '';
            if ($rootScope.authUser.permissions == 'tenant') {
                url = `/api/v1/tenants/activity-mobile?tenant_id=${$scope.tenantId}&page=${$scope.currentPageCan.value}&filter=${$scope.filterString}`;
            } else {
                url = `/api/v1/tenants/activity-mobile?page=${$scope.currentPageCan.value}&filter=${$scope.filterString}`;
            }

            $http.get(url).success(function (response) {
                $scope.allRequests = response.data
                $scope.filteredRequests = response.data

                $scope.viewbyCan = 15;
                $scope.totalItemsCan = response.total;
                $scope.itemsPerPageCan = $scope.viewbyCan;
                $scope.maxSizeCan = 5; //Number of pager buttons to show

                $scope.managerMobile.loading.tickets = false;
            });
            $scope.setPageCan = function (pageNo) {
                $scope.currentPageCan.value = pageNo;
            };
        };

        $scope.getManagerRequests();
        $scope.pageChanged = function() {
            $log.log('Page changed to: ' + $scope.currentPage);
            console.log($scope.currentPage)
        };


        $scope.filterString = '';
        $scope.paginationFilter = {
            property: {},
            unit: {},
            date: '',
            status: ''
        };

        $scope.managerMobileFilterBy = () => {
            $scope.paginationFilter.property = $scope.properties.filter(i => i.selected).map(i => i.id);
            $scope.paginationFilter.unit = $scope.units.filter(i => i.selected);
            $scope.paginationFilter.date = $scope.managerMobile.filter.timeline;
            $scope.paginationFilter.status = $scope.managerMobile.filter.status;

            $scope.filterString = JSON.stringify($scope.paginationFilter);
            $scope.currentPageCan.value = 1;
            $scope.getManagerRequests();
        }

        $scope.showFilterModal = (type) => {
            if (type === 'timeline') {
                $('#mobileManagerFilterTimeline').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'unit') {
                $('#mobileManagerFilterUnit').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'status') {
                $('#mobileManagerFilterStatus').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'properties') {
                $scope.getProperties();
                $('#mobileManagerFilterProperties').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
        }

        $scope.properties = [];
        $scope.propertiesFiltered = [];
        $scope.getProperties = function () {
            if ($scope.properties.length === 0) {
                $scope.managerMobile.loading.properties = true;
                $http.post('/api/v1/get-filter-properties').success(function (data) {
                    $scope.properties = data.data.properties;
                    $scope.propertiesFiltered = data.data.properties;
                    $scope.managerMobile.loading.properties = false;
                });
            }
        };

        $scope.filterProperties = (e) => {
            const search = $scope.propertiesFilterString.value.toLowerCase();
            $scope.propertiesFiltered = $scope.properties.filter(propeerty => ~propeerty.name.toLowerCase().indexOf(search));
        }

        $scope.units = [];

        $scope.getUnits = propertyIds => {
            propertyService.getUnitsForProperties(propertyIds).then(data => {
                $scope.units = data;
            });
        };

        $scope.propertyChanged = () => {
            let propertyIds = [];
            $scope.properties.map(property => {
                if (property.selected) {
                    propertyIds.push(property.id);
                }
            });

            $scope.getUnits(propertyIds);
        }

        $scope.getUnitBtnClass = () => {
            return {"active": Object.values($scope.units).some(e => e.selected)};
        }

        $scope.getStatusBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.status).some(e => e)};
        }

        $scope.getTimelineBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.timeline).some(e => e)};
        }

        $scope.getPropertyBtnClass = () => {
            return {"active": Object.values($scope.properties).some(e => e.selected)};
        }
    }
}());
