(function () {

    'use strict';

    angular
        .module('app')
        .controller('ShowingIndexController', function ( $scope, $state, helperService, $http, propertyService, $timeout, $rootScope, prospectService) {
            $scope.isMobile = helperService.isMobileOrTablet() || Boolean(window.Capacitor.isNativePlatform());

            $scope.state = $state;
            $scope.ref = {prospect: ''};
            $scope.prospects = [];

            $scope.prospectSelected = () => {

                $('#add-showing-modal').modal('hide');
                $state.go('main.showings.create', {prospectId: $scope.ref.prospect.id});
            };

            $scope.openAddPopup = () => {
                $http.post('/api/v1/prospect/get-list').success(function(data) {
                    if (!data.success) {
                        return;
                    }
                    $scope.prospects = data.data.prospects.map(item => {
                        item['name'] = `${item.first_name} ${item.last_name}`;
                        return item;
                    });
                });
                $('#add-showing-modal').appendTo('body').modal('show');

            };

            $scope.goToCreate = () => {
                $('#add-showing-modal').modal('hide');
                $state.go('main.showings.createBlank');
            };

            $scope.loadDataTables = () => {
                if (!$.fn.DataTable.isDataTable('#dataTable')) {
                    const table = $('#dataTable').dataTable({
                        responsive: $(window).width() < 1200,
                        'processing': true,
                        'serverSide': true,
                        'columnDefs': [{
                            "targets": [7],
                            "visible": false,
                            "searchable": false
                        }],
                        'order': [[7, 'desc']], //order by id desc
                        'ajax': {
                            'url': pqDomain + '/api/v1/showing/manage' + '?cordova=' + (!!window.Capacitor.isNativePlatform() ? '1' : '0') + '&token=' + pqCachedToken,
                            'type': 'POST',
							error: function(jqXHR, textStatus, errorThrown) {
                                if (jqXHR.status != 0) {
                                    alert('There was an error retrieving your data.');
                                }
                            },
                        },
                        buttons: []
                    });

                    $('#dataTable tbody').on('click', 'tr', function (e) {
                        if ($(window).width() < 1200) {
                            e.preventDefault();

                            const id = $(e.target).parent().next().children().children().find('li').last().find('span').last().attr('data-id');
                            if (id) {
                                $('#dataTable tbody').on('click', 'tr.child', (function () {
                                    angular.element($('#dataTable tbody .child')).scope().state.go('main.showings.edit', {id});
                                }));
                            }
                        } else {
                            if ($(e.target).parent().children().index(e.target)) {
                                const id = $(this).find('span').first().data('id'); // eslint-disable-line angular/controller-as-vm

                                if (id) {
                                    angular.element($('#dataTable tbody')).scope().state.go('main.showings.edit', {id});
                                }
                            }
                        }
                    });
                }
            }

            $scope.$on('$includeContentLoaded', () => $scope.loadDataTables());


            // filter


            $scope.badge_counts = {
                open: 0,
                in_progress: 0,
                completed: 0,
                scheduled: 0,
                approvals: 0,
                rejected: 0,
                cancelled: 0
            };

            $scope.managerMobile = {
                filter: {
                    text: '',
                    timeline: {
                        today: false,
                        '1dayago': false,
                        '1weekago': false,
                        '2weeksago': false,
                        '1monthago': false,
                        '2monthago': false,
                        '4monthago': false,
                        '6monthago': false
                    },
                    status: {
                        booked: false,
                        completed: false,
                        noShow: false,
                        cancelled: false
                    },
                },
                loading: {
                    tickets: false,
                    properties: false
                }
            };

            $scope.requests = [];
            $scope.getManagerRequests = function () {
                $scope.managerMobile.loading.tickets = true;
                $http.get('/api/v1/showing/manage-mobile').success(function (response) {
                    $scope.allRequests = response.data
                    $scope.filteredRequests = response.data

                    $scope.viewbyCan = 5;
                    $scope.totalItemsCan = $scope.filteredRequests.length;
                    $scope.currentPageCan = 1;
                    $scope.itemsPerPageCan = $scope.viewbyCan;
                    $scope.maxSizeCan = 5; //Number of pager buttons to show

                    $scope.setPageCan = function (pageNo) {
                        $scope.currentPageCan = pageNo;
                    };

                    $scope.setItemsPerPageCan = function (num) {
                        $scope.itemsPerPageCan = num;
                        $scope.currentPageCan = 1; //reset to first page
                    };
                    $scope.managerMobile.loading.tickets = false;
                });
            };


            angular.element($('body')).scope().state = $state;

            $scope.managerMobileFilterBy = () => {
                const t = $scope.managerMobile.filter.text.toLowerCase();
                const filteredResults = $scope.allRequests.filter(item => {
                    const filterByTextResult = ~item.propertyName.toLowerCase().indexOf(t)
                        || (item.unit && ~item.unit.indexOf(t))
                        || ~item.status.toLowerCase().indexOf(t)
                        || ~item.id.toString().indexOf(t)
                        || ~item.prospectName.toLowerCase().indexOf(t)
                        || ~item.managerName.toLowerCase().indexOf(t)
                        || ~item.email.toLowerCase().indexOf(t)
                        || ~item.phone.toString().toLowerCase().indexOf(t);

                    const filterByDate = ($scope.managerMobile.filter.timeline.today ? moment(item.date).startOf('day').isSame(moment().startOf('day')) : false)
                        || ($scope.managerMobile.filter.timeline["1dayago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'days')) : false)
                        || ($scope.managerMobile.filter.timeline["1weekago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'weeks')) : false)
                        || ($scope.managerMobile.filter.timeline["2weeksago"] ? moment(item.date).isAfter(moment().startOf('day').add(-2, 'weeks')) : false)
                        || ($scope.managerMobile.filter.timeline["1monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'month')) : false)
                        || ($scope.managerMobile.filter.timeline["2monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-2, 'month')) : false)
                        || ($scope.managerMobile.filter.timeline["4monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-4, 'month')) : false)
                        || ($scope.managerMobile.filter.timeline["6monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-6, 'month')) : false);
                    const filterByStatus = ($scope.managerMobile.filter.status.open ? item.status === 'open' : false)
                        || ($scope.managerMobile.filter.status.booked ? item.status === 'Booked' : false)
                        || ($scope.managerMobile.filter.status.completed ? item.status === 'Completed' : false)
                        || ($scope.managerMobile.filter.status.noShow ? item.status === 'No show' : false)
                        || ($scope.managerMobile.filter.status.cancelled ? item.status === 'Cancelled' : false);
                    const filterByProperty = $scope.properties.some(e => {
                        return e.selected && e.name.toLowerCase() === item.propertyName.toLowerCase();
                    });
                    const filterByUnit = $scope.units.some(e => {
                        return e.selected && e.unit == item.unit;
                    });

                    return (Object.values($scope.managerMobile.filter.status).some(e => e) ? filterByStatus : true)
                        && (Object.values($scope.units).some(e => e.selected) ? filterByUnit : true)
                        && (Object.values($scope.managerMobile.filter.timeline).some(e => e) ? filterByDate : true)
                        && (Object.values($scope.properties).some(e => e.selected) ? filterByProperty : true)
                        && (filterByTextResult !== -1 ? filterByTextResult : true);
                });

                $scope.filteredRequests = filteredResults;
            }

            $scope.showFilterModal = (type) => {
                if (type === 'timeline') {
                    $('#mobileManagerFilterTimeline').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
                if (type === 'status') {
                    $('#mobileManagerFilterStatus').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
                if (type === 'unit') {
                    $('#mobileManagerFilterUnit').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
                if (type === 'properties') {
                    $scope.getProperties();
                    $('#mobileManagerFilterProperties').appendTo('body').modal({backdrop: 'static', keyboard: false});
                }
            }

            $scope.properties = [];
            $scope.getProperties = function () {
                if ($scope.properties.length === 0) {
                    $scope.managerMobile.loading.properties = true;
                    $http.post('/api/v1/get-filter-properties').success(function (data) {
                        $scope.properties = data.data.properties;
                        $scope.managerMobile.loading.properties = false;
                    });
                }
            };

            $scope.units = [];

            $scope.getUnits = propertyIds => {
                propertyService.getUnitsForProperties(propertyIds).then(data => {
                    $scope.units = data;
                });
            };

            $scope.propertyChanged = () => {
                let propertyIds = [];
                $scope.properties.map(property => {
                    if (property.selected) {
                        propertyIds.push(property.id);
                    }
                });

                $scope.getUnits(propertyIds);
            }


            $scope.getManagerRequestsForBookedStatus = function () {
                $rootScope.isFromShowingsCardBoxBooked = false;
                $scope.managerMobile.loading.tickets = true;
                $http.get('/api/v1/showing/manage-mobile').success(function (response) {
                    $scope.allRequests = response.data
                    $scope.managerMobile.filter.status.booked = true;
                    $scope.managerMobileFilterBy();

                    $scope.viewbyCan = 5;
                    $scope.totalItemsCan = $scope.filteredRequests.length;
                    $scope.currentPageCan = 1;
                    $scope.itemsPerPageCan = $scope.viewbyCan;
                    $scope.maxSizeCan = 5; //Number of pager buttons to show

                    $scope.setPageCan = function (pageNo) {
                        $scope.currentPageCan = pageNo;
                    };

                    $scope.setItemsPerPageCan = function (num) {
                        $scope.itemsPerPageCan = num;
                        $scope.currentPageCan = 1; //reset to first page
                    };
                    $scope.managerMobile.loading.tickets = false;
                });
            };

            if ($rootScope.isFromShowingsCardBoxBooked) {
                $scope.getManagerRequestsForBookedStatus();
            } else {
                $scope.getManagerRequests();
            }

            $scope.getUnitBtnClass = () => {
                return {"active": Object.values($scope.units).some(e => e.selected)};
            }

            $scope.getStatusBtnClass = () => {
                return {"active": Object.values($scope.managerMobile.filter.status).some(e => e)};
            }

            $scope.getTimelineBtnClass = () => {
                return {"active": Object.values($scope.managerMobile.filter.timeline).some(e => e)};
            }

            $scope.getPropertyBtnClass = () => {
                return {"active": Object.values($scope.properties).some(e => e.selected)};
            }

        });

}());
