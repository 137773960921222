(function () {

    'use strict';

    angular
        .module('app')
        .factory('authenticationData', authenticationFactory);

    function authenticationFactory($q, authenticationService)
    {
        return {
            load: load
        };

        function load() {

            var authUser = authenticationService.getAuth();

            return $q.all([authUser]).then(
                function (results) {
                    return {
                        authUser: results[0]
                    }
                });

        }
    }

})();