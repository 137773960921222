(function() {

    'use strict';

    angular
        .module('app')
        .factory('accountService', accountService);

    function accountService($http) {

        var base_url = '/api/v1/';
        var local_url = 'accounts';

        return {
            getAccountInformation: getAccountInformation,
            updateAccountInformation: updateAccountInformation,
            changePassword: changePassword,
            updateSawProMessage: () => {
                $http.post(`${base_url}${local_url}/update-saw-pro-message`).then(() => {});
            }
        };

        function getAccountInformation()
        {
            return $http.get(base_url + local_url);
        }

        function updateAccountInformation(data)
        {
            console.log('should be posting now?',data);
            return $http.post(base_url + local_url+'/update', data);

        }

        function changePassword(data)
        {
            return $http.post(base_url + local_url + '/change-password', data);
        }

    }

}());
