
(function () {

    'use strict';

    angular
        .module('app')
        .controller('MaintenanceRequestCreateController', maintenanceRequestCreateController);

    function maintenanceRequestCreateController($scope, $http, $state, $rootScope, $timeout, langService, propertyService) {
        $scope.t = langService.t('maint_req');

        $scope.time_values = [
            {text: '15 ' + $scope.t('minutes'), value: 15},
            {text: '30 ' + $scope.t('minutes'), value: 30},
            {text: '45 ' + $scope.t('minutes'), value: 45},
            {text: '1 ' + $scope.t('hour'), value: 60},
            {text: '1 ' + $scope.t('hour') + ' 30' + $scope.t('minutes'), value: 90},
            {text: '2 ' + $scope.t('hours'), value: 120},
        ];

        $scope.time_slots = {
            1: $scope.t('Anytime'),
            2: '9 - 12',
            3: '1 - 5'
        };

        $scope.loading_page_spinner = 1;
        $timeout(function () {
            $scope.loading_page_spinner = 0;
        }, 8000);

        $scope.selectedProperty = {
            name: ''
        };
        $scope.properties = [];
        $scope.propertiesWithPortfolios = [];

        $scope.current_unit_check = '';
        $scope.selectedPropertyUnits = [];
        $scope.selectedUnit = {
            unit: ''
        };
        $scope.creatingMR = 0;
        $scope.residents = [];
        $scope.mr = {
            manager_id: 0,
            unit_specific: 1,
            recurring: 0,
            start_at: moment().format('MM/DD/YYYY'),
            interval: '',
            interval_count: 1,
            access_date: moment().format('MM/DD/YYYY'),
            opening_time: '',
            closing_time: '',
            properties: [],
            available_time: 1,
            reminder: '',
            reminder_days_before: '',
            assigned_start_time: '',
            time_to_complete: 0,
            tenant_responsible: 0,
            investigated: null,
            smoke_alarm_working: '',
            why_uninvestigated: '',
            problem_made_aware_at: moment().format('MM/DD/YYYY'),
            pm_advised: null,
            why_pm_not_advised: '',
            additional_questions: []
        };
        $scope.terms_confirmed = false;

        $scope.calendar_start_time = Date.now();
        $timeout(function () {
            $('#start_datetimepicker1').daterangepicker({
                    'startDate': $scope.calendar_start_time,
                    'singleDatePicker': true,
                    'autoUpdateInput': false,
                    'timePicker': true,
                    'autoApply': true,
                    'timePickerIncrement': 15,
                    locale: {
                        format: 'YYYY-MM-DD hh:mm A'
                    }
                },
                function (start, end, label) {
                    if( $scope.calendar_start_time != start.format('YYYY-MM-DD hh:mm A')){
                        $scope.mr.assigned_start_time = start.format('YYYY-MM-DD HH:mm');
                        $scope.calendar_start_time = start.format('YYYY-MM-DD hh:mm A');
                        $('#start_datetimepicker1').val(start.format('YYYY-MM-DD hh:mm A'));
                    }
                }
            );
        });


        $scope.recurring_allowed = 0;

        $scope.photos = [];
        $scope.showPhotosActions = true;

        $('#start-at').datepicker();

        if ($rootScope.authUser.permissions == 'tenant') {
            $scope.mr.phone = $rootScope.authUser.phone_number;
            $scope.loading_page_spinner = 0;
        } else {
            $http.post('/api/v1/properties/get-all-for-manager-payments?portfolios=1').success(function (data) {
                $scope.propertiesWithPortfolios = data.data;
                $scope.properties = $scope.propertiesWithPortfolios.filter(item => !~('' + item.id).indexOf('portfolio'));

                $scope.propertiesWithPortfolios = $scope.propertiesWithPortfolios.map(item => {
                    if(~('' + item.id).indexOf('portfolio')){
                        item.maintenance_request_pro_enabled = 0;
                        item.properties_ids.split(',').forEach(t => {
                           const prop = $scope.properties.find(i => i.id == t);
                           if(prop && prop.maintenance_request_pro_enabled){
                               item.maintenance_request_pro_enabled = 1;
                           }
                        });
                    }
                    return item;
                });

                $timeout(function () {
                    $('select#properties').multipleSelect({filter: true, width: 350});
                    $scope.loading_page_spinner = 0;
                });
            });
        }


        $scope.unitSpecificChange = function () {
            if ($scope.mr.unit_specific == 0) {
                $timeout(function () {
                    $('select#properties').multipleSelect({filter: true, width: 350});
                });
            }
            $scope.setRecurringAllowed();

            if ($scope.mr.unit_specific == 1) {
                $scope.getCategories($scope.selectedProperty.name.id);
            }
        };

        $scope.first_property = 0;
        $scope.setRecurringAllowed = function () {
            if ($scope.mr.unit_specific == 0) {
                $scope.recurring_allowed = 0;

                angular.forEach($scope.propertiesWithPortfolios, function (property) {
                    if ($scope.mr.properties.includes(property.id) && property.maintenance_request_pro_enabled == 1) {
                        $scope.recurring_allowed = 1;
                    }
                });

                $scope.first_property = $scope.mr.properties[0];
                $scope.getCategories($scope.mr.properties[0]);
                $scope.getPriorities($scope.mr.properties[0]);
            } else {
                $scope.recurring_allowed = typeof $scope.selectedProperty.name != 'undefined' && $scope.selectedProperty.name.maintenance_request_pro_enabled == 1 ? 1 : 0;
            }
        };

        $scope.unitPermissionChanged = function () {
            if ($scope.mr.permission_to_enter_unit_alone == 0) {
                $timeout(function () {
                    $('#access-at').datepicker({
                        minDate: 0,
                    },
                    );
                });
            }
        };
        
        $scope.getQuestions = (property_id) => {
            propertyService.getMRQuestions(property_id).success((response) => {
                $scope.mr.additional_questions = response.data;
            });
        };

        if($rootScope.authUser.property_id){
            $scope.getQuestions($rootScope.authUser.property_id);
        }

        $scope.intervals = [];
        $scope.getIntervals = function () {
            $http.post('/api/v1/maintenance-requests/get-intervals').success(function (data) {
                $scope.intervals = data.data;
                $scope.mr.interval = $scope.intervals[0].slug;
            });
        };
        $scope.getIntervals();

        $scope.categories = {};
        $scope.getCategories = function (property_id) {
            $http.post('/api/v1/maintenance-requests/get-categories/' + property_id, {unit_specific: $scope.mr.unit_specific}).success(function (data) {
                $scope.categories = data.data;
            });
        };    
        $scope.getCategories(0);

        $scope.priorities = [];
        $scope.priorities_strings = {};
        if($rootScope.authUser.permissions === 'tenant'){
            $scope.getPriorities = function (property_id) {
                $http.get('/api/v1/translations/get-maintenence-request-priorities-for-tenant/').success(function(data) {
                    var lang = data.data.current_locale;
                    $scope.priorities = data.data.maintenance_request_priorities.en_keys; //will be empty if no extension
                    if(lang === 'fr'){
                        $scope.priorities_strings = data.data.maintenance_request_priorities.fr;
                    }else{
                        for(let i = 0; i < $scope.priorities.length; i++ ){
                            $scope.priorities_strings[$scope.priorities[i]] = $scope.priorities[i];
                        }
                    }
                });
            };
        }else{
            $scope.getPriorities = function (property_id) {
                $http.post('/api/v1/maintenance-requests/get-priorities/' + property_id).success(function (data) {
                    $scope.priorities = data.data;
                });
            };    
        }
        $scope.getPriorities(0);

        $scope.propertyUnitsLoading = false;
        $scope.$watch('selectedProperty.name', function () {
            if ($scope.selectedProperty.name !== undefined && $scope.selectedProperty.name.name !== undefined) {
                if ($scope.selectedProperty.name.maintenance_request_module_enabled < 1) {
                    $scope.selectedProperty = false;
                    toastr.error($scope.t('The Maintenance Request module is not enabled for that property'));
                } else {
                    $scope.propertyUnitsLoading = true;
                    $http.get('/api/v1/units-in-property/' + $scope.selectedProperty.name.id).success(function (data) {
                        $scope.selectedPropertyUnits = data.data;
                        // console.log('$scope.selectedPropertyUnits', $scope.selectedPropertyUnits);
                        $scope.managers = data.data.managers;

                        if(!$rootScope.authUser.user_permissions['maintenance-requests-full']){
                            $scope.mr.manager_id = $rootScope.authUser.id;
                        }
                        $scope.selectedUnit.unit = null;
                        $scope.mr.tenant_id = 0;
                        $scope.propertyUnitsLoading = false;
                    });
                    $scope.getCategories($scope.selectedProperty.name.id);
                    $scope.getPriorities($scope.selectedProperty.name.id);
                    $scope.getQuestions($scope.selectedProperty.name.id);
                }
            }
        });

        $scope.$watch('selectedUnit.unit', function () {
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }

            if ($scope.current_unit_check == $scope.selectedUnit.unit?.unit) {
                return;
            }

            $scope.current_unit_check = $scope.selectedUnit.unit?.unit;

            $scope.residents = [];
            $scope.mr.tenant_id = 0;
            $scope.mr.phone = '';
            if ($scope.selectedUnit.unit !== undefined && $scope.selectedUnit.unit.unit !== undefined) {
                //filter all property tenants by unit
                for (var i in $scope.selectedPropertyUnits.tenants) {
                    var t = $scope.selectedPropertyUnits.tenants[i];

                    if (t.property_unit != null && t.property_unit.unit == $scope.selectedUnit.unit.unit) {
                        $scope.residents.push(t);
                    }
                }

                if ($scope.residents.length == 0) {
                    toastr.warning($scope.t('There is no active tenant in unit')+ ' ' + $scope.selectedUnit.unit.unit);
                }else {
                    $scope.mr.tenant_id = $scope.residents[0].id; //select 1st tenant by default (instead of no tenant)
                    $scope.residentChange();
                }
            }
        });

        $scope.residentChange = function () {
            if(!$scope.mr.tenant_id){
                $scope.mr.phone = null;
                return;
            }

            for (var i in $scope.selectedPropertyUnits.tenants) {
                var t = $scope.selectedPropertyUnits.tenants[i];

                if (t.id == $scope.mr.tenant_id && t.property_unit.unit == $scope.selectedUnit.unit.unit) {
                    $scope.mr.phone = t.phone_number;
                }
            }
        };

        $scope.setOptions = function(srcType) {
            var options = {
                // Some common settings are 20, 50, and 100
                quality: 75,
                destinationType: Camera.DestinationType.FILE_URI,
                // In this app, dynamically set the picture source, Camera or photo gallery
                sourceType: srcType,
                encodingType: Camera.EncodingType.JPEG,
                mediaType: Camera.MediaType.PICTURE,
                allowEdit: false,
                correctOrientation: true,
                targetWidth: 1000,
                targetHeight: 1000
            }
            return options;
        }

        $scope.createNewFileEntry = function(imgUri) {
            window.resolveLocalFileSystemURL(cordova.file.cacheDirectory, function success(dirEntry) {

                // JPEG file
                dirEntry.getFile("tempFile.jpeg", { create: true, exclusive: false }, function (fileEntry) {

                    // Do something with it, like write to it, upload it, etc.
                    // writeFile(fileEntry, imgUri);
                    console.log("got file: " + fileEntry.fullPath);
                    // displayFileData(fileEntry.fullPath, "File copied to");

                }, $scope.onErrorCreateFile);

            }, $scope.onErrorResolveUrl);
        }

        $scope.onErrorCreateFile = function() {

        }

        $scope.onErrorResolveUrl = function() {

        }

        $scope.resetImageSelection = function(index) {
            $scope.photos.splice(index, 1);
            $scope.canShowPhotosActions();
        }

        $scope.canShowPhotosActions = function() {
            $scope.showPhotosActions = $scope.photos.every(p => p.urlConverted !== null) && $scope.photos.length < 3;
        }

        $scope.photoTypeSelection = function(type) {
            var newPhoto = {
                "type": type,
                "value": null,
                "url": null,
                "urlConverted": null
            }

            $scope.photos.push(newPhoto);

            if(type === 'photo') {
                $scope.addCameraPhoto($scope.photos.length - 1);
            } else if(type === 'file') {
                $scope.addFile($scope.photos.length - 1);
            }
        }

        $scope.addCameraPhoto = function(index) {
            var srcType = Camera.PictureSourceType.CAMERA;
            var options = $scope.setOptions(srcType);
            var func = $scope.createNewFileEntry();

            navigator.camera.getPicture(function cameraSuccess(imageUri) {
                $scope.photos[index].url = imageUri;
                $scope.photos[index].urlConverted = window.Ionic.WebView.convertFileSrc(imageUri);

                $scope.getImageBlobFromUrl($scope.photos[index].urlConverted, index);

                $scope.canShowPhotosActions();

            }, function cameraError(error) {
                $scope.photos.splice($scope.photos.length -1, 1);
                $scope.canShowPhotosActions();
            }, options);
        }

        $scope.addFile = function(index) {
            var srcType = Camera.PictureSourceType.PHOTOLIBRARY;
            var options = $scope.setOptions(srcType);
            var func = $scope.createNewFileEntry;

            navigator.camera.getPicture(function cameraSuccess(imageUri) {
                $scope.photos[index].url = imageUri;
                $scope.photos[index].urlConverted = window.Ionic.WebView.convertFileSrc(imageUri);

                $scope.getImageBlobFromUrl($scope.photos[index].urlConverted, index);
                $scope.canShowPhotosActions();
            }, function cameraError(error) {
                $scope.photos.splice($scope.photos.length -1, 1);
                $scope.canShowPhotosActions();

            }, options);
        }



        $scope.getImageBlobFromUrl = function(url, photoIndex) {
            var url = url.replace('http:', 'https:'); //temp fix for CORS issue?
            $http.get(url, { responseType: 'blob' })
                .then(function(data) {
                    $scope.photos[photoIndex].value = data.data;
                })
                .catch(function(error) {
                    console.log(error);
                    $scope.resetImageSelection(photoIndex);
                });
        }

        $scope.validateAttachments = function () {
            const allowedTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/bmp',
                'image/svg+xml',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ];
            var f1 = null;
            var f2 = null;
            var f3 = null;

            if($rootScope.platform == 'android' || $rootScope.platform == 'ios') {
                f1 = $scope.photos[0] ? $scope.photos[0]['value'] : null;
                f2 = $scope.photos[1] ? $scope.photos[1]['value'] : null;
                f3 = $scope.photos[2] ? $scope.photos[2]['value'] : null;
            } else {
                f1 = document.getElementById('file1').files[0];
                f2 = document.getElementById('file2').files[0];
                f3 = document.getElementById('file3').files[0];
            }

            console.log('f1', f1)
            console.log('f2', f2)
            console.log('f3', f3)
            let hasError = false;

            if (f1 && f1.size > 20 * 1024 * 1024) {
                toastr.error(f1.name + ' can\'t be submitted', 'File size exceeds the 20MB limit')
                hasError = true;
            }
            if (f1 && !allowedTypes.includes(f1.type)) {
                toastr.error(f1.name + ' can\'t be submitted', 'Please only upload Images and Documents')
                hasError = true;
            }

            if (f2 && f2.size > 20 * 1024 * 1024) {
                toastr.error(f2.name + ' can\'t be submitted', 'File size exceeds the 20MB limit')
                hasError = true;
            }
            if (f2 && !allowedTypes.includes(f2.type)) {
                toastr.error(f2.name + ' can\'t be submitted', 'Please only upload Images and Documents')
                hasError = true;
            }

            if (f3 && f3.size > 20 * 1024 * 1024) {
                toastr.error(f3.name + ' can\'t be submitted', 'File size exceeds the 20MB limit')
                hasError = true;
            }
            if (f3 && !allowedTypes.includes(f3.type)) {
                toastr.error(f3.name + ' can\'t be submitted', 'Please only upload Images and Documents')
                hasError = true;
            }
            return hasError;
        }

        $scope.createMR = function () {
            if ($scope.creatingMR == 1) {
                toastr.warning($scope.t('Please wait while we process your maintenance request'));
                return;
            }

            if ($rootScope.authUser.permissions == 'tenant') {
                $scope.mr.tenant_id = $rootScope.authUser.id;
            }

            if ($scope.validateAttachments()) {
                return;
            }


            var hide_permission_to_enter = $rootScope.authUser.permissions == 'tenant' ? $rootScope.authUser.property.maintenance_request_hide_permission_to_enter : $scope.selectedProperty.maintenance_request_hide_permission_to_enter;

            // should be validated on the server side but we're too far gone now
            var errors = 1;
            if ($scope.mr.subject === undefined || $scope.mr.subject == '') {
                toastr.error($rootScope.trans('maint_req.enter_subject'));
            } else if ($scope.mr.unit_specific == 1 && $scope.mr.tenant_id && ($scope.mr.phone === undefined || $scope.mr.phone == '')) {
                console.log($scope.mr.phone, $scope.mr.tenant_id);
                toastr.error($rootScope.trans('maint_req.enter_phone'));
            } else if ($scope.mr.unit_specific == 1 && ($scope.mr.problem_in_unit === undefined || $scope.mr.problem_in_unit == '')) {
                toastr.error($rootScope.trans('maint_req.is_problem'));
            } else if (hide_permission_to_enter == 0 &&$scope.mr.problem_in_unit > 0 && $scope.residents.length > 0 && ($scope.mr.permission_to_enter_unit_alone === undefined || $scope.mr.permission_to_enter_unit_alone == '')) {
                $rootScope.authUser.permissions == 'tenant' ? toastr.error($rootScope.trans('maint_req.can_enter_tenant')) : toastr.error($rootScope.trans('maint_req.can_enter_manager'));
            } else if ($scope.mr.details === undefined || $scope.mr.details == '') {
                toastr.error($rootScope.trans('maint_req.provide_details'));
            } else if ($scope.priorities.length == 0 && ($scope.mr.priority === undefined || $scope.mr.priority == '')) {
                toastr.error($rootScope.trans('maint_req.select_priority'));
            } else if ($scope.priorities.length > 0 && ($scope.mr.extension_priority === undefined || $scope.mr.extension_priority == '')) {
                toastr.error($rootScope.trans('maint_req.select_priority'));
            } else if($scope.selectedProperty.name.maintenance_request_additional_questions_enabled === 1 && $scope.mr.investigated === null) {
                toastr.error($rootScope.trans('maint_req.submit_investigated'));
            } else if($scope.selectedProperty.name.maintenance_request_additional_questions_enabled === 1 && $scope.mr.pm_advised === null) {
                toastr.error($rootScope.trans('maint_req.submit_project_manager_advised'));
            } else if($scope.selectedProperty.name.maintenance_request_additional_questions_enabled === 1 && $scope.mr.investigated == "0" && $scope.mr.why_uninvestigated.length === 0) {
                toastr.error($rootScope.trans('maint_req.submit_why_uninvestigated'));
            } else if($scope.selectedProperty.name.maintenance_request_additional_questions_enabled === 1 && $scope.mr.pm_advised == "0" && $scope.mr.why_pm_not_advised.length === 0) {
                toastr.error($rootScope.trans('maint_req.submit_why_pm_not_advised'));
            } else {
                $scope.creatingMR = 1;
                errors = 0;
            }

            $scope.mr.additional_questions.forEach(function(question){
                if(question.is_required && !question.response && question.response_type !== "Checkbox"){
                    toastr.error('The question "'+question.question+'" is required');
                    errors = 1;
                }
                if(!question.response && question.response_type == "Checkbox"){
                    question.response = false;
                }
            })

            if (errors == 1) {
                $scope.creatingMR = 0;
                return;
            }

            $scope.mr.property_id = $scope.selectedProperty.name.id;
            if ($rootScope.authUser.permissions !== 'tenant') {
                $scope.mr.property_unit_id = $scope.selectedUnit.unit ? $scope.selectedUnit.unit.id : null;
            }else{
                $scope.mr.property_unit_id = $rootScope.authUser.property_unit_id;
            }

            $http.post('/api/v1/maintenance-requests/create', $scope.mr).success(function (data) {
                if (!data.success) {
                    $scope.creatingMR = 0;
                    toastr.error(data.errors);
                    return;
                }
                $scope.creatingMR = 0;
                toastr.success($scope.t('req_created'));
                $scope.new_mr_id = data.data.id;
                $scope.createSendDocuments();
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                if (typeof data.errors != 'undefined') { //likely validation errors
                    angular.forEach(data.errors, function(error, key){
                        toastr.error(error);
                    });
                }else if(typeof data.error.message != undefined){
                    toastr.error(data.error.message);
                }

                $scope.creatingMR = 0;
                return;
            });
        };


        $scope.createSendDocuments = function () {
            var finishedFiles = 0;
            if(($rootScope.platform == 'web' && (!document.getElementById('file1') || document.getElementById('file1').files.length == 0)) || ((($rootScope.platform == 'android' || $rootScope.platform == 'ios') && $scope.photos.length === 0))) {
                finishedFiles++;
            } else {
                var f1 = null;

                if($rootScope.platform == 'android' || $rootScope.platform == 'ios') {
                    f1 = $scope.photos[0] ? $scope.photos[0]['value'] : null;
                } else {
                    f1 = document.getElementById('file1').files[0];
                }
                if(f1 !== null && f1 !== undefined) {
                    var r1 = new FileReader();

                    r1.readAsDataURL(f1);
                    r1.onloadend = function (e) {
                        $scope.mr.image1 = e.target.result;
                        $scope.mr.image1_name = f1.name;
                        finishedFiles++;
                        if (finishedFiles > 2) {
                            $state.go('main.maintenanceRequests');
                            $http.post('/api/v1/maintenance-requests/create-dosc-upload/' + $scope.new_mr_id, $scope.mr).success(function (data) {
                                if (!data.success) {
                                    data.errors.forEach(msg => toastr.error(msg));
                                }
                            }).error(function (data) {
                                if (typeof data.stopaction != 'undefined') {
                                    return;
                                }

                                toastr.error(data.error.message);
                            });
                        }
                    };
                }
            }

            if(($rootScope.platform == 'web' && (!document.getElementById('file2') || document.getElementById('file2').files.length == 0)) || ((($rootScope.platform == 'android' || $rootScope.platform == 'ios') && $scope.photos.length <= 1))) {
                finishedFiles++;
            } else {
                var f2 = null;

                if($rootScope.platform == 'android' || $rootScope.platform == 'ios') {
                    // f2 = $scope.photos[2].type == 'photo' ? $scope.photos[2].value : document.getElementById('file2').files[0];
                    f2 = $scope.photos[1] ? $scope.photos[1]['value'] : null;
                } else {
                    f2 = document.getElementById('file2').files[0];
                }
                if(f2 !== null && f2 !== undefined) {

                    var r2 = new FileReader();
                    r2.readAsDataURL(f2);
                    r2.onloadend = function (e) {
                        $scope.mr.image2 = e.target.result;
                        $scope.mr.image2_name = f2.name;
                        finishedFiles++;
                        if (finishedFiles > 2) {
                            $state.go('main.maintenanceRequests');
                            $http.post('/api/v1/maintenance-requests/create-dosc-upload/' + $scope.new_mr_id, $scope.mr).success(function (data) {
                                if (!data.success) {
                                    toastr.error(data.errors);
                                }
                            }).error(function (data) {
                                if (typeof data.stopaction != 'undefined') {
                                    return;
                                }

                                toastr.error(data.error.message);
                            });
                        }
                    };
                }
            }


            if(($rootScope.platform == 'web' && (!document.getElementById('file3') || document.getElementById('file3').files.length == 0)) || ((($rootScope.platform == 'android' || $rootScope.platform == 'ios') && $scope.photos.length <= 2))) {
                finishedFiles++;
            } else {
                var f3 = null;

                if($rootScope.platform == 'android' || $rootScope.platform == 'ios') {
                    // f3 = $scope.photos[3].type == 'photo' ? $scope.photos[3].value : document.getElementById('file3').files[0];
                    f3 =  $scope.photos[2] ? $scope.photos[2].value : null;
                } else {
                    f3 = document.getElementById('file3').files[0];
                }
                if(f3 !== null && f3 !== undefined) {
                    var r3 = new FileReader();

                    r3.readAsDataURL(f3);
                    r3.onloadend = function (e) {
                        $scope.mr.image3 = e.target.result;
                        $scope.mr.image3_name = f3.name;
                        finishedFiles++;
                        if (finishedFiles > 2) {
                            $state.go('main.maintenanceRequests');
                            $http.post('/api/v1/maintenance-requests/create-dosc-upload/' + $scope.new_mr_id, $scope.mr).success(function (data) {
                                if (!data.success) {
                                    toastr.error(data.errors);
                                }
                            }).error(function (data) {
                                if (typeof data.stopaction != 'undefined') {
                                    return;
                                }

                                toastr.error(data.error.message);
                            });
                        }
                    };
                }
            }

            if (finishedFiles > 2) {
                $state.go('main.maintenanceRequests');
                $http.post('/api/v1/maintenance-requests/create-dosc-upload/' + $scope.new_mr_id, $scope.mr).success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors);
                    }
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                });
            }
            $state.go('main.maintenanceRequests');
        };

        $scope.getTimes = function () {
            $http.post('/api/v1/maintenance-requests/get-times-for-dropdowns').success(function (data) {
                $scope.times = data.data;
            });
        };
        $scope.getTimes();

        $scope.dismissTermsAgreement = () => {
            $('#mr-terms').modal('hide').on('hidden.bs.modal', function (e) {
                $state.go('main.maintenanceRequests');
            })
        }

        if ($rootScope.authUser.permissions === 'tenant') {
            if ($rootScope.authUser.property.maintenance_request_custom_message_display_option === 'popup') {
                toastr.warning($rootScope.authUser.property.maintenance_request_emergency_message);
            }
            if ($rootScope.authUser.property.maintenance_request_custom_agree_terms_message_enabled){
                $('#mr-terms').modal({backdrop: 'static', keyboard: false})  ;
            }
        }
    }
}());
