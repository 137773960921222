(function () {

    'use strict';

    angular
        .module('app')
        .factory('surveyService', surveyService);

    function surveyService($http, helperService) {

        const base_url = '/api/v1/survey';

        return {
            create: survey => $http.post(base_url, JSON.stringify(survey)),
            get: surveyId => $http.get(`${base_url}/${surveyId}`).then(response => response.data),
            getProperties: () => $http.get(`${base_url}/properties-for-survey`),
            publish: surveyId => $http.post(`${base_url}/publish?id=${surveyId}`),
            unpublish: surveyId => $http.post(`${base_url}/unpublish?id=${surveyId}`),
            publishAndSend: surveyId => $http.post(`${base_url}/publish-send?id=${surveyId}`),
            edit: survey => $http.patch(`${base_url}/${survey.id}`, JSON.stringify(survey)),
            remove: id => $http.delete(`${base_url}/${id}`),
            fill: (surveyId, answers) => $http.post(`${base_url}/fill/${surveyId}`, JSON.stringify(answers)),
            getEvents: () => $http.get(`${base_url}/get-events`).then(response => response.data.data),
            getIndividuals: (surveyId, properties = null, fromDate = null, toDate = null) => {
                console.log('properties', properties)

                const url = new URL(
                    !!window.Capacitor.isNativePlatform() ?  pqDomain : (`${window.location.protocol}//${window.location.host}/response/${surveyId}`)
                );

                const params = url.searchParams;
                Object.entries(properties||{})
                    .forEach(([k, v]) => params.set(`filter[${k}]`, v));
                if (fromDate) {
                    params.set('fromDate', fromDate);
                }
                if (toDate) {
                    params.set('toDate', toDate);
                }

                return $http.post(`${base_url}/tenant-response/${surveyId}`,
                    Object.fromEntries(url.searchParams.entries()),
                    {
                        headers: {
                            'Content-Type':
                                'application/x-www-form-urlencoded; charset=UTF-8'
                        },
                        transformRequest: params =>
                            Object.entries(params).map(([k,v]) =>
                                encodeURIComponent(k) + "="
                                + encodeURIComponent(v)
                            ).join('&')
                    }).then(response => response.data);
            },
            getForTenant: (surveyId, mr_id = null, slug = null ) => {
                let url = `${base_url}/tenant/${surveyId}`;
                if(mr_id){
                    url += `?mr_id=${mr_id}`;
                }
                if(slug){
                    url += `?slug=${slug}`;
                }
                return $http.get(url).then(response => {
                    if (typeof response.data.success === 'undefined') {
                        response.data['success'] = true;
                    }
                    return response.data;
                }, data => {
                    return data;
                });
            },
            getWithAnswers: (surveyId, properties = null, fromDate = null, toDate = null) => {
                console.log('properties', properties)
                const url = new URL(
                    !!window.Capacitor.isNativePlatform() ?  pqDomain : (`${window.location.protocol}//${window.location.host}/response/${surveyId}`)
                );

                const params = url.searchParams;
                Object.entries(properties||{})
                    .forEach(([k, v]) => params.set(`filter[${k}]`, v));
                if (fromDate) {
                    params.set('fromDate', fromDate);
                }
                if (toDate) {
                    params.set('toDate', toDate);
                }

                return $http.post(`${base_url}/response/${surveyId}`,
                    Object.fromEntries(url.searchParams.entries()),
                {
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    transformRequest: params =>
                        Object.entries(params).map(([k,v]) =>
                            encodeURIComponent(k) + "="
                            + encodeURIComponent(v)
                        ).join('&')
                }
                ).then(response => {
                    var is_public = response.data.is_public;
                    const answers = response.data.questions.map(item => {
                        const question = {
                            id: item.id,
                            question: item.question,
                            type: item.type,
                            count: 0,
                            answers: []
                        };
                        if (item.type !== 'Free text' && item.type !== 'Cost Adder') {
                            let count = 0;
                            const answers = [];
                            item.variants.forEach(variant => {
                                const answer = {answer: variant.variant, count: 0};
                                variant.tenants.forEach(tenant => { //tenants = repsondents (not asociated with tenant in public survey)
                                    count++;
                                    answer.count++;
                                });
                                answers.push(answer);
                            });
                            answers.forEach(item => {
                                item['percentage'] = (Math.round(item.count / (count / 100) * 100) / 100);
                                item['percentage'] = isNaN(item['percentage']) ? 0 : item['percentage'];
                            });
                            question.count = count;
                            question.answers = answers;
                        } else {
                            question.count = item.freetext.length; //freetext = VariantTenants
                            question.answers = item.freetext.map(answer => {
                                return {
                                    id: answer.id,
                                    name: is_public ? answer.respondent.name : `${answer.tenant.first_name} ${answer.tenant.last_name}`,
                                    property: is_public ? null : answer.tenant.property.name,
                                    unit: is_public ? null : answer.tenant.property_unit.unit,
                                    answer: answer.variant,
                                    date: helperService.mysqlDatetimeToDate(answer.updated_at)
                                };
                            });
                        }

                        return question;
                    });

                    return {
                        name: response.data.name,
                        answers,
                        id: response.data.id,
                        is_public: is_public
                    };
                });
            },
        };

    }

}());
