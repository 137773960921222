(function() {

    'use strict';

    angular
        .module('app')
        .factory('messageService', messageService);

    function messageService($http) {

        var base_url = '/api/v1/';
        var local_url = 'messages';

        return {
            getAllInboxMessages: getAllInboxMessages,
            getAllSentMessages: getAllSentMessages,
            sendAMessage: create,
            saveMessage: save,
            setMessagesAsRead: setMessagesAsRead,
			setMessagesAsDeleted: setMessagesAsDeleted,
            setMessagesAsArchived: setMessagesAsArchived,
            setMessagesAsUnarchived: setMessagesAsUnarchived,
            setAllAsRead: setAllAsRead,
            checkNewMessage: checkNewMessage,
            getFullMessage: getFullMessage,
        };

        function getAllInboxMessages(isProspect, recipientId, filter)
        {
            return $http.get(`${base_url}${local_url}/index-new?folder=inbox&is_prospect=${isProspect}&recipient=${recipientId}&filter=${filter}`);
        }

        function getAllSentMessages(isProspect, recipientId)
        {
            return $http.get(`${base_url}${local_url}/index-new?folder=sent&is_prospect=${isProspect}&recipient=${recipientId}`);
        }

        function create(data)
        {
            return $http({
                method: 'POST',
                url: base_url + local_url+"/create-new",
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

        function save(data)
        {
            return $http({
                method: 'PUT',
                url: base_url + local_url + "/scheduled/" + data.id,
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

        function setMessagesAsRead(data)
        {
            return $http({
                method: 'PUT',
                url: base_url + local_url+'/set-read',
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

		function setMessagesAsDeleted(data)
        {
            return $http({
                method: 'PUT',
                url: base_url + local_url+'/set-deleted',
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

        function setMessagesAsArchived(data)
        {
            return $http({
                method: 'PUT',
                url: base_url + local_url+'/set-archived',
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

        function setMessagesAsUnarchived(data)
        {
            return $http({
                method: 'PUT',
                url: base_url + local_url+'/set-unarchived',
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

        function setAllAsRead()
        {
            return $http({
                method: 'POST',
                url: base_url + local_url+'/set-all-read',
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
            });
        }

        function checkNewMessage(){
            return $http.get(base_url + local_url + '/check-new-messages?cordova=' + (!!window.Capacitor.isNativePlatform() ? '1':'0'));
        }

        function getFullMessage(id){
            return $http.get(base_url + local_url + '/view-message?id='+id);
        }

    }

}());
