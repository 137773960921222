(function() {

    'use strict';

    angular
        .module('app')
        .factory('companyChartService', companyChartService);

    function companyChartService($http, $q) {


        var base_url = 'api/v1';
        //var local_url = '/companies/63/companyCharts';

        return {
            getCharts: getCharts
        };

        /**
         *
         * @param permissions
         * @returns {HttpPromise}
         */
        function getCharts(id)
        {
            // console.log(permissions);
            // return $http.get(base_url + getUrl(id))
            //     .then(function (object) {
            //         console.log(object);
            //         return object.data;
            //     });
            var deferred = $q.defer();

            $http.get(base_url + getUrl(id)).success(function(object, status) {
                console.log(object);
                deferred.resolve(object.data);
            });
            return deferred.promise;
        }
        function getUrl(id){
            var local_url = '/companies/'+id+'/company-charts';
            return local_url;
        }
    }

}());