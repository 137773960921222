(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqMessageSideNav', pqMessageSideNav);

    angular
        .module('app')
        .controller('PqMessageSidenavController', PqMessageSidenavController)
        .run(['$rootScope', '$state', '$stateParams',
            function ($rootScope, $state, $stateParams) {
                $rootScope.$stateParams = $stateParams;
            }]);;

    function pqMessageSideNav() {
        return {
            restrict: 'E',
            templateUrl: '/scripts/modules/message/sidenav/message.sidenav.html',
            controller: 'PqMessageSidenavController',
            scope: {
                selectedId: '=',
            }
        };
    }


    function PqMessageSidenavController(  $scope, $rootScope, $window, $timeout, $http, langService, $stateParams, $state) {
        // console.log('PqMessageSidenavController', $rootScope.authUser, $rootScope.authUser.permissions);
        $scope.t = langService.t('messages');

        $scope.authUser = $rootScope.authUser;
        $scope.permissions = $rootScope.authUser.permissions;

        $scope.archiveView = $stateParams.filter === 'archived';

        $scope.dashboard_messages_enabled = false;
        $http.post('/api/v1/dashboard-message/is-enabled').success(function (data) {
            if (data.success) {
                $scope.dashboard_messages_enabled = true;
            }
        });

        $timeout(function(){
            $('.active').removeClass('active');
            $('.brandingUnderline').removeClass('brandingUnderline');
            $(`#${$scope.selectedId}`).addClass('active');
            $(`#${$scope.selectedId}`).addClass('brandingUnderline');
        },0)


        $scope.goToInbox = function(isArchived = null) {
            if ($stateParams.recipient){
                $state.go('main.messagesInboxRecipient', {
                    recipient: $stateParams.recipient
                });
            } else {
                $state.go('main.messagesInbox', {
                    filter: isArchived
                });
            }
        }

        $scope.goToSent = function() {
            if ($stateParams.recipient){
                $state.go('main.messagesSentRecipient', {
                    recipient: $stateParams.recipient
                });
            }else {
                $state.go('main.messagesSent');
            }
        }
    }

})();
