(function() {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/payment/method/';

        $stateProvider
            .state('main.paymentMethodCreate', {
                url: '/payments/methods/create/:doc_key?',
                views: {
                    "body@": {
                        templateUrl: url + 'create/payment-method.create.step-1.html',
                        controller: 'PaymentMethodCreateController'
                    }
                }
            })
            .state('main.paymentMethodCreate.step_2', {
                templateUrl: url + 'create/payment-method.create.step-2.html'

            })
            .state('main.paymentMethodCreate.step_3', {
                templateUrl: url + 'create/payment-method.create.step-3.html'
            })
            .state('main.paymentMethods', {
                url: '/payments/methods',
                views: {
                    "body@": {
                        templateUrl: url + 'index/payment-method.index.html',
                        controller: 'PaymentMethodIndexController'
                    }
                }
            })
            .state('main.paymentMethodEdit', {
                url: '/payments/methods/:paymentMethodId/edit',
                views: {
                    "body@": {
                        templateUrl: url + 'edit/payment-method.edit.html',
                        controller: 'PaymentMethodEditController'
                    }
                }
            });

    }

}());
