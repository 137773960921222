(function () {
    'use strict';

    angular
        .module('app')
        .controller('AmenityEditController', amenityEditController)
        .directive('noFloat', function () {
            return  {
                restrict: 'A',
                link: function (scope, elm, attrs, ctrl) {
                    elm.on('keydown', function (event) {
                      if ([110, 190].indexOf(event.which) > -1) {
                            // dot and numpad dot
                            event.preventDefault();
                            return false;
                        }
                        else{
                          return true;
                        }
                    });
                }
            }
        });

    function amenityEditController($scope, $http, $state, $stateParams, $rootScope, $timeout, langService, $window) {
        $scope.t = langService.t('amenities');

        $scope.intersecting_intervals_error = $rootScope.trans('amenities.Sorry, you specified intersecting intervals showings');
        $scope.quantities = [...Array(18).keys()].map(x => x + 1);
        $scope.booking_limit_intervals = {
            'Daily': $scope.t('Daily'),
            'Weekly': $scope.t('Weekly'),
            'Monthly': $scope.t('Monthly'),
        };
        $scope.times = [];
        $scope.first_terms = '';
        $scope.intervals = {};
        $scope.intervalsArray = [];
        $scope.show_opening_times = 0;
        $scope.bookings_active_original = 1;
        $scope.property;
        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform());
        $scope.amenity = {
            id: parseInt($stateParams.id),
            name: '',
            description: '',
            bookings_active: 1,
            booking_type: 'Hourly',
            booking_interval: '0.5 hour',
            approval: 'Manual',
            property_id: null,
            properties: [],
            quantity: 1,
            opening_time: '',
            closing_time: '',
            more_than_one: 'no',
            booking_tickets: 'no',
            tenant_limit: 'no',
            booking_limit: 0,
            booking_limit_type: 'Monthly',
            is_recurring: 0,
            how_assigned: 'random',
            room_names: [{name: ''}],
            terms: '',
            payment_enabled: 0,
            usage_fee_holds_enabled: 0,
            payment_optional: 0,
            usage_fee_amount: 0,
            reservation_fee_enabled: 0,
            reservation_fee_amount: 0,
            charged_per_booking: 1,
            is_license_plate: 0,
            terms_label: 'Agree to terms',
            has_image: 0,
            hours_advance_notice: 0,
            cooldown_interval_in_hours: 0,
        };

        $scope.dropdowns = {
            properties: []
        };

        $scope.cancel_bookings_date = '';
        $scope.cancel_bookings = 'no';

        $scope.processedUpdateAmenityRequestData = [];

        if (!$rootScope.authUser.user_permissions['manage-amenities']){
            $state.go('main.amenityBook');
        }

        $scope.showOpeningTimes = function(){
            $scope.show_opening_times = $scope.show_opening_times == 1 ? 0 : 1;
            $scope.forceRender();
        };

        $scope.setHourlyIntervals = () => {
            $scope.intervals = {};
            for (let i = 0.5; i <= 8; i += 0.5) {
                let h = i;
                let hStr = $scope.t('hour');
                let kStr = 'hour';
                if (i > 1) {
                    hStr = $scope.t('hours');
                    kStr = 'hours';
                }
                $scope.intervals[`${h} ${kStr}`] = `${h} ${hStr}`;
            }

            // transfer object to array for sorted display
            $scope.intervalsArray = Object.entries($scope.intervals).map(function(option) {
                return option[1];
            });
        };
        $scope.setHourlyIntervals();

        $scope.setDaysIntervals = () => {
            $scope.intervals = {};
            for (let i = 1; i <= 40; i++) {
                let dStr = $scope.t('day');
                let kStr = 'day';
                if (i > 1) {
                    dStr = $scope.t('days');
                    kStr = 'days';
                }
                $scope.intervals[`${i} ${kStr}`] = `${i} ${dStr}`;
            }

            // transfer object to array for sorted display
            $scope.intervalsArray = Object.entries($scope.intervals).map(function(option) {
                return option[1];
            });
        };

        $scope.bookingTypeChanged = () => {
            if ($scope.amenity.booking_type === 'Hourly') {
                $scope.setHourlyIntervals();
                $scope.amenity.booking_interval = '0.5 hour';
            } else {
                $scope.setDaysIntervals();
                $scope.amenity.booking_interval = '1 day';
                $scope.is_recurring = 0;
            }
        };

        $scope.setProperty = function() {
            if (typeof $scope.amenity.properties[0] == 'undefined') {
                return;
            }
            var property_id = $scope.amenity.properties[0];
            $scope.amenity.property_id = property_id;
            $.each($scope.dropdowns.properties, function (index, value) {
                if (value.id == property_id) {
                    $scope.property = value;
                }
            });
        }

        $scope.getProperties = function () {
            return $http.post('/api/v1/amenity/get-properties').success(function (data) {
                $scope.dropdowns.properties = data.data.properties;
                if (!$scope.amenity.id) {
                    $scope.amenity.property_id = $scope.dropdowns.properties[0].id;
                    $scope.amenity.properties = [$scope.dropdowns.properties[0].id];
                    $scope.property = $scope.dropdowns.properties[0];
                    $scope.getTerms();
                }

                $timeout(function () {
                    $('select#properties').multipleSelect({
                        filter: true,
                        width: '350px',
                    });
                });
            });
        };

        $scope.getTimes = function () {
            return $http.post('/api/v1/amenity/get-times-for-dropdowns').success(function (data) {
                $scope.times = data.data;
                if (!$scope.amenity.id) {
                    $scope.amenity.opening_time = '8:00 am';
                    $scope.amenity.closing_time = '8:00 pm';
                }
            });
        };

        $scope.getTerms = function () {
            if ($scope.first_terms == '' || $scope.first_terms == $scope.amenity.terms) {
                $http.post('/api/v1/amenity/get-starter-terms/' + $scope.amenity.property_id).success(function (data) {
                    $scope.first_terms = data.data;
                    $scope.amenity.terms = data.data;
                });
            }
        };

        $scope.getAmenity = function () {
            $http.post('/api/v1/amenity/get/' + $scope.amenity.id).success(function (data) {
                if (!data.success) {
                    $state.go('main.amenities');
                }

                const amenity = data.data.amenity;

                $scope.bookings_active_original = amenity.bookings_active;

                $scope.amenity = amenity;
                $scope.amenity.room_names = amenity.rooms;
                $scope.amenity.booking_tickets = amenity.booking_tickets ? 'yes' : 'no';
                $scope.amenity.tenant_limit = amenity.booking_limit ? 'yes' : 'no';
                $scope.amenity.has_image = amenity.image_url == '' ? 0 : 1;
                
                angular.forEach(amenity.properties, function(property, key){
                    $scope.amenity.properties.push(property.id);
                });

                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });

                $scope.setProperty();

                if (amenity.showings.length > 0) {
                    $scope.showings = amenity.showings;
                }

                if ($scope.amenity.booking_type === 'Hourly') {
                    $scope.setHourlyIntervals();
                    $scope.amenity.opening_time = amenity.opening_time;
                    $scope.amenity.closing_time = amenity.closing_time;
                } else {
                    $scope.setDaysIntervals();
                }
            });
        };

        $scope.quantityChange = function () {
            if ($scope.amenity.quantity < $scope.amenity.room_names.length) {
                $scope.amenity.room_names = $scope.amenity.room_names.slice(0, $scope.amenity.quantity);
            } else {
                for (var i = 0; i < $scope.amenity.quantity; i++) {
                    if (typeof $scope.amenity.room_names[i] == 'undefined') {
                        $scope.amenity.room_names[i] = {name: ''};
                    }
                }
            }
        };

        $scope.deleteAmenity = function () {
            if (!confirm('Are you sure you want to delete this amenity?')) {
                return; // they did not confirm
            }

            $http.post('/api/v1/amenity/delete/' + $scope.amenity.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, 'Error deleting amenity');
                    return;
                }

                $state.go('main.amenities');
                toastr.success('Amenity deleted');
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingAmenity = false;
                toastr.error(data.error.message);
            });
        };

        $scope.processedUpdateAmenity = function () {
            if ($scope.cancel_bookings === 'yes' && !$scope.cancel_bookings_date) {
                toastr.error('Enter date value');
            } else {
                $scope.processedUpdateAmenityRequest();
            }
        };

        $scope.processAmenity = () => {
            if ($scope.validation.isValid == false) {
                toastr.error($scope.validation.error);
                return;
            }

            if (!$scope.amenity.id && document.getElementById('file1').files.length == 0) {
                toastr.error('Please choose a file to upload');
                return;
            }
            //if ($scope.amenity.booking_type !== 'Hourly') {
                //$scope.amenity.hours_advance_notice = 0;
            //}

            $scope.creatingAmenity = true;

            var data = new FormData();
            if (document.getElementById('file1').files.length > 0) {
                data.append('document', document.getElementById('file1').files[0]);
            }

            $.each($scope.amenity, function (index, value) {
                if (index == 'properties') {
                    var properties = [];
                    $.each(value, function (pindex, property) {
                        properties[pindex] = property;
                    });
                    data.append(index, properties);
                } else if (index == 'room_names') {
                    var rooms = [];
                    $.each(value, function (rindex, room) {
                        rooms[rindex] = room.name;
                    });
                    data.append(index, rooms);
                } else {
                    data.append(index, value);
                }
            });

            data.append('showings', JSON.stringify($scope.showings));

            $scope.processedUpdateAmenityRequestData = data;
            console.log($scope.processedUpdateAmenityRequestData);
        }

        $scope.updateAmenity = function () {
            $scope.processAmenity();

            if ($scope.amenity.bookings_active == 0 && $scope.bookings_active_original != 0){
                $("#cancel-bookings-date").datepicker({
                    minDate: 0,
                    showOtherMonths: true,
                    showButtonPanel: true
                });
                $('#bookings-not-active').appendTo('body').modal('show');
            } else {
                $scope.processedUpdateAmenityRequest();
            }
        };

        $scope.processedUpdateAmenityRequest = function () {
            const url = $scope.amenity.id ? '/api/v1/amenity/update/' + $scope.amenity.id : '/api/v1/amenity/create';
            let data = $scope.processedUpdateAmenityRequestData;

            data.append('cancel_bookings_date', $scope.cancel_bookings_date);

            $('#bookings-not-active').modal('hide');

            $http.post(url, data, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                .success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error saving amenity');
                        return;
                    }

                    $scope.creatingAmenity = false;
                    $state.go('main.amenities');
                    toastr.success('Amenity saved');
                })
                .error(function (data) {
                    console.log('hele', data)
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                    console.log('hddele', data)
                    $scope.creatingAmenity = false;
                    toastr.error(data.error?.message ?? '', 'Error saving amenity');
                });
        };

        $http.post('/api/v1/amenity/is-enabled').success(function (data) {
            if (data.data.enabled == 0) {
                // not enabled for this user
                $state.go('main.dashboard', {redirect: true});
                return 1;
            }

            if ($rootScope.authUser.permissions === 'tenant') {
                $state.go('main.amenityBook');
                return 1;
            }


            $scope.getProperties().then(() => {
                if ($scope.amenity.id) {
                    $scope.getTimes().then(() => {
                        $scope.getAmenity();
                    });
                } else {
                    $scope.getTimes();
                }
            });
        });






        // Hours slider stuff
        $scope.stepsArray = [];
        for (let i = 0; i <= 48; i++) {
            const t = i > 25 ? i - 24 : i;
            let hour, minute;
            if (i % 2 === 0) {
                hour = t / 2;
                minute = '00';
            } else {
                hour = Math.floor(t / 2);
                minute = '30';
            }
            const ampm = i > 23 ? 'PM' : 'AM';
            if (hour == 0 && ampm == 'AM') {
                hour = 12;
            }
            if (i == 48) {
                hour = 11;
                minute = 59;
            }
            $scope.stepsArray.push(`${hour}:${minute} ${ampm}`);
        }

        $scope.blankShowings = [];
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        days.forEach(day => $scope.blankShowings.push({
            name: day,
            isEnabled: false,
            ranges: [{from: '12:00 AM', to: '11:59 PM'}]
        }));

        $scope.$watch('showings', newVal => {
            if (!newVal) {
                $scope.showings = angular.copy($scope.blankShowings);
            }
        });

        $scope.$watch('validation', newVal => {
            if (!newVal) {
                $scope.validation = {isValid: true, error: ''};
            }
        });

        $scope.addRange = index => {
            $scope.showings[index].ranges.push({from: '12:00 AM', to: '11:59 PM'});
        };

        $scope.removeRange = (showingIndex, rangeIndex) => {
            $scope.showings[showingIndex].ranges.splice(rangeIndex, 1);
        };

        $scope.forceRender = () => {
            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        };

        $scope.onChange = () => {
            const timeToNumber = time => {
                const part = time.split(':');
                const part2 = part[1].split(' ');
                let number = +part[0];
                if (part2[1] === 'PM' && number !== 12) {
                    number += 12;
                }
                number *= 2;
                if (part2[0] === '30') {
                    number += 1;
                }
                return number;
            };

            let invalidShowingDay = '';

            $scope.showings.filter(day => day.isEnabled).forEach(day => {
                day.ranges.forEach((range, index) => {
                    day.ranges.forEach((checkedRange, checkedIndex) => {
                        if (index !== checkedIndex) {
                            const wrongFrom = timeToNumber(range.from) >= timeToNumber(checkedRange.from) && timeToNumber(range.from) <= timeToNumber(checkedRange.to);
                            const wrongTo = timeToNumber(range.to) >= timeToNumber(checkedRange.from) && timeToNumber(range.to) <= timeToNumber(checkedRange.to);
                            if (wrongFrom || wrongTo) {
                                invalidShowingDay = day.name;
                            }
                        }
                    });
                });
            });

            if (invalidShowingDay.length) {
                $scope.validation.isValid = false;
                $scope.validation.error = `Sorry, you specified intersecting intervals showings. Day: ${invalidShowingDay}`;
            } else {
                $scope.validation.isValid = true;
            }
        };

        $scope.getSliderColor = () => '#008B95';
        $scope.getPointerColor = () => '#008B95';

        $scope.$watch('amenity.usage_fee_holds_enabled', usage_fee_holds_enabled => {
            if (usage_fee_holds_enabled == 0) {
                $scope.amenity.reservation_fee_enabled = 0;
                $scope.amenity.reservation_fee_amount = 0;
                $scope.amenity.reservation_fee_holds_enabled = 0;
            }
        })

        $scope.$watch('amenity.quantity', function (newValue, oldValue) {
            if (newValue < oldValue) {
                toastr.warning('Please cancel all bookings for the removed rooms. Otherwise, all removed rooms are moving to the first room available from the list.');
            }
        });

        $scope.isMobileView = function() {
            let mobileViewThreshold = 768; // Define your threshold here
            return $window.innerWidth < mobileViewThreshold;
        };

    }
}());
