(function() {

	'use strict';

    angular
		.module('app')
		.controller('PaymentScheduleIndexController', PaymentScheduleIndexController);

	function PaymentScheduleIndexController($state, $scope, paymentScheduleService, $rootScope, langService) {

	    $scope.t = langService.t('payments');

        paymentScheduleService.getAll()
            .success(function(data) {
                $scope.paymentSchedules = data.data;
            })
            .error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $log.log(data.error + ' ' + status);
            });

            $scope.goToSchedule = function(schedule_id){
                $state.go('main.paymentScheduleEdit', {paymentScheduleId: schedule_id});
                return;
            }
    }

}());
