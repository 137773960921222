(function () {

    'use strict';

    angular
        .module('app')
        .filter('titleCase', function () {
            return function (input) {
                input = input || '';
                return input.replace(/\w\S*/g, function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            };
        })
        .controller('PaymentReceiptController', PaymentReceiptController);

	function PaymentReceiptController($scope,$http,$rootScope, $stateParams, $state, $log, $sce, langService) {
	    $scope.t = langService.t('payment_history');
        $scope.is_doc_receipt = 0;
        $scope.t_confirm_payment = $scope.t('confirm_payment');

        $scope.getReceipt = function(){
            $http.get('api/v1/payments/receipt/'+$stateParams.id).success(function(data) {
                $scope.receipt = data.data;
                var name = $scope.receipt.tenant.name;
                var address = $scope.receipt.tenant.address;
                var unit = $scope.receipt.tenant.unit;
                if ($scope.receipt.status.toLowerCase().includes('declined')) {
                    if ($scope.receipt.payment_method == 'Direct Debit') {
                        if (unit == '') {
                            $scope.t_confirm_payment = $scope.t('confirm_payment_declined_debit_no_unit',{reason: $scope.receipt.declined_reason, name: name, address: address});
                        } else {
                            $scope.t_confirm_payment = $scope.t('confirm_payment_declined_debit',{reason: $scope.receipt.declined_reason, name: name, address: address, unit: unit});
                        }
                    } else {
                        if (unit == '') {
                            $scope.t_confirm_payment = $scope.t('confirm_payment_declined_card_no_unit',{reason: $scope.receipt.declined_reason, name: name, address: address});
                        } else {
                            $scope.t_confirm_payment = $scope.t('confirm_payment_declined_card',{reason: $scope.receipt.declined_reason, name: name, address: address, unit: unit});
                        }
                    }
                } else {
                    if (unit == '') {
                        $scope.t_confirm_payment = $scope.t('confirm_payment_no_unit',{name: name, address: address});
                    } else {
                        $scope.t_confirm_payment = $scope.t('confirm_payment',{name: name, address: address, unit: unit});
                    }
                }
                $scope.is_doc_receipt = $scope.receipt.is_doc_payment;
                if ($scope.receipt.is_quickpay == 1 && !$scope.receipt.linked_to_tenant) {
                    $scope.getProperties();
                }
            })
            .error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $log.log(data.error + ' ' + status);
                toastr.error('','Payment not found');
            });
        }
        $scope.getReceipt();

        $scope.print = function() {
    	   window.print();
    	};

        $scope.trustAsHtml = function(html_string) {
            return $sce.trustAsHtml(html_string);
        };

        $scope.goToDocs = function(){
            $state.go('main.documentsDownloads');
        };

        $scope.doDelete = function(){
            if(!confirm($rootScope.trans('payments.confirm_delete')))
                return;

            $scope.receipt.status = 'deleting';
            $http.post('api/v1/payments/delete/'+$stateParams.id)
                .success(function(data) {
                    if(!data.success){

                        $scope.receipt.status = 'pending';
                        toastr.error('',data.message);
                    }
                    else{
                        toastr.success('', $rootScope.trans('payments.payment_deleted'));
                        $scope.receipt.status = 'deleted'
                    }

                })
                .error(function(data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.receipt.status = 'pending';
                    toastr.error('','There was an error deleting this payment');
                });

        }

        $scope.quickpay = {
            tenant_id: 0
        }
        $scope.openLinkToTenant = function(){
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }

            $('#quickpay-modal').appendTo('body').modal('show');
        };

        $scope.hold = {
            action: 'none',
            complete_amount: 0
        };
        $scope.showHoldModal = function(){
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }

            $('#hold-modal').appendTo('body').modal('show');
            $scope.hold.action = 'none';
            $scope.hold.complete_amount = $scope.receipt.amount;
        };

        $scope.chooseCancelHold = function() {
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }

            //in case we want an inbetween step later
            $scope.cancelHold();
        };

        $scope.cancelHold = function(){
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }

            if (!confirm($scope.t('Are you sure you want to cancel this hold'))) {
                return;
            }

            $http.post('api/v1/payments/cancel-hold/' + $stateParams.id).success(function(data) {
                if(!data.success){
                    toastr.error($scope.t('Could not cancel hold'));
                    return;
                }

                $scope.getReceipt();
                toastr.success('Hold has been cancelled');
                $('#hold-modal').modal('hide');
            }).error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error($scope.t('Could not cancel hold'));
            });
        }

        $scope.completeHold = function(){
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }

            if(!$scope.hold.complete_amount){
                alert($scope.t('Please choose an amount for hold completion'));
                return;
            }

            if ($scope.hold.complete_amount > $scope.receipt.amount) {
                alert($scope.t('Amount can not be more than the original hold amount'));
                return;
            }

            if (!confirm($scope.t('Are you sure you want to complete this hold'))) {
                return;
            }

            $http.post('api/v1/payments/complete-hold/' + $stateParams.id, {amount: $scope.hold.complete_amount}).success(function(data) {
                if(!data.success){
                    toastr.error($scope.t('Could not complete hold'));
                    return;
                }

                $scope.getReceipt();
                $('#hold-modal').modal('hide');
                toastr.success($scope.t('Hold has been completed'));
            }).error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error($scope.t('Could not complete hold'));
            });
        }

        $scope.makeRefund = () => {
            if (!confirm($scope.t('Are you sure you want to make a refund?'))) {
                return;
            }

            $http.post('api/v1/payments/make-refund/' + $stateParams.id, {}).success(function (data) {
                if (!data.success) {
                    toastr.error($scope.t('Could not make a refund'));
                    return;
                }

                $scope.getReceipt();
                toastr.success($scope.t('Refund has been queued'));
            }).error(function (data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error($scope.t('Could not make a refund'));
            });
        }

        $scope.properties = [];
        $scope.units = [];
        $scope.tenantData = {paymentType: false, paymentTypeOther: 'cash'};

        $scope.getProperties = function () {
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }
            $http.post('/api/v1/properties/get-all-for-manager-payments').success(function (data) {
                if (!data.success) {
                    toastr.error('', data.errors);
                    return;
                }

                $scope.properties = data.data;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error getting properties 1');
            });
        };

        $scope.getPropertyUnits = function (property_id) {
            if ($rootScope.authUser.permissions == 'tenant') {
                return;
            }
            $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + property_id).success(function (data) {
                if (!data.success) {
                    toastr.error('', data.errors);
                    return;
                }

                angular.forEach(data.data, function (unit, key) {
                    if (!$scope.units.includes(unit.unit)) {
                        $scope.units.push(unit.unit);
                    }
                });
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('There was an error getting properties 2');
            });
        };

        $scope.$watch('selectedProperty', function () {
            if (typeof ($scope.selectedProperty) == 'object') {
                $scope.tenantData.unit = '';
                $scope.units = [];

                $scope.getPropertyUnits($scope.selectedProperty.id);
            }

        });

        $scope.handleReturnedTenant = function (data) {
            $scope.tenantData.tenant_id = data.data.tenant.id;
            $scope.tenantInfo = data.data;
        };

        $scope.show_tenants_list = 0;
        $scope.handleMultipleTenants = function (data) {
            toastr.info($scope.t('This unit has multiple tenants, please select one from the list'));
            // more than one tenant in the unit, lets display them for selection
            $scope.tenants = data.data.tenants;
            $scope.show_tenants_list = 1;
        };

        $scope.fetchTenantFromUnit = function () {
            if ($scope.selectedProperty === undefined) {
                toastr.error($scope.t('Please select a property'));
                return false;
            }
            if ($scope.tenantData.unit === undefined || $scope.tenantData.unit == '') {
                toastr.error($scope.t('Please select a unit'));
                return false;
            }
            $scope.tenantInfo = false;
            $scope.tenants = null;
            $scope.show_tenants_list = 0;
            var url = '/api/v1/tenants/find-by-unit';
            $http.post(url, {
                property_id: $scope.selectedProperty.id,
                unit_number: $scope.tenantData.unit
            }).success(function (data) {
                if (data.success) {
                    if (typeof data.data.tenants !== 'undefined') {
                        $scope.handleMultipleTenants(data);
                        return;
                    }

                    $scope.handleReturnedTenant(data);
                } else {
                    toastr.error(data.data.error);
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('Error finding tenant');
            });
        };

        $scope.fetchTenantFromUnitId = function (unit_id) {
            $scope.tenantInfo = false;
            var url = '/api/v1/tenants/find-by-unit-id/' + unit_id;
            $http.get(url).success(function (data) {
                if (data.success) {
                    $scope.handleReturnedTenant(data);
                } else
                    toastr.error(data.data.error);
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('Error finding tenant');
            });
        };

        $scope.redirectToCreateTenant = function() {
            console.log('$scope.receipt', $scope.receipt);
            $state.go("main.tenantCreate", {
                receipt: $scope.receipt //add email?
            });
        }

        $scope.completeLinkToTenant = function() {
            var url = '/api/v1/quick-pay/link/' + $scope.tenantInfo.tenant.id + '/' + $scope.receipt.id;
            $http.post(url).success(function (data) {
                if (data.success) {
                    toastr.info($scope.t('Transaction has been linked to tenant'));
                    $scope.getReceipt();
                    $('#quickpay-modal').modal('hide');
                } else {
                    toastr.error(data.data.error);
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error('Error finding tenant');
            });
        };
    }

}());
