(function() {
    'use strict';

    angular
        .module('app')
        .controller('MaintenanceRequestCalendarController', maintenanceRequestCalendarController);

    function maintenanceRequestCalendarController($scope,$http,$state,$rootScope, $stateParams, $timeout, langService) {
        $scope.t = langService.t('maint_req');
        $scope.calendar_initilized = 0;

        if (!$rootScope.authUser.maintenance_requests_pro) {
            $state.go('main.maintenanceRequests');
            return;
        }

        $scope.chosen_properties = [];
        $scope.hidden = [];
        $scope.colours = {};
        $scope.dropdownColours = [];
        $scope.calendar_requests = [];
        $scope.test = [];

        $scope.current_modal_duration = '';

        $scope.time_values = [
            { text: '15 '+ $scope.t('minutes'), value: 15},
            { text: '30 '+ $scope.t('minutes'), value: 30},
            { text: '45 '+ $scope.t('minutes'), value: 45},
            {text: '1 ' + $scope.t('hour'), value: 60},
            {text: '1 ' + $scope.t('hour') + ' 30' + $scope.t('minutes'), value: 90},
            {text: '2 ' + $scope.t('hours'), value: 120},
        ];

        $scope.getProperties = function() {
            $http.post('/api/v1/maintenance-requests/get-pro-properties').success(function(data) {
                $scope.properties = data.data.properties;

                if($scope.chosen_properties.length < 1) {
                    $scope.properties.forEach(function (el, index) {
                        $scope.chosen_properties.push(el.id);
                    })
                }

                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 500});
                });

                $scope.getRequests();
            });
        };


        $scope.getRequests = function(){

            $scope.managerInited = false;

            var colourPostData = {
                property_ids: $scope.chosen_properties
            };
            $http.post('/api/v1/maintenance-requests/get-calendar-colours', colourPostData).success(function(data) {

                $scope.colours = data.data;
                $scope.dropdownColours = [];

                for(let i in $scope.colours){
                    $scope.dropdownColours.push({
                        text: $scope.colours[i].first_name + ' ' +$scope.colours[i].last_name,
                        id: 'manager'+$scope.colours[i].manager_id
                    });
                }

                var multiselect = $('select#managers');

                $timeout(function () {
                    $('select#managers').multipleSelect({
                        filter: true,
                        styler: function (row) {
                                return 'text-align: left'
                        },
                        width: 500,
                        selectAll: false,
                        onClick: function (view) {
                            $scope.flipEventsFromLegend($scope.dropdownColours[+view.value].id, 0)
                        }
                    });

                    multiselect.multipleSelect('checkAll');
                });

                if ($scope.calendar_initilized) {
                    $scope.loadCalendarEvents();
                } else {
                    setTimeout(function(){
                        $scope.initCalendar();
                    }, 0);
                }
            })
        };

        $scope.doChange = function(colours){
            $scope.dropdownColours = colours;
        }

        $scope.doChangeProperty = function(props){
            $scope.chosen_properties = props;
            $scope.hidden = [];
            $scope.getRequests();
        };

     $scope.bookings_call_count = 0;
        $scope.loadCalendarEvents = function(){
            if (!$scope.current_calendar_view) {
                return;
            }
            $scope.bookings_call_count++;

            var view = $scope.current_calendar_view;

            var postdata = {
                property_ids: $scope.chosen_properties,
                hidden_requests: $scope.hidden,
                start: view.start.format('MM/DD/YYYY'),
                end: view.end.format('MM/DD/YYYY'),
                bookings_call_count: $scope.bookings_call_count
            };

            $http.post('/api/v1/maintenance-requests/calendar', postdata).success(function(data) {
                if (data.data.bookings_call_count != $scope.bookings_call_count) {
                    return; // subsiquent calls have been done, so we can ignroe this one
                }

                $('#calendar').fullCalendar('removeEvents');

                $scope.calendar_requests = data.data;
                $timeout(
                    () => {
                        $.each(data.data.maintenance_requests, function(index, request){
                            if($scope.colours['manager' + request.manager_id]){
                                var start_date = moment(request.assigned_start_time);
                                var end_date = moment(request.assigned_start_time).add('minutes', request.time_to_complete);
                                if (start_date.isValid()) {
                                    $('#calendar').fullCalendar('renderEvent', {
                                        title: request.subject + ' | ' +request.priority,
                                        start: start_date,
                                        end: end_date,
                                        color: $scope.colours['manager' + request.manager_id].colour,
                                        calendar_index: index,
                                        description: request.subject + ' | ' +request.priority,
                                        id: request.id,
                                        duration: request.time_to_complete,
                                    });
                                }
                            }
                        })
                    }
                );
            });
        };

        $scope.setCalendarRatio = () => {
            let ratio = 1;
            if (window.innerWidth > 600) {
                ratio = 2;
            }
            $('#calendar').fullCalendar('option', 'aspectRatio', ratio);
        };

        $scope.initCalendar = function(){
            $('.ui-draggable').draggable({
                revert: true,      // immediately snap back to original position
                revertDuration: 0,  //
                scroll: false,
                cursor: "move",
            });

            const calendarHeader = (( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ))
                ? {left: 'title' , center: 'month,agendaWeek,listWeek today prev,next', right: ''}
                : { center: 'month,agendaWeek,listWeek'};

            $('#calendar').fullCalendar({
                locale: $rootScope.localeButtonString,
                slotDuration: '00:15:00',
                noEventsMessage: $rootScope.fullcalendardefaults['noEventsMessage'][$rootScope.localeButtonString],
                buttonText: $rootScope.fullcalendardefaults['localeButtonText'][$rootScope.localeButtonString],
                header: calendarHeader,
                viewRender: function(view){
                    $scope.current_calendar_view = view;
                    $scope.loadCalendarEvents();
                },

                eventRender: function(event, element) {
                    var title = element.find('.fc-title');
                    title.attr("title", event.description);
                    title.css('cursor', 'pointer');
                },

                droppable: true,
                // //dragging changes inside of a calendar
                eventDrop: function(event, delta, revertFunc, jsEvent, ui, view) {

                    $scope.current_modal_date = event.start.format();
                    $('#time-modal').appendTo('body').modal('show');

                    $scope.$applyAsync (function () {
                        $scope.current_modal_duration = event.duration
                    })
                    var func = function () {
                        $('#start_datetimepicker').daterangepicker({
                                "startDate" : $scope.current_modal_date,
                                "singleDatePicker": true,
                                "timePicker": true,
                                "autoApply" : true,
                                "timePickerIncrement": 15,
                                locale: {
                                    format: 'YYYY-MM-DD hh:mm A'
                                }
                            },
                            function(start, end, label) {
                                $scope.current_modal_date = start.format('YYYY-MM-DD HH:mm');
                            }
                        );

                        var deferred = new $.Deferred();
                        $('#buttons').on("click", "#applyButton", deferred.resolve).on("click", "#cancelButton", deferred.reject);

                        return deferred.promise();
                    }

                    var promise = func();
                    promise.done(function(){
                        var postData = {
                            assigned_start_time: $scope.current_modal_date,
                            time_to_complete: $scope.current_modal_duration
                        }
                        $http.post('/api/v1/maintenance-requests/update-time/'+event.id, postData).success(function(data) {
                            if(!data.success) {
                                toastr.error('An error has occurred!');
                            }
                            toastr.success($scope.t('Maintenance request updated'));
                            $scope.getRequests();
                        });
                        $('#time-modal').modal('hide');
                    })
                    promise.fail(function () {
                        revertFunc();
                        $('#time-modal').modal('hide');
                    })

                },
                eventResize: function(event, delta, revertFunc, jsEvent, ui, view){
                    var postData = {
                        assigned_start_time: event.start.format(),
                        time_to_complete: event.duration + delta.asMinutes()
                    }
                    $http.post('/api/v1/maintenance-requests/update-time/'+event.id, postData).success(function(data) {
                        if(!data.success) {
                            toastr.error('An error has occurred!');
                        }
                        toastr.success($scope.t('Maintenance request updated'));
                    });
                },
                editable: true,
                // eventReceive: function(data) {
                // },
                //
                // //drops from the list
                drop: function(date) {
                    alert("Dropped on " + date.format());
                },
                eventClick: function(calEvent, jsEvent, view) {
                    if(calEvent.id > 0) {
                        $state.go('main.maintenanceRequestEdit', {id: calEvent.id});
                    }
                },
                windowResize: $scope.setCalendarRatio
            });
            $scope.setCalendarRatio();
            $scope.calendar_initilized = 1;
        };

        $scope.coloursActive = true;
        $scope.flipEventsFromLegend = function(index, legend, without_reload = false){

            var key = $scope.hidden.indexOf($scope.colours[index].manager_id);
            if (key >= 0) {
                $scope.hidden.splice(key, 1);
                $scope.colours[index].hidden = 0;
            } else {
                $scope.hidden.push($scope.colours[index].manager_id);
                $scope.colours[index].hidden = 1;
            }
            $scope.coloursActive = Object.values($scope.colours).some(e => e.hidden != 1);

            if(legend > 0){
                const selected = [];
                Object.keys($scope.colours).forEach((key, index) => {
                    if(!$scope.colours[key].hidden){
                        selected.push(index);
                    }
                });
                $('select#managers').multipleSelect('setSelects', selected);
            }

            if(!without_reload) {
                $scope.loadCalendarEvents();
            }
        }

        $scope.calendarBookingDetails = function(index){
            $scope.m.booking = $scope.calendar_requests[index];
            $scope.showBookingDetails();
        }

        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.maintenanceRequests');
            return 1;
        }

        $scope.changeModalDropdown = function(time){
            $scope.current_modal_duration = time
        }

        $timeout(function () {
            $scope.getProperties();
        })

        $scope.showCalendarFilterModal = type => {
            if (type === 'managers') {
                $('#filterManagersCalendarView').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'properties') {
                $('#filterPropertiesCalendarView').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
        }
    }
}());
