(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqBackButton', pqBackButton);

    function pqBackButton($state)
    {
        
        return {
            restrict: 'E',
            scope: {
                loc: '=',
                style: '='
            },
            templateUrl: 'scripts/components/back-button/back-button.html',
            link: function($scope) {

                $scope.goBack = function() {
                    console.log('goBack');
                    console.log($scope.loc);
                    
                    if($scope.loc) {
                        $state.go($scope.loc)
                    } else {
                        window.history.back();
                    }
                }
            }
        }
    }

})();