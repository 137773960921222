(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        const url = 'scripts/modules/portfolio/';

        $stateProvider
            .state('main.portfolioCreate', {
                url: '/companies/:companyId/portfolio/edit/:portfolioId',
                views: {
                    'body@': {
                        templateUrl: url + 'edit/portfolio.edit.html',
                        controller: 'PortfolioEditController',
                        resolve: {
                            resolvedCompany: function ($q, companyService, $stateParams) {
                                return $q.when(companyService.getCompanyById($stateParams.companyId).then(r => r.data));
                            },
                            resolvedPortfolio: function ($q, portfolioService, $stateParams) {
                                if (+$stateParams.portfolioId === 0) {
                                    return portfolioService.getBlank($stateParams.companyId);
                                } else {
                                    return $q.when(portfolioService.get($stateParams.portfolioId));
                                }
                            },
                            resolvedProperties: function ($q, propertyService) {
                                return $q.when(propertyService.getAll().then(r => r.data.data));
                            }
                        }
                    }
                }
            });
    }

}());
