(function() {
    
    'use strict';

	angular
		.module('app.routes')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/rewards/views/';

		$stateProvider
			.state('main.rewards', {
				url: '/rewards',
				views: {
					"body@": {
						templateUrl: url + 'rewards.index.html',
						controller: 'RewardsController'
					}
				}
			}).state('main.perks', {
				url: '/property-perks',
				views: {
					"body@": {
						templateUrl: url + 'rewards.manage.html',
						controller: 'PerksController'
					}
				}
			});

	}

}());
