(function() {
    'use strict';

    angular
    .module('app')
    .controller('ProfileRegisterController', profileRegisterController);

    function profileRegisterController($scope,$http,$state,$rootScope,$timeout,$stateParams,authenticationService, brandingService, langService) {

        $scope.t = langService.t('registration');

        $scope.authData = {
            /*
            first_name: "a",
            last_name: "b",
            phone_number: "1231231231",
            email: "tenant@payquad.com" + Math.floor((Math.random() * 100) + 1),
            password:"asdasd",
            password_confirmation: "asdasd",
            */
            property_id: null,
            unit: null,
            agree_terms: false,
            agree_privacy: false,
			security_code: ''
        };

		$scope.authenticationService = authenticationService;

        $scope.emailPattern = (function() {
            var regexp = /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/;
            return {
                test: function(value) {
                    if( $scope.requireTel === false ) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();

        $scope.phoneNumberPattern = (function() {
            var regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
            return {
                test: function(value) {
                    if( $scope.requireTel === false ) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();

        $scope.checkRegistration = function() {
            $scope.clientErrors = {
                errors: []
            };
            $scope.errors = '';

            // validating email
            var input = {
                email: $scope.authData.email
            };
            authenticationService.validateEmail(input).success(function(data) {
                if(data.success === false) {
                    $scope.errors = data.errors;
                    return;
                } else if($scope.authData.password != $scope.authData.password_confirmation) {
                    $scope.clientErrors.errors.push('your passwords do not match');
                    return;
                } else if($scope.authData.agree_terms === false) {
                    $scope.clientErrors.errors.push($scope.lang.must_agree);
                    return;
                }

                $scope.register();
            });

        };

        $scope.register = function() {
            var input = {
                email: $scope.authData.email,
                password: $scope.authData.password,
                password_confirmation: $scope.authData.password_confirmation,
                first_name: $scope.authData.first_name,
                last_name: $scope.authData.last_name,
                phone_number: $scope.authData.phone_number,
                company: $scope.authData.company,
                is_profile_registration: 1
            };
            authenticationService.register(input).success(function(data) {
                if (data.success === true) {
                    $scope.login();
                } else if(data.success === false) {
                    $scope.errors = data.errors;
                }
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                if (typeof data.errors != 'undefined') {
                    $scope.errors = data.errors[Object.keys(data.errors)[0]];
                }
            });
        };

        $scope.login = function() {
            var authData = {
                property: 1,
                email: $scope.authData.email,
                password: $scope.authData.password,
                remember_me: 0,
                agree_terms: true,
                phone_number: '',
                last_name:'',
                first_name: ''
            };

            authenticationService.login(authData).success(function(data) {
                if (data.success === false) {
                    $scope.submitting = false;
                    $state.go('main.documents');
                    return;
                }

                $rootScope.authUser = data.data;
                $rootScope.$broadcast('check-new-messages');
                brandingService.get().then(function (data) {
                    $rootScope.branding = data.data;
                    $scope.submitting = false;
                    $state.go('main.documents');
                });
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };
    }
}());
