(function() {

    'use strict';

    angular
        .module('app')
        .factory('tenantData', tenantData);

    function tenantData($q, tenantService, propertyDataService)
    {
        return {
            load: load,
            loadProperties: loadProperties
        };

        function loadProperties(tenantId) {
            const properties = propertyDataService.getProperties();
            return $q.all([properties]).then(
                function (results) {
                    return {
                        properties: results[0]
                    }
                });

        }

        function load(tenantId) {

            var manageTenants = tenantService.getTenantManage();
            var approvalTenants = tenantService.getTenantApprovals();
            var activityTenants = tenantService.getTenantActivities();

            if(tenantId != null) {
                var tenant = tenantService.getTenantById(tenantId);
            }

            return $q.all([manageTenants, approvalTenants, activityTenants, tenant]).then(
                function (results) {
                    return {
                        manageTenants: results[0],
                        approvalTenants: results[1],
                        activityTenants: results[2],
                        tenant: results[3]
                    }
                });

        }
    }
})();