(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqJuniorPermissions', pqJuniorPermissions);

    function pqJuniorPermissions() {
        return {
            restrict: 'E',
            controller: 'PqJuniorPermissionsController',
            templateUrl: 'scripts/modules/manager/edit/junior-permissions/junior-permissions.html',
            scope: {
                juniorPermissions: '=',
                globalPermissionsStatus: '=',
                userId: '=',
                companyId: '='
            }
        };
    }

    angular
        .module('app')
        .controller('PqJuniorPermissionsController', function ($scope, managerService, $timeout, $rootScope, $http) {

            $scope.ticketing_enabled = false;
            $http.get('/api/v1/companies/' + $scope.companyId).success(data => {
                $scope.ticketing_enabled = data.data.properties.some(el => el.ticketing_enabled == 1);
                $scope.showCommunicationDropdown();
            });

            $scope.permissions = [];
            managerService.getJuniorPermissions()
                .then(data => {
                    $scope.permissions = data;
                    $scope.permissions.forEach(item => {
                        item.isChecked = $scope.isChecked(item.id);
                        item.isVisible = $scope.isVisible(item.name);
                        item.friendly_name = item.friendly_name ? item.friendly_name : item.name;
                    });
                    console.log('permissions: ', $scope.permissions);
                    $scope.showCommunicationDropdown();
                    $scope.showAmenitiesDropdown();
                    $scope.showPaymentsDropdown();
                    $scope.showReportsDropdown();
                    $scope.showInspectionsDropdown();
                    $scope.showUtilitiesDropdown();
                 });

            $scope.isChecked = id => {
                return !!$scope.juniorPermissions.find(item => +item.permission_id === +id);
            };

            $scope.isVisible = name => {
                if (name == 'payments') {
                    return $scope.globalPermissionsStatus['payments'] == 1 ? true : false;
                }
                if (name == 'surveys') {
                    return $scope.globalPermissionsStatus['surveys'] == 1 ? true : false;
                }
                if (name == 'parcels') {
                    return $scope.globalPermissionsStatus['parcels'] == 1 ? true : false;
                }
                if (name == 'maintenance-requests') {
                    return $scope.globalPermissionsStatus['maintenance-requests'] == 1 ? true : false;
                }
                if (name == 'documents') {
                    return $scope.globalPermissionsStatus['documents'] == 1 ? true : false;
                }
                if (name == 'amenities') {
                    return $scope.globalPermissionsStatus['amenities'] == 1 ? true : false;
                }
                if (name == 'lease-applications') {
                    return $scope.globalPermissionsStatus['lease-applications'] == 1 ? true : false;
                }
                if (name == 'social-events') {
                    return $scope.globalPermissionsStatus['social-events'] == 1 ? true : false;
                }
                return true;
            };

            $scope.changed = permission => {
                if (permission.name === 'communications' && permission.isChecked) {
                    $scope.showCommunicationDropdown();
                }
                if (permission.name === 'communications' && !permission.isChecked) {
                    let communicationPermissionsIds = [];
                    $scope.permissions.map(el => {
                        if (el.group === 'communication') {
                            el.isChecked = false;
                            communicationPermissionsIds.push(el.id);
                        }
                    });
                    $scope.juniorPermissions = $scope.juniorPermissions.filter(item => !communicationPermissionsIds.includes(item.permission_id));
                }
                if (permission.name === 'amenities' && permission.isChecked) {
                    $scope.showAmenitiesDropdown();
                }
                if (permission.name === 'amenities' && !permission.isChecked) {
                    let amenitiesPermissionsIds = {};
                    $scope.permissions.map(el => {
                        if (el.name === 'create-bookings'){
                            el.isChecked = false;
                            amenitiesPermissionsIds.create = el.id;
                        }
                        if (el.name === 'manage-amenities'){
                            el.isChecked = false;
                            amenitiesPermissionsIds.edit = el.id;
                        }
                    });
                    $scope.juniorPermissions = $scope.juniorPermissions.filter(item => item.permission_id != amenitiesPermissionsIds.create && item.permission_id != amenitiesPermissionsIds.edit);
                }
                if (permission.name === 'inspections' && permission.isChecked) {
                    $scope.showInspectionsDropdown();
                }
                if (permission.name === 'payments' && permission.isChecked) {
                    $scope.showPaymentsDropdown();
                }
                if (permission.name === 'payments' && !permission.isChecked) {
                    let paymentsPermissionsIds = [];
                    $scope.permissions.map(el => {
                        if (el.group === 'payments'){
                            el.isChecked = false;
                            paymentsPermissionsIds.push(el.id);
                        }
                    });
                    $scope.juniorPermissions = $scope.juniorPermissions.filter(item => !paymentsPermissionsIds.includes(item.permission_id))
                }

                if (permission.name === 'reports' && permission.isChecked) {
                    $scope.showReportsDropdown();
                }

                if (permission.name === 'reports' && !permission.isChecked) {
                    let reportsPermissionsIds = [];
                    $scope.permissions.map(el => {
                        if (el.group === "reporting"){
                            el.isChecked = false;
                            reportsPermissionsIds.push(el.id)
                        }
                    });
                    $scope.juniorPermissions = $scope.juniorPermissions.filter(item => !reportsPermissionsIds.includes(item.permission_id));
                }

                if (permission.name === 'utilities' && permission.isChecked) {
                    $scope.showUtilitiesDropdown();
                }

                if (permission.name === 'utilities' && !permission.isChecked) {
                    let utilitiesPermissionsIds = [];
                    $scope.permissions.map(el => {
                        if (el.group === "utilities"){
                            el.isChecked = false;
                            utilitiesPermissionsIds.push(el.id)
                        }
                    });
                    $scope.juniorPermissions = $scope.juniorPermissions.filter(item => !utilitiesPermissionsIds.includes(item.permission_id));
                }

                if ($scope.isChecked(permission.id)) {
                    $scope.juniorPermissions = $scope.juniorPermissions.filter(item => +item.permission_id !== +permission.id);
                } else {
                    $scope.juniorPermissions.push({permission_id: permission.id, user_id: $scope.userId});
                }
            };

            $scope.showCommunicationDropdown = () => {
                $timeout(function () {
                    $('#communications-advanced-settings').multipleSelect({
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => {
                            e.checked
                                ? $scope.juniorPermissions = [...$scope.juniorPermissions, {permission_id: +e.value, user_id: $scope.userId}]
                                : $scope.juniorPermissions = $scope.juniorPermissions.filter(item => +item.permission_id !== +e.value);
                        }
                    })
                }, 500);
            }
            $scope.showAmenitiesDropdown = () => {
                $timeout(function () {
                    $('#amenities-advanced-settings').multipleSelect({
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => {
                             e.checked
                                ? $scope.juniorPermissions = [...$scope.juniorPermissions, {permission_id: +e.value, user_id: $scope.userId}]
                                : $scope.juniorPermissions = $scope.juniorPermissions.filter(item => +item.permission_id !== +e.value);
                            // console.log('$scope.juniorPermissions', $scope.juniorPermissions);
                        }
                    })
                }, 500);
            }
            $scope.showPaymentsDropdown = () => {
                $timeout(function () {
                    $('#payments-advanced-settings').multipleSelect({
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => {
                            e.checked
                                ? $scope.juniorPermissions = [...$scope.juniorPermissions, {permission_id: +e.value, user_id: $scope.userId}]
                                : $scope.juniorPermissions = $scope.juniorPermissions.filter(item => +item.permission_id !== +e.value);
                            // console.log('$scope.juniorPermissions', $scope.juniorPermissions);
                        }
                    })
                }, 500);
            }
            $scope.showInspectionsDropdown = () => {
                $timeout(function () {
                    $('#inspections-advanced-settings').multipleSelect({
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => {
                            e.checked
                                ? $scope.juniorPermissions = [...$scope.juniorPermissions, {permission_id: +e.value, user_id: $scope.userId}]
                                : $scope.juniorPermissions = $scope.juniorPermissions.filter(item => +item.permission_id !== +e.value);
                        }
                    })
                }, 500);
            }
            $scope.showReportsDropdown = () => {
                $timeout(function () {
                    $('#reporting-advanced-settings').multipleSelect({
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => {
                            e.checked
                                ? $scope.juniorPermissions = [...$scope.juniorPermissions, {permission_id: +e.value, user_id: $scope.userId}]
                                : $scope.juniorPermissions = $scope.juniorPermissions.filter(item => +item.permission_id !== +e.value);
                        }
                    })
                }, 500);
            }
            $scope.showUtilitiesDropdown = () => {
                $timeout(function () {
                    $('#utilities-advanced-settings').multipleSelect({
                        placeholder: 'Click Here To Set Up',
                        selectAll: false,
                        onClick: (e) => {
                            e.checked
                                ? $scope.juniorPermissions = [...$scope.juniorPermissions, {permission_id: +e.value, user_id: $scope.userId}]
                                : $scope.juniorPermissions = $scope.juniorPermissions.filter(item => +item.permission_id !== +e.value);
                        }
                    })
                }, 500);
            }
        });

})();
