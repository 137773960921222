(function() {

    'use strict';

    angular
        .module('app')
        .factory('paymentMethodService', paymentMethodService);

    function paymentMethodService($http) {

        var base_url = '/api/v1/';
        var local_url = 'payments/payment-methods';

        return {

            getAll : function()
            {
                return $http.get(base_url + local_url);
            },

            getById : function(id)
            {
                return $http.get(base_url + local_url + "/" + id);
            },

            create : function(data)
            {
                console.log("creating payment method",data);
                return $http({
                    method: 'POST',
                    url: '/api/v1/payments/payment-methods',
                    headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                    dataType: 'json',
                    data: $.param(data)
                });
            },

            getAvailable : function (){
                return $http.get(base_url + local_url + "/" + 'available');
            },

            update : function(id, data)
            {
                return $http.put(base_url + local_url + "/" + id, data);
            },

            delete : function(id)
            {
                return $http.delete(base_url + local_url + "/" + id);
            }

        }

    }

}());