(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationMessageController', leaseApplicationMessageController);

    function leaseApplicationMessageController($scope,$http,$state,$rootScope,$timeout,$stateParams,$interval, langService) {
        $scope.t = langService.t('lease_app');
        $scope.chat = {
            message:''
        };

        $scope.application = {
            id: $stateParams.id
        };

        $scope.sendMessage = function(){
            if ($scope.chat.message.length < 1) {
                toastr.error('Please enter a message');
            } else {
                var url =  '/api/v1/lease-application/add-message/' + $scope.application.id;
                if ($rootScope.authUser.permissions != 'applicant') {
                    url = '/api/v1/lease-application/manager-add-message/' + $scope.application.id;
                }
                $http.post(url, {'message':$scope.chat.message}).success(function(data) {
                    $scope.application.messages.push(data.data.message);
                    $scope.chat.message = '';
                });
            }
        };

        $scope.getApplication = function(){
            var url =  '/api/v1/lease-application/get/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get/' + $scope.application.id;
            }
            $http.post(url).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error loading application");
                    return;
                }

                $scope.application = data.data.application;

                if ($rootScope.authUser.permissions == 'applicant' && $scope.application.property.lease_application_messages_managers_only == 1) {
                    $state.go('main.leaseApplicationDashboard');
                    return;
                }
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };
        $scope.getApplication();

        $scope.checkForNewMessages = function() {
            var max_id = 0;

            for (var i in $scope.application.messages) {
                if ($scope.application.messages[i].id > max_id) {
                    max_id = $scope.application.messages[i].id;
                }
            }

            var url =  '/api/v1/lease-application/messages/' + $scope.application.id + '/after/' + max_id;
            if ($rootScope.authUser.permissions != 'applicant') {
                var url =  '/api/v1/lease-application/manager-messages/' + $scope.application.id + '/after/' + max_id;
            }
            $http.post(url).success(function(data) {
                for (var i in data.data) {
                    $scope.application.messages.push(data.data[i]);
                }
            });
        }

        var promise = $interval($scope.checkForNewMessages, 10000);
        $scope.$on('$destroy',function(){
            if (promise) {
                $interval.cancel(promise);
            }
        });
    }
}());
