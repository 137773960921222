(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqPolicies', pqPolicies);

    function pqPolicies()
    {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/policies/policies.html'
        }
    }

})();