(function () {

    'use strict';

    angular
        .module('app')
        .factory('portfolioService', portfolioService);

    function portfolioService($http) {

        const base_url = '/api/v1/portfolio';

        return {
            get: id => $http.get(`${base_url}/${id}`).then(r => r.data),
            create: portfolio => $http.post(`${base_url}`, JSON.stringify(portfolio)),
            update: portfolio => $http.patch(`${base_url}/${portfolio.id}`, JSON.stringify(portfolio)),
            getBlank: companyId => {
                return {
                    id: 0,
                    companyId,
                    name: '',
                    description: '',
                    properties: []
                };
            }
        };

    }

}());
