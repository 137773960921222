(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqCommentPopup', pqCommentPopup);

    angular
        .module('app')
        .controller('PqCommentPopupController', pqCommentPopupController);

    function pqCommentPopup() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/comment-popup/comment-popup.html',
            controller: 'PqCommentPopupController',
            scope: {
                ref: '=',
            }
        };
    }


    function pqCommentPopupController($scope, $timeout) {

        $scope.ref.open = (item) => {
            $scope.item = item;
            if (!$scope.item.comment) {
                $scope.item.comment = '';
            }
            $scope.editedComment = $scope.item.comment;
            $('#pq-comment-modal').appendTo('body').modal('show');
            $timeout(() => {
                $('.modal-backdrop').hide();
            }, 0);
        };

        $scope.close = () => {
            $('#pq-comment-modal').modal('hide');
        };

        $scope.save = () => {
            $scope.item.comment = $scope.editedComment;
            $scope.close();
        };

    }

})();
