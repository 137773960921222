(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageGroupingEditController', MessageGroupingEditController);

    function MessageGroupingEditController($scope,$http,$state,$stateParams,$rootScope,$timeout) {
        $("#app-wrapper").scrollTop(0);
        window.scrollTo(0, 0);

        $scope.submitting = false;

        $scope.grouping = {
            id: $stateParams.id,
            name: '',
            company_id: 0,
            managers: [],
            tenants: [],
            properties: []
        };

        $scope.dropdowns = {
            companies: {},
            managers: [],
            tenants: [],
            properties: []
        };

        $scope.getGrouping = function(){

            $http.post('/api/v1/message-grouping/get/' + $scope.grouping.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.messagesGroupingList');
                }
                

                // lets populate stuff
                var grouping = data.data.grouping;

                $scope.grouping.name = grouping.name;
                $scope.grouping.company_id = grouping.company_id;

                angular.forEach(grouping.managers, function(manager, key){
                    $scope.grouping.managers.push(manager.id);
                });
                angular.forEach(grouping.tenants, function(tenant, key){
                    $scope.grouping.tenants.push(tenant.id);
                });
                angular.forEach(grouping.properties, function(property, key){
                    $scope.grouping.properties.push(property.id);
                });

                if ($rootScope.authUser.permissions == 'payquad_admin') {
                    $scope.getCompanies();
                } else {
                    $scope.getTenants();
                    $scope.getManagers();
                    $scope.getProperties();
                }
            });
        };

        $scope.handleCompanyChange = function(){
            $scope.grouping.properties = [];
            $scope.grouping.tenants = [];
            $scope.grouping.managers = [];

            $scope.getProperties();
            $scope.getTenants();
            $scope.getManagers();
        };

        $scope.handlePropertiesChange = function() {
            $scope.getTenants();
            $scope.getManagers();
        };

        $scope.deleteGrouping = function() {
            if (!confirm('Are you sure you want to delete this grouping?')) {
                return; // they did not confirm
            }

            $scope.submitting = true;

            $http.post('/api/v1/message-grouping/delete/' + $scope.grouping.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error deleting grouping");
                    $scope.submitting = false;
                    return;
                }

                $state.go('main.messagesGroupingList');
                toastr.success('Grouping deleted');
                $scope.submitting = false;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.updateGrouping = function() {
            $scope.submitting = true;

            $http.post('/api/v1/message-grouping/update/' + $scope.grouping.id, $scope.grouping).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error updating grouping");
                    return;
                }

                $scope.submitting = false;
                $state.go('main.messagesGroupingList');
                toastr.success('Grouping Updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.manager_call_count = 0;
        $scope.getManagers = function() {
            $scope.manager_call_count++;
            $http.post('/api/v1/message-grouping/get-managers', {company_id: $scope.grouping.company_id, properties: $scope.grouping.properties, call_count: $scope.manager_call_count}).success(function(data) {
                if (data.data.call_count != $scope.manager_call_count) {
                    return;// gets called multiple times close together, this ensures the last one is the one used
                }
                $scope.dropdowns.managers = data.data.managers;
                $timeout(function(){
                    $('select#managers').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.tenant_call_count = 0;
        $scope.getTenants = function() {
            $scope.tenant_call_count++;
            $http.post('/api/v1/message-grouping/get-tenants', {company_id: $scope.grouping.company_id, properties: $scope.grouping.properties, call_count: $scope.tenant_call_count}).success(function(data) {
                if (data.data.call_count != $scope.tenant_call_count) {
                    return;// gets called multiple times close together, this ensures the last one is the one used
                }
                $scope.dropdowns.tenants = data.data.tenants;
                $timeout(function(){
                    $('select#tenants').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getProperties = function() {
            $http.post('/api/v1/message-grouping/get-properties', {company_id: $scope.grouping.company_id}).success(function(data) {
                $scope.dropdowns.properties = data.data.properties;
                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getCompanies = function() {
            $http.post('/api/v1/message-grouping/get-companies').success(function(data) {
                $scope.dropdowns.companies = data.data.companies;

                $scope.getTenants();
                $scope.getManagers();
                $scope.getProperties();
            });
        };

        if (!$rootScope.authUser.pro_messaging) {
            $state.go('main.messagesInbox', { redirect : true });
            return 1;
        }

        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.messagesInbox', { redirect : true });
            return 1;
        }

        $scope.getGrouping();
    }
}());
