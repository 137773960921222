(function() {
    'use strict';

    angular
    .module('app')
    .controller('ProspectCreatePropertiesController', prospectCreatePropertiesController);

    function prospectCreatePropertiesController($scope,$http,$state,$rootScope,$timeout,$stateParams,langService) {
        $scope.properties = [];
        $scope.ref = {property_id:null};
        $scope.show_properties = 0;

        $scope.getProperties = function(){
            $http.post('/api/v1/lease-application/get-properties/' + $stateParams.companyId).success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Properties");
                }

                // lets populate stuff
                $scope.properties = data.data.properties;

                $timeout(function () {
                    $('#properties-select').multipleSelect({
                        placeholder: 'Properties',
                        single: true,
                        filter: true,
                        width: 400
                    });
                    $scope.show_properties = 1;
                }, 500);
            });
        }

        $scope.propertyChanged = function(){
            if ($scope.ref.property_id.length == 0) {
                return;
            }
            $state.go('main.prospects.new', {'propertyId': $scope.ref.property_id[0]});
        }

        $scope.getProperties();
    }
}());
