(function() {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/password/views/';

        $stateProvider
            .state('main.passwordRemind', {
                url: '/password/remind',
                views: {
                    "body@": {
                        templateUrl: url + 'password.remind.html',
                        controller: 'PasswordController'
                    }
                }
            })
            .state('main.passwordReset', {
                url: '/password/reset/:passwordToken',
                views: {
                    "body@": {
                        templateUrl: url + 'password.reset.html',
                        controller: 'PasswordController'
                    },
                }
            });

    }

}());
