(function() {
    'use strict';

    angular
    .module('app')
    .controller('DocumentRequestController', documentRequestController);

    function documentRequestController($scope,$http,$state,$stateParams,$rootScope,$timeout) {

        $scope.loading_spinner=0;

        $scope.document = {
            id: $stateParams.id,
            name: '',
            description: '',
            payment_required: 'no',
            payment_amount: 0,
            unit: ''
        };

        $scope.property_units = [];

        $scope.getDocument = function(){
            $http.post('/api/v1/document/get-for-request/' + $scope.document.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.documents');
                }

                // lets populate stuff
                var doc = data.data.document;

                $scope.document.name = doc.name;
                $scope.document.description = doc.description;
                $scope.document.payment_required = doc.payment_required;
                $scope.document.payment_amount = doc.payment_amount;
            });
        }

        $scope.getDocument();

        $scope.getPropertyUnits = function(property_id) {
            $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + property_id).success(function(data){
                if(!data.success){
                    return;
                }

                $scope.property_units = [];
                angular.forEach(data.data, function(unit, key){
                    if (!$scope.property_units.includes(unit.unit)) {
                        $scope.property_units.push(unit.unit);
                    }
                });
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        if (typeof $rootScope.realtor_property_id != 'undefined') {
            $scope.getPropertyUnits($rootScope.realtor_property_id);
        }

        $scope.payDocument = function(){
            //window.location = "#/payments/schedule/create/doc-" + $scope.document.id;
            $state.go('main.paymentScheduleCreate', {paymentMethodId: "doc-" + $scope.document.id});
        }

        $scope.requestDocument = function(){
            var property_id = 0;
            if (typeof $rootScope.realtor_property_id != 'undefined') {
                property_id = $rootScope.realtor_property_id;
            }

            var postdata = {
                property_id: property_id,
                unit: $scope.document.unit
            };

            $scope.loading_spinner=1;

            $http.post('/api/v1/document/submit-request/' + $scope.document.id, postdata).success(function(data) {
                $scope.loading_spinner=0;
                if (!data.success) {
                    var message = 'there was an error submitting your request';
                    if (typeof data.message != 'undefined') {
                        message = data.message
                    }

                    toastr.error(message);
                    return;
                }

                toastr.success('Your request has been submitted');
                $state.go('main.documents');
            });
        }
    }
}());
