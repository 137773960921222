(function () {
    "use strict";

    angular
        .module('pq.property')
        .factory('propertyTenantsData', propertyTenantsDataFactory);

    function propertyTenantsDataFactory($q, propertyDataService)
    {
        return {
            load: load
        };

        // Loads all data into this.data and returns a promise
        function load(propertyId) {
			return;
            var tenants = propertyDataService.getTenants(propertyId);
            var property = propertyDataService.getProperty(propertyId);

            // Retrieve all promised response and package it into an object
            return $q.all([tenants, property]).then(
                function (results) {
                    return {
                        tenants: results[0],
                        property: results[1]
                    }
                });
        }
    }

})();