(function () {
    "use strict";

    angular
        .module('pq.property')
        .directive('pqPropertyMerchant', pqPropertyMerchant);

    function pqPropertyMerchant() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/modules/property/property-create/property-merchant/property-merchant.html'
        }
    }

})();