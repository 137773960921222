(function() {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        $stateProvider
            .state('main.test', {
                url: '/test',
                views: {
                    "body@": {
                        templateUrl: 'scripts/utilities/miscellaneous/form.html',
                        controller: 'FormController',
                        controllerAs: 'vm'
                    }
                }
            });

    }

}());
