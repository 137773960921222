(function () {
    'use strict';

    angular
        .module('app.filterBar')
        .directive('pqFilterBar', pqFilterBar);


    function pqFilterBar(_, $filter, langService) {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/filter-bar/filter-bar/filter-bar.html',
            scope: {
                items: '=',
                filterItems: '='
            },
            link: function ($scope) {
                $scope.t = langService.t('maint_req');
                var origItems;
                activate();

                function activate() {
                    if (typeof $scope.items !== 'undefined') {
                        origItems = $scope.items;
                    }
                }

                //in the event that the view is renedered and items is being requested via ajax
                //set origValue when the callback sets scope.items
                $scope.$watch('items', function (newValue, oldValue) {
                    if (oldValue === undefined) {
                        origItems = newValue;
                        $scope.items = newValue;
                    }
                });

                $scope.applyFilterToItems = function () {
                    if ($scope.filterItems.length < 1) {
                        $scope.items = origItems;
                    } else {
                        $scope.items = $filter('multiPropertyCompleteMatching')(origItems, $scope.filterItems);
                    }
                };

                $scope.applyFilterToItemsBroken = function () {
                    if ($scope.filterItems.length < 1) {
                        $scope.items = origItems;
                    } else {
                        $scope.items = $filter('multiPropertyCompleteMatching')(origItems, $scope.filterItems);
                    }
                };

                $scope.addFilter = function (filter) {
                    // Add Filter
                    if (filter === undefined)
                        return;
                    if (!_.contains($scope.filterItems, filter)) {
                        $scope.filterItems.push(filter);
                    }
                    $scope.applyFilterToItems();
                    // Clear input box
                    $scope.filterItem = '';
                };

                $scope.removeFilter = function (filter) {
                    _.pull($scope.filterItems, filter);
                    $scope.applyFilterToItems();
                };
            }
        };
    }

})();
