
(function () {
    "use strict";

    /**
     * Core module configuration
     */
    angular
        .module('app.core')
        .run(function (Restangular) {
            restangularConfig(Restangular);
        });

    /* @ngInject */
    function restangularConfig(Restangular) {
        Restangular.setBaseUrl('api/v1');
        Restangular.setResponseExtractor(function(response, operation) {
            return response.data;
        });
    }

})();
