(function() {

    'use strict';

    angular
        .module('app')
        .factory('merchantAccountService', merchantAccountService);

    function merchantAccountService($http) {

        var base_url = '/api/v1/';
        var local_url = 'merchant-accounts';

        return {
            getAllMerchants: getAll,
            getMerchantAccountById: getById,
            createMerchantAccount: create,
            updateMerchantAccount: update,
            deleteMerchantAccount: destroy
        };

        function getAll()
        {
            return $http.get(base_url + local_url)
                .then(function (object) {
                    return object.data;
                });
        }

        function getById(id)
        {
            return $http.get(base_url + local_url + "/" + id)
                .then(function (object) {
                    return object.data;
                });
        }

        function create(data)
        {
            return $http({
                method: 'POST',
                url: base_url + local_url,
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                data: $.param(data)
            });
        }

        function update(id, data)
        {
            return $http.put(base_url + local_url + "/" + id, data);
        }

        function destroy(id)
        {
            return $http.delete(base_url + local_url + "/" + id);
        }

    }

}());