(function () {

    'use strict';

    angular
        .module('pq.property')
        .controller('PropertyAllController', PropertyAllController);

    /* @ngInject */
    function PropertyAllController(propertiesAllData)
    {
        var vm = this;

        vm.tenants = propertiesAllData.properties.data;

        vm.sortFields = [
            {label:'Name', value:'[last_name, first_name]'},
            {label:'Date', value:'created_at'},
            {label:'Amount ($)', value:'total_amount'},
            {label:'Status', value:'status'},
            {label:'Unit', value:'unit'},
            {label:'Reference', value:''},
            {label:'Fee ($)', value:'fee'},
            {label:'Property', value:'property'},
            {label:'User Status', value:''}
        ];
    }

})();