(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/dashboard/views/';

		$stateProvider
			.state('main.dashboard', {
				url: '/dashboard',
				views: {
					"body@": {
						templateUrl: url + 'dashboard.main.html',
						controller: 'DashboardController',
                        controllerAs: 'vm',
					}
				}
			});

        function getNotificationData(notificationData)
        {
            return notificationData.load();
        }

    }

}());
