(function () {

    'use strict';

    angular
        .module('app.routes')
        .config(routes);

    function routes($stateProvider)
    {

        const url = 'scripts/modules/report/views/';
        const reportUrl = url + 'reports/';
        const resolvers = {
            resolvedFilters: function ($q, ReportsService) {
                return $q.when(ReportsService.getFilters());
            },
            resolvedTerminals: function ($q, ReportsService) {
                return $q.when(ReportsService.getTerminals());
            },
			resolvedAmenities: function ($q, ReportsService) {
				return $q.when(ReportsService.getAmenities());
			},
        };

        $stateProvider
            .state('main.reports', {
                url: '/reports/:reportID?',
                views: {
                    "body@": {
                        templateUrl: url + 'report.index.html',
                        controller: 'ReportController',
                        resolve: resolvers
                    }
                }
            })
            .state('main.report', {
                url: '/reports',
                views: {
                    "body@": {
                        templateUrl: url + 'report.index.html',
                        controller: 'ReportController',
                        resolve: resolvers
                    }
                }
            })
            .state('main.reports.initial-blank', {
                // Blank report template to avoid issues PD-1377 and PD-1374
                templateUrl: reportUrl + 'initial-blank.html',
            })
            .state('main.reports.user', {
                templateUrl: reportUrl + 'user.html',
            })
            .state('main.reports.paymentMethods', {
                templateUrl: reportUrl + 'paymentMethods.html',
            })
            .state('main.reports.nsf', {
                templateUrl: reportUrl + 'nsf.html',
            })
            .state('main.reports.master', {
                templateUrl: reportUrl + 'master.html',
            })
            .state('main.reports.lmr', {
                templateUrl: reportUrl + 'lmr.html',
            })
            .state('main.reports.recurring', {
                templateUrl: reportUrl + 'recurring.html',
            })
            .state('main.reports.arrears', {
                templateUrl: reportUrl + 'arrears.html',
            })
            .state('main.reports.financial', {
                templateUrl: reportUrl + 'financial.html',
            })
            .state('main.reports.deposit', {
                templateUrl: reportUrl + 'deposit.html',
            })
            .state('main.reports.insurance', {
                templateUrl: reportUrl + 'insurance.html',
            })
            .state('main.reports.insurance-unit', {
                templateUrl: reportUrl + 'insurance-unit.html',
            })
            .state('main.reports.parking', {
                templateUrl: reportUrl + 'parking.html',
            })
            .state('main.reports.yardi_genesis', {
                templateUrl: reportUrl + 'yardi_genesis.html',
            })
            .state('main.reports.yardi_genesis_2', {
                templateUrl: reportUrl + 'yardi_genesis_2.html',
            })
            .state('main.reports.prospect', {
                templateUrl: reportUrl + 'prospect.html',
            })
            .state('main.reports.application', {
                templateUrl: reportUrl + 'application.html',
            })
            .state('main.reports.rent-roll', {
                templateUrl: reportUrl + 'rent-roll.html',
            })
            .state('main.reports.emails', {
                templateUrl: reportUrl + 'emails.html',
            })
            .state('main.reports.marketing', {
                templateUrl: reportUrl + 'marketing.html',
            })
            .state('main.reports.credit_checks', {
                templateUrl: reportUrl + 'credit-checks.html',
            })
            .state('main.reports.splitpay', {
                templateUrl: reportUrl + 'splitpay.html',
            })
            .state('main.reports.client-billing', {
                templateUrl: reportUrl + 'client-biling.html',
			})
			.state('main.reports.amenity', {
				templateUrl: reportUrl + 'amenity.html',
			})
            .state('main.reports.prompt-payment', {
				templateUrl: reportUrl + 'prompt-payment.html',
			})
            .state('main.reports.new-tenants-without-pad', {
				templateUrl: reportUrl + 'new-tenants-without-pad.html',
            });
    }

}());
