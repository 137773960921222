(function () {

    'use strict';

    angular
        .module('app')
        .factory('managerData', managerDataFactory);

    function managerDataFactory($q, managerService)
    {
        return {
            getNewPreset: getNewPreset,
            load2: load2,
            load: load
        };

        function load(managerId) {
            var managers = managerService.getAllManagers();

            if(managerId != null) {
                var manager = managerService.getManagerById(managerId);
            }

            return $q.all([managers, manager]).then(
                function (results) {
                    return {
                        managers: results[0],
                        manager: results[1]
                    }
                });
        }

        function load2(id){
            return managerService.getPreset(id)
        }

        function getNewPreset(companyId){
            return managerService.getNewPreset(companyId)
        }
    }

})();
