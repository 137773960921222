(function () {

    'use strict';

    angular
        .module('app')
        .controller('MessageViewController', MessageViewController)
        .config(function ($animateProvider) {
            $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
        });

    function MessageViewController($scope, $state, messageService, $stateParams, $rootScope, langService, $http) {
        $scope.t = langService.t('messages');

        var vm = this;
        vm.showFullMessage = showFullMessage;

        $rootScope.deleteMessage = function (id) {
            messageService.setMessagesAsDeleted({"ids": [id]}).then(function () {
                $state.go('main.messagesInbox');
                toastr.success($scope.t("Message Deleted"));
            });
        };

        $rootScope.archiveMessage = function (id) {
            messageService.setMessagesAsArchived({"ids": [id]}).then(function () {
                $state.go('main.messagesInbox');
                toastr.success($scope.t("Message Archived"));
            });
        };

        $rootScope.unarchiveMessage = function (id) {
            messageService.setMessagesAsUnarchived({"ids": [id]}).then(function () {
                $state.go('main.messagesInbox');
                toastr.success($scope.t("Message Unarchived"));
            });
        };

        $scope.messagesManagers = [];
        $http.post('/api/v1/message-grouping/get-managers').success(function (data) {

            if (!data) {
                toastr.error('Error retrieving Managers');
                return;
            }
            $scope.messagesManagers = data.data ? data.data.managers : [];
        });

        $scope.messagesTags = [];
        $http.get('/api/v1/messages/tags').success(function (data) {
            if (!data) {
                toastr.error('Error retrieving Tags');
                return;
            }
            $scope.messagesTags = data.data;
        });

        $scope.loadTags = function (query) {
            return $scope.messagesTags.filter(obj => {
                return obj.name.toLowerCase().includes(query.toLowerCase())
            });
        };

        $scope.messagesCategories = [];
        $http.get('/api/v1/messages/categories').success(function (data) {
            if (!data) {
                toastr.error('Error Retrieving Categories');
                return;
            }
            $scope.messagesCategories = data.data;
        });

        $scope.replyToMessage = function (message_id) {
            $state.go("main.messageCreateReply", {reply_message_id: message_id});
        }

        /**
         * Show messages by id
         */
        $scope.isTicketingEnabled = false;
        $scope.isLoading = false;

        function showFullMessage() {
            $scope.isLoading = true;
            if (!angular.isUndefined($stateParams.id)) {
                $scope.messageId = parseInt($stateParams.id);
                var currentMessage = '';
                if ($scope.messageId > 0) {
                    messageService.getFullMessage($scope.messageId)
                        .success(function (data) {
                            $scope.tenants_can_message = Boolean(data.tenants_can_message);
                            $scope.isTicketingEnabled = Boolean(data.ticketing_enabled);
                            if (data.message.length > 0) {
                                vm.message = data.message;

                                vm.message.forEach(function (message, index) {
                                    if ($scope.messageId == message.id) {
                                        $scope.currentMessageIndex = index;
                                        currentMessage = message;
                                    }
                                });

                                $scope.current_inbox = vm.message[0].archived == true ? 'archived' : 'inbox'; 
                                console.log('vm.message[0]',vm.message[0], $scope.current_inbox);

                                //really should only send if the unread message was received (not sent) by auth user 
                                //but backend will take care of it anyways, this is just preliminary filter so not sending too many requests
                                if(vm.message.some(message => message.is_read == false)){ 
                                    messageService.setMessagesAsRead({"ids": [$scope.messageId]}).then(function success() {
                                        $rootScope.$broadcast('check-new-messages');
                                    });
                                }                              
                            } else {
                                $state.go('main.messagesInbox');
                            }
                            $scope.isLoading = false;
                        }).error(()=>{
                            $scope.isLoading = false;
                        });
                } else {
                    $state.go('main.messagesInbox');
                }
            } else {
                $state.go('main.messagesInbox');
            }
        }

        vm.showFullMessage();

        $scope.isUpdating = false;
        $scope.updateTicket = function (message_id, type_id, priority, tags, manager, ticket_status, note) {
            console.log(note)
            $scope.isUpdating = true;
            $http.put('/api/v1/ticket/' + message_id + '/update', {
                manager: manager,
                message_type_id: type_id,
                ticket_status: ticket_status,
                priority: priority,
                tags: tags,
                note: note,
            }).success(function (data) {
                toastr.success('Saved');
            }).error(function (data) {
                toastr.error('Failed. Try again.');
            }).finally(function () {
                $scope.isUpdating = false;
            });
        };

        vm.dashboard_messages_enabled = false;
        $http.post('/api/v1/dashboard-message/is-enabled').success(function (data) {
            if (data.success) {
                vm.dashboard_messages_enabled = true;
            }
        });

        $scope.goToArchiveInbox = function() {
            $state.go('main.messagesInbox', {
                filter: 'archived'
            });
        }
    }

}());
