(function () {

    'use strict';

    angular
        .module('app')
        .controller('MaintenanceRequestPoListController', maintenanceRequestPoListController);

    function maintenanceRequestPoListController($scope, $http, $state, $stateParams, $rootScope, $interval, $timeout, langService) {
        $scope.t = langService.t('maint_req');

        if ($rootScope.authUser.permissions !== 'payquad_admin') {
            $state.go('main.maintenanceRequests');
            return 1;
        }
    }

}());
