(function () {
    "use strict";

    angular
        .module('app.filters')
        .filter('startFrom', startFromFilter);

    function startFromFilter()
    {
        return function(input, start) {
            if (angular.isDefined(input)) {
                start = +start; //parse to int
                return input.slice(start);
            } else {
                return [];
            }
        }
    }

})();