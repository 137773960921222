(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationManagerCompleteController', leaseApplicationManagerCompleteController);

    function leaseApplicationManagerCompleteController($scope,$http,$state,$rootScope,$timeout,$stateParams,$window, langService) {
        $scope.t = langService.t('lease_app');

        $scope.application = {
            id : $stateParams.id,
        };

        $scope.loading_spinner = 1;

        $scope.getApplication = function(){
            $http.post('/api/v1/lease-application/manager-get/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,$scope.t("Error loading application"));
                    return;
                }

                $scope.application = data.data.application;

                //cast to 2 decimal places
                $scope.application.vars.rent_discount_amount ? $scope.application.vars.rent_discount_amount = Number($scope.application.vars.rent_discount_amount).toFixed(2) : null;
                $scope.application.vars.total_rent ? $scope.application.vars.total_rent = Number($scope.application.vars.total_rent).toFixed(2) : null;
                $scope.application.vars.base_rent ? $scope.application.vars.base_rent = Number($scope.application.vars.base_rent).toFixed(2) : null;
                $scope.application.vars.parking_amount ? $scope.application.vars.parking_amount = Number($scope.application.vars.parking_amount).toFixed(2) : null;
                $scope.application.vars.first_partial_payment ? $scope.application.vars.first_partial_payment = Number($scope.application.vars.first_partial_payment).toFixed(2) : null;
                $scope.application.vars.additional_service1_amount ? $scope.application.vars.additional_service1_amount = Number($scope.application.vars.additional_service1_amount).toFixed(2) : null;
                $scope.application.vars.additional_service2_amount ? $scope.application.vars.additional_service2_amount = Number($scope.application.vars.additional_service2_amount).toFixed(2) : null;
                $scope.application.vars.nsf_charge_amount ? $scope.application.vars.nsf_charge_amount = Number($scope.application.vars.nsf_charge_amount).toFixed(2) : null;
                $scope.application.vars.rent_deposit_amount ? $scope.application.vars.rent_deposit_amount = Number($scope.application.vars.rent_deposit_amount).toFixed(2) : null;
                $scope.application.vars.damage_deposit_amount ? $scope.application.vars.damage_deposit_amount = Number($scope.application.vars.damage_deposit_amount).toFixed(2) : null;
                $scope.application.vars.pet_deposit_amount ? $scope.application.vars.pet_deposit_amount = Number($scope.application.vars.pet_deposit_amount).toFixed(2) : null;
                $scope.application.vars.key_deposit_amount ? $scope.application.vars.key_deposit_amount = Number($scope.application.vars.key_deposit_amount).toFixed(2) : null;

                if ($scope.application.status != 'approved') {
                    $state.go('main.leaseApplicationList');
                }

                $scope.template_fields = data.data.template_fields;

                $scope.loading_spinner = 0;

                $timeout(function(){
                    $('select#payment-methods').multipleSelect({filter: true});
                    $('#term-time-start-date').datepicker();
                    $('#term-time-end-date').datepicker();
                    $('#first-partial-payment-date').datepicker();
                    $('#first-partial-payment-start-date').datepicker();
                    $('#first-partial-payment-end-date').datepicker();
                }, 500);
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getApplication();

        $scope.preview = function(){
            $scope.loading_spinner = 1;

            $scope.application.vars.unit_number = $scope.application.unit_number;
            $scope.application.vars.rent_discount_notes = $scope.application.rent_discount_notes;
            $scope.application.vars.utilities_notes = $scope.application.utilities_notes;
            $scope.application.vars.key_deposit_notes = $scope.application.key_deposit_notes;
            $scope.application.vars.smoking_rules = $scope.application.smoking_rules;
            $scope.application.vars.template_fields = $scope.template_fields;
            $http.post('/api/v1/lease-application/manager-complete-preview/' + $scope.application.id, $scope.application.vars).success(function(data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors,"Error previewing lease");
                    return;
                }

                if ($scope.application.property.lease_application_template_id) {
                    window.location.href = '/#/n/templates/preview/' + $scope.application.property.lease_application_template_id + '/lease-application/' + $scope.application.id;
                } else {
                    if(window.Capacitor.isNativePlatform()) {
                        try {
                            window.Capacitor.Plugins.Browser.open({url: (pqDomain + '/lease-application-pdf/' + $scope.application.id + '?cordova='+(!!window.Capacitor.isNativePlatform()? '1':'0') + '&token=' + pqCachedToken)});
                        } catch (e) {
                            alert(e)
                        }
                    } else {
                        $window.open('/lease-application-pdf/' + $scope.application.id, '_blank');
                    }
                }
                return;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.approveApplication = function(){
            if (!confirm($scope.t('You are about to create the lease document and a copy will be sent to the tenant to review and sign'))) {
                return;
            }
            $scope.loading_spinner = 1;

            $scope.application.vars.unit_number = $scope.application.unit_number;
            $http.post('/api/v1/lease-application/manager-complete/' + $scope.application.id, $scope.application.vars).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,$scope.t("Error completing application"));
                    $scope.loading_spinner = 0;
                    return;
                }

                toastr.info($scope.t('Lease has been created and sent for signature'));
                $state.go('main.leaseApplicationList');
                return;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.isFieldLocked = function (field_name) {
            if ($rootScope.authUser.permissions != 'junior_manager') {
                return false;
            }
            return $scope.application.property.lease_application_locked_fields.includes(field_name);
        };

        $scope.calculateTotalRent = () => {
            $scope.application.vars.total_rent = Number($scope.application.vars.base_rent || 0) + Number($scope.application.vars.parking_amount || 0) + Number($scope.application.vars.additional_service1_amount || 0) + Number($scope.application.vars.additional_service2_amount || 0);
        }

        $scope.parking_warning_given = false;
        $scope.parkingWarning = () => {
            if ($scope.parking_warning_given) {
                return;
            }

            $scope.parking_warning_given = true;
            toastr.warning($scope.t('By ediitng the number of parking spaces here'));
        }
    }
}());
