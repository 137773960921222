(function () {

    'use strict';

    angular
        .module('app')
        .controller('NG2LoginController', NG2LoginController)


    function NG2LoginController($scope, $state, $rootScope, $location) {
        $rootScope.isAngular2 = true;
        if ($location.path().includes('/n/') && !$location.path().includes('2fa')) {
            $state.go('main.ng2Login', {}, {notify: false}).then(() => {
                $rootScope.isAngular2 = true;
                window.routerSubject.next('login');
            });
        }
    }

}());
