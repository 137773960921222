(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationCreateController', leaseApplicationCreateController);

    function leaseApplicationCreateController($scope,$http,$state,$rootScope,$timeout,$stateParams,authenticationService, passwordService, brandingService, langService) {
        $scope.t = langService.t('lease_app');

        $scope.ref = {show_form: 0};
        $scope.property = {};

        $scope.passwordData = {};

        $scope.is_logged_in = 1;
        if (typeof $rootScope.authUser == 'undefined') {
            $scope.is_logged_in = 0;
        }

        $scope.login_type = 0;
        $scope.show_reminder = 0;

        $scope.application = {
            id: 0,
            property_id: $stateParams.propertyId,
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            password: '',
            password_confirmation: '',
            bedrooms: 0,
            floorplan: '',
            unit_type_id: 0,
            unit: '',
            move_in_at: ''//moment().format('MM/DD/YYYY')
        };

        $scope.datepicker_times = 'allfuturedates';
        if ($stateParams.propertyId == 3624) {
            $scope.datepicker_times = 'afterjuly12023';
        }

        $scope.unit_types = [];
        $scope.getUnitTypes = function () {
            if ($scope.property.company.extension_type == 'yardi' && $scope.property.lease_applications_extension_enabled == 1 && $scope.property.lease_application_yardi_unit_types == 1) {
                return;
            }
            $http.post('/api/v1/lease-application/get-unit-types/' + $scope.property.id).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.unit_types = data.data.unit_types;
                // add default unit type if not set
                if ($scope.application.unit_type_id == 0) {
                    $scope.application.unit_type_id = $scope.unit_types[0]?.id;
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.show_floorplans = 0;
        $scope.floorplans = [];

        $scope.getProperty = function(){
            $http.post('/api/v1/lease-application/get-property/' + $scope.application.property_id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error getting property information");
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                $scope.property = data.data.property;
                $scope.ref.show_form = 1;
                $scope.getBranding();
                $scope.getFloorplans();
                $scope.getUnitTypes();

                if (window.location.host.indexOf('leasing') == -1) {
                    window.location.href = 'https://' + $scope.property.company.subdomain  + '.leasing.payquad.com/' +     window.location.hash
                }

            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
                $state.go('main.leaseApplicationCreateCompanies');
            });
        };
        $scope.getProperty();

        $scope.getFloorplans = function(){
            if ($scope.property.company.extension_type != 'yardi' || $scope.property.lease_applications_extension_enabled != 1 || $scope.property.lease_application_yardi_unit_types ==0) {
                return;
            }
            $http.post('/api/v1/lease-application/get-floorplans/' + $scope.application.property_id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.floorplans = data.data.floorplans;
                $scope.show_floorplans = 1;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
                $state.go('main.leaseApplicationCreateCompanies');
            });
        };

        $scope.setShowReminder = function(reminder) {
            $scope.show_reminder = reminder;
        };

        $scope.getBranding = function(){
            brandingService.get(null, $scope.property.id).then(function(data) {
                $rootScope.branding = data.data;
            });
        };

        $scope.saveAndContinue = function(){
            var confirmmessage = $scope.t('by_clicking');
            confirmmessage += '<b>';
            confirmmessage += '<a href="javascript:void(0)"';
            confirmmessage += 'title='+$scope.t('view_terms_of_use');
            confirmmessage += 'class="user_agree_checkbox_info"';
            confirmmessage += 'id="terms_conditions_link"';
            confirmmessage += 'data-toggle="modal"';
            confirmmessage += 'data-target=".terms">';
            confirmmessage += $scope.t('terms_of_use');
            confirmmessage += '</a>';
            confirmmessage += '</b>';
            confirmmessage += $scope.t('and');
            confirmmessage += '<b>';
            confirmmessage += '<a href="javascript:void(0)"';
            confirmmessage += 'title='+$scope.t('view_privacy_policy');
            confirmmessage += 'class="user_agree_checkbox_info"';
            confirmmessage += 'id="privacy_policy_link"';
            confirmmessage += 'data-toggle="modal"';
            confirmmessage += 'data-target=".privacy">';
            confirmmessage += $scope.t('privacy_policy')+'.';
            confirmmessage += '</a>';
            confirmmessage += '</b> '+$scope.t('account_will_be_created');
            bootbox.confirm(confirmmessage, function(result){
                if (result) {
                    $scope.saveAndContinueConfirmed();
                }
            });
        };

        $scope.saveAndContinueConfirmed = function(){
            $http.post('/api/v1/lease-application/create', $scope.application).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error saving application");
                    return;
                }

                if (typeof data.data.application_id == 'undefined') {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error saving application");
                    return;
                }

                $scope.application.id = data.data.application_id;

                if ($scope.is_logged_in == 1) {
                    $state.go('main.leaseApplicationContinue', { id: $scope.application.id });
                }

                if (data.data.pre_existing == 0) {
                    toastr.success('We have sent you an email to verify your email address.  Please click the link contained in the email to verify your address.');
                }
                $scope.login();
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.changeLoginType = function(login_type) {
            $scope.login_type = login_type;
        };

        $scope.login = function() {
            var authData = {
                property: 1,
                email: $scope.application.email,
                password: $scope.application.password,
                remember_me: 0,
                agree_terms: true,
                phone_number: '',
                last_name:'',
                first_name: ''
            };

            authenticationService.login(authData).success(function(data) {
                if (data.success === false) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error saving application");
                    return;
                }

                $rootScope.authUser = data.data;
                console.log($rootScope.authUser);
                $rootScope.$broadcast('check-new-messages');
                brandingService.get().then(function (data) {
                    $rootScope.branding = data.data;
                    $scope.submitting = false;
                    $state.go('main.leaseApplicationContinue', { id: $scope.application.id });
                });
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.available_units = [];
        $scope.getAvailableUnits = function() {
            $http.post('/api/v1/lease-application/get-floorplan-units/' + $scope.application.property_id+'/'+$scope.application.floorplan, $scope.application).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.available_units = data.data.units;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
                $state.go('main.leaseApplicationCreateCompanies');
            });
        };

        $scope.remindPassword = function() {
            var data = {
                "email" : $scope.passwordData.email
            };
            passwordService.remind(data).success(function(data) {
                if(data.success == true) {
                    $scope.setShowReminder(0);
                    $scope.message = data.message;

                    toastr.success('We have e-mailed your password reset link!', 'Email Sent!');
                    toastr.options = {
                        "closeButton": true,
                        "debug": false,
                        "positionClass": "toast-top-right",
                        "preventDuplicates": true
                    };
                } else{
                    $scope.message = data.message;
                }
            }).error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                console.log('password reminded didnt go through bro');
                $log.log(data.error + ' ' + status);
            });
        };
    }
}());
