(function () {

    'use strict';

    angular
        .module('app')
        .controller('SurveyPassController', function ($rootScope, $scope, $location, resolvedSurvey, surveyService, langService) {
            $scope.t = langService.t('surveys');
            $scope.survey = resolvedSurvey;
            if(!resolvedSurvey.success){
                $scope.error = resolvedSurvey.message;
                toastr.error($scope.error);
            }
            $scope.isPublic = $scope.survey.is_public;
            $scope.isSubmited = false;
            $scope.error = false;
            $scope.costAdderSum = 0;
            $scope.costAdderQuestionExists = $scope.survey.questions.some(q => q.type === 'Cost Adder');
            $scope.respondent = {
                name:  $rootScope.authUser !== undefined ? $rootScope.authUser.first_name + ' ' + $rootScope.authUser.last_name : null,
                email: $rootScope.authUser !== undefined ? $rootScope.authUser.email : null
            };


            $scope.save = () => {
                const answers = $scope.survey.questions.map(item => {
                    const answer = {question_id: item.id};
                    if (item.type === 'Free text' || item.type === 'Cost Adder') {
                        answer.answer = item.answer;
                    } else if (item.type === 'Multiple choice') {
                        answer.variants = item.variants.filter(v => v.answer).map(v => +v.id);
                    } else {
                        answer.variants = [+item.answer];
                    }
                    return answer;
                });

                const data =  {answers};
                const mr_id = $location.search().mr_id;
                if(mr_id){
                    data.mr_id = mr_id;
                }
                data.respondent_name = $scope.respondent.name;
                data.respondent_email = $scope.respondent.email;

                surveyService.fill($scope.survey.id, data).then(function successCallback(response){
                    $scope.isSubmited = true
                }, function errorCallback(response){
                    toastr.error(response['data']);
                });
            };

            $scope.calculateCostAdderSum = () => {
                $scope.costAdderSum = $scope.survey.questions.reduce((a, b) => {
                    if (b.type === 'Cost Adder'){
                        return a + (b.answer || 0);
                    }
                    return 0;
                }, 0);
            };
        });

}());
