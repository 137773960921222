(function () {

    'use strict';

    angular
        .module('app')
        .controller('InspectionEditController', function ($scope, $state, $timeout, resolvedInspection, inspectionService, helperService, $http, jwtService, $rootScope) {
            window.scrollTo(0, 0);
            let body = document.querySelector('body');
            body.style.overflowY = 'hidden';

            $scope.$on("$destroy", function () {
                let body = document.querySelector('body');
                body.style.overflowY = 'unset';
            });
            $scope.inspection = resolvedInspection;
            $scope.photosRef = {};
            $scope.photosRefCordova = {};
            $scope.commentRef = {};
            $scope.isSaving = false;

            $scope.isMobile = helperService.isMobileOrTablet();
            $scope.newPhoto = null;
            $scope.isUploading = false;
            $scope.lastPhoto = '';
            $scope.animatedPhoto = '';
            $scope.showedPhoto = '';
            $scope.photoFiles = null;
            $scope.photos = [];
            $scope.inspectionItem = null;
            $scope.token = jwtService.getToken();
            $scope.signRef = {};

            const fileInput = document.getElementById('photosPopupFiles');

            $scope.setMark = (item, mark) => item.mark = mark;
            $scope.getCompleted = area => area.items.filter(item => item.mark).length;
            $scope.save = (status) => {
                $scope.isSaving = true;
                $scope.inspection.status = status;
                inspectionService.update($scope.inspection).then(
                function (response) {
                    $state.go('main.inspections').then(function(response) {
                        toastr.success('Inspection successfully updated.');
                        $state.reload();
                    })
                }, 
                function (data) {
                    toastr.error(data.data);
                    $scope.inspection.status = 'In Progress';
                    $scope.isSaving = false;
                });
            };

            $scope.openGallery = () => {
                $('#pq-photos-modal-gallery-inline').appendTo('body').modal('show');
                $timeout(() => {
                    $('.modal-backdrop').hide();
                }, 0);
            };

            $scope.showPhoto = index => {
                $scope.showedPhoto = $scope.photos[index];
                $scope.openShowedPhoto();
            };

            $scope.openShowedPhoto = () => {
                $('#pq-photos-modal-photo-inline').appendTo('body').modal('show');
                $timeout(() => {
                    $('.modal-backdrop').hide();
                }, 0);
            }

            $scope.closeGallery = () => {
                $('#pq-photos-modal-gallery-inline').modal('hide');
            };

            $scope.closeShowedPhoto = () => {
                $('#pq-photos-modal-photo-inline').modal('hide');
            };

            $scope.photoTypeSelection = function (type) {
                $scope.newPhoto = {
                    "type": type,
                    "value": null,
                    "url": null,
                    "urlConverted": null
                };

                if (type === 'photo') {
                    $scope.addCameraPhoto();
                } else if (type === 'file') {
                    $scope.addFile();
                }
            }

            $scope.viewInspectionReport = function(url) {
                if(!!window.Capacitor.isNativePlatform()) {
                    window.Capacitor.InAppBrowser.open(pqDomain + url + '?cordova=1&token=' + pqCachedToken, '_system');
                } else {
                    window.open(url);
                }
            }

            $scope.addCameraPhoto = function () {
                var srcType = Camera.PictureSourceType.CAMERA;
                var options = $scope.setOptions(srcType);

                navigator.camera.getPicture(function cameraSuccess(imageUri) {
                    $scope.$apply(function () {
                        $scope.newPhoto.url = imageUri;
                        $scope.newPhoto.urlConverted = window.Ionic.WebView.convertFileSrc(imageUri);

                        $scope.getImageBlobFromUrl($scope.newPhoto.urlConverted);

                    });

                }, function cameraError(error) {
                    $scope.$apply(function () {
                        console.debug("Unable to obtain picture: " + error, "app");
                        $scope.newPhoto = null;
                    });
                }, options);
            }

            $scope.setOptions = function (srcType) {
                var options = {
                    // Some common settings are 20, 50, and 100
                    quality: 75,
                    destinationType: Camera.DestinationType.FILE_URI,
                    // In this app, dynamically set the picture source, Camera or photo gallery
                    sourceType: srcType,
                    encodingType: Camera.EncodingType.JPEG,
                    mediaType: Camera.MediaType.PICTURE,
                    allowEdit: false,
                    correctOrientation: true,
                    targetWidth: 1000,
                    targetHeight: 1000
                }
                return options;
            }

            $scope.getImageBlobFromUrl = function (url) {
                $http.get(url, {responseType: 'blob'})
                    .then(function (data) {
                        window.ddd = data;

                        var reader = new FileReader();
                        reader.readAsDataURL(data.data);
                        reader.onloadend = function () {
                            var base64data = reader.result;
                            animatePhoto(base64data)
                            $scope.newPhoto.value = base64data;
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }

            $scope.onErrorCreateFile = function () {

            }

            $scope.onErrorResolveUrl = function () {

            }

            $scope.photoUploaded = () => {
                console.log('is this even used?')
                const filesLength = fileInput.files.length;
                if (filesLength) {
                    for (let i = 0; i < filesLength; i++) {
                        let fileReader = new FileReader();
                        fileReader.onload = () => {
                            $timeout(() => {
                                $scope.photos.push(fileReader.result);
                                $scope.lastPhoto = fileReader.result;
                            }, 0);
                        };
                        const file = fileInput.files.item(i);
                        if (availableFormats.includes(file.type)) {
                            fileReader.readAsDataURL(file);
                        } else {
                            toastr.error('Only JPG and PNG are available.');
                        }
                    }
                    fileInput.value = '';
                }
            };

            const animatePhoto = photo => {
                $scope.$evalAsync(function () {
                    $scope.animatedPhoto = photo;

                    $scope.photos.push(photo);
                    $scope.lastPhoto = photo;
                });
            };

            $scope.addFile = function (selection) {
                var srcType = Camera.PictureSourceType.PHOTOLIBRARY;
                var options = $scope.setOptions(srcType);

                navigator.camera.getPicture(function cameraSuccess(imageUri) {
                    $scope.$apply(function () {
                        $scope.newPhoto.url = imageUri;
                        $scope.newPhoto.urlConverted = window.Ionic.WebView.convertFileSrc(imageUri);

                        $scope.getImageBlobFromUrl($scope.newPhoto.urlConverted);
                    });

                }, function cameraError(error) {
                    $scope.$apply(function () {
                        $scope.newPhoto = null;
                    });
                    console.debug("Unable to obtain picture: " + error, "app");

                }, options);
            }

            $scope.removePhoto = index => {
                if ($scope.photos[index] === $scope.lastPhoto) {
                    $scope.$evalAsync(function () {
                        $scope.lastPhoto = $scope.photos[index - 1] ? $scope.photos[index - 1] : '';
                    });
                }
                $scope.$evalAsync(function () {
                    $scope.photos.splice(index, 1);
                });

                if (!$scope.photos.length) {
                    $scope.closeGallery();
                }
            };

            //signiture stuff
            $scope.signature1 = {
                img: null,
                url: null,
                name: ''
            };
            $scope.signature2 = {
                img: null,
                url:null,
                name: ''
            };

            if($scope.inspection.signatures.length){
                for(let s of $scope.inspection.signatures){
                    if(s.type === 'inspector'){
                        $scope.signature1.url = `${pqDomain}/inspection/signatures/${$scope.inspection.id}/${s.type}?cordova=${(!!window.Capacitor.isNativePlatform() ? '1':'0')}&token=${pqCachedToken}`;
                        $scope.signature1.name = s.name;
                    }else if(s.type === 'tenant'){
                        $scope.signature2.url = `${pqDomain}/inspection/signatures/${$scope.inspection.id}/${s.type}?cordova=${(!!window.Capacitor.isNativePlatform() ? '1':'0')}&token=${pqCachedToken}`;
                        $scope.signature2.name = s.name;
                    }
                }
            }

            $scope.signSave1 = (sign, name) => { 
                if(sign && name.length){
                    if(!$scope.signature1.url){
                        $scope.signature1.img = sign; //picture content
                        $scope.signature1.name = name;
                        $scope.inspection.inspector_signature = $scope.signature1;
                    }else{
                        toastr.error('You already have a signature saved');
                        return false;
                    }
                    return true;
                }else{
                    toastr.error('Name and signature are required.');
                    return false;
                }
            };
            $scope.signSave2 = (sign, name) => { 
                if(sign && name.length){
                    if(!$scope.signature2.url){
                        $scope.signature2.img = sign;
                        $scope.signature2.name = name;
                        $scope.inspection.tenant_signature = $scope.signature2;
                    }else{
                        toastr.error('You already have a signature saved');
                        return false;
                    }
                    return true;
                }else{
                    toastr.error('Name and signature are required.');
                    return false;
                }
            };

            $scope.openSignPopup = (id) => {
                $scope['signRef' + id].open();
            };

        });

}());
