(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationInviteController', leaseApplicationInviteController);

    function leaseApplicationInviteController($scope,$http,$state,$rootScope,$timeout,$stateParams,authenticationService, passwordService, brandingService) {
        $scope.property = {};

        $scope.passwordData = {};

        $scope.is_logged_in = 1;
        if (typeof $rootScope.authUser == 'undefined') {
            $scope.is_logged_in = 0;
        }

        $scope.login_type = 0;
        $scope.show_reminder = 0;

        $scope.application = {
            id: 0,
            property_id: 0,
            invite_id: $stateParams.inviteId,
            invite_token: $stateParams.token,
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            password: '',
            password_confirmation: '',
            bedrooms: 0,
            unit: '',
            move_in_at: ''//moment().format('MM/DD/YYYY')
        };

        $scope.getInvite = function(){
            $http.post('/api/v1/lease-application/get-invite/' + $scope.application.invite_id + '/' + $scope.application.invite_token).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error getting invite information");
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                $scope.property = data.data.property;
                $scope.application.property_id = $scope.property.id
                $scope.application.unit = data.data.unit;
                $scope.application.move_in_at = moment(data.data.move_in_at).format('MM/DD/YYYY');
                $scope.getBranding();
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
                $state.go('main.leaseApplicationCreateCompanies');
            });
        };
        $scope.getInvite();

        $scope.setShowReminder = function(reminder) {
            $scope.show_reminder = reminder;
        };

        $scope.getBranding = function(){
            brandingService.get(null, $scope.property.id).then(function(data) {
                $rootScope.branding = data.data;
            });
        };

        $scope.saveAndContinue = function(){
            var confirmmessage = 'By clicking next you are agreeing to the ';
            confirmmessage += '<b>';
            confirmmessage += '<a href="javascript:void(0)"';
            confirmmessage += 'title="View Terms & Conditions"';
            confirmmessage += 'class="user_agree_checkbox_info"';
            confirmmessage += 'id="terms_conditions_link"';
            confirmmessage += 'data-toggle="modal"';
            confirmmessage += 'data-target=".terms">';
            confirmmessage += 'Terms of Use';
            confirmmessage += '</a>';
            confirmmessage += '</b>';
            confirmmessage += ' and ';
            confirmmessage += '<b>';
            confirmmessage += '<a href="javascript:void(0)"';
            confirmmessage += 'title="View Privacy Policy"';
            confirmmessage += 'class="user_agree_checkbox_info"';
            confirmmessage += 'id="privacy_policy_link"';
            confirmmessage += 'data-toggle="modal"';
            confirmmessage += 'data-target=".privacy">';
            confirmmessage += 'Privacy Policy.';
            confirmmessage += '</a>';
            confirmmessage += '</b> An account will be created for you to continue creating your application. This will allow you to access your account later to either complete your application or provide more information.';
            bootbox.confirm(confirmmessage, function(result){
                if (result) {
                    $scope.saveAndContinueConfirmed();
                }
            });
        };

        $scope.saveAndContinueConfirmed = function(){
            $http.post('/api/v1/lease-application/create', $scope.application).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error saving application");
                    return;
                }

                if (typeof data.data.application_id == 'undefined') {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error saving application");
                    return;
                }

                $scope.application.id = data.data.application_id;

                if ($scope.is_logged_in == 1) {
                    $state.go('main.leaseApplicationContinue', { id: $scope.application.id });
                }

                if (data.data.pre_existing == 0) {
                    toastr.success('We have sent you an email to verify your email address.  Please click the link contained in the email to verify your address.');
                }
                $scope.login();
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.changeLoginType = function(login_type) {
            $scope.login_type = login_type;
        };

        $scope.login = function() {
            var authData = {
                property: 1,
                email: $scope.application.email,
                password: $scope.application.password,
                remember_me: 0,
                agree_terms: true,
                phone_number: '',
                last_name:'',
                first_name: ''
            };

            authenticationService.login(authData).success(function(data) {
                if (data.success === false) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error saving application");
                    return;
                }

                $rootScope.authUser = data.data;
                console.log($rootScope.authUser);
                $rootScope.$broadcast('check-new-messages');
                brandingService.get().then(function (data) {
                    $rootScope.branding = data.data;
                    $scope.submitting = false;
                    $state.go('main.leaseApplicationContinue', { id: $scope.application.id });
                });
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.remindPassword = function() {
            var data = {
                "email" : $scope.passwordData.email
            };
            passwordService.remind(data).success(function(data) {
                if(data.success == true) {
                    $scope.setShowReminder(0);
                    $scope.message = data.message;

                    toastr.success('We have e-mailed your password reset link!', 'Email Sent!');
                    toastr.options = {
                        "closeButton": true,
                        "debug": false,
                        "positionClass": "toast-top-right",
                        "preventDuplicates": true
                    };
                } else{
                    $scope.message = data.message;
                }
            }).error(function(data, status, headers, config) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                console.log('password reminded didnt go through bro');
                $log.log(data.error + ' ' + status);
            });
        };
    }
}());
