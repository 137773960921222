(function () {

    'use strict';

    angular
        .module('app')
        .controller('ShowingEditController', function (resolvedShowing, resolvedProspect, $scope, showingService, $state, prospectService) {
            if (resolvedShowing) {
                resolvedShowing.prospect.move_in_at = moment(resolvedShowing.prospect.move_in_at).format('MM/DD/YYYY');
                resolvedShowing.prospect.contact_at = moment(resolvedShowing.prospect.contact_at).format('MM/DD/YYYY');
                $scope.showing = resolvedShowing;
            } else if (resolvedProspect) {
                resolvedProspect.move_in_at = moment(resolvedProspect.move_in_at).format('MM/DD/YYYY');
                resolvedProspect.contact_at = moment(resolvedProspect.contact_at).format('MM/DD/YYYY');
                $scope.showing = {
                    start_date: '',
                    start_time: '',
                    virtual_showing_type: '',
                    other_virtual_type: '',
                    prospect: resolvedProspect
                };
            } else {
                $scope.showing = {
                    start_date: '',
                    start_time: '',
                    virtual_showing_type: '',
                    other_virtual_type: '',
                    prospect: prospectService.getBlank()
                };
            }
            $scope.step = 1;
            $scope.isSending = false;
            $scope.inviteControl = {};

            $scope.invite = () => {
                $scope.inviteControl.openInviteWindow();
                $('.modal-backdrop').hide();
            };

            $scope.goToStep = step => $scope.step = step;

            $scope.create = () => {
                $scope.isSending = true;
                showingService.create($scope.showing).success(() => {
                    $state.go('main.showings', {}, {reload: true}).then(() => {
                        toastr.success('Showing was created successfully.');
                    });
                }).error(data => {
                    Object.keys(data.errors).forEach(key => data.errors[key].forEach(msg => toastr.error(msg)));
                    $scope.isSending = false;
                });
            };

            $scope.save = status => {
                $scope.isSending = true;
                if (status) {
                    $scope.showing.status = status;
                }
                showingService.save($scope.showing).success(() => {
                    $state.go('main.showings', {}, {reload: true}).then(() => {
                        toastr.success('Showing was saved successfully.');
                    });
                }).error(data => {
                    Object.keys(data.errors).forEach(key => data.errors[key].forEach(msg => toastr.error(msg)));
                    $scope.isSending = false;
                });
            };

        });

}());
