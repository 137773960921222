(function() {

    'use strict';

    angular
        .module('app')
        .controller('AccountTenantController', AccountTenantController);

    function AccountTenantController(accountService, $state, $rootScope) {

        var vm = this;

        vm.logosize = 'Image size must be between <b>60px * 60px</b> and <b>80px * 80px</b>!';
        vm.bgsize = 'Image size must be between <b>300px * 1200px</b> and <b>80px * 80px</b>!';

        vm.accountData = {
            password: null
        };

        accountService.getTenantAccount($rootScope.authUser.id)
            .success(function(data) {
                vm.tenant = data.data;
                console.log(data);
            });

        vm.updateTenant = function(tenantId)
        {
            var data = {
                first_name: vm.tenant.first_name,
                last_name: vm.tenant.last_name,
                password: vm.accountData.password,
                address: vm.tenant.address,
                unit: vm.tenant.unit,
                postal_code: vm.tenant.postal_code,
                country: vm.tenant.country,
                city: vm.tenant.city,
                email: vm.tenant.user.email,
                phone_number: vm.tenant.phone_number,
                work_number: vm.tenant.work_number,
                cell_number: vm.tenant.cell_number,
                fax_number: vm.tenant.fax_number,
                management_news: vm.tenant.account.management_news,
                payquad_news: vm.tenant.account.payquad_news,
                reminders: vm.tenant.account.reminders
            };

            accountService.updateTenantAccount(tenantId, data)
                .success(function (data) {
                    console.log('tenant successfully updated!');
                    console.log(data);
                    location.reload();
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });
        }

    }

}());
