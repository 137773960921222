(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationConvertController', leaseApplicationConvertController);

    function leaseApplicationConvertController($scope,$http,$state,$rootScope,$timeout,$stateParams) {

        if ($rootScope.authUser.permissions == 'applicant') {
            $state.go('main.leaseApplicationDashboard');
        }

        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform());
        $scope.pdfFile = null;
        $scope.scaleCurrent = 1;

        $scope.page_numbers = [1];
        $scope.signature_box_jump_locations = [];
        $scope.signature_box_jump_current = 0;

        $scope.get_extension_ids = 0;

        $scope.application = {
            id : $stateParams.id,
            unit_number: '',
            applicants: []
        };
        $scope.disable_unit_field = false;

        $scope.getApplication = function(){
            $http.post('/api/v1/lease-application/manager-get/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error loading application");
                    return;
                }

                // for yardi, only allow conversion from yardi, not pq
                var extension_type = data.data.application.property.company.extension_type;
                var enabled = data.data.application.property.lease_applications_extension_enabled;
                if (extension_type == 'yardi' && enabled==1 && $rootScope.authUser.permissions != 'payquad_admin') {
                    // only if authuser id is not 1367 or 1366
                    if ($rootScope.authUser.id != 1367 && $rootScope.authUser.id != 1366) {
                        $state.go('main.leaseApplicationList');
                        return;
                    }
                }

                if (data.data.application.status != 'complete') {
                    $state.go('main.leaseApplicationList');
                    return;
                }

                if (data.data.application.property.company.yardi_lease_require_extension_id == 1) {
                    $scope.get_extension_ids = 1;

                    $scope.application.applicants.push({
                        user_id: data.data.application.user_id,
                        name: data.data.application.user.first_name + ' ' + data.data.application.user.last_name,
                        type: 'main-applicant',
                        id: data.data.application.id,
                        extension_id: ''
                    });

                    data.data.application.applicants.forEach(applicant => {
                        $scope.application.applicants.push({
                            user_id: applicant.user_id,
                            name: applicant.first_name + ' ' + applicant.last_name,
                            type: 'co-applicant',
                            id: applicant.id,
                            extension_id: ''
                        });
                    });
                }

                $scope.application.unit_number = data.data.application.unit_number;

                if ($scope.application.unit_number) {
                    $scope.disable_unit_field = true;
                }
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getApplication();

        $scope.convertApplication = function(){
            var missing_extensions = 0;
            if ($scope.get_extension_ids == 1) {
                $scope.application.applicants.forEach(applicant => {
                    if (applicant.extension_id == '') {
                        missing_extensions = 1;
                        return;
                    }
                });
            }

            if (missing_extensions == 1) {
                toastr.error('You must fill in the extension id for all applicants');
                return;
            }

			if (!confirm('You are about to convert this application into a new tenant.  Are you sure?  Please review the attached document before proceeding.')) {
                return;
            }

            // Send data to server instead...
            $http.post('/api/v1/lease-application/convert/' + $scope.application.id, $scope.application).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error converting Application");
                    return;
                }

                toastr.info('Application has been converted to a Tenant account');
                $state.go('main.leaseApplicationList');
                return;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.loadPdf = function(reload){
            if($scope.pdfDoc && !reload) {
                $scope.initPdf();
            } else {
                pdfjsLib.getDocument($scope.url).promise.then(function(pdfDoc_) {
                    $scope.pdfDoc = pdfDoc_;
                    $scope.initPdf();
                });
            }

        };

        $scope.initPdf = function() {
            $scope.page_numbers = [];
            var i;
            for (i = 0;i<$scope.pdfDoc.numPages;i++) {
                $scope.page_numbers.push(i+1);
            }

            $timeout(function(){
                for (i = 0;i<$scope.pdfDoc.numPages;i++) {
                    $scope.renderPage(i+1);
                }
            }, 0);
        }

        $scope.renderPage = function(num) {
            $scope.pageRendering = true;

            $scope.pdfDoc.getPage(num).then(function(page) {
                if (!page) {
                    console.error('Page is undefined.');
                    $scope.pageRendering = false;
                    return;
                }

                var targetDiv = document.getElementById("lease-pdf-" + num);

                var viewport = page.getViewport({ scale: 1 });
                $scope.scale = (!window.Capacitor.isNativePlatform() || $scope.scaleCurrent <= 1) ? ($scope.canvas_div.clientWidth - 40) / viewport.width : $scope.scaleCurrent;
                viewport = page.getViewport({ scale: $scope.scale });

                targetDiv.style.height = viewport.height + 'px';
                targetDiv.style.width = viewport.width + 'px';

                // Convert the PDF page to an image
                var canvas = document.createElement('canvas');
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                var context = canvas.getContext('2d');
                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                var renderTask = page.render(renderContext);
                renderTask.promise.then(function() {
                    // Convert canvas to data URL
                    var imageDataUrl = canvas.toDataURL('image/png');
                    var existingImg = document.getElementById('lease-pdf-img-' + num);

                    if (existingImg) {
                        existingImg.src = imageDataUrl;
                    } else {

                        // Create an image element
                        var img = document.createElement('img');
                        img.id = 'lease-pdf-img-' + num;
                        img.src = imageDataUrl;

                        // Append the image to the target div
                        targetDiv.appendChild(img);
                    }

                    $scope.pageRendering = false;

                    if ($scope.pageNumPending !== null) {
                        // New page rendering is pending
                        $scope.renderPage($scope.pageNumPending);
                        $scope.pageNumPending = null;
                    }
                }).catch(function(renderError) {
                    console.error('Error rendering page:', renderError);
                    $scope.pageRendering = false;
                });
            }).catch(function(fetchError) {
                console.error('Error fetching page:', fetchError);
                $scope.pageRendering = false;
            });
        };

        $scope.zoom = function(amount) {
            $scope.scaleCurrent += amount;
            $scope.initPdf();
        }

        $timeout(function(){
            $scope.url = pqDomain + "/lease-application-pdf/" + $scope.application.id  + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken;
            $scope.pdfDoc = null;
            $scope.pageNum = 1;
            $scope.pageRendering = false;
            $scope.pageNumPending = null;
            $scope.canvas_div = document.getElementById("lease-application-pdf-div");
            $scope.scale = 1;
            $scope.scaleCurrent = 1;
            $scope.loadPdf();
        }, 500);
    }
}());
