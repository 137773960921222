(function () {
    "use strict";

    angular
        .module('app.fieldSorter')
        .directive('pqFieldSorter', pqFieldSorter);

    function pqFieldSorter() {
        return {
            restrict: 'A',
            scope: {
                sortPredicate: '=',
                sortFields: '=',
                defaultSortField: '='
            },
            replace: true,
            templateUrl: 'scripts/components/field-sorter/field-sorter.html',
            controller: 'FieldSorterController'
        }
    }

})();