(function() {

    'use strict';

    angular
        .module('app')
        .constant('appValues', {
            title: 'Payquad',
            version: '1.0',
            base_url: 'http://localhost:8000/api/v1/',
            api_version: '/api/v1/'
        });

}());