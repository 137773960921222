(function() {

	'use strict';

    angular
		.module('app')
		.controller('MerchantAccountIndexController', MerchantAccountIndexController);

	function MerchantAccountIndexController($scope) {

    }

}());