(function() {
    'use strict';

    angular
    .module('app')
    .controller('MessageTemplateCreateController', MessageTemplateCreateController);

    function MessageTemplateCreateController($scope,$http,$state,$rootScope,$timeout) {

        $scope.submitting = false;

        $scope.template = {
            name: '',
            subject: '',
            content: '',
            company_id: 0,
            properties: []
        };

        $scope.dropdowns = {
            companies: {}
        };

        $scope.properties = [];

        $scope.getProperties = function() {
            $http.post('/api/v1/vendor/get-properties/' + $scope.template.company_id).success(function(data) {
                $scope.properties = data.data.properties;

                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        }

        $scope.createMessageTemplate = function() {
            $scope.submitting = true;

            $http.post('/api/v1/message-template/create', $scope.template).success(function(data) {
                if (!data.success) {
                    $scope.submitting = false;
                    toastr.error(data.errors,"Error creating message template");
                    return;
                }

                $scope.submitting = false;
                $state.go('main.messagesTemplateList');
                toastr.success('Message Template Created');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        }

        $scope.initDraggableTags = function(){
            //$("#allfields li").draggable({
                //appendTo: "body",
                //helper: "clone",
                //cursor: "select",
                //revert: "invalid"
            //});
            $(".bedraggable").draggable({
                appendTo: "body",
                helper: "clone",
                cursor: "select",
                revert: "invalid"
            });
            $scope.initDroppable();
        }

        $scope.initDroppable = function(){
            $("#droppable-subject").droppable({
                hoverClass: "textarea",
                accept: ":not(.ui-sortable-helper)",
                drop: function(event, ui) {
                    var tempid = ui.draggable.data('tag');
                    var dropText;
                    dropText = " {{" + tempid + "}} ";
                    $scope.template.subject  = $scope.template.subject + dropText;
                    $scope.$apply();
                }
            });
            $("#droppable-content").droppable({
                hoverClass: "textarea",
                accept: ":not(.ui-sortable-helper)",
                drop: function(event, ui) {
                    var tempid = ui.draggable.data('tag');
                    var dropText;
                    dropText = " {{" + tempid + "}} ";

                    $scope.template.content  = $scope.template.content + dropText;
                    $scope.$apply();
                }
            });
        }

        if (!$rootScope.authUser.pro_messaging) {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.messagesTemplatesInfo', { redirect : true });
            return 1;
        }

        if ($rootScope.authUser.permissions == 'payquad_admin') {
            $http.post('/api/v1/message-template/get-companies').success(function(data) {
                $scope.dropdowns.companies = data.data.companies
                $scope.template.company_id = $scope.dropdowns.companies[0].id;
                $scope.getProperties();
            });
        } else {
            $scope.template.company_id = $rootScope.authUser.company_id;
            $scope.getProperties();
        }

        $timeout(function(){
            $scope.initDraggableTags();
        },500);
    }
}());
