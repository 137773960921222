(function() {

	'use strict';

    angular
		.module('app')
		.controller('PaymentMethodEditController', PaymentMethodEditController);

	function PaymentMethodEditController($scope, $state, $stateParams, paymentMethodService, $log, $rootScope, langService) {

	    $scope.t = langService.t('payments');

		$scope.paymentMethodData = {
            billing_same_as_mailing: false
        };

        if ($stateParams.paymentMethodId != null) {
            paymentMethodService.getById($stateParams.paymentMethodId)
                .success(function(data) {
                    console.log('---------------------------------------');
                    console.log('----- Payment Method by id Success. ---');
                    console.log('---------------------------------------');
                    console.log(data);

                    $scope.payment_method = data.data;
                })
                .error(function(data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    console.log(data);
                });
        }

		$scope.updatePaymentMethod = function(id) {

            if ($rootScope.authUser.suspended == 1) {
                toastr.error('Can not modify payment methods while account is suspended');
                return;
            }

			var data = {
				friendly_name: $scope.payment_method.friendly_name,
				card_holder_name: $scope.payment_method.card_holder_name,
				expiration_year: $scope.payment_method.expiration_year,
                expiration_month: $scope.payment_method.expiration_month,
                billing_same_as_mailing: $scope.paymentMethodData.billing_same_as_mailing,
                institution_number: $scope.payment_method.institution_number,
                transit_number: $scope.payment_method.transit_number,
                account_number: $scope.payment_method.account_number,
                address: $scope.payment_method.address,
                unit: $scope.payment_method.unit,
                city: $scope.payment_method.city,
                province: $scope.payment_method.province,
                postal_code: $scope.payment_method.postal_code,
                country: $scope.payment_method.country
			};

			paymentMethodService.update(id, data)
				.success(function(data) {
                    console.log('---------------------------------------');
                    console.log('----- Payment Method Update Success. --');
                    console.log('---------------------------------------');
					console.log(data);

                    toastr.success($scope.t('payment_updated_message'), $scope.t('payment_updated'));
                    toastr.options = {
                        closeButton: true
                    };
				})
				.error(function(data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

					console.log(data);
				});
		};

		$scope.deletePaymentMethod = function(paymentMethodId) {
            if(!confirm($rootScope.trans('payments.delete_method')))
                return;
			paymentMethodService.delete(paymentMethodId)
                .success(function(data) {
                    if(data.success)
                    {
                        console.log('---------------------------------------');
                        console.log('----- Payment Method Delete Success. --');
                        console.log('---------------------------------------');
                        console.log(data);
                        $state.go('main.paymentMethods');
                        toastr.success($scope.t('payment_deleted_message'), $scope.t('payment_deleted'));
                        toastr.options = {
                            closeButton: true
                        };

                    }
                    else if(data.success == false)
                    {
                        console.log('---------------------------------------');
                        console.log('----- Payment Method Delete FAIL. -----');
                        console.log('---------------------------------------');
                        data.errors.forEach((n, key) => {
                            console.log(data);
                            toastr.error(n, $scope.t('payment_delete_fail'));
                        });
                    }
				})
				.error(function(data, status, headers, config) {
                    console.log(data);
                    console.log(status);
                    console.log(headers);
                    console.log(config);
				});
		};
    }

}());
