(function () {

    'use strict';

    angular
        .module('app')
        .factory('prospectService', prospectService);

    function prospectService($http) {

        const base_url = '/api/v1/prospect';

        return {
            all: () => $http.post(`${base_url}/get-list`).then(r => r.data.data.prospects),
            get: id => $http.post(`${base_url}/get/${id}`).then(r => r.data.data.prospect),
            getBlank: () => {
                return {
                    id: 0,
                    company_id: 0,
                    property_id: 0,
                    unit: '',
                    first_name: '',
                    last_name: '',
                    address: '',
                    suite: '',
                    city: '',
                    postal: '',
                    country: '',
                    province: '',
                    phone: '',
                    mobile_phone: '',
                    email: '',
                    contact_at: moment().format('MM/DD/YYYY'),
                    referral: '',
                    contact_type: '',
                    manager_id: '',
                    move_in_at: null,
                    unit_type: '',
                    notes: '',
                    agent_first_name: '',
                    agent_last_name: ''
                };
            },

            getProperty: id => $http.get(`/api/v1/property/public/${id}`).then(r => r.data.data),
            createByClient: showing => $http.post(`${base_url}/create-by-client`, JSON.stringify(showing)),
            getVirtualMethods: () => ['WhatsApp', 'Zoom', 'FaceTime', 'Duo', 'Skype', 'Other']
        };

    }

}());
