(function () {

    'use strict';

    angular
        .module('app.routes')
        .run(function ($rootScope, $location, $state, authenticationService, $q, $timeout, routerService, jwtService) {
            appRun($rootScope, $location, $state, authenticationService, $q, $timeout, routerService, jwtService);
        });

    function appRun($rootScope, $location, $state, authenticationService, $q, $timeout, routerService, jwtService) {

        $rootScope.redirectedAfterLogin = false;
        $rootScope.tenants_waiting_approval = 0;
        $rootScope.publicStates = ['main.login', 'main.register', 'main.register.step_2', 'main.register.step_3', 'main.emailConfirmation',
            'main.passwordRemind', 'main.passwordReset', 'main.email',
            'main.leaseApplicationCreate', 'main.leaseApplicationCreateProperties', 'main.showingsProperties', 'main.prospectProperties', 'main.leaseApplicationCreateCompanies',
            'main.profileRegister', 'main.showingsBooking', 'main.showingsBooking.step2', 'main.prospects.new', 'main.quickPayForm', 'main.quickPayReceipt', 'main.quickPayStart',
            'main.surveysPassPublic', 'main.ng2Login'
        ];

        $rootScope.$on('$viewContentLoaded', function () {
            setTimeout('$(\'.btn-disable-me\').parent().on(\'click\',function() {\
                       if($(\'.toast-error\').length == 0 && $(\'.btn[disabled]\').length > 0 && $(this).parents(\'.ta-root\').length == 0)\
                               toastr.error("Please fill out the entire form before continuing");\
        });\
            $(\'.only-number\').on(\'change keyup\', function() {\
                                 var sanitized = $(this).val().replace(/[^0-9]/g, \'\');\
                                     $(this).val(sanitized);\
                       });', 500);
        });

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if( (fromState.views && toState.views) && !fromState.views['sidebar@'] && !toState.views['sidebar@'] ){
                $('.modal:not(.terms):not(.privacy):not(.support)').remove();
            }
            console.log('$stateChangeStart', toState);
            $rootScope.showLoader = true;
            $timeout(() => { // hack to show loader immediately
            }, 0);
            // if ($rootScope.isAngular2) {
            //     return;
            // }
            $rootScope.authPromise = $q.defer();

            if (!$rootScope.authUser) {
                authenticationService.initAuth().then(() => {
                    checkRoute($rootScope, $state, $location, event, toState, routerService);
                });
            } else {
                checkRoute($rootScope, $state, $location, event, toState, routerService);
            }
        });

        $rootScope.$on('$stateChangeSuccess', () => {
            console.log('$stateChangeSuccess');
            $rootScope.isAngular2 = false;
            $rootScope.showLoader = false;
            window['fireAngularEvent']('pq.angularjs-route');
        });
        $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => {
            console.log('$stateChangeError', event, toState, toParams, fromState, fromParams, error);
            $rootScope.showLoader = false;
            if (toParams.a === 'dashboard') {
                // $state.go('main.dashboard');
                window.location.href = '/#/n/dashboard';
            }
        });

        $rootScope.$on('$locationChangeStart', function (angularEvent, newUrl, oldUrl, newState, oldState) {
            let oldUrlFixed = oldUrl;
            let newUrlFixed = newUrl;

            if($rootScope.platform == 'ios') {
                if(oldUrl.indexOf('localhost#/') > -1) {
                    let splitOldUrl = oldUrl.split('localhost');
                    oldUrlFixed = splitOldUrl[0] + 'localhost/' + splitOldUrl[1];
                }

                if(newUrl.indexOf('localhost#/') > -1) {
                    let splitNewUrl = newUrl.split('localhost');
                    newUrlFixed = splitNewUrl[0] + 'localhost/' + splitNewUrl[1];
                }
            }

            console.log('$locationChangeStart', oldUrlFixed, newUrlFixed, oldState, newState, angularEvent);

            // console.log('new url', oldUrl.indexOf('/n/'), newUrl.indexOf('/n/'), oldUrl.indexOf('/n/') > 0 && newUrl.indexOf('/n/') === -1)
            if (oldUrlFixed.indexOf('/n/') > 0 && newUrlFixed.indexOf('/n/') === -1) {
                $rootScope.isAngular2  = false;
            }

            if (oldUrlFixed.indexOf('/n/') > 0 && newUrlFixed.indexOf('/n/') > 0 && oldState === null && newState === null && oldUrlFixed === newUrlFixed) {
                console.error('PREVENTED  ', newUrlFixed)
                if (newUrlFixed.indexOf('preventRedirect') === -1) {
                    console.error('PREVENTED confirmed')
                    angularEvent.preventDefault();

                    let url = new URL(newUrlFixed);
                    window.location.href = `/${url.hash}?preventRedirect`; // FIX as sometimes it should be & not ?
                }

                $rootScope.isAngular2  = true;
            }

            /*
            on a prospect events list page we have a subpage of individual that works as an overlay.
            When the PE page opens, the URL in the browser changes, which causes a change in the route state in the  AngularJS, which causes the page with the event to be redrawn.
            To prevent this I will try to load events in the old Angular for these pages
             */
            let pattern = /\/prospects\/events\/\d+/;
            // if (pattern.test(newUrlFixed)) {
            if (
                oldUrlFixed.indexOf('prospects/events') > 0 && pattern.test(newUrlFixed)
            ) {
                console.error('FIX PROSPECT EVENTS')
                // angularEvent.preventDefault();
            }

            //Hack for angular router and ui-router conflict
            if (newUrlFixed !== oldUrlFixed && newState !== null && oldState !== null && angular.equals(newState, oldState) && $location.path().indexOf('/n/') === -1 && oldUrlFixed.indexOf('/n/') > 0) {
                // checking if any state already loaded. Throwing errors if no. Helpful for navigating from angular to angularjs when page was opened directly or recently reloaded
                if ($state.current.name.length > 0) {
                    console.error('state reload');
                    $state.reload()
                }
            }
        });

        $rootScope.goTo = url => {
            window.location.href = url;
            return false;
        };

        window['subscribeToAngularEvent']('pq.sign-out', () => {
            console.log('pq.sign-out', $rootScope.authUser);
            $rootScope.authUser = undefined;
            window.location.href = '/#/n/login';
        });
    }

	const checkRoute = ($rootScope, $state, $location, event, toState, routerService) => {
        //public url and logged in
        // console.log($rootScope.isAngular2, $state, $location, event, toState, routerService)
        if ($.inArray(toState.name, $rootScope.publicStates) >= 0 && $rootScope.authUser !== undefined && toState.name != 'main.emailConfirmation' && toState.name !='main.surveysPassPublic') {
            event.preventDefault();
            $rootScope.authPromise.reject();

            if ($rootScope.authUser.permissions === 'applicant') {
                $state.go('main.leaseApplicationDashboard');
            } else {
                const isOnlyOnePermission = routerService.userHasOnlyOnePermission();
                if (isOnlyOnePermission) {
                    $state.go(routerService.getStateByPermission(isOnlyOnePermission));
                } else {
                    if (
                        $rootScope.authUser['permissions'] === 'payquad_admin'
                        || $rootScope.authUser['permissions'] === 'junior_manager'
                        || $rootScope.authUser['permissions'] === 'admin_manager'
                    ) {
                        console.log('goToAngular2 dash')
                        $rootScope.goToAngular2('dashboard');
                    } else {
                        $state.go('main.dashboard');
                    }
                }
            }
        }

        let url = $location.url();
        const patterns = [
            /\/n\/showings\/\d+/,
            /\/n\/maintenance-request\/\d+\/vendor\/\d+\/\d+/,
        ];
        const isMatch = patterns.some(pattern => pattern.test(url));
        //private url and not logged in
        if ($.inArray(toState.name, $rootScope.publicStates) < 0 && $rootScope.authUser === undefined && !isMatch) {
            $rootScope.postLogInRoute = $location.url();
            event.preventDefault();
            $rootScope.authPromise.reject();
            // $state.go('main.ng2Login', {}, {reload: true});
            // $rootScope.goToAngular2('login');
            $rootScope.goToAngular2Login();
        }

        $rootScope.authPromise.resolve();
    };

})();
