(function() {

    'use strict';

    angular
        .module('app')
        .controller('AuthenticationRegisterController', AuthenticationRegisterController);

    function AuthenticationRegisterController($scope, $modal, $state, authenticationService, $http, $rootScope, brandingService, langService) {

        $scope.t = langService.t('registration');

        if (typeof $rootScope.temp_registration_auto_approval != 'undefined') {
            $scope.registration_auto_approval = $rootScope.temp_registration_auto_approval;
            delete $rootScope.temp_registration_auto_approval;
        }
        $scope.payments_enabled = false;

		$scope.authenticationService = authenticationService;
        $scope.emailPattern = (function() {
            var regexp = /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/;
            return {
                test: function(value) {
                    if( $scope.requireTel === false ) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();

        $scope.phoneNumberPattern = (function() {
            var regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
            return {
                test: function(value) {
                    if( $scope.requireTel === false ) {
                        return true;
                    }
                    return regexp.test(value);
                }
            };
        })();

        $scope.goToPaymentMethodCreate = function()
        {
		
				$scope.authenticationService.getAuth()
                            .success(function(data){
								var $body = angular.element(document.body);   // 1
  								var $rootScope = $body.scope().$root;    
                                $rootScope.authUser = data.data;                               
                                $rootScope.waitingForLogin = false;
                                $state.go('main.paymentMethodCreate', {redirect: true});

              });
        };

        $scope.goToDashboard = function()
        {
			$scope.authenticationService.getAuth()
                            .success(function(data){
								var $body = angular.element(document.body);   // 1
  								var $rootScope = $body.scope().$root;    
                                $rootScope.authUser = data.data;                               
                                $rootScope.waitingForLogin = false;
                                $state.go('main.dashboard', {redirect: true});
              });
        };

        $http.get('/api/v1/properties-list').
            success(function(data) {
                $scope.properties = data.data
            });

        $scope.updateForm = function($item, $model, $label) {
            console.log($item);
            if ($item != '') {
                console.log($item);
                console.log($model);
                console.log('label:' + $label);
                $scope.authData.property_id = $item.id;
            }
        };

        if (typeof $rootScope.temp_authData != 'undefined') {
            $scope.authData = $rootScope.temp_authData;
            delete $rootScope.temp_authData;
        } else {
            $scope.authData = {
                /*
                first_name: "a",
                last_name: "b",
                phone_number: "1231231231",
                email: "tenant@payquad.com" + Math.floor((Math.random() * 100) + 1),
                password:"asdasd",
                password_confirmation: "asdasd",
                */
                property_id: null,
                unit: null,
                agree_terms: false,
                agree_privacy: false,
                security_code: ''
            };
        }

        // steps
        if (typeof $rootScope.temp_registration_step != 'undefined') {
            $scope.step = $rootScope.temp_registration_step;
            delete $rootScope.temp_registration_step;
        } else {
            $scope.step = 1;
        }
        $scope.stepOne = function()
        {
            $scope.step = 1;
        };
        $scope.stepTwo = function()
        {
            $scope.clientErrors = {
                errors: []
            };
            $scope.errors = '';
            //$scope.step = 2;

            // validating email
            var input = {
                email: $scope.authData.email
            };
            authenticationService.validateEmail(input)
                .success(function(data) {
                    if(data.success === false)
                    {
                        $scope.errors = data.errors;
                        console.log(data.errors);
                        console.log('email validation failed, another exists');
                    }
                    else if($scope.authData.password != $scope.authData.password_confirmation)
                    {
                        $scope.clientErrors.errors.push('your passwords do not match');
                    }
                    else if($scope.authData.agree_terms === false)
                    {
                        console.log('You must agree to the Terms of Use and Privacy Policy.');
                        $scope.clientErrors.errors.push('You must agree to the Terms of Use and Privacy Policy.');
                    }
                    else
                    {
                        
						
						if($scope.authData.security_code == ''){
							$scope.errors = "";
                            $scope.step = 2;
							//$state.go('main.register.step_2');
						}
						else
							$scope.register();
                    }
                });

        };
        $scope.stepThree = function()
        {
			console.log('setting step to 3');
            $scope.step = 3;
        };

        $scope.register = function()
        {
            var input = {
                email: $scope.authData.email,
                password: $scope.authData.password,
                password_confirmation: $scope.authData.password_confirmation,
                property_id: $scope.authData.property_id,
                unit: $scope.authData.unit,
                first_name: $scope.authData.first_name,
                last_name: $scope.authData.last_name,
                phone_number: $scope.authData.phone_number,
                billing_same_as_mailing: $scope.authData.billing_same_as_mailing_input,
                address: $scope.authData.address,
                city: $scope.authData.city,
                province: $scope.authData.province,
                postal_code: $scope.authData.postal_code,
                country: $scope.authData.country,
				security_code: $scope.authData.security_code
            };
            authenticationService.register(input)
                .success(function(data) {
                    if (data.success === true)
                    {
                        console.log(data.data.address);
                        $scope.property_address = data.data.property_address;
                        $scope.registration_auto_approval = data.data.registration_auto_approval;
                        $scope.payments_enabled = data.data.payments_enabled;
                        $rootScope.temp_registration_auto_approval = $scope.registration_auto_approval;
                        $rootScope.temp_authData = $scope.authData;

                        brandingService.get().then(function (data) {
                            $rootScope.branding = data.data;
                            $scope.step = 3;
                            $rootScope.temp_registration_step = $scope.step;
                            //$state.go('main.register.step_3');
                            console.log('registration success!');
                            $scope.$apply();
                        });
                    }
                    else if(data.success === false)
                    {
                        $scope.errors = data.errors;
                        console.log('registration failed');
                        console.log(data.errors);
                    }
                    else
                    {
                        console.log('response 500?');
                    }
                })
                .error(function(data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    if (typeof data.errors != 'undefined') {
                        $scope.errors = data.errors[Object.keys(data.errors)[0]];
                    }
                });
        };

    }

}());
