(function () {
    'use strict';

    angular
        .module('app.filterBar')
        .directive('filterBarDataTablesByColumn', filterBarDataTablesByColumn)
        .filter('unique', function () {
            return function (items, filterOn) {
                if (filterOn === false) {
                  return items;
                }
            
                if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
                  var hashCheck = {}, newItems = [];
            
                  var extractValueToCompare = function (item) {
                    if (angular.isObject(item) && angular.isString(filterOn)) {
                      return item[filterOn];
                    } else {
                      return item;
                    }
                  };
            
                  angular.forEach(items, function (item) {
                    var valueToCheck, isDuplicate = false;
            
                    for (var i = 0; i < newItems.length; i++) {
                      if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                        isDuplicate = true;
                        break;
                      }
                    }
                    if (!isDuplicate) {
                      newItems.push(item);
                    }
            
                  });
                  items = newItems;
                }
                return items;
              };
            });

    function filterBarDataTablesByColumn($http, $timeout, $location, propertyService, helperService, langService) {

        const isMobile = helperService.isMobileOrTablet();

        return {
            restrict: 'E',
            templateUrl: function () {
                if (!isMobile) {
                    return 'scripts/components/filter-bar/filter-bar-data-tables-by-column/filter-bar-data-tables-by-column.html';
                } else {
                    return 'scripts/components/filter-bar/filter-bar-data-tables-by-column/filter-bar-data-tables-by-column-mobile.html';
                }
            },
            scope: {
                filterTag: '=',
                filterItems: '=',
                filterColumns: '=',
                tableIdValue: '=?',
                filterHeaders: '=?',
                isProperty: '=?',
                isTenants: '=?',
                isLeaseList: '=?',
                hideUnit: '=?',
            },
            link: function ($scope) {
                $scope.t = langService.t('maint_req');
                $scope.filterColumn = 'all';
                $scope.filter_type = 'other';
                $scope.multi = {
                    properties: []
                };
                $scope.properties = [];
                $scope.units = [];
                $scope.selectedProperty = {ids: []};
                $scope.selectedUnit = {id: []};
                $scope.selectedUnitText = {text: ''};
                $scope.filtersHidden = false;
                $scope.pre_property_ids = null;
                $scope.pre_unit_ids = null;
                $scope.prospectStatuses = [];

                $scope.propertyChangedInitializing = false;
                $scope.unitChangedInitializing = false;

                $scope.residentFilter = {
                    value: 'enabled'
                };
                $scope.leaseStatusFilter = {
                    value: 'active'
                };
                $scope.propertiesFilterString = {
                    value: ''
                };

                $scope.tenantActivityFilter = {
                    value: 'all'
                };

                $scope.setPaymentFilter = (status) => {
                    $scope.tenantActivityFilter.value = status;
                    $scope.filterItems = $scope.filterItems.filter(item => item.indexOf(':status') === -1);

                    if (status !== 'all') {
                        $scope.filterItems.push(status + ':status');
                    }

                    $scope.applyFilterToItems();
                }

                $scope.residentFilterChanged = () => {
                    $scope.applyFilterToItems(`${$scope.residentFilter.value}:status`);
                };

                $scope.leaseFilterChanged = () => {
                    $scope.filterItems = $scope.filterItems.filter(item => item.indexOf(':status') === -1);
                    $scope.applyFilterToItems(`${$scope.leaseStatusFilter.value}:status`);
                };

                $scope.propertyChanged = () => {
                    if (!$scope.propertyChangedInitializing && !isMobile){
                        $scope.propertyChangedInitializing = true;
                        return;
                    }
                    $scope.selectedUnit.id = [];
                    $scope.selectedUnitText = '';
                    if ($scope.selectedProperty.ids.length) {
                        $scope.getUnits($scope.selectedProperty.ids);
                        let filter = $scope.selectedProperty.ids.join('--') + ':properties.id';
                        $scope.filter_type = 'other';
                        $scope.filterItems = $scope.filterItems.filter(item => item.indexOf(':properties.id') === -1);
                        $scope.filterItems.push(filter);
                        $scope.applyFilterToItems();
                    } else {
                        $scope.filterItems = $scope.filterItems.filter(item => item.indexOf(':properties.id') === -1);
                        $scope.units = [];
                        $scope.applyFilterToItems();
                    }
                };

                $scope.unitChanged = () => {
                    if (!$scope.unitChangedInitializing && !isMobile){
                        $scope.unitChangedInitializing = true;
                        return;
                    }
                    $scope.filterItems = $scope.filterItems.filter(item => item.indexOf(':unit') === -1);
                    if (`${$scope.selectedUnit.id}` != '') {
                        let filter = $scope.selectedUnit.id.join(',') + ':unit';
                        $scope.filterItems.push(filter);
                    }
                    $scope.applyFilterToItems();
                };

                $scope.toggleFilters = () => {
                    $scope.filtersHidden = !$scope.filtersHidden;
                };

                $scope.getUnits = propertyIds => {
                    propertyService.getUnitsForProperties(propertyIds).then(data => {
                        $scope.units = data;
                        if (!isMobile) {
                            $timeout(() => {
                                $('#unit-multiselect + .ms-parent').removeClass('ng-hide');
                                $('#unit-multiselect').multipleSelect('refresh');
                                $scope.preFill();
                            }, 0);
                        }
                    });
                };

                $scope.preFill = function(){
                    if ($scope.pre_unit_ids != null) {
                        $scope.selectedUnit.id = $scope.pre_unit_ids;
                        $scope.pre_unit_ids = null;
                        $scope.unitChanged();
                        $timeout(() => {
                            $('#unit-multiselect').multipleSelect('refresh');
                        }, 500);
                    }
                };

                $scope.checkFieldType = function () {
                    //$scope.filterColumn === 'created_at' || $scope.filterColumn === 'move_in_at' || $scope.filterColumn === 'contact_at'
                    if ($scope.filterColumn === 'created_at' ||$scope.filterColumn === 'date' || $scope.filterColumn.match(/_at$/i)) {
                        $scope.filter_type = 'date';

                        $('#filter-by-input-date').daterangepicker({
                            opens: 'left',
                            autoUpdateInput: false,
                            locale: {
                                cancelLabel: 'Clear'
                            }
                        }, function (start, end, label) {
                            $scope.filter_type = 'other';
                            $scope.addFilter(start.format('YYYY-MM-DD') + '---' + end.format('YYYY-MM-DD'));
                        });

                        return;
                    }

                    if ($scope.filterColumn === 'properties.id') {
                        if (!$scope.properties.length) {
                            $scope.getProperties();
                        }
                        $scope.filter_type = 'properties';
                        return;
                    }
                    $scope.filter_type = 'other';
                };

                $scope.propertiesFiltered = [];
                $scope.getProperties = function () {
                    $http.post(pqDomain + '/api/v1/get-filter-properties').success(function (data) {
                        $scope.properties = data.data.properties;
                        $scope.propertiesFiltered = data.data.properties;
                        if (!isMobile) {
                            $timeout(() => {
                                $('#filter-by-input-properties').multipleSelect({filter: true, width: 200});
                                $('#propertyFilter').multipleSelect({filter: true, width: 190, placeholder: $scope.t('properties')});
                                $('#unit-multiselect').multipleSelect({filter: true, width: 60, placeholder: $scope.t('unit_short')});
                            }, 0);
                            $timeout(() => {
                                $('#controls-container').show();
                            }, 500);
                        }
                    });
                };

                $scope.filterProperties = (e) => {
                    const search = $scope.propertiesFilterString.value.toLowerCase();
                    $scope.propertiesFiltered = $scope.properties.filter(propeerty => ~propeerty.name.toLowerCase().indexOf(search));
                }

                $scope.applyFilterToItems = function (customFilter) {
                    var filters = $scope.filterItems.slice();
                    if (isMobile) {
                        filters.push($scope.mobileFilterBy.text);
                    }
                    if (customFilter !== undefined) {
                        filters.push(customFilter);
                    } else if ($scope.isTenants) {
                        filters.push(`${$scope.residentFilter.value}:status`);
                    } else if ($('#filterByPaymentStatus li.active').length > 0) {
                        filters.push('filterByPaymentStatus_' + $('#filterByPaymentStatus li.active').attr('id'));
                        $scope.filterHeaders = $('#filterByPaymentStatus li.active').attr('id');
                    }

                    if (typeof $scope.filterTag != 'undefined' && !isMobile) {
                        localStorage.setItem('filter_tags' + $scope.filterTag, JSON.stringify({
                            header: $scope.filterHeaders,
                            tags: $scope.filterItems,
                        }))
                    }
                    var table_id = typeof $scope.tableIdValue !== 'undefined' ? $scope.tableIdValue : 'dataTable';
                    var table = $('#' + table_id).DataTable();
                    table.search(filters.join('^^')).draw();
                };

                $scope.addFilter = function (filter) {
                    $scope.leaseStatusFilter.value = 'all';
                    if (filter === undefined && $scope.filter_type !== 'properties') {
                        return;
                    }

                    if ($scope.filter_type === 'date') {
                        toastr.error('Please use datepicker for date filters');
                        return;
                    }

                    if ($scope.filter_type === 'properties') {
                        filter = $scope.multi.properties.join('--');
                        $scope.selectedProperty.ids = $scope.selectedProperty.ids.concat($scope.multi.properties);
                        $scope.multi.properties = [];
                        $scope.filter_type = 'other';

                        if (!isMobile) {
                            setTimeout(function () {
                                $('#propertyFilter').multipleSelect('refresh');
                                $('#filter-by-input-properties').multipleSelect('refresh');
                            }, 200);
                        }
                    }


                    if (!_.contains($scope.filterItems, filter)) {
                        if ($scope.filterColumn !== 'all') {
                            filter = filter + ':' + $scope.filterColumn;
                        }

                        $timeout(() => {
                            $scope.$apply(function () {
                                $scope.filterItems.push(filter);
                            });
                            $scope.applyFilterToItems();
                        }, 0);

                    }
                    // Clear input box
                    $scope.filterColumn = 'all';
                    $scope.filterItem = '';
                };

                $scope.clearAllFilters = function () {
                    if (typeof $scope.filterTag != 'undefined') {
                        localStorage.removeItem('filter_tags' + $scope.filterTag)
                    }
                    $scope.filterItems = [];
                    $scope.selectedProperty = {ids: []};
                    $scope.selectedUnit = {id: []};
                    $scope.residentFilter = {value: 'enabled'};
                    $scope.leaseStatusFilter = {value: 'active'};
                    $scope.propertiesFilterString = {value: ''};
                    $scope.tenantActivityFilter = {value: 'all'};
                    $('#unit-multiselect + .ms-parent').addClass('ng-hide');
                    setTimeout(function () {
                        $('#propertyFilter').multipleSelect('refresh');
                    }, 200);
                    $scope.applyFilterToItems();
                };

                $scope.removeFilter = function (filter) {
                    var filter_split = filter.split(":");
                    if (filter_split[1] == 'properties.id') {
                        // var filter_id = filter_split[0];
                        // var index = $scope.selectedProperty.ids.indexOf(filter_id);
                        // $scope.selectedProperty.ids.splice(index,1);
                        $scope.selectedProperty.ids.length = 0; //clear array
                        $('#unit-multiselect + .ms-parent').addClass('ng-hide');
                    }
                    setTimeout(function () {
                        $('#propertyFilter').multipleSelect('refresh');
                    }, 200);

                    if(filter_split[1] === 'status'){
                        $scope.tenantActivityFilter.value = 'all';
                    }

                    _.pull($scope.filterItems, filter);
                    if (typeof $scope.filterTag != 'undefined') {
                        localStorage.removeItem('filter_tags' + $scope.filterTag)
                    }
                    $scope.applyFilterToItems();
                };

                $scope.displayFilterItem = function (filter) {
                    var value = filter;
                    if (filter.indexOf(':') !== -1) {
                        var arr = filter.split(':');
                        if(typeof $scope.filterColumns[arr[1]] === 'undefined'){
                            return value;
                        }
                        value = arr[0] + ':' + $scope.filterColumns[arr[1]];
                    }
                    return value;
                };

                if ($scope.isProperty) {
                    $scope.getProperties();
                }

                setTimeout(function () {
                    if (typeof $scope.filterTag != 'undefined') {

                        let testt = localStorage.getItem('filter_tags' + $scope.filterTag);
                        testt = JSON.parse(testt);

                        if (testt && testt.header && testt.header.length > 0) {
                            console.log('header',testt.header);
                            $('#filterByPaymentStatus li#' + testt.header).addClass('active');

                            setTimeout(function () {
                                $scope.applyFilterToItems();
                                $scope.$apply();
                            }, 200);
                        }

                        if (testt && testt.tags.length > 0) {

                            $scope.filterItems = testt.tags;
                            $scope.filterItems.forEach(item => {
                                const pos = item.indexOf(':properties.id');
                                if (pos > -1) {
                                    $scope.pre_property_ids = item.substr(0, pos).split('--').map(i => +i);
                                }
                                const pos2 = item.indexOf(':unit');
                                if (pos2 > -1) {
                                    $scope.pre_unit_ids = item.substr(0, pos2).split(',');
                                }
                            });

                            if ($scope.pre_property_ids != null) {
                                $scope.selectedProperty.ids = $scope.pre_property_ids;
                                $scope.getUnits($scope.selectedProperty.ids);
                            }

                            setTimeout(function () {
                                $scope.applyFilterToItems();
                                $scope.$apply();
                            }, 200);

                        }

                    }
                }, 200);

                $scope.showFilterModal = (type) => {
                    if (type === 'unit') {
                        $('#mobileManagerFilterUnit').appendTo('body').modal({backdrop: 'static', keyboard: false});
                    }
                    if (type === 'status') {
                        $('#mobileManagerFilterStatus').appendTo('body').modal({backdrop: 'static', keyboard: false});
                    }
                    if (type === 'properties') {

                        if ($scope.properties.Length > 0) {
                            $scope.getProperties();
                        }
                        $('#mobileManagerFilterProperties').appendTo('body').modal({backdrop: 'static', keyboard: false});
                    }
                }

                $timeout(function () {
                    $('#filterByPaymentStatus li').on('click', function () {
                        $scope.filterHeaders = $(this).attr('id');
                        $scope.applyFilterToItems('filterByPaymentStatus_' + $(this).attr('id'));
                    });
                });

                const checkCheckboxes = function () {
                    let properties = $scope.properties;

                    properties.forEach(function (property, i, properties) {
                        $scope.selectedProperty.ids.push(property.id);
                        property.isChecked = true;
                    })

                    $(".propertyCheckbox").each(function() {
                        $(this).prop('checked', true);
                    });
                };

                const uncheckCheckboxes = function () {
                    $(".propertyCheckbox").each(function() {
                        $(this).prop('checked', false);
                    });

                    $scope.selectedProperty.ids = [];
                };

                $scope.addOrRemoveAllProperties = function () {
                    if ($('#selectAllProps').is(':checked')) {
                        checkCheckboxes();
                    } else {
                        uncheckCheckboxes();
                    }
                }

                $scope.addOrRemoveProperty = function(property) {
                    if (!$scope.selectedProperty.ids.includes(property.id)) {
                        $scope.selectedProperty.ids.push(property.id);
                    } else {
                        let index = $scope.selectedProperty.ids.indexOf(property.id);
                        if (index > -1) {
                            $scope.selectedProperty.ids.splice(index, 1);
                        }
                    }
                }

                const checkUnitCheckboxes = function () {
                    let units = $scope.units;

                    units.forEach(function (unit, i, units) {
                        $scope.selectedUnit.id.push(unit.unit);
                        unit.isChecked = true;
                    })

                    $(".unitCheckbox").each(function() {
                        $(this).prop('checked', true);
                    });
                };

                const uncheckUnitCheckboxes = function () {
                    $(".unitCheckbox").each(function() {
                        $(this).prop('checked', false);
                    });

                    $scope.selectedUnit.id = [];
                };

                $scope.addOrRemoveAllUnits = function() {
                    if ($('#selectAllUnits').is(':checked')) {
                        checkUnitCheckboxes();
                    } else {
                        uncheckUnitCheckboxes();
                    }
                }

                $scope.addOrRemoveUnit = function(unit) {
                    if (!$scope.selectedUnit.id.includes(unit.unit)) {
                        $scope.selectedUnit.id.push(unit.unit);
                    } else {
                        let index = $scope.selectedUnit.id.indexOf(unit.unit);
                        if (index > -1) {
                            $scope.selectedUnit.id.splice(index, 1);
                        }
                    }
                }

                $scope.openCloseFilter = function () {
                    $('.filter_buttons').toggleClass('active');
                }

                $scope.mobileFilterBy = {text: []};

                $scope.getProspectStatuses = () => {
                    $http.get(pqDomain + '/api/v1/prospect/get-stage-list').then(function success(data){
                        $scope.prospectStatuses = data.data.data;
                    }, function error(data){
                        toastr.error(data.errors, 'Error retrieving prospect statuses');
                    })
                }
                if($scope.filterTag == 'prospects-list'){
                    $scope.getProspectStatuses();
                }
            }
        };
    }
})();
