(function () {
    "use strict";

    angular
        .module('pq.property.data')
        .factory('propertyDataService', propertyDataService);

    /* @ngInject */
    function propertyDataService($http, Restangular, $upload, _, $state) {
        return {
            getProperties: getProperties,
            getPropertiesQuery: getPropertiesQuery,
            getTenants: getTenants,
            getPropertyModel: getPropertyModel,
            getProperty: getProperty,
            addProperty: addProperty,
            editProperty: editProperty,
            deleteProperty: deleteProperty,
            getSampleCSV: getSampleCSV,
            getAllProperties: getAllProperties
        };

        function getPropertyModel() {
            return Restangular.all('properties');
        }

        function getProperties() {
            return Restangular.all('properties').getList()
                .then(function (obj) {
                    return obj;
                });
        }

        function getPropertiesQuery(searchKey) {
            return $http.get('api/v1/properties/properties-search?search=' + searchKey)
                .then(function (obj) {
                    return obj.data.data;
                });
        }

        function getProperty(id) {
            return $http.get('api/v1/properties/' + id)
                .then(function (obj) {
                    return obj.data.data;
                });
        }

        function getTenants(propertyId) {
            return $http.get('api/v1/properties/' + propertyId + '/tenants')
                .then(function (obj) {
                    return obj.data.data;
                },
                function (probl) {
                    console.log(probl);
                });
        }

        function addProperty(property) {
            saveProperty(property, "POST");
        }

        function editProperty(property) {
            saveProperty(property, "PUT","");
        }

        function saveProperty(property, httpMethod) {

            return;
        }

        function deleteProperty(id) {
            return $http.delete('api/v1/properties/'+id)
                .then(function(res) {
                    $state.go('main.properties')
                });
        }

        function getSampleCSV() {
            window.location = '/api/v1/properties/download/sample-csv';
            return;
            /*
            return $http.get('/api/v1/properties/download/sample-csv')
                .then(function (file) {
                    var hiddenElement = document.createElement('a');

                    hiddenElement.href = 'data:attachment/csv,' + encodeURI(file.data);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = 'sample.csv';
                    hiddenElement.click();
                });
            */
        }

        function getAllProperties()
        {
            return $http.get('api/v1/properties/all')
                .then(function (obj) {
                    return obj.data;
                },
                function (probl) {
                    console.log(probl);
                });
        }
    }

})();