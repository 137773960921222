(function() {

    'use strict';

    // polyfill solution for datetime conversion
    if ( !Date.prototype.toISOString ) {
        ( function() {

            function pad(number) {
                if ( number < 10 ) {
                    return '0' + number;
                }
                return number;
            }

            Date.prototype.toISOString = function() {
                return this.getUTCFullYear() +
                    '-' + pad( this.getUTCMonth() + 1 ) +
                    '-' + pad( this.getUTCDate() ) +
                    'T' + pad( this.getUTCHours() ) +
                    ':' + pad( this.getUTCMinutes() ) +
                    ':' + pad( this.getUTCSeconds() ) +
                    '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                    'Z';
            };

        }() );
    }

    /**
     * You first need to create a formatting function to pad numbers to two digits…
     **/
    function twoDigits(d) {
        if(0 <= d && d < 10) return "0" + d.toString();
        if(-10 < d && d < 0) return "-0" + (-1*d).toString();
        return d.toString();
    }

    /**
     * …and then create the method to output the date string as desired.
     * Some people hate using prototypes this way, but if you are going
     * to apply this to more than one Date object, having it as a prototype
     * makes sense.
     **/
    Date.prototype.toMysqlFormat = function() {
        return this.getUTCFullYear() + "-" + twoDigits(1 + this.getUTCMonth()) + "-" + twoDigits(this.getUTCDate()) + " " + twoDigits(this.getUTCHours()) + ":" + twoDigits(this.getUTCMinutes()) + ":" + twoDigits(this.getUTCSeconds());
    };

}());